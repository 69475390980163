import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiStudents from "../../../services/apiStudents";

export const studentAttendanceSlice = createSlice({
  name: "studentAttendance",
  initialState: {
    list: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },

  reducers: {
    setStudentAttendance: (state, { payload }) => {
      state.list.data = payload;
    },
    setLoading: (state, { payload }) => {
      state.list.isLoading = payload;
    },
    setCleanErrorsStudentCoursesAttenances: (state) => {
      state.list.errors = [];
    },
  },
  extraReducers(builder) {
    //Cargar asistencia
    builder.addCase(tryTickStudentAttendance.pending, (state) => {
      state.list.status = "loading";
      state.list.isLoading = true;
      state.list.errors = [];
    });
    builder.addCase(
      tryTickStudentAttendance.fulfilled,
      (state, { payload }) => {
        state.list.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.list.errors = errors;

        if (payload.data.isValid === true) {
          state.list = { ...payload.data.data };
        }

        state.list.isLoading = false;
      }
    );
    builder.addCase(tryTickStudentAttendance.rejected, (state, { payload }) => {
      state.list.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.list.errors = errors;
      state.list.isLoading = false;
    });

    //Listar Asistencia
    builder.addCase(tryGetStudentAttendance.pending, (state) => {
      state.list.status = "loading";
      state.list.isLoading = true;
      state.list.errors = [];
    });
    builder.addCase(tryGetStudentAttendance.fulfilled, (state, { payload }) => {
      state.list.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.list.errors = errors;

      if (payload.data.isValid === true) {
        state.list = { ...payload.data };
      }

      state.list.isLoading = false;
    });
    builder.addCase(tryGetStudentAttendance.rejected, (state, { payload }) => {
      state.list.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"

      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.list.errors = errors;
      state.list.isLoading = false;
    });
  },
});

export const selectStudentAttendance = (state) => state.studentAttendance.list;

export const {
  setStudentAttendance,
  setLoading,
  setCleanErrorsStudentCoursesAttenances,
} = studentAttendanceSlice.actions;

export default studentAttendanceSlice.reducer;

export const tryCleanErrorsStudentCoursesAttenances = () => (dispatch) => {
  dispatch(setCleanErrorsStudentCoursesAttenances());
};

export const tryGetStudentAttendance = createAsyncThunk(
  "studentAttendance/tryGetStudentAttendance",

  async ({ model, beforeToday }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getStudentAttendance(
        model,
        beforeToday
      );

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryTickStudentAttendance = createAsyncThunk(
  "studentAttendance/tryTickStudentAttendance",
  async ({ studentId, courseId, model }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.postTickStudentAttendance(
        studentId,
        courseId,
        model
      );
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
