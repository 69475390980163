import React from "react";
import { Container, Grid } from "@mui/material";
import CoursesAvailables from "../../components/signup/courses-availables";

const CoursesAvailablesPage = () => {
  return (
    <Container fixed>
      <Grid
        container
        spacing={2}
        sx={{
          margin: 2,
        }}
      >
        <Grid
          sx={{
            backgroundColor: "primary.contrastText",
          }}
          item
          xs={12}
          md={12}
        >
          <CoursesAvailables />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoursesAvailablesPage;
