import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTypeTable,
  selectMaintenances,
} from "../../../store/slices/maintenances";
import {
  selectDownloadedFile,
  selectTeacherCliCaseBill,
  selectTeacherNewCliCaseBill,
  tryDownloadFile,
  tryGetTeacherCliCaseBill,
  tryGetTeacherCliCasesSummary,
  tryGetTeacherCliprecasesHeader,
  tryPostTeacherClicase,
} from "../../../store/slices/teacher-payments";
import { selectCurrentUser } from "../../../store/slices/currentUser";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Alert,
  Button,
  List,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
  AlertTitle,
} from "@mui/material";
import {
  CloudUploadOutlined,
  UploadFileOutlined,
  Close,
} from "@mui/icons-material";
import ModalMessages from "../../layout/modal-messages";
import convertToSelectList from "../../../components/layout/functions/convert-to-select-list";
import isNullOrZero from "../../../services/functions/isNullOrZero";
import Spinner from "../../layout/spinner";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { NumericFormat } from "react-number-format";
import BillingDrawerSkeleton from "../skeleton/billingDrawerSkeleton";

const validate = (values) => {
  const errors = {};
  if (!values.month || values.month === "") errors.month = "Requerido";

  if (!values.invoice || values.invoice === "") errors.invoice = "Requerido";

  if (!values.reference || values.reference === "")
    errors.reference = "Requerido";

  if (!values.amount || values.amount === "") errors.amount = "Requerido";

  if (values.roleId !== 1 && values.clicaseId == 0) {
    if (!values.file || values.file === "") errors.file = "Requerido";
  }

  return errors;
};

const BillingDrawer = ({
  setOpenBillingDrawer,
  setOpenDialog,
  openBillingDrawer,
  clientId,
  clicaseid,
  month,
  cliPrecasesid,
  businessId,
  isSummary,
}) => {
  const dispatch = useDispatch();

  //Trae informacion del rol del usuario
  const { roleId } = useSelector(selectCurrentUser);

  //TRAE LAS TABLAS CATALOGO
  const typeTables = useSelector(selectMaintenances);

  //TRAE LA ULTIMA FACTURA CARGADA O MODIFICADA
  const newCliCaseBill = useSelector(selectTeacherNewCliCaseBill);

  //TRAE EL DETALLE DE UNA FACTURA
  const cliCaseBill = useSelector(selectTeacherCliCaseBill);

  const selDownloadedFile = useSelector(selectDownloadedFile);

  const { data } = cliCaseBill;

  useEffect(() => {
    (async () => {
      const action = await dispatch(tryGetTeacherCliCaseBill(clicaseid));
      if (action.payload.data.isValid) {
        const { data } = action.payload.data;

        formik.setValues({
          clicaseId: !isNullOrZero(data.clicaseId) ? data.clicaseId : clicaseid,
          cliPrecaseId: !isNullOrZero(data.cliPrecaseId) ? data.cliPrecaseId : cliPrecasesid, //prettier-ignore
          clientId: !isNullOrZero(data.clientId) ? data.clientId : clientId,
          month: isNullOrZero(data.clicaseId) ? caseMonth() : data?.month,
          invoice: !isNullOrZero(data.invoice) ? data.invoice : "",
          reference: !isNullOrZero(data.reference) ? data.reference : "",
          comments: !isNullOrZero(data.comments) ? data.comments : "",
          amount: !isNullOrZero(data.amount) ? data.amount : "",
          statusCliCase: data.statusCliCase ?? 2,
          file: data.file,
          reasons: data.statusCliCase !== 4 ? "" : data.reasons,
          roleId: roleId,
        });
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(fetchTypeTable(57)); //TBLMONTHS
    dispatch(fetchTypeTable(43)); // TBLSTATUSCASES
  }, []);

  //MANEJO DE LOS MESES VIENEN CON PALABRAS LA API RECIBE NUMEROS
  const caseMonth = () => {
    let cMonth;

    switch (month) {
      case "Enero":
        cMonth = 1;
        break;
      case "Febrero":
        cMonth = 2;
        break;
      case "Marzo":
        cMonth = 3;
        break;
      case "Abril":
        cMonth = 4;
        break;
      case "Mayo":
        cMonth = 5;
        break;
      case "Junio":
        cMonth = 6;
        break;
      case "Julio":
        cMonth = 7;
        break;
      case "Agosto":
        cMonth = 8;
        break;
      case "Septiembre":
        cMonth = 9;
        break;
      case "Octubre":
        cMonth = 10;
        break;
      case "Noviembre":
        cMonth = 11;
        break;
      case "Diciembre":
        cMonth = 12;
        break;
    }

    return cMonth;
  };

  //DESCARGAR EL ARCHIVO
  const fileDownload = async (cliprecasesid) => {
    const action = await dispatch(tryDownloadFile(cliprecasesid));

    if (action.payload.data.isValid) {
      var attachmentElement = document.createElement("a");

      attachmentElement.href = action.payload.data.data.file;

      attachmentElement.download = action.payload.data.data.name;

      attachmentElement.click();
    }
  };

  //ARCHIVO
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 10000000,
    onDropAccepted: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        formik.setFieldValue("file", base64data);
      };
    },
  });

  const files = acceptedFiles.map((filename) => (
    <ListItem>
      <ListItemIcon>
        <UploadFileOutlined color="primary" />
      </ListItemIcon>
      <ListItemText key={filename.path} primary={filename.path} />
    </ListItem>
  ));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      clicaseId: clicaseid,
      cliPrecaseId: cliPrecasesid,
      clientId: clientId,
      month: caseMonth(),
      invoice: "",
      reference: "",
      comments: "",
      amount: "",
      statusCliCase: 2,
      file: "",
      reasons: "",
      roleId: roleId,
    },
    validate,
    onSubmit: async (values) => {
      const { roleId, ...request } = values;
      //Si el usuario no es de backOffice al presentar o modificar una factura se le pasa el ID 2 que es factura presentada

      if (roleId !== 1) request.statusCliCase = 2;
      const action = await dispatch(tryPostTeacherClicase(request));
      if (action.payload.data.isValid) {
        dispatch(
          tryGetTeacherCliprecasesHeader({
            teacherId: businessId,
            isFirstOrLastRecords: isSummary,
          })
        );
        dispatch(tryGetTeacherCliCasesSummary(businessId));
        setOpenDialog(true);
        setOpenBillingDrawer(false);
      }
    },
  });

  return (
    <>
      <Drawer
        anchor="right"
        open={openBillingDrawer}
        PaperProps={{
          sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
        }}
        onClose={() => setOpenBillingDrawer(false)}
        sx={{
          zIndex: 1203,
          width: 500,
          flexShrink: 0,
        }}
      >
        <Grid container p={1}>
          <Grid item xs={10}>
            <Typography
              align="left"
              sx={{
                paddingLeft: 2,
                paddingRight: 5,
                paddingTop: 4,
                fontWeight: 700,
                fontSize: 18,
              }}
            >
              Adjuntar/Modificar Factura
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setOpenBillingDrawer(false)}
            >
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ paddingLeft: 2, paddingBottom: 5 }}>
            <Typography variant="h7" align="center">
              Por favor, complete información en cada campo que se solicite en
              el formulario
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          spacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          p={2}
          sx={{ backgroundColor: "white" }}
        >
          {cliCaseBill.isLoading || newCliCaseBill.isLoading ? (
            <BillingDrawerSkeleton />
          ) : (
            <>
              <Grid item xs={12}>
                <InputLabel id="month-label">Mes</InputLabel>
                <Select
                  labelId="month-label"
                  name="month"
                  label="Mes"
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="off"
                  disabled
                  onChange={(e) => {
                    const { value } = e.target;
                    formik.setFieldValue("month", value);
                  }}
                  value={formik.values.month}
                  error={Boolean(formik.touched.month && formik.errors.month)}
                  helperText={formik.touched.month && formik.errors.month}
                >
                  {typeTables.TBLMONTHS
                    ? convertToSelectList(typeTables.TBLMONTHS.data).map(
                        (x) => (
                          <MenuItem key={x.value} value={x.value}>
                            {x.text}
                          </MenuItem>
                        )
                      )
                    : null}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="paymentCode"
                  label="Referencia Mes"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;
                    formik.setFieldValue("reference", value);
                  }}
                  value={formik.values.reference}
                  error={Boolean(
                    formik.touched.reference && formik.errors.reference
                  )}
                  helperText={
                    formik.touched.reference && formik.errors.reference
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="paymentCode"
                  label="Nro. de Factura"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  onChange={(e) => {
                    const { value } = e.target;
                    formik.setFieldValue("invoice", value);
                  }}
                  value={formik.values.invoice}
                  error={Boolean(
                    formik.touched.invoice && formik.errors.invoice
                  )}
                  helperText={formik.touched.invoice && formik.errors.invoice}
                />
              </Grid>
              <Grid item xs={12} whiteSpace="normal">
                <TextField
                  id="complement"
                  label="Comentarios"
                  multiline
                  rows={5}
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;
                    formik.setFieldValue("comments", value);
                  }}
                  value={formik.values.comments}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <NumericFormat
                    variant="outlined"
                    label="Monto"
                    size="small"
                    name="paymentAmount"
                    thousandSeparator="."
                    decimalSeparator=","
                    isNumericString
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    InputLabelProps={{ shrink: true }}
                    customInput={TextField}
                    value={formik.values.amount}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      formik.setFieldValue("amount", floatValue);
                    }}
                    error={formik.touched.amount && formik.errors.amount}
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </FormControl>
              </Grid>
              {clicaseid !== 0 && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={() => fileDownload(data.cliPrecaseId)}
                    disabled={
                      newCliCaseBill.isLoading ||
                      cliCaseBill.isLoading ||
                      selDownloadedFile.isLoading
                    }
                    sx={{
                      marginTop: 0,
                      marginBottom: 0,
                      width: "100%",
                      marginRight: 2,
                    }}
                  >
                    {(newCliCaseBill.isLoading ||
                      cliCaseBill.isLoading ||
                      selDownloadedFile.isLoading) && (
                      <Spinner color="primary" />
                    )}
                    Descargar archivo
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                {roleId == 1 && (
                  <>
                    <InputLabel id="month-label">Estado</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        name="statusCliCase"
                        id="statusCliCase"
                        value={formik.values.statusCliCase}
                        size="small"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.state &&
                          Boolean(formik.errors.statusCliCase)
                        }
                      >
                        {typeTables.TBLSTATUSCASES &&
                          convertToSelectList(typeTables.TBLSTATUSCASES.data)
                            .filter((x) => {
                              // Facturado
                              if (formik.values.statusCliCase === 2) {
                                return [2, 3, 4].includes(x.value);
                              }
                              //Aprobado O Rechazada
                              if (
                                formik.values.statusCliCase === 3 ||
                                formik.values.statusCliCase === 4
                              ) {
                                return [3, 4].includes(x.value);
                              }
                              //Transferido a tesorería
                              if (formik.values.statusCliCase === 5) {
                                return [5, 6, 7].includes(x.value);
                              }
                              //Pagado
                              if (formik.values.statusCliCase === 6) {
                                return [6].includes(x.value);
                              } 
                              //Pago rechazado - Backoffice
                              if (formik.values.statusCliCase === 7 && roleId == 1) {
                                return [7].includes(x.value);
                              } else {
                                return x.value != 1 && x.value != 5;
                              } 
                            })
                            .map((x) => (
                              <MenuItem key={x.value} value={x.value}>
                                {x.text}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                {formik.values.statusCliCase == 4 && roleId == 1 && (
                  <TextField
                    id="reasons"
                    label="Motivo del rechazo"
                    multiline
                    rows={5}
                    fullWidth
                    onChange={(e) => {
                      const { value } = e.target;
                      formik.setFieldValue("reasons", value);
                    }}
                    value={formik.values.reasons}
                  />
                )}
              </Grid>
              {formik.values.statusCliCase == 4 && roleId !== 1 && (
                <Grid item xs={12}>
                  <Alert
                    variant="filled"
                    severity="error"
                    sx={{ textAlign: "left" }}
                    icon={false}
                  >
                    <AlertTitle>RECHAZADO</AlertTitle>
                    {formik.values.reasons}
                  </Alert>
                </Grid>
              )}
              {roleId !== 1 && (
                <Grid item xs={12} whiteSpace="initial">
                  <Alert
                    severity="warning"
                    sx={{ textAlign: "center", color: "#ffab00" }}
                    icon={false}
                  >
                    Adjunte el archivo de respaldo asociado al Pago, puede ser
                    una imágen o un .pdf, por ejemplo.
                  </Alert>
                </Grid>
              )}

              {roleId !== 1 && (
                <Grid item xs={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      border: "4px",
                      borderRadius: "12px",
                      borderStyle: "solid",
                      borderColor: "#85BFE6",
                      marginTop: 2,
                    }}
                  >
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                          <Grid
                            item
                            xs={1}
                            sm={2}
                            md={2}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CloudUploadOutlined
                              color="primary"
                              fontSize="large"
                            />
                          </Grid>
                          <Grid item xs={3} sm={6} md={6} whiteSpace="initial">
                            <Typography
                              color="primary"
                              variant="subtitle2"
                              sx={{ fontWeight: 700, paddingTop: 5 }}
                            >
                              Seleccione un archivo o arrástrelo hasta acá
                            </Typography>

                            <Typography
                              color="primary"
                              variant="subtitle2"
                              sx={{ paddingTop: 1, paddingBottom: 5 }}
                            >
                              JPG, PNG o PDF, tamaño del archivo no mayor a 10MB
                            </Typography>
                          </Grid>{" "}
                          <Grid
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Button
                              variant="outline"
                              color="secondary"
                              sx={{ border: 2 }}
                            >
                              Buscar
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                      <List sx={{ width: "100%" }}>{files}</List>
                    </section>
                  </Paper>
                  <FormHelperText
                    htmlFor="file"
                    error={formik.touched.file && !!formik.errors.file}
                  >
                    {formik.errors.file}
                  </FormHelperText>
                </Grid>
              )}
              <Grid item sx={{ width: "100%", textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={formik.handleSubmit}
                  disabled={newCliCaseBill.isLoading || cliCaseBill.isLoading}
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    width: 100,
                    marginRight: 2,
                  }}
                >
                  {(newCliCaseBill.isLoading || cliCaseBill.isLoading) && (
                    <Spinner color="primary" />
                  )}
                  Guardar
                </Button>
                <Button
                  variant="outline"
                  color="error"
                  disabled={newCliCaseBill.isLoading || cliCaseBill.isLoading}
                  sx={{
                    border: 2,
                    marginTop: 2,
                    marginBottom: 2,
                    width: 100,
                    color: "red",
                  }}
                  onClick={() => setOpenBillingDrawer(false)}
                >
                  Cancelar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        {newCliCaseBill.errors && newCliCaseBill.errors.length > 0 && (
          <ModalMessages errors={newCliCaseBill.errors} />
        )}
        {cliCaseBill.errors && cliCaseBill.errors.length > 0 && (
          <ModalMessages errors={cliCaseBill.errors} />
        )}
        {selDownloadedFile.errors && selDownloadedFile.errors.length > 0 && (
          <ModalMessages errors={selDownloadedFile.errors} />
        )}
      </Drawer>
    </>
  );
};

export default BillingDrawer;
