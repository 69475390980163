import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../functions/use-auth";
import "./index.css";

const PagePublicLayout = ({ component, anonymous }) => {
  const navigate = useNavigate();
  const authorized = useAuth();

  useEffect(() => {
    if (!authorized && !anonymous) navigate("/login");
  }, [authorized]);

  return <>{component}</>;
};

export default PagePublicLayout;
