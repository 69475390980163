import { tryCleanErrorsBackCourse } from "../../store/slices/back-course";
import { tryCleanErrorsBackofficeCourses } from "../../store/slices/backoffice-courses";
import { tryCleanErrorsBackofficeStudents } from "../../store/slices/backoffice-students";
import { tryCleanErrorsBackofficeTeachers } from "../../store/slices/backoffice-teachers";
import { tryCleanErrorsCatalogs } from "../../store/slices/catalogs";
import { tryCleanErrorsCoursesAvailables } from "../../store/slices/courses-availables";
import { tryCleanErrorsCurrentUser } from "../../store/slices/currentUser";
import { tryCleanErrorsMaintenances } from "../../store/slices/maintenances";
import { tryCleanPassErrors } from "../../store/slices/password";
import { tryCleanErrors } from "../../store/slices/personal-data";
import { tryCleanErrorsStudentCourses } from "../../store/slices/student-courses";
import { tryCleanErrorsStudentCoursesAttenances } from "../../store/slices/student-courses-attendances";
import { tryCleanErrorsStudentCuil } from "../../store/slices/student-cuil";
import { tryCleanErrorsStudentPaymentNotification } from "../../store/slices/student-paymentNotification";
import { tryCleanErrorsStudentPayments } from "../../store/slices/student-payments";
import { tryCleanErrorsStudentTickets } from "../../store/slices/student-tickets";
import { tryCleanErrorsStudents } from "../../store/slices/students";
import { tryCleanErrorsTeacher } from "../../store/slices/teacher";
import { tryCleanErrorsTeacherCourses } from "../../store/slices/teacher-courses";
import { tryCleanErrorsTeacherPayments } from "../../store/slices/teacher-payments";
import { tryCleanErrorsTeacherTickets } from "../../store/slices/teacher-tickets";
import { tryCleanErrorsUsers } from "../../store/slices/users";

export const CleanAllErrors = (dispatch) => {
  dispatch(tryCleanErrorsBackCourse());
  dispatch(tryCleanErrorsBackofficeCourses());
  dispatch(tryCleanErrorsBackofficeTeachers());
  dispatch(tryCleanErrorsCatalogs());
  dispatch(tryCleanErrorsCoursesAvailables());
  dispatch(tryCleanErrorsCurrentUser());
  dispatch(tryCleanErrorsMaintenances());
  dispatch(tryCleanErrorsStudentCourses());
  dispatch(tryCleanErrorsStudentCoursesAttenances());
  dispatch(tryCleanErrorsStudentCuil());
  dispatch(tryCleanErrorsStudentPaymentNotification());
  dispatch(tryCleanErrorsStudentPayments());
  dispatch(tryCleanErrorsStudentTickets());
  dispatch(tryCleanErrorsStudents());
  dispatch(tryCleanErrorsTeacher());
  dispatch(tryCleanErrorsTeacherCourses());
  dispatch(tryCleanErrorsTeacherPayments());
  dispatch(tryCleanErrorsTeacherTickets());
  dispatch(tryCleanErrorsUsers());
  dispatch(tryCleanErrors());
  dispatch(tryCleanPassErrors());
  dispatch(tryCleanErrorsBackofficeStudents());
};
