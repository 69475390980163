import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/slices/currentUser";
import {
  selectTeacherCliCasesSummary,
  tryGetTeacherCliCasesSummary,
} from "../../store/slices/teacher-payments";
import { Alert, AlertTitle, Grid, Typography } from "@mui/material";
import ModalMessages from "../layout/modal-messages";
import { Container } from "@mui/system";
import moment from "moment";
import TeacherPaymentsSummarySkeleton from "./skeleton/paymentsSummarySkeleton";

const TeacherPaymentsSummary = ({ businessId }) => {
  const dispatch = useDispatch();
  const { roleId } = useSelector(selectCurrentUser);

  //TRE EL DETALLE DEL SUMMARY
  const clicasesSummary = useSelector(selectTeacherCliCasesSummary);
  const { data } = clicasesSummary;

  useEffect(() => {
    dispatch(tryGetTeacherCliCasesSummary(businessId));
  }, []);

  return (
    <Container>
      <Grid sx={{ pt: 3, pb: 3 }} container spacing={3}>
        {clicasesSummary.isLoading ? (
          <TeacherPaymentsSummarySkeleton />
        ) : (
          <>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 20 }}>
                <strong style={{ fontWeight: "bold" }}>Resumen</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="warning">
                <AlertTitle>Asistencia</AlertTitle>
                {roleId !== 1 ? (
                  <>
                    Por favor, verificar facturas rechazadas o liquidaciones
                    pendientes de facturación.
                  </>
                ) : (
                  <>
                    Por favor, verificar facturas presentadas por el profesor,
                    pendientes de tratamiento.
                  </>
                )}{" "}
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                Última liquidación:{" "}
                {data?.lastSettlementDate
                  ? moment(data.lastSettlementDate).format("ll")
                  : "Sin información"}
              </Typography>
              <Typography sx={{ color: "grey" }}>
                $
                {data
                  ? data.lastSettlement?.toLocaleString("es-ES", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    })
                  : "Sin información"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                Ultima factura:{" "}
                {data.lastBillingDate
                  ? moment(data.lastBillingDate).format("ll")
                  : "Sin información"}
              </Typography>
              <Typography sx={{ color: "grey" }}>
                $
                {data
                  ? data.lastBilling?.toLocaleString("es-ES", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    })
                  : "Sin información"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                Último pago recibido:{" "}
                {data.lastPaymentReceivedDate
                  ? moment(data.lastPaymentReceivedDate).format("ll")
                  : "Sin información"}
              </Typography>
              <Typography sx={{ color: "grey" }}>
                $
                {data
                  ? data.lastPaymentReceived?.toLocaleString("es-ES", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    })
                  : "Sin información"}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                Total adeudado:${" "}
                {data
                  ? data.totalDebt?.toLocaleString("es-ES", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    })
                  : "Sin información"}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>

      {clicasesSummary.errors && clicasesSummary.errors.length > 0 && (
        <ModalMessages errors={clicasesSummary.errors} />
      )}
    </Container>
  );
};

export default TeacherPaymentsSummary;
