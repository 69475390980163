import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Switch,
  Typography,
  AccordionDetails,
  Chip,
  Tooltip,
  Link,
} from "@mui/material";
import Slider from "react-slick";
import {
  SchoolOutlined,
  QueryBuilderOutlined,
  ExpandMore,
  RequestQuoteOutlined,
  InfoOutlined,
  NewspaperOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { Stack } from "@mui/system";
import {
  selectCoursesAvailables,
  selectCourseAvailable,
  selectedCourse,
  tryCoursesAvailables,
  tryValidateStudentCourses,
  tryDownloadCourseInformation,
} from "../../../store/slices/courses-availables";
import { selectUser, tryUpdateUser } from "../../../store/slices/users";
import "pure-react-carousel/dist/react-carousel.es.css";
import ModalRegistrationLimit from "../courses-availables/modal";
import Spinner from "../../layout/spinner";
import CoursesAvailablesSkeleton from "./skeleton";
import ModalMessages from "../../layout/modal-messages";
import dayjs from "dayjs";
import HelperMail from "../../layout/helper-mail";

//TRAER CURSOS DISPONIBLES

const CoursesAvailables = ({
  currentStep,
  setCurrentStep,
  studentId,
  isMobile,
}) => {
  const dispatch = useDispatch();

  //Estado de la metodología
  const [methodology, setMethodology] = useState(false);

  const SampleNextArrow = (props) => {
    const { className, _, onClick } = props;
    return (
      <div
        className={className}
        style={{
          display: "block",
          color: "#22577A",
          fontSize: 35,
          marginRight: 5,
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, _, onClick } = props;
    return (
      <div
        className={className}
        style={{
          display: "block",
          color: "#22577A",
          fontSize: 35,
          marginLeft: -17,
        }}
        onClick={onClick}
      />
    );
  };

  const settingsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    dots: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // LIMITE DE CURSOS EN LOS QUE SE PUEDE INSCRIBIR
  const limitCoursesQuantity = process.env.REACT_APP_LIMITCOURSES;
  const {
    isLoading,
    errors,
    data: coursesAvailables,
  } = useSelector(selectCoursesAvailables);
  const { isLoading: isLoadingCourse } = useSelector(selectCourseAvailable);
  const user = useSelector(selectUser);

  // CONTADOR DE CURSOS SELECCIONADOS Y/O EN CURSO
  const countCourses = coursesAvailables.filter((x) => x.isSelected).length;

  //CARGAR CURSOS SELECCIONADOS EN EL ESTADO Y PASAR A PAGINA SIGUIENTE
  const updateCourses = async (values) => {
    const userRequest = { ...user };

    userRequest.clients = {
      ...userRequest.clients,
      students: {
        ...userRequest.clients.students,
        studentCourses: coursesAvailables.filter((x) => x.isSelected),
      },
    };

    dispatch(tryUpdateUser(userRequest));

    const validateStudentCourses = await dispatch(
      tryValidateStudentCourses(userRequest)
    );

    if (validateStudentCourses.payload.data.isValid) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    dispatch(tryCoursesAvailables({ isVirtual: false, studentId }));
  }, []);

  const onSwitchMode = (isVirtual) => {
    setMethodology(isVirtual);
    dispatch(tryCoursesAvailables({ isVirtual, studentId }));
  };

  const onClickDownloadCourseInformation = async (courseId) => {
    var responseFileDownload = await dispatch(
      tryDownloadCourseInformation(courseId)
    );
    if (responseFileDownload.payload.isValid)
      window.open(responseFileDownload.payload.data.file);
  };

  const onClickCourse = (course) => {
    const index = coursesAvailables.indexOf(course);
    dispatch(selectedCourse({ index: index, isSelected: !course.isSelected }));
  };

  const Item = ({ item }) => {
    return (
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0px 5px 5px 5px",
          marginBottom: "10px",
          marginTop: "30px",
          gap: "15px",
          width: "310px",
          backgroundColor: "white",
          boxShadow: "5px 6px 5px 0px rgba(0,0,0,0.17)",
          borderRadius: "6px",
          height: "auto",
          id: "container",
          marginLeft: "1px",
          margingRight: "1px",
          flexWrap: "wrap",
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={1}
            pt={2}
            pb={2}
            direction={"row"}
            justifyContent="space-between"
          >
            <Grid item>
              <Tooltip
                title={
                  methodology
                    ? item.briefDescription.toString().toUpperCase()
                    : item.categorys.description.toString().toUpperCase()
                }
              >
                <Chip
                  label={
                    methodology
                      ? item.briefDescription.toString().toUpperCase()
                      : item.categorys.description.toString().toUpperCase()
                  }
                  sx={{
                    "& .MuiChip-label": {
                      height: "auto",
                      whiteSpace: "normal",
                      maxWidth: "260px",
                    },
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Descargar Información">
                <Link
                  className="hover-pointer"
                  onClick={async () =>
                    await onClickDownloadCourseInformation(item.courseId)
                  }
                >
                  <FileDownloadOutlined />
                </Link>
              </Tooltip>
            </Grid>
          </Grid>
          <Typography variant="h6" color="#22577A" pb={1.5}>
            <strong>{item.briefDescription}</strong>
          </Typography>
          {item.additionalDocs && (
            <Grid item xs={12}>
              <Alert
                severity="warning"
                variant="filled"
                style={{ textAlign: "left" }}
              >
                ¡Atención! <br />
                La carrera de guardavidas está abierta para inscripciones, pero
                tené en cuenta que solo se admiten participantes menores de 40
                años. <br />
                Si tenés más de 40 años y deseás inscribirte, aún podés hacerlo,
                pero deberás presentar antecedentes deportivos para ser
                considerado.
              </Alert>
            </Grid>
          )}
          {!methodology && (
            <Accordion
              defaultExpanded="true"
              sx={{
                border: 0,
                boxShadow: "none",
                backgroundColor: "white",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography pl={2} pr={8.3}>
                  <strong>
                    {" "}
                    <InfoOutlined /> Programa
                  </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="subtitle2"
                  className="information-program"
                  pt={0}
                  pb={1}
                  mt={1}
                >
                  {item.program}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ bgcolor: "secondary.light" }}
        />
        <Grid item xs={12} sm={12} md={12} sx={{ width: "inherit" }}>
          <Accordion
            sx={{
              border: 0,
              boxShadow: "none",
              backgroundColor: "white",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={<ExpandMore />}
            >
              <Typography variant="subtitle1" pl={2}>
                <strong>
                  <QueryBuilderOutlined /> Horarios de Cursada
                </strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ minHeight: 75 }}>
              {item.courseGroups.length > 0 &&
                item.courseGroups[0].courseGroupsSchedules && (
                  <>
                    {item.courseGroups[0].courseGroupsSchedules.map((x) => (
                      <Typography
                        variant="subtitle2"
                        textAlign="left"
                        pt={0}
                        pb={0}
                        pr={4}
                        pl={1}
                      >
                        <strong>{x.weekDay.description} </strong>
                        de {x.startHour.toString().substring(0, 5)} a{" "}
                        {x.endHour.toString().substring(0, 5)}
                      </Typography>
                    ))}
                    <Divider
                      orientation="horizontal"
                      flexItem
                      sx={{ bgcolor: "secondary.light" }}
                    />
                  </>
                )}
              <Typography align="left" variant="subtitle2" pt={1} pb={0} pl={1}>
                <div>
                  <strong>Fecha de Inicio</strong>{" "}
                  {dayjs(item.initDate).format("DD/MM/YYYY")}
                </div>
              </Typography>

              <Typography align="left" variant="subtitle2" pt={0} pb={1} pl={1}>
                <div>
                  <strong>Fecha de Fin</strong>{" "}
                  {dayjs(item.endDate).format("DD/MM/YYYY")}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ bgcolor: "secondary.light" }}
        />
        {!methodology && (
          <Grid item xs={12} sm={12} md={12} sx={{ width: "inherit" }}>
            <Accordion
              sx={{
                border: 0,
                boxShadow: "none",
                backgroundColor: "white",
              }}
              id="enrolmentDetail"
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{ flexDirection: "row" }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography pl={2} pr={8.3} variant="subtitle1">
                  <strong>
                    <NewspaperOutlined /> Matrícula
                  </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {item.courseFees
                  .filter((x) => x.feeType === 1)
                  .map((feeDetail) => (
                    <Grid container>
                      <Grid item xs={7} pl={1}>
                        <Typography align="left" variant="subtitle2">
                          {feeDetail.studentType.description}
                        </Typography>
                      </Grid>
                      <Grid item color="primary" xs={4}>
                        <Typography color="primary" align="right" pl={0}>
                          ${feeDetail.feeAmount.toLocaleString("es-AR")}
                          <Typography variant="caption" color="primary">
                            /curso
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        <Divider
          orientation="horizontal"
          flexItem
          sx={{ bgcolor: "secondary.light" }}
        />
        {!methodology && (
          <Grid item xs={12} sm={12} md={12}>
            <Accordion
              sx={{
                border: 0,
                boxShadow: "none",
                backgroundColor: "white",
              }}
              id="feesDetail"
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="subtitle1" pl={2} pr={18}>
                  <strong>
                    <RequestQuoteOutlined /> Cuotas
                  </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {item.courseFees
                  .filter((x) => x.feeType === 2)
                  .map((student) => (
                    <Grid container>
                      <Grid item xs={7} pl={1}>
                        <Typography align="left">
                          {student.studentType.description}
                        </Typography>

                        <Typography
                          align="left"
                          variant="subtitle2"
                          fontSize="11px"
                        >
                          Se abona de forma
                          {/* {student.feeAmount === 0
													? "Excento de cuota"
													: student.frequencyType.description} */}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography align="right" color="primary" pl={3}>
                          ${student.feeAmount.toLocaleString("es-AR")}
                          <Typography
                            align="right"
                            variant="subtitle1"
                            fontSize="12px"
                          >
                            {student.frequencyType.description}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        <Grid container>
          <Divider flexItem sx={{ bgcolor: "secondary.light" }} />
          <Button
            name={`${item.courseId}_buttom`}
            color={item.isSelected ? "error" : "primary"}
            variant="contained"
            fullWidth
            disabled={isLoadingCourse}
            onClick={() => {
              onClickCourse(item);
            }}
            sx={{
              ...(isMobile
                ? {
                    margin: "8px 4px", // Ajustar el margen entre los botones en móvil
                    width: "100%", // Ajustar el ancho del botón en móvil
                  }
                : { marginBottom: 0.75 }),
            }}
          >
            {isLoadingCourse && <Spinner color="primary" />}
            {item.isSelected ? "Quitar Selección" : "Seleccionar"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      {...(isMobile ? { maxWidth: "xs" } : { fixed: true })}
      sx={{
        margin: isMobile ? 0 : undefined,
        padding: isMobile ? 0 : undefined,
      }}
    >
      <Grid
        sx={{
          backgroundColor: "primary.contrastText",
        }}
        item
        xs={12}
        md={12}
      >
        <Box sx={{ width: "100%" }}>
          <Grid display="flex" justifyContent="left" alignItems="left">
            <Button
              variant="text"
              disabled={isLoading}
              sx={{
                marginTop: 2,
                marginBottom: 2,
                marginLeft: 2,
                width: 100,
              }}
              onClick={() => setCurrentStep(currentStep - 1)}
              type="button"
              style={{ fontWeight: "bold" }}
            >
              Volver
            </Button>
          </Grid>
          <Typography
            variant="h5"
            align="center"
            color="primary"
            sx={{
              paddingLeft: 5,
              paddingRight: 5,
              paddingBottom: 1,
              marginTop: isMobile ? "5px" : -5,
            }}
          >
            Sistema Integral de Formación
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="primary"
            sx={{
              fontWeight: "700",
              paddingLeft: 5,
              paddingRight: 5,
              paddingBottom: 2,
            }}
          >
            <SchoolOutlined sx={{ marginRight: 2 }} />
            Cursos disponibles
          </Typography>
          <Typography
            color="primary"
            align="center"
            variant="subtitle2"
            sx={{ paddingBottom: 2 }}
          >
            Indícanos cuál o cuáles cursos son de tu elección para completar tu
            inscripción.
          </Typography>

          <Container
            fixed
            sx={{
              paddingTop: 5,
            }}
          >
            {!methodology && (
              <>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Alert severity="info">
                    <AlertTitle>Importante</AlertTitle>◼ Los cursos presenciales
                    se dictan en Viamonte 2084, CABA. <br />◼ Los cursos
                    virtuales son exclusivamente para nuestros afiliados,
                    aportantes, empleados y sus familiares directos.
                  </Alert>
                </Grid>
              </>
            )}
            <Grid
              minHeight={160}
              sx={{
                paddingTop: 5,
              }}
            >
              <Grid display="flex" justifyContent="center" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Presenciales</Typography>
                  <Switch
                    onChange={(e) => onSwitchMode(e.target.checked)}
                    disabled={user.clients.students.typeStudentId === 3} // Bloqueamos el switch para usuarios EXTERNOS
                  />
                  <Typography>Virtuales</Typography>
                </Stack>
              </Grid>
              <Container
                align="center"
                width="80%"
                className="background-carousel"
                fixed
              >
                {isLoading ? (
                  <CoursesAvailablesSkeleton />
                ) : (
                  <>
                    {coursesAvailables.length > 0 && (
                      <Slider
                        {...settingsSlider}
                        className="background-carousel"
                      >
                        {coursesAvailables.map((item) => (
                          <Grid container spacing={2}>
                            <Item
                              item={item}
                              key={`courseItem_${item.courseId}`}
                            />
                          </Grid>
                        ))}
                      </Slider>
                    )}
                  </>
                )}
              </Container>
              <Grid display="flex" justifyContent="center" alignItems="center">
                {countCourses >= limitCoursesQuantity && (
                  <ModalRegistrationLimit />
                )}
                {countCourses === 0 &&
                  countCourses < limitCoursesQuantity &&
                  coursesAvailables.length > 0 && (
                    <Grid
                      display="flex"
                      pt={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Alert severity="error">
                        <AlertTitle>Importante</AlertTitle>
                        No podrás continuar con el proceso de PreInscripción, si
                        no seleccionas al menos un curso.
                      </Alert>
                    </Grid>
                  )}
                {coursesAvailables.length === 0 && (
                  <Grid
                    display="flex"
                    pt={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Alert severity="info">
                      <AlertTitle>Importante</AlertTitle>
                      No hay cursos disponibles para la inscripción. <br />
                      Por favor comuniquesé con nosotros mediante mail a
                      <Typography fontWeight={700}>
                        {process.env.REACT_APP_HELPERMAIL}
                      </Typography>
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <Grid display="flex" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  id="continueButton"
                  disabled={
                    countCourses === 0 ||
                    countCourses >= limitCoursesQuantity ||
                    isLoading
                  }
                  sx={{ marginTop: 2, marginBottom: 2, width: 500 }}
                  onClick={() => {
                    updateCourses();
                  }}
                >
                  {user.isLoading && <Spinner color="primary" />}
                  Continuar
                </Button>
              </Grid>
              <Grid display="flex" justifyContent="center" alignItems="center">
                <Button
                  variant="text"
                  disabled={isLoading}
                  sx={{ marginTop: 1, marginBottom: 2, width: 500 }}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  type="button"
                  style={{ fontWeight: "bold" }}
                >
                  Volver
                </Button>
              </Grid>
            </Grid>
            {errors && errors.length > 0 && <ModalMessages errors={errors} />}
          </Container>
          <></>
          <Typography
            color="primary"
            align="center"
            variant="subtitle2"
            sx={{ paddingTop: 5, paddingBottom: 2 }}
          >
            <HelperMail label="¿Tienes alguna duda? Envíanos un mail" />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CoursesAvailables;
