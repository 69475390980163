import { Grid, Skeleton } from "@mui/material";
import React from "react";

const UsersSkeleton = () => {
  return (
    <Grid
      container
      rowSpacing={3}
      spacing={3}
      paddingTop={6}
      paddingLeft={4}
      paddingRight={4}
      paddingBottom={6}
      sx={{ backgroundColor: "white" }}
    >
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={60} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={240} />
      </Grid>
    </Grid>
  );
};

export default UsersSkeleton;
