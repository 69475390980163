import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUser } from "../../../store/slices/currentUser";
import {
  fetchTypeTable,
  selectMaintenances,
} from "../../../store/slices/maintenances";
import {
  selectStudentPaymentsDetail,
  tryStudentPayments,
  tryStudentPaymentDetail,
  trySaveStudentPaymentDetail,
  tryPaymentApproved,
  tryPaymentRejected,
  tryDownloadStudentFile,
  selectDownloaded,
  selectRejected,
  selectApproved,
  tryStudentPaymentsSummary,
  selectNewPayment,
} from "../../../store/slices/student-payments";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Alert,
  Button,
  List,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
  AlertTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import {
  CloudUploadOutlined,
  UploadFileOutlined,
  Close,
} from "@mui/icons-material";
import DrawerSkeleton from "../skeleton/drawerSkeleton";
import ModalMessages from "../../layout/modal-messages";
import Spinner from "../../layout/spinner";
import convertToSelectList from "../../../components/layout/functions/convert-to-select-list";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";

const validate = (values, roleId) => {
  const errors = {};
  const today = dayjs();
  const paymentDate = dayjs(values.paymentDate, "YYYY-MM-DD", true);

  if (!values.paymentCode || values.paymentCode === "")
    errors.paymentCode = "Requerido";

  if (!values.paymentAmount || values.paymentAmount === "")
    errors.paymentAmount = "Requerido";

  if (!values.wayOfPayment || values.wayOfPayment === "")
    errors.wayOfPayment = "Requerido";

  if (!paymentDate.isValid()) {
    errors.paymentDate = "Fecha inválida";
  }
  if (paymentDate.isAfter(today, "day")) {
    errors.paymentDate =
      "La fecha de inicio no puede ser posterior al día actual";
  }

  if (!values.paymentDate || values.paymentDate === "Invalid Date")
    errors.paymentDate = "Requerido";

  if (roleId !== 1 && values.paymentId == 0) {
    if (!values.fileOfPayment || values.fileOfPayment === "")
      errors.fileOfPayment = "Requerido, debe incluir un archivo";
  }

  if (!values.stateOfPayment) errors.stateOfPayment = "Requerido";

  return errors;
};

const StudentPaymentDrawer = ({
  openDrawer,
  setOpenDrawer,
  courseId,
  course,
  studentId,
  studentCourseId,
  coursefeeId,
  paymentId,
  setOpenConfirmationDialog,
}) => {
  const dispatch = useDispatch();

  const typeTables = useSelector(selectMaintenances);
  const { roleId } = useSelector(selectCurrentUser);
  const studentPaymentsDetail = useSelector(selectStudentPaymentsDetail);
  const fileDownloaded = useSelector(selectDownloaded);
  const paymentRejected = useSelector(selectRejected);
  const paymentApproved = useSelector(selectApproved);
  const paymentDone = useSelector(selectNewPayment);

  //MANEJO GESTION DE PAGO
  const [aproval, setAproval] = useState(null);

  useEffect(() => {
    dispatch(fetchTypeTable(3)); // via de pago
    dispatch(fetchTypeTable(21)); //Estado del pago
    (async () => {
      const action = await dispatch(
        tryStudentPaymentDetail({ studentId, courseId, coursefeeId, paymentId })
      );
      if (action.payload.data.isValid) {
        const { data } = action.payload.data;
        formik.setValues({
          paymentId: paymentId,
          studentCourseId: data.studentCourseId ?? studentCourseId,
          paymentCode: data.paymentCode ?? "",
          paymentAmount: data.paymentAmount,
          wayOfPayment: data.wayOfPayment,
          complement: data.complement,
          paymentDate: data.paymentDate
            ? dayjs(data.paymentDate).format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD"),
          fileOfPayment: data.fileOfPayment,
          stateOfPayment: data.stateOfPayment !== 0 ? data.stateOfPayment : 3,
          wayOfPayments: data.wayOfPayments,
          statusStudentCoursePayments: data.statusStudentCoursePayments,
          reasons: data.reasons ?? "",
        });
        if (data.stateOfPayment == 2) setAproval(2);
        if (data.stateOfPayment !== 2) setAproval(null);
      }
    })();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      paymentId: paymentId,
      studentCourseId: studentCourseId,
      paymentCode: "",
      paymentAmount: "",
      wayOfPayment: "",
      complement: "",
      paymentDate: studentPaymentsDetail?.paymentDate
        ? dayjs(studentPaymentsDetail.paymentDate).format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD"),
      fileOfPayment: "",
      stateOfPayment: 3,
      wayOfPayments: "",
      statusStudentCoursePayments: "",
      reasons: "",
    },
    validate: (values) => validate(values, roleId),
    onSubmit: async (values) => {
      //Aproval solo tendra valor si un backoffice esta aprobando o rechazando el pago
      if (aproval && roleId == 1) {
        if (aproval == 1) {
          const action = await dispatch(
            tryPaymentApproved({
              studentId,
              courseId,
              paymentId,
              coursefeeId,
            })
          );
          if (action.payload.isValid) {
            setOpenConfirmationDialog(true);
            dispatch(
              tryStudentPayments({
                studentId,
                isFirstOrLastRecords: false,
              })
            );
          }
        }
        if (aproval == 2) {
          const action = await dispatch(
            tryPaymentRejected({
              studentId,
              courseId,
              paymentId,
              coursefeeId,
              reasons: values.reasons,
            })
          );
          if (action.payload.isValid) {
            setOpenConfirmationDialog(true);
            dispatch(
              tryStudentPayments({
                studentId,
                isFirstOrLastRecords: false,
              })
            );
          }
        }
      }

      if (roleId !== 1) {
        values.stateOfPayment = 3;
        values.reasons = "";
        const action = await dispatch(
          trySaveStudentPaymentDetail({
            studentId,
            courseId,
            coursefeeId,
            model: values,
          })
        );

        if (action.payload.data.isValid) {
          dispatch(
            tryStudentPayments({
              studentId,
              isFirstOrLastRecords: false,
            })
          );
          setOpenConfirmationDialog(true);
        }
      }
      setOpenDrawer(false);
      dispatch(tryStudentPaymentsSummary(studentId));
    },
  });

  // DESCARGAR EL ARCHIVO
  const fileDownload = async () => {
    const action = await dispatch(
      tryDownloadStudentFile({ paymentId: formik.values.paymentId })
    );

    if (action.payload.isValid) {
      var attachmentElement = document.createElement("a");

      attachmentElement.href = action.payload.data.file;

      attachmentElement.download = action.payload.data.name;

      attachmentElement.click();
    }
  };

  //Archivo
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 10000000,
    onDropAccepted: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        formik.setFieldValue("fileOfPayment", base64data);
      };
    },
  });

  const files = acceptedFiles.map((filename) => (
    <ListItem>
      <ListItemIcon>
        <UploadFileOutlined color="primary" />
      </ListItemIcon>
      <ListItemText key={filename.path} primary={filename.path} />
    </ListItem>
  ));

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      PaperProps={{
        sx: {
          zIndex: 1203,
          width: 500,
          boxSizing: "border-box",
          border:
            aproval == 2 ? "3px solid red" : aproval == 1 && "3px solid lime",
        },
      }}
      onClose={() => setOpenDrawer(false)}
      sx={{
        zIndex: 1203,
        width: 500,
        flexShrink: 0,
      }}
    >
      <Grid container p={1}>
        <Grid item xs={10}>
          <Typography
            variant="h4"
            align="left"
            sx={{
              paddingLeft: 3,
              paddingRight: 5,
              paddingBottom: 1,
              paddingTop: 4,
            }}
          >
            Pagos
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "end" }}>
          <IconButton
            sx={{ color: "white" }}
            onClick={() => setOpenDrawer(false)}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ padding: 3 }}>
          <Typography variant="h7" align="center">
            Por favor, complete información en cada campo que se solicite en el
            formulario
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        spacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        p={2}
        sx={{ backgroundColor: "white" }}
      >
        {studentPaymentsDetail.isLoading ? (
          <DrawerSkeleton />
        ) : (
          <>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="course-label">
                  Curso asociado al pago
                </InputLabel>
                <Select
                  labelId="course-label"
                  name="course"
                  label="Curso asociado al pago"
                  size="small"
                  value={courseId}
                  disabled={!false}
                  onChange={formik.handleChange}
                >
                  <MenuItem key={courseId} value={courseId}>
                    {course}
                  </MenuItem>
                </Select>
                <FormHelperText htmlFor="course"></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="wayOfPayment-label">Medio de pago</InputLabel>
                <Select
                  labelId="wayOfPayment-label"
                  name="wayOfPayment"
                  label="Medio de pago"
                  size="small"
                  value={formik.values.wayOfPayment}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.wayOfPayment && formik.errors.wayOfPayment
                  )}
                  helperText={
                    formik.touched.wayOfPayment && formik.errors.wayOfPayment
                  }
                >
                  {typeTables.TBLWAYOFPAYMENTS
                    ? convertToSelectList(typeTables.TBLWAYOFPAYMENTS.data).map(
                        (x) => (
                          <MenuItem key={x.value} value={x.value}>
                            {x.text}
                          </MenuItem>
                        )
                      )
                    : null}
                </Select>
                <FormHelperText
                  htmlFor="wayOfPayment"
                  error={
                    formik.touched.wayOfPayment && !!formik.errors.wayOfPayment
                  }
                >
                  {formik.errors.wayOfPayment}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="paymentCode"
                label="Referencia asociada al pago"
                variant="outlined"
                size="small"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.paymentCode}
                error={Boolean(
                  formik.touched.paymentCode && formik.errors.paymentCode
                )}
                helperText={
                  formik.touched.paymentCode && formik.errors.paymentCode
                }
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  id="paymentDate"
                  name="paymentDate"
                  disableFuture
                  label="Fecha del pago"
                  inputFormat="DD/MM/YYYY"
                  value={formik.values.paymentDate}
                  type="date"
                  onChange={(value) => {
                    formik.setFieldValue(
                      "paymentDate",
                      dayjs(value).format("YYYY-MM-DD"),
                      true
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={
                        formik.touched.paymentDate &&
                        Boolean(formik.errors.paymentDate)
                      }
                      helperText={
                        formik.touched.paymentDate && formik.errors.paymentDate
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <NumericFormat
                  variant="outlined"
                  label="Monto del pago"
                  size="small"
                  name="paymentAmount"
                  thousandSeparator="."
                  decimalSeparator=","
                  isNumericString
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  InputLabelProps={{ shrink: true }}
                  customInput={TextField}
                  value={formik.values.paymentAmount}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    formik.setFieldValue("paymentAmount", floatValue);
                  }}
                  error={
                    formik.touched.paymentAmount && formik.errors.paymentAmount
                  }
                  helperText={
                    formik.touched.paymentAmount && formik.errors.paymentAmount
                  }
                />
              </FormControl>
            </Grid>
            {paymentId !== 0 && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => fileDownload()}
                  disabled={
                    studentPaymentsDetail.isLoading || fileDownloaded.isLoading
                  }
                  sx={{
                    marginTop: 0,
                    marginBottom: 0,
                    width: "100%",
                    marginRight: 2,
                  }}
                >
                  {(studentPaymentsDetail.isLoading ||
                    fileDownloaded.isLoading) && <Spinner color="primary" />}
                  Descargar archivo
                </Button>
              </Grid>
            )}
            <Grid item xs={12} whiteSpace="normal">
              <TextField
                id="complement"
                label="Comentarios"
                multiline
                rows={5}
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.complement}
              />
            </Grid>
            {/* si el estado del pago es CERO entonces el pago es nuevo y no le mostramos al estudiante el mismo */}
            {roleId == 1 && (
              <Grid item xs={12}>
                {roleId == 1 && (
                  <>
                    <FormControl>
                      <FormLabel id="genre-label">Gestionar Pago</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="aproval-label"
                        name="aproval"
                      >
                        <FormControlLabel
                          value="Aprobar pago"
                          onChange={() => setAproval(1)}
                          control={<Radio />}
                          label="Aprobar Pago"
                          sx={{ color: "black" }}
                        />
                        <FormControlLabel
                          value="Rechazar pago"
                          onChange={() => setAproval(2)}
                          checked={aproval == 2}
                          control={<Radio />}
                          label="Rechazar Pago"
                          sx={{ color: "black" }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </>
                )}
              </Grid>
            )}

            {aproval == 2 && roleId == 1 && (
              <Grid item xs={12}>
                <TextField
                  id="reasons"
                  label="Motivo del rechazo"
                  multiline
                  rows={5}
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;
                    formik.setFieldValue("reasons", value);
                  }}
                  value={formik.values.reasons}
                />
              </Grid>
            )}

            {formik.values.stateOfPayment == 2 && roleId !== 1 && (
              <Grid item xs={12}>
                <Alert
                  variant="filled"
                  severity="error"
                  sx={{ textAlign: "left" }}
                  icon={false}
                >
                  <AlertTitle>RECHAZADO</AlertTitle>
                  {formik.values.reasons}
                </Alert>
              </Grid>
            )}
            {roleId !== 1 && (
              <>
                <Grid item xs={12} whiteSpace="initial">
                  <Alert
                    severity="warning"
                    sx={{ textAlign: "center", color: "#ffab00" }}
                    icon={false}
                  >
                    Adjunte el archivo de respaldo asociado al Pago, puede ser
                    una imágen o un .pdf, por ejemplo.
                  </Alert>
                </Grid>

                <Grid item xs={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      border: "4px",
                      borderRadius: "12px",
                      borderStyle: "solid",
                      borderColor: "#85BFE6",
                      marginTop: 2,
                    }}
                  >
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                          <Grid
                            item
                            xs={1}
                            sm={2}
                            md={2}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CloudUploadOutlined
                              color="primary"
                              fontSize="large"
                            />
                          </Grid>
                          <Grid item xs={3} sm={6} md={6} whiteSpace="initial">
                            <Typography
                              color="primary"
                              variant="subtitle2"
                              sx={{ fontWeight: 700, paddingTop: 5 }}
                            >
                              Seleccione un archivo o arrástrelo hasta acá
                            </Typography>

                            <Typography
                              color="primary"
                              variant="subtitle2"
                              sx={{ paddingTop: 1, paddingBottom: 5 }}
                            >
                              JPG, PNG o PDF, tamaño del archivo no mayor a 10MB
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <Button
                              variant="outline"
                              color="secondary"
                              sx={{ border: 2 }}
                            >
                              Buscar
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                      <List sx={{ width: "100%" }}>{files}</List>
                    </section>
                  </Paper>
                  <FormHelperText
                    htmlFor="fileOfPayment"
                    error={
                      formik.touched.fileOfPayment &&
                      !!formik.errors.fileOfPayment
                    }
                  >
                    {formik.errors.fileOfPayment}
                  </FormHelperText>
                </Grid>
              </>
            )}
            <Grid item sx={{ width: "100%", textAlign: "center" }}>
              <Button
                variant="contained"
                onClick={formik.handleSubmit}
                disabled={
                  studentPaymentsDetail?.isLoading ||
                  paymentRejected?.isloading ||
                  paymentApproved?.isLoading
                }
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  width: 100,
                  marginRight: 2,
                }}
              >
                {(studentPaymentsDetail?.isLoading ||
                  paymentRejected?.isLoading ||
                  paymentApproved?.isLoading) && <Spinner color="primary" />}
                Guardar
              </Button>
              <Button
                variant="outline"
                color="error"
                disabled={studentPaymentsDetail.isLoading}
                sx={{
                  border: 2,
                  marginTop: 2,
                  marginBottom: 2,
                  width: 100,
                  color: "red",
                }}
                onClick={() => setOpenDrawer(false)}
              >
                Cancelar
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      {studentPaymentsDetail.errors &&
        studentPaymentsDetail.errors.length > 0 && (
          <ModalMessages errors={studentPaymentsDetail.errors} />
        )}{" "}
      {fileDownloaded.errors && fileDownloaded.errors.length > 0 && (
        <ModalMessages errors={fileDownloaded.errors} />
      )}
      {paymentDone.errors && paymentDone.errors.length > 0 && (
        <ModalMessages errors={paymentDone.errors} />
      )}
    </Drawer>
  );
};

export default StudentPaymentDrawer;
