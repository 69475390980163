import { createTheme } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import { esES as dataGridEsES } from "@mui/x-data-grid";
import { esES as pickersEsES } from "@mui/x-date-pickers/locales";

const Theme = createTheme(
  {
    typography: {
      fontFamily: ["Inter"],
      fontSize: 13,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 400,
      fontWeightMedium: 500,
    },
    palette: {
      primary: {
        main: "#22577A",
        dark: "#85BFE6",
        light: "#3FA0E0",
        contrastText: "#fff",
      },
      secondary: {
        main: "#385161",
        contrastText: "#000",
      },
      buttons: {
        primary: "#56CCF2",
      },
      success: {
        main: "#2e7d32",
        alternative: "#3eb049",
      },
      error: { main: "#d32f2f" },
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: "#22577A",
            color: "white",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            fontSize: "13px",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: "13px",
          },
        },
      },
    },
  },
  dataGridEsES,
  pickersEsES,
  esES
);

export default Theme;
