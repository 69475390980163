import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Grid, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  margin: "auto",

  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CourseGroupModalMails = ({ open, setOpen, mails }) => {
  if (!open) return null;
  return (
    <Box sx={style} display="flex" justifyContent="center" alignItems="center">
      <Grid container>
        <Grid item xs={12}>
          <TextField
            id="E-Mails"
            label="E-Mails"
            value={mails ? mails : "Sin Información"}
            disabled
            multiline
            rows={6}
            maxRows={6}
            autoSize
            variant="outlined"
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(mails);
            }}
            variant="contained"
            color="success"
            size="large"
            fullWidth="true"
          >
            COPIAR
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            color="error"
            size="large"
            fullWidth="true"
          >
            CERRAR
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CourseGroupModalMails;
