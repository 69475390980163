import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiMaintenances from "../../../services/apiMaintenances";

export const maintenancesSlice = createSlice({
  name: "maintenances",
  initialState: {
    typeTables: {
      data: [],
      isLoading: false,
      errors: [],
    },
    typeTablesAll: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    selectedCatalog: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    newTuple: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setTypeTable: (state, { payload }) => {
      const { className } = payload.data.meta;
      state.typeTables[className] = payload.data;
    },
    setTypeTableLoading: (state, { payload }) => {
      state.typeTables.isLoading = payload;
    },
    setCleanCatalog: (state) => {
      state.selectedCatalog.data = [];
    },
    setCleanErrorsMaintenances: (state) => {
      state.typeTables.errors = [];
      state.typeTablesAll.errors = [];
      state.selectedCatalog.errors = [];
      state.newTuple.errors = [];
    },
  },
  //Trae las tablas catalogo
  extraReducers(builder) {
    builder.addCase(tryGetTypeTables.pending, (state) => {
      state.typeTablesAll.status = "loading";
      state.typeTablesAll.isLoading = true;
      state.typeTablesAll.errors = [];
    });
    builder.addCase(tryGetTypeTables.fulfilled, (state, { payload }) => {
      state.typeTablesAll.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.typeTablesAll.errors = errors;
      //console.log(payload.data.data);
      if (payload.data.isValid) {
        state.typeTablesAll.data = [...payload.data.data];
      }

      state.typeTablesAll.isLoading = false;
    });
    builder.addCase(tryGetTypeTables.rejected, (state, { payload }) => {
      state.typeTablesAll.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.typeTablesAll.errors = errors;
      state.typeTablesAll.isLoading = false;
    });

    //TRAE EL DETALLE DE UNA TABLA CATALOGO

    builder.addCase(tryGetCatalog.pending, (state) => {
      state.selectedCatalog.status = "loading";
      state.selectedCatalog.isLoading = true;
      state.selectedCatalog.errors = [];
    });
    builder.addCase(tryGetCatalog.fulfilled, (state, { payload }) => {
      state.selectedCatalog.status = "succeeded";

      const errors = payload.data.data.isValid ? payload.data.data.errors : [];
      state.selectedCatalog.errors = errors;

      if (payload.data.data.isValid) {
        state.selectedCatalog.data = [...payload.data.data.data];
      }

      state.selectedCatalog.isLoading = false;
    });
    builder.addCase(tryGetCatalog.rejected, (state, { payload }) => {
      state.selectedCatalog.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.data.isValid === false)
          errors = payload.data.data.errors;

        if (payload.data.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.selectedCatalog.errors = errors;
      state.selectedCatalog.isLoading = false;
    });

    //CREA O MODIFICA UNA TUPLA DE UNA TABLA CATALOGO

    builder.addCase(tryNewTuple.pending, (state) => {
      state.newTuple.status = "loading";
      state.newTuple.isLoading = true;
      state.newTuple.errors = [];
    });
    builder.addCase(tryNewTuple.fulfilled, (state, { payload }) => {
      state.newTuple.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.newTuple.errors = errors;

      if (payload.data.isValid) {
        state.newTuple.data = { ...payload.data.data };
      }

      state.newTuple.isLoading = false;
    });
    builder.addCase(tryNewTuple.rejected, (state, { payload }) => {
      state.newTuple.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newTuple.errors = errors;
      state.newTuple.isLoading = false;
    });
  },
});

export const {
  setTypeTable,
  setTypeTableLoading,
  setCleanErrorsMaintenances,
  setCleanCatalog,
} = maintenancesSlice.actions;

export default maintenancesSlice.reducer;

export const tryCleanErrorsMaintenances = () => (dispatch) => {
  dispatch(setCleanErrorsMaintenances());
};

export const tryCleanCatalog = () => (dispatch) => {
  dispatch(setCleanCatalog());
};

export const selectMaintenances = (state) => state.maintenances.typeTables;

export const selectTypeTables = (state) => state.maintenances.typeTablesAll;

export const selectCatalog = (state) => state.maintenances.selectedCatalog;

export const selectNewTuple = (state) => state.maintenances.newTuple;

export const fetchTypeTable = (id) => (dispatch) => {
  dispatch(setTypeTableLoading(true));
  const query = { isPaginated: false };

  apiMaintenances
    .getCatalogs(id, query)
    .then((resp) => {
      if (resp) {
        dispatch(setTypeTable(resp.data));
        dispatch(setTypeTableLoading(false));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(setTypeTableLoading(false));
    });
};

export const tryGetTypeTables = createAsyncThunk(
  "maintenances/tryGetTypeTables",
  async (_, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiMaintenances.getTypeTables();

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetCatalog = createAsyncThunk(
  "maintenances/tryGetCatalog",
  async (typeTableId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiMaintenances.getCatalog(typeTableId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryNewTuple = createAsyncThunk(
  "maintenances/tryNewTuple",
  async ({ model }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiMaintenances.postNewTuple(model);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
