import React from "react";
import { Grid } from "@mui/material";
import LeftSection from "./left-section";
import RightSection from "./right-section";

const ValidateCUIL = ({ currentStep, setCurrentStep, setCuil, isMobile }) => (
  <Grid
    container
    spacing={2}
    sx={{
      margin: isMobile ? 0 : 2,
    }}
  >
    <Grid
      sx={{
        background: "linear-gradient(to right bottom, #85BFE6, #22577A)",
      }}
      item
      xs={12}
      md={8}
    >
      <LeftSection />
    </Grid>
    <Grid
      sx={{
        backgroundColor: "primary.contrastText",
      }}
      item
      xs={12}
      md={4}
    >
      <RightSection
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setCuil={setCuil}
      />
    </Grid>
  </Grid>
);

export default ValidateCUIL;
