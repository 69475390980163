import { Container, Grid, Skeleton } from "@mui/material";
import React from "react";

const TeacherTicketsTabSummary = () => {
  return (
    <Container>
      <Grid
        container
        sx={{
          heigth: "auto",
          width: "auto",
          marginBottom: 1,
          marginTop: 0,
          paddingBottom: 10,
        }}
        rowSpacing={6}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={12} marginTop={7}>
            <Skeleton variant="rectangular" height={60} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TeacherTicketsTabSummary;
