import axios from "axios";
import store from "store";

const nameUserLogged = "sif-dev-userLogged";

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API}api`,
});

apiClient.interceptors.request.use((request) => {
  const userLogged = store.get(nameUserLogged);

  if (userLogged) {
    request.headers.Authorization = `Bearer ${userLogged.accessToken}`;
    // request.headers.AccessToken = userLogged.accessToken;
    // request.headers['Content-Type'] = 'application/json;charset=UTF-8'
  }

  return request;
});

apiClient.interceptors.response.use(
  (response) => {
    const pagination = response.headers["x-pagination"];

    if (pagination) {
      const parsed = JSON.parse(pagination);

      const meta = {
        currentPage: parsed.CurrentPage,
        pageSize: parsed.PageSize,
        totalPages: parsed.TotalPages,
        totalCount: parsed.TotalCount,
        hasNextPage: parsed.HasNextPage,
        hasPreviousPage: parsed.HasPreviousPage,
      };

      response.data = {
        meta,
        data: response.data,
      };
      return response;
    }
    return response;
  },
  (error) => {
    // Errors handling
    let { response } = error;

    if (!response) {
      response = {
        status: 500,
        data: {
          isValid: false,
          errors: [
            {
              errorCode: "0000",
              errorMessage: "Error de conexión",
            },
          ],
        },
      };
    }

    throw response;
  }
);

export default apiClient;
