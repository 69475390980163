import React from "react";
import {
  Alert,
  AlertTitle,
} from "@mui/material";
import { Container } from "@mui/system";
// import { useDispatch } from 'react-redux';
// import { tryLogout } from '../../store/slices/users';

const AlertMessages = ({ type, title, icon, data }) => {
  // const dispatch = useDispatch();

  // const expired = data.find((x) => x.errorCode === 'HTTP401');

  // if (expired) dispatch(tryLogout());

  return (
    <Container fixed>
      <Alert severity={type} variant="outlined">
        <AlertTitle>{type === "error" ? "Error" : title}</AlertTitle>
        <ul>
          {data.map((x) => (
            <li key={x.errorCode}>{`${x.errorCode}: ${x.errorMessage}`}</li>
          ))}
        </ul>
      </Alert>
    </Container>
  );
};

export default AlertMessages;
