import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tryCreateCourse } from "../../../store/slices/back-course";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ModalMessages from "../../layout/modal-messages";
import { selectNewCourse } from "../../../store/slices/back-course";
import BackCoursesComplementsDrawerSkeleton from "../skeleton/drawerSkeleton";

const ComplementsDrawer = ({
  openDrawer,
  setOpenDrawer,
  title,
  id,
  values,
  openDialog,
  setOpenDialog,
  courseDetail,
}) => {
  const dispatch = useDispatch();

  //Trae el estado de un nuevo curso
  const newCourseState = useSelector(selectNewCourse);

  //Setea el valor del campo que estamos cargando
  const [value, setValue] = useState("");

  //Contiene el detalle de un curso recien creado o modificado
  const newCourse = useSelector(selectNewCourse);

  //Contiene el detalle del curso
  const model = { ...courseDetail.data };
  //Variable para que no valide
  const validateFees = false;

  //Cargamos el campo con el valor que traiga de la pantalla anterior
  useEffect(() => {
    setValue(values);
  }, [openDrawer]);

  //Se prepara el modelo que se le va a entregar a la api y se envia

  const saveComplement = async () => {
    switch (id) {
      case 1:
        model.program = value;
        break;
      case 2:
        model.bibilography = value;
        break;
      case 3:
        model.fundaments = value;
        break;
      case 4:
        model.objectives = value;
        break;
      case 5:
        model.methodologyDescription = value;
        break;
      case 6:
        model.requirements = value;
        break;
      case 7:
        model.approvalConditions = value;
        break;
    }
    const action = await dispatch(tryCreateCourse({ model, validateFees }));

    //Si se cargo la informacion de forma exitosa mostramos un Dialog de confirmación
    if (action.payload.data.isValid) {
      setOpenDrawer(false);
      setOpenDialog(true);
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        PaperProps={{
          sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
        }}
        onClose={() => setOpenDrawer(false)}
        sx={{
          zIndex: 1203,
          width: 500,
          flexShrink: 0,
        }}
      >
        <Grid container p={1}>
          <Grid item xs={10}>
            <Typography
              variant="h4"
              align="left"
              sx={{
                paddingLeft: 1,
                paddingRight: 5,
                paddingBottom: 1,
                paddingTop: 4,
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                paddingLeft: 1,
                paddingRight: 5,
                paddingBottom: 3,
                paddingTop: 1,
              }}
            >
              Por favor, complete información del campo requerido
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setOpenDrawer(false)}
            >
              <GridCloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {newCourseState.isLoading ? (
          <BackCoursesComplementsDrawerSkeleton />
        ) : (
          <Grid
            container
            rowSpacing={3}
            spacing={3}
            paddingTop={6}
            paddingLeft={4}
            paddingRight={4}
            paddingBottom={6}
            sx={{ backgroundColor: "white" }}
          >
            <Grid item xs={12}>
              <TextField
                label="Texto"
                value={value}
                onChange={(event) => setValue(event.target.value)}
                multiline
                rows={20}
                variant="outlined"
                sx={{ width: "100%" }}
                error={!value}
              />
            </Grid>

            <Grid item xs={6} align="right" marginTop={3}>
              <Button
                disabled={!value}
                variant="contained"
                onClick={() => saveComplement()}
              >
                GUARDAR
              </Button>
            </Grid>
            <Grid item xs={6} marginTop={3}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenDrawer(false)}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        )}
        {newCourse.errors && newCourse.errors.length > 0 && (
          <ModalMessages errors={newCourse.errors} />
        )}
      </Drawer>
      {openDialog && (
        <>
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"La información ha sido guardada con éxito"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
    </>
  );
};

export default ComplementsDrawer;
