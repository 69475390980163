const filterByMessageSent = (messageSent, value) => {
  return messageSent
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove accents from string
    .toLowerCase()
    .includes(
      value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    );
};

export default filterByMessageSent;
