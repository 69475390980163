import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  tryValidateNeo,
  tryUpdateUser,
} from "../../../store/slices/users";
import {
  tryValidateStudentData,
  selectPersonalData,
  tryCleanErrors,
} from "../../../store/slices/personal-data";
import {
  fetchTypeTable,
  selectMaintenances,
} from "../../../store/slices/maintenances";
import { selectCurrentUser } from "../../../store/slices/currentUser";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  PermMediaOutlined,
  ContactEmergencyOutlined,
  CloudUploadOutlined,
  UploadFileOutlined,
  Badge,
  Receipt,
} from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useDropzone } from "react-dropzone";
import convertToSelectList from "../../layout/functions/convert-to-select-list";
import PersonalDataSkeleton from "./skeleton";
import Spinner from "../../layout/spinner";
import ModalMessages from "../../layout/modal-messages";
import HelperMail from "../../layout/helper-mail";
import { useFormik } from "formik";
import { IsValidEmail } from "../../../services/functions/validator";
import "dayjs/locale/es";

const dayjs = require("dayjs");

// TIPOS DE ESTUDIANTES ADMITIDOS PARA LA MODIFICACIÓN DE COMPONENTES
// 1 Afiliado - 2 Aportante - 3 Externo - 4 Familiar Afiliado - 5 Familiar Empleado - 6 Empleado
const situation = [1, 2];

const validate = (values) => {
  const errors = {};

  if (!values.firstName || values.firstName === "")
    errors.firstName = "Requerido";

  if (!values.lastName || values.lastName === "") errors.lastName = "Requerido";

  if (!values.nationality || values.nationality <= 0)
    errors.nationality = "Requerido";

  if (!values.genre || values.genre === 0) errors.genre = "Requerido";

  if (!values.document || values.document === "") errors.document = "Requerido";

  if (!values.cuil || values.cuil === "") errors.cuil = "Requerido";

  if (values.cuil.length < 11) errors.cuil = "Longitud de CUIL inválida";

  if (!values.birthDate || values.birthDate === "0001-01-01T00:00:00")
    errors.birthDate = "Requerido";

  if (dayjs(values.birthDate).$d.toString() === "Invalid Date")
    errors.birthDate = "Fecha inválida";

  if (!values.fullAddress || values.fullAddress === "")
    errors.fullAddress = "Requerido";

  if (!values.phoneAreaCode || values.phoneAreaCode === "")
    errors.phoneAreaCode = "Requerido";

  if (values.phoneAreaCode.length > 4)
    errors.phoneAreaCode = "Máximo 4 dígitos";

  if (!values.phoneNumber || values.phoneNumber === "")
    errors.phoneNumber = "Requerido";

  if (values.phoneNumber.length > 10) errors.phoneNumber = "Máximo 10 dígitos";

  if (!values.celAreaCode || values.celAreaCode === "")
    errors.celAreaCode = "Requerido";

  if (values.celAreaCode.length > 4) errors.celAreaCode = "Máximo 4 dígitos";

  if (!values.mobileNumber || values.mobileNumber === "")
    errors.mobileNumber = "Requerido";

  if (values.mobileNumber.length > 10)
    errors.mobileNumber = "Máximo 10 dígitos";

  if (!values.labouralSituation || values.labouralSituation <= 0)
    errors.labouralSituation = "Requerido";

  if (!values.academic || values.academic <= 0) errors.academic = "Requerido";
  if (!values.province || values.province <= 0 || values.province == undefined)
    errors.province = "Requerido";

  if (!values.email || values.email === "") errors.email = "Requerido";

  if (values.email !== values.emailCopy)
    errors.email = "Los correos electrónicos no coinciden";

  if (!IsValidEmail(values.email))
    errors.email = "Formato de correo electrónico invalido";

  if ([4, 5].includes(values.situation)) {
    if (!values.relationship || values.relationship <= 0)
      errors.relationship = "Requerido";

    if (!values.sectional || values.sectional <= 0)
      errors.sectional = "Requerido";

    if (
      values.situation === 4 &&
      (!values.cardNumber || values.cardNumber === "")
    )
      errors.cardNumber = "Requerido";
  }

  if (values.fileRequired) {
    if ([4, 5, 6].includes(values.situation)) {
      if (!values.file || values.file <= 0) errors.file = "Requerido";
    }
    if (!values.frontDocument || values.frontDocument <= 0)
      errors.frontDocument = "Requerido";
    if (!values.backDocument || values.backDocument <= 0)
      errors.backDocument = "Requerido";
  }

  return errors;
};

const PersonalData = ({
  currentStep,
  setCurrentStep,
  cuil,
  isMobile,
  returnToStudents,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const currentUser = useSelector(selectCurrentUser);
  const typeTables = useSelector(selectMaintenances);
  const { errors, isLoading } = useSelector(selectPersonalData);
  const [selectedProvince, setSelectedProvince] = useState();

  const errorsCleaner = () => {
    if (currentUser.businessId && currentUser.roleId !== 1) {
      navigate("/students");
    }

    if (!currentUser?.businessId || currentUser.roleId === 1) {
      if (returnToStudents) navigate("/backoffice/students");
      if (!returnToStudents) setCurrentStep(currentStep - 1);
    }
  };
  const [fileErrorOn, setFileErrorOn] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      emailCopy: "",
      email: "",
      birthDate: "",
      fullAddress: "",
      phoneNumber: "",
      mobileNumber: "",
      document: "",
      cuil: "",
      labouralSituation: null,
      academic: null,
      genre: 0,
      nationality: 0,
      celAreaCode: "",
      phoneAreaCode: "",
      sectional: 0,
      relationship: 0,
      cardNumber: "",
      province: 1,
      situation: null,
      notes: "",
      file: null,
      fileRequired: true,
      frontDocument: "",
      backDocument: "",
    },
    validate,
    onSubmit: async (values) => {
      let cliDocuments = [];

      let cliAddressAndPhone = [];
      let cliRelations = [];
      let cliExternals = null;

      if (user.clients.cliDocuments != null) {
        user.clients.cliDocuments.map((x) => {
          let documents = { ...x };

          if (x.typeDocumentId === 2) {
            documents.document = values.cuil;
          }
          if (x.typeDocumentId === 1) {
            documents.document = values.document;
          }

          cliDocuments.push(documents);
        });

        if (
          values.cardNumber != null &&
          values.cardNumber != "" &&
          values.cardNumber.length > 0
        ) {
          if (user.clients.cliDocuments.every((x) => x.typeDocumentId != 4)) {
            cliDocuments.push({
              cliDocumentId: 0,
              clientId: 0,
              typeDocumentId: 4,
              document: values.cardNumber,
            });
          }
        }
      }

      if (user.clients.cliAdress != null) {
        user.clients.cliAddress.map((x) => {
          let address = { ...x };

          if (x.typeAddress === 2) {
            address.country = 1;
            address.clientId = user.clientId;
            address.cliAddresId = 0;
            address.fullAddress = values.fullAddress;
            address.province = values.province;
            address.phone = values.mobileNumber;
            address.areaCode = values.celAreaCode;
          }

          if (x.typeAddress === 1) {
            address.country = 1;
            address.clientId = user.clientId;
            address.cliAddresId = 0;
            address.fullAddress = values.fullAddress;
            address.province = values.province;
            address.phone = values.phoneNumber;
            address.areaCode = values.phoneAreaCode;
          }

          cliAddressAndPhone.push(address);
        });
      } else {
        cliAddressAndPhone.push({
          country: 1,
          clientId: user.clientId,
          cliAddresId: 0,
          typeAddress: 2,
          fullAddress: values.fullAddress,
          province: values.province,
          phone: values.mobileNumber,
          areaCode: values.celAreaCode,
        });
        cliAddressAndPhone.push({
          country: 1,
          clientId: user.clientId,
          cliAddresId: 0,
          typeAddress: 1,
          fullAddress: values.fullAddress,
          province: values.province,
          phone: values.phoneNumber,
          areaCode: values.phoneAreaCode,
        });
      }

      if (user.clients.cliRelations != null && !user.clientId) {
        user.clients.cliRelations.map((x) => {
          let relation = { ...x };
          //DNI FRENTE
          if (x.clientIdRel === -999996) {
            relation.additionalInfo = values.frontDocument;
          }
          //DNI DORSO
          if (x.clientIdRel === -999995) {
            relation.additionalInfo = values.backDocument;
          }
          if (
            x.clientIdRel === -999997 ||
            x.clientIdRel === -999998 ||
            x.clientIdRel === -999999
          ) {
            relation.relSectional = [4, 5].includes(values.situation)
              ? values.sectional
              : 0;
            relation.unionCard = [4].includes(values.situation)
              ? String(values.cardNumber)
              : "";
          }

          cliRelations.push(relation);
        });

        //RECIBO DE SUELDO
        if (
          [4, 5, 6].includes(values.situation) &&
          !user.clients.cliRelations.find(
            (x) =>
              x.clientIdRel === -999997 ||
              x.clientIdRel === -999998 ||
              x.clientIdRel === -999999
          )
        ) {
          const newRelation = {
            cliRelationId: 0,
            clientId: user.clientId,
            clientIdRel: [4, 5].includes(values.situation) ? 0 : -999997,
            relationship: [6].includes(values.situation)
              ? 4
              : values.relationship,
            relSectional: [4, 5].includes(values.situation)
              ? values.sectional
              : 0,
            unionCard: [4].includes(values.situation)
              ? String(values.cardNumber)
              : "",
            additionalInfo: [4, 5, 6].includes(values.situation)
              ? values.file
              : null,
          };
          cliRelations.push(newRelation);
        }
      }

      if (user.clients.cliExternals.length > 0) {
        cliExternals = user.clients.cliExternals.map((x) => {
          let relation = { ...x };
          return relation;
        });
      }

      const userRequest = {
        ...user,
        email: values.email,
        userName: values.email,
        clients: {
          ...user.clients,
          email: values.email,
          userName: values.email,
          nationality: values.nationality,
          cliAddress: cliAddressAndPhone,
          cliDocuments: cliDocuments,
          notes: values.notes,
          cliNaturals: {
            ...user.clients.cliNaturals,
            firstName: values.firstName,
            lastName: values.lastName,
          },
          cliSocialEconomics: {
            ...user.clients.cliSocialEconomics,
            birthDate: values.birthDate,
            labouralSituationId: values.labouralSituation,
            genderId: values.genre,
            sectionalId: [1, 6].includes(values.situation)
              ? values.sectional
              : 0,
            situationId: values.situation,
            academicId: values.academic,
          },
          cliRelations: cliRelations,
          cliExternals: cliExternals,
          students: {
            ...user.clients.students,
            clients: null,
            nickName: values.firstName,
            typeStudentId: [4, 5, 6].includes(values.situation)
              ? 1
              : user.clients.students.typeStudentId,
            studentId: user.clients.students.studentId
              ? user.clients.students.studentId
              : 0,
          },
        },
      };
      const validateData = await dispatch(tryValidateStudentData(userRequest));
      if (validateData.payload.data.isValid) {
        navegateToCoursesAvailables(values);
      }
    },
  });

  // LLEVAR TODOS LOS DATOS AL ESTADO Y NAVEGAR A LA PAGINA SIGUIENTE
  const navegateToCoursesAvailables = (values) => {
    let cliDocuments = [];

    let cliAddressAndPhone = [];
    let cliRelations = [];
    let cliExternals = null;

    if (user.clients.cliDocuments != null) {
      user.clients.cliDocuments.map((x) => {
        let documents = { ...x };

        if (x.typeDocumentId === 2) {
          documents.document = values.cuil;
        }
        if (x.typeDocumentId === 1) {
          documents.document = values.document;
        }

        cliDocuments.push(documents);
      });
    }

    if (user.clients.cliAdress != null) {
      user.clients.cliAddress.map((x) => {
        let address = { ...x };

        if (x.typeAddress === 2) {
          address.country = 1;
          address.clientId = user.clientId;
          address.cliAddresId = 0;
          address.fullAddress = values.fullAddress;
          address.province = values.province;
          address.phone = values.mobileNumber;
          address.areaCode = values.celAreaCode;
        }

        if (x.typeAddress === 1) {
          address.country = 1;
          address.clientId = user.clientId;
          address.cliAddresId = 0;
          address.fullAddress = values.fullAddress;
          address.province = values.province;
          address.phone = values.phoneNumber;
          address.areaCode = values.phoneAreaCode;
        }

        cliAddressAndPhone.push(address);
      });
    } else {
      cliAddressAndPhone.push({
        country: 1,
        clientId: user.clientId,
        cliAddresId: 0,
        typeAddress: 2,
        fullAddress: values.fullAddress,
        province: values.province,
        phone: values.mobileNumber,
        areaCode: values.celAreaCode,
      });
      cliAddressAndPhone.push({
        country: 1,
        clientId: user.clientId,
        cliAddresId: 0,
        typeAddress: 1,
        fullAddress: values.fullAddress,
        province: values.province,
        phone: values.phoneNumber,
        areaCode: values.phoneAreaCode,
      });
    }

    if (user.clients.cliRelations != null && !user.clientId) {
      user.clients.cliRelations.map((x) => {
        let relation = { ...x };
        //DNI FRENTE
        if (x.clientIdRel === -999996) {
          relation.additionalInfo = values.frontDocument;
        }
        //DNI DORSO
        if (x.clientIdRel === -999995) {
          relation.additionalInfo = values.backDocument;
        }
        if (
          x.clientIdRel === -999997 ||
          x.clientIdRel === -999998 ||
          x.clientIdRel === -999999
        ) {
          relation.relSectional = [4, 5].includes(values.situation)
            ? values.sectional
            : 0;
          relation.unionCard = [4].includes(values.situation)
            ? String(values.cardNumber)
            : "";
        }

        cliRelations.push(relation);
      });

      //RECIBO DE SUELDO
      if (
        [4, 5, 6].includes(values.situation) &&
        !user.clients.cliRelations.find(
          (x) =>
            x.clientIdRel === -999997 ||
            x.clientIdRel === -999998 ||
            x.clientIdRel === -999999
        )
      ) {
        const newRelation = {
          cliRelationId: 0,
          clientId: user.clientId,
          clientIdRel: [4, 5].includes(values.situation) ? 0 : -999997,
          relationship: [6].includes(values.situation)
            ? 4
            : values.relationship,
          relSectional: [4, 5].includes(values.situation)
            ? values.sectional
            : 0,
          unionCard: [4].includes(values.situation)
            ? String(values.cardNumber)
            : "",
          additionalInfo: [4, 5, 6].includes(values.situation)
            ? values.file
            : null,
        };
        cliRelations.push(newRelation);
      }
    }

    if (user.clients.cliExternals.length > 0) {
      cliExternals = user.clients.cliExternals.map((x) => {
        let relation = { ...x };
        return relation;
      });
    }

    const userRequest = {
      ...user,
      email: values.email,
      userName: values.email,
      clients: {
        ...user.clients,
        email: values.email,
        userName: values.email,
        nationality: values.nationality,
        cliAddress: cliAddressAndPhone,
        cliDocuments: cliDocuments,
        notes: values.notes,
        cliNaturals: {
          ...user.clients.cliNaturals,
          firstName: values.firstName,
          lastName: values.lastName,
        },
        cliSocialEconomics: {
          ...user.clients.cliSocialEconomics,
          birthDate: values.birthDate,
          labouralSituationId: values.labouralSituation,
          genderId: values.genre,
          academicId: values.academic,
          sectionalId: [1, 6].includes(values.situation) ? values.sectional : 0,
          situationId: values.situation,
        },
        cliRelations: cliRelations,
        cliExternals: cliExternals,
        students: {
          ...user.clients.students,
          clients: null,
          nickName: values.firstName,
          typeStudentId: [4, 5, 6].includes(values.situation)
            ? 1
            : user.clients.students.typeStudentId,
          studentId: user.clients.students.studentId
            ? user.clients.students.studentId
            : 0,
        },
      },
    };

    dispatch(tryUpdateUser(userRequest));

    setCurrentStep(currentStep + 1);
  };

  const onlyLetters = (event) => {
    const onlyLettersPattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/;
    if (!onlyLettersPattern.test(event.key)) {
      event.preventDefault();
    }
  };

  const fetchData = useCallback(async () => {
    const action = await dispatch(tryValidateNeo(cuil));

    if (action.payload.data.isValid) {
      const userResponse = action.payload.data.data;

      var documentCuil = userResponse.clients.cliDocuments?.filter(
        (x) => x.typeDocumentId == 2
      );

      var documentDNI = userResponse.clients.cliDocuments?.filter(
        (x) => x.typeDocumentId == 1
      );

      var celPhone = userResponse.clients.cliAddress?.filter(
        (x) => x.typeAddress == 2
      );

      var phone = userResponse.clients.cliAddress?.filter(
        (x) => x.typeAddress == 1
      );

      formik.setValues({
        firstName: userResponse.clients.cliNaturals.firstName ?? "",
        lastName: userResponse.clients.cliNaturals.lastName ?? "",
        emailCopy: userResponse.clients.email ?? "",
        email: userResponse.clients.email ?? "",
        birthDate: userResponse.clients.cliSocialEconomics.birthDate,
        fullAddress: userResponse.clients.cliAddress[0]?.fullAddress ?? "",
        phoneNumber: phone[0]?.phone ?? "",
        mobileNumber: celPhone[0]?.phone ?? "",
        document: documentDNI[0] ? documentDNI[0].document : null,
        cuil: documentCuil[0] ? documentCuil[0].document : null,
        labouralSituation:
          userResponse.clients.cliSocialEconomics.situationId == 1
            ? 1
            : userResponse.clients.cliSocialEconomics.labouralSituationId !== 0
            ? userResponse.clients.cliSocialEconomics.labouralSituationId
            : null,
        academic:
          userResponse.clients.cliSocialEconomics.academicId !== 0
            ? userResponse.clients.cliSocialEconomics.academicId
            : null,
        genre: userResponse.clients.cliSocialEconomics.genderId,
        nationality: userResponse.clients.nationality,
        celAreaCode: celPhone[0]?.areaCode ?? "",
        phoneAreaCode: phone[0]?.areaCode ?? "",

        relationship:
          userResponse.clients.cliSocialEconomics.situationId === 4
            ? userResponse.clients.cliRelations.find(
                (x) => x.clientIdRel === -999999
              )?.relationship
            : userResponse.clients.cliSocialEconomics.situationId === 5
            ? userResponse.clients.cliRelations.find(
                (x) => x.clientIdRel === -999998
              )?.relationship
            : "",
        sectional:
          userResponse.clients.cliSocialEconomics.situationId === 4
            ? userResponse.clients.cliRelations.find(
                (x) => x.clientIdRel === -999999
              )?.relSectional
            : userResponse.clients.cliSocialEconomics.situationId === 5
            ? userResponse.clients.cliRelations.find(
                (x) => x.clientIdRel === -999998
              )?.relSectional
            : userResponse.clients.cliSocialEconomics.situationId === 1 ||
              userResponse.clients.cliSocialEconomics.situationId === 6
            ? userResponse.clients.cliSocialEconomics.sectionalId
            : "",
        cardNumber: [4].includes(
          userResponse.clients.cliSocialEconomics.situationId
        )
          ? userResponse.clients.cliRelations.find(
              (x) => x.clientIdRel === -999999
            )?.unionCard
          : "",
        province: userResponse.clients.cliAddress[0]?.province ?? 1,

        situation:
          userResponse.clients.cliSocialEconomics !== 0
            ? userResponse.clients.cliSocialEconomics.situationId
            : 3,
        fileRequired: userResponse.clientId ? false : true,
        frontDocument:
          userResponse.clients.cliRelations.find(
            (x) => x.clientIdRel === -999996
          )?.additionalInfo || "",
        backDocument:
          userResponse.clients.cliRelations.find(
            (x) => x.clientIdRel === -999995
          )?.additionalInfo || "",
        file:
          userResponse.clients.cliRelations.find(
            (x) => x.clientIdRel !== -999995 && x.clientIdRel !== -999996
          )?.additionalInfo || "",
      });
    }
  }, []);

  useEffect(() => {
    if (!user.clientId) {
      formik.setFieldValue("file", null);
      formik.setFieldValue("sectional", null);
      formik.setFieldValue("cardNumber", null);
      formik.setFieldValue("relationship", null);
    }
  }, [formik.values.situation]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (typeTables?.TBLPROVINCES?.data !== null) {
      const province = typeTables?.TBLPROVINCES?.data.find(
        (x) => x.id == formik.values.province
      );
      setSelectedProvince(province);
    }
  }, [formik.values.province, typeTables?.TBLPROVINCES?.data]);

  useEffect(() => {
    dispatch(fetchTypeTable(16)); // Nationality
    dispatch(fetchTypeTable(45)); // Academic
    dispatch(fetchTypeTable(14)); // LabouralSituation
    dispatch(fetchTypeTable(24)); // StudentTypes
    dispatch(fetchTypeTable(18)); // Relationship
    dispatch(fetchTypeTable(19)); // Sectionals
    dispatch(fetchTypeTable(17)); // Provinces
    dispatch(fetchTypeTable(49)); // Gender
    dispatch(fetchTypeTable(20)); // Situation
  }, []);

  const affiliateNumber =
    user.clients.cliDocuments?.filter((x) => x.typeDocumentId == 4) || false;

  function useCustomDropzone(fieldName, onDropAccepted) {
    const {
      getRootProps,
      getInputProps,
      acceptedFiles, // Agrega acceptedFiles aquí
    } = useDropzone({
      noKeyboard: true,
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpeg", ".jpg"],
        "application/pdf": [".pdf"],
      },
      maxFiles: 1,
      multiple: false,
      maxSize: 10000000,
      onDropAccepted: onDropAccepted,
    });

    const files = acceptedFiles.map((file) => (
      <ListItem key={file.path}>
        <ListItemIcon>
          <UploadFileOutlined color="primary" />
        </ListItemIcon>
        <ListItemText primary={file.path} />
      </ListItem>
    ));

    return {
      getRootProps,
      getInputProps,
      files,
    };
  }

  const { getRootProps, getInputProps, files } = useCustomDropzone(
    "file",
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        formik.setFieldValue("file", base64data);
      };
    }
  );

  const {
    getRootProps: getRootProps2,
    getInputProps: getInputProps2,
    files: files2,
  } = useCustomDropzone("frontDocument", (acceptedFiles2) => {
    const file2 = acceptedFiles2[0];
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    reader.onloadend = () => {
      const base64data = reader.result;
      formik.setFieldValue("frontDocument", base64data);
    };
  });

  const {
    getRootProps: getRootProps3,
    getInputProps: getInputProps3,
    files: files3,
  } = useCustomDropzone("backDocument", (acceptedFiles3) => {
    const file3 = acceptedFiles3[0];
    const reader = new FileReader();
    reader.readAsDataURL(file3);
    reader.onloadend = () => {
      const base64data = reader.result;
      formik.setFieldValue("backDocument", base64data);
    };
  });

  return (
    <Grid
      container
      spacing={2}
      sx={{
        margin: isMobile ? 0 : 2,
      }}
    >
      <Grid
        sx={{
          backgroundColor: "primary.contrastText",
        }}
        item
        xs={12}
        md={12}
      >
        <Box sx={{ width: "100%" }}>
          <Grid display="flex" justifyContent="left" alignItems="left">
            <Button
              variant="text"
              sx={{ marginTop: 0, marginBottom: 2, width: 100 }}
              disabled={user.isLoading}
              onClick={() => errorsCleaner()}
              type="button"
              style={{ fontWeight: "bold" }}
            >
              Volver
            </Button>
          </Grid>
          <Typography
            variant="h5"
            align="center"
            color="primary"
            sx={{
              paddingLeft: 5,
              paddingRight: 5,
              paddingBottom: 1,
              marginTop: isMobile ? "5px" : -5,
            }}
          >
            Sistema Integral de Formación
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="primary"
            sx={{
              fontWeight: "700",
              paddingLeft: 5,
              paddingRight: 5,
              paddingBottom: 2,
            }}
          >
            <ContactEmergencyOutlined sx={{ marginRight: 2 }} />
            Datos personales
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={process.env.PUBLIC_URL + "/images/personalData.png"}
              width="200px"
            />
          </div>
          {user.isLoading ? (
            <PersonalDataSkeleton />
          ) : (
            <Container
              fixed
              sx={{
                paddingTop: 5,
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="firstName"
                      label="Nombre"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onKeyDown={onlyLetters}
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      type="text"
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      disabled={
                        (situation.includes(formik.values.situation) &&
                          user.clients.cliNaturals.firstName != null) ||
                        user.clientId
                      }
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="lastName"
                      label="Apellido"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                      disabled={
                        (situation.includes(formik.values.situation) &&
                          user.clients.cliNaturals.lastName != null) ||
                        user.clientId
                      }
                      onKeyDown={onlyLetters}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="job-label">Nacionalidad</InputLabel>
                      <Select
                        labelId="nationality-label"
                        name="nationality"
                        value={formik.values.nationality}
                        label="Nacionalidad"
                        size="small"
                        onChange={formik.handleChange}
                        // disabled={
                        //   situation.includes(formik.values.situation) ||
                        //   user.clientId
                        // }
                        error={
                          formik.touched.nationality &&
                          Boolean(formik.errors.nationality)
                        }
                      >
                        {typeTables.TBLNATIONALITYS
                          ? convertToSelectList(
                              typeTables.TBLNATIONALITYS.data
                            ).map((x) => (
                              <MenuItem key={x.value} value={x.value}>
                                {x.text}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      <FormHelperText
                        htmlFor="nationality"
                        error={
                          formik.touched.nationality &&
                          !!formik.errors.nationality
                        }
                      >
                        {formik.errors.nationality}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <FormLabel id="genre-label">Género</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="genre-label"
                        name="genre"
                        onChange={formik.handleChange}
                        value={formik.values.genre}
                      >
                        {typeTables.TBLGENDER
                          ? convertToSelectList(typeTables.TBLGENDER.data).map(
                              (x) => (
                                <FormControlLabel
                                  value={x.value}
                                  onChange={formik.handleChange}
                                  control={<Radio size="small" />}
                                  label={x.text}
                                  disabled={situation.includes(
                                    formik.values.situation
                                  )}
                                />
                              )
                            )
                          : null}
                      </RadioGroup>
                      <FormHelperText
                        htmlFor="genre"
                        error={formik.touched.genre && formik.errors.genre}
                      >
                        {formik.errors.genre}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <NumericFormat
                        id="document"
                        label="DNI"
                        customInput={TextField}
                        variant="outlined"
                        size="small"
                        onChange={formik.handleChange}
                        value={formik.values.document}
                        error={
                          formik.touched.document &&
                          Boolean(formik.errors.document)
                        }
                        helperText={
                          formik.touched.document && formik.errors.document
                        }
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <PatternFormat
                        id="cuil"
                        label="CUIL"
                        customInput={TextField}
                        format="##-########-#"
                        mask="_"
                        variant="outlined"
                        size="small"
                        disabled={true}
                        onChange={formik.handleChange}
                        value={formik.values.cuil}
                        required
                        error={
                          formik.touched.cuil && Boolean(formik.errors.cuil)
                        }
                        helperText={formik.touched.cuil && formik.errors.cuil}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="email"
                      label="Correo Electrónico"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onPaste={(event) => event.preventDefault()}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      type="email"
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="emailCopy"
                      label="Confirmación Correo Electrónico"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onPaste={(event) => event.preventDefault()}
                      onChange={formik.handleChange}
                      value={formik.values.emailCopy}
                      type="email"
                      autoComplete="off"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="fullAddress"
                      label="Dirección"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.fullAddress}
                      error={
                        formik.touched.fullAddress &&
                        Boolean(formik.errors.fullAddress)
                      }
                      helperText={
                        formik.touched.fullAddress
                          ? formik.errors.fullAddress
                          : null
                      }
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        size="small"
                        onChange={(e, newV) => {
                          setSelectedProvince(newV);
                          formik.values.province = newV?.id;
                          formik.handleChange(e);
                        }}
                        value={selectedProvince}
                        options={typeTables?.TBLPROVINCES?.data || []}
                        getOptionLabel={(option) => option.description || ""}
                        renderInput={(params) => (
                          <TextField {...params} label="Provincia" />
                        )}
                      />
                      {formik.touched.province && (
                        <FormHelperText
                          htmlFor="province"
                          error={
                            formik.touched.province && !!formik.errors.province
                          }
                        >
                          {formik.errors.province}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="phoneAreaCode"
                        label="Código de Área"
                        variant="outlined"
                        size="small"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxlength: "4",
                        }}
                        fullWidth
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length > 0 && value[0] === "0") {
                            formik.setFieldValue(
                              "phoneAreaCode",
                              value.substring(1)
                            );
                          } else {
                            formik.setFieldValue("phoneAreaCode", value);
                          }
                        }}
                        value={formik.values.phoneAreaCode}
                        error={
                          formik.touched.phoneAreaCode &&
                          Boolean(formik.errors.phoneAreaCode)
                        }
                        helperText={
                          formik.touched.phoneAreaCode &&
                          formik.errors.phoneAreaCode
                        }
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <NumericFormat
                        id="phoneNumber"
                        label="Teléfono"
                        variant="outlined"
                        size="small"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxlength: "10",
                        }}
                        fullWidth
                        customInput={TextField}
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}
                        error={
                          formik.touched.phoneNumber &&
                          Boolean(formik.errors.phoneNumber)
                        }
                        helperText={
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                        }
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="celAreaCode"
                        label="Código de Área"
                        variant="outlined"
                        size="small"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxlength: "4",
                        }}
                        fullWidth
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length > 0 && value[0] === "0") {
                            formik.setFieldValue(
                              "celAreaCode",
                              value.substring(1)
                            );
                          } else {
                            formik.setFieldValue("celAreaCode", value);
                          }
                        }}
                        value={formik.values.celAreaCode}
                        error={
                          formik.touched.celAreaCode &&
                          Boolean(formik.errors.celAreaCode)
                        }
                        helperText={
                          formik.touched.celAreaCode &&
                          formik.errors.celAreaCode
                        }
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <NumericFormat
                        id="mobileNumber"
                        label="Celular"
                        variant="outlined"
                        size="small"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxlength: "10",
                        }}
                        onChange={formik.handleChange}
                        value={formik.values.mobileNumber}
                        customInput={TextField}
                        error={
                          formik.touched.mobileNumber &&
                          Boolean(formik.errors.mobileNumber)
                        }
                        helperText={
                          formik.touched.mobileNumber &&
                          formik.errors.mobileNumber
                        }
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Alert severity="info">
                      En situaciones urgentes, es fundamental tener números de
                      teléfono y nombres de personas para atender emergencias.
                      La información es confidencial.
                    </Alert>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="notes"
                      label="Información de emergencia"
                      multiline
                      rows={5}
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.notes}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="es"
                    >
                      <DesktopDatePicker
                        id="birthDate"
                        disableFuture
                        name="birthDate"
                        label="Fecha de Nacimiento"
                        inputFormat="DD/MM/YYYY"
                        value={formik.values.birthDate}
                        onChange={(value) => {
                          formik.setFieldValue(
                            "birthDate",
                            dayjs(value).format("YYYY-MM-DD")
                          );
                        }}
                        type="date"
                        disabled={
                          (situation.includes(formik.values.situation) &&
                            user.clients.cliSocialEconomics.birthDate !==
                              null) ||
                          user.clientId
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            variant="outlined"
                            error={
                              formik.touched.birthDate &&
                              Boolean(formik.errors.birthDate)
                            }
                            helperText={
                              formik.touched.birthDate &&
                              formik.errors.birthDate
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} textAlign="left">
                    <FormControl fullWidth>
                      <InputLabel id="academic-label">
                        Nivel de Estudios Alcanzados
                      </InputLabel>
                      <Select
                        key={
                          formik.values.academic !== null
                            ? formik.values.academic
                            : "default"
                        }
                        labelId="academic-label"
                        name="academic"
                        value={formik.values.academic}
                        label="Nivel de Estudios Alcanzados"
                        size="small"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.academic &&
                          Boolean(formik.errors.academic)
                        }
                      >
                        {typeTables.TBLACADEMICS &&
                          convertToSelectList(typeTables.TBLACADEMICS.data).map(
                            (x) => (
                              <MenuItem key={x.value} value={x.value}>
                                {x.text}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      {formik.touched.academic && (
                        <FormHelperText
                          htmlFor="academic"
                          error={
                            formik.touched.academic && !!formik.errors.academic
                          }
                        >
                          {formik.errors.academic}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="labouralSituation-label">
                        Situación Laboral Actual
                      </InputLabel>
                      <Select
                        key={
                          formik.values.labouralSituation !== null
                            ? formik.values.labouralSituation
                            : "default"
                        }
                        labelId="labouralSituation-label"
                        name="labouralSituation"
                        value={formik.values.labouralSituation}
                        disabled={formik.values.situation == 1}
                        label="Situación Laboral Actual"
                        size="small"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.labouralSituation &&
                          Boolean(formik.errors.labouralSituation)
                        }
                      >
                        {typeTables.TBLLABOURSITUATIONS &&
                          convertToSelectList(
                            typeTables.TBLLABOURSITUATIONS.data
                          ).map((x) => (
                            <MenuItem key={x.value} value={x.value}>
                              {x.text}
                            </MenuItem>
                          ))}
                      </Select>
                      {formik.touched.labouralSituation && (
                        <FormHelperText
                          htmlFor="labouralSituation"
                          error={
                            formik.touched.labouralSituation &&
                            !!formik.errors.labouralSituation
                          }
                        >
                          {formik.errors.labouralSituation}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="situation-label">
                        Relación con UTEDYC
                      </InputLabel>
                      <Select
                        key={
                          formik.values.situation !== null
                            ? formik.values.situation
                            : "default"
                        }
                        labelId="situation-label"
                        name="situation"
                        value={formik.values.situation}
                        label="Relación con UTEDYC"
                        size="small"
                        onChange={formik.handleChange}
                        disabled={
                          situation.includes(formik.values.situation) ||
                          user.clientId
                        }
                        error={
                          formik.touched.situation &&
                          Boolean(formik.errors.situation)
                        }
                      >
                        {typeTables.TBLSITUATIONS &&
                          convertToSelectList(
                            ![1, 2].includes(formik.values.situation) // EN CASO DE NO SER AFILIADO, APORTANTE, EMPLEADO SE DEBE FILTRAR LA LISTA
                              ? typeTables.TBLSITUATIONS.data.filter((x) =>
                                  [3, 4, 5, 6].includes(x.id)
                                )
                              : typeTables.TBLSITUATIONS.data // EN CASO DE  SER AFILIADO, APORTANTE, EMPLEADO SE MUESTRA LA LISTA COMPLETA
                          ).map((x) => (
                            <MenuItem key={x.value} value={x.value}>
                              {x.text}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText
                        htmlFor="situation"
                        error={
                          formik.touched.situation && !!formik.errors.situation
                        }
                      >
                        {formik.errors.situation}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {affiliateNumber.length !== 0 && (
                    <>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            labelId="affiliateNumber-label"
                            name="affiliateNumber"
                            label="Nro. de Afiliado"
                            value={affiliateNumber[0]?.document}
                            size="small"
                            disabled={true}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}

                  {[1, 4, 5, 6].includes(formik.values.situation) && (
                    <>
                      {[4, 5].includes(formik.values.situation) && (
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel id="relationship-label">
                              Parentesco
                            </InputLabel>
                            <Select
                              labelId="relationship-label"
                              name="relationship"
                              value={formik.values.relationship}
                              label="Tipo de Parentesco"
                              size="small"
                              onChange={formik.handleChange}
                              disabled={
                                situation.includes(formik.values.situation) ||
                                user.clientId
                              }
                              error={
                                formik.touched.relationship &&
                                Boolean(formik.errors.relationship)
                              }
                            >
                              {typeTables.TBLRELATIONSHIPS &&
                                convertToSelectList(
                                  typeTables.TBLRELATIONSHIPS.data
                                ).map((x) => (
                                  <MenuItem key={x.value} value={x.value}>
                                    {x.text}
                                  </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText
                              htmlFor="relationship"
                              error={
                                formik.touched.relationship &&
                                !!formik.errors.relationship
                              }
                            >
                              {formik.errors.relationship}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <InputLabel id="sectional-label">
                            Seccional
                          </InputLabel>
                          <Select
                            labelId="sectional-label"
                            name="sectional"
                            value={formik.values.sectional}
                            label="Seccional"
                            size="small"
                            disabled={user.clientId}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.sectional &&
                              Boolean(formik.errors.sectional)
                            }
                          >
                            {typeTables.TBLSECTIONALS &&
                              convertToSelectList(
                                typeTables.TBLSECTIONALS.data
                              ).map((x) => (
                                <MenuItem key={x.value} value={x.value}>
                                  {x.text}
                                </MenuItem>
                              ))}
                          </Select>
                          <FormHelperText
                            htmlFor="sectional"
                            error={
                              formik.touched.sectional &&
                              !!formik.errors.sectional
                            }
                          >
                            {formik.errors.sectional}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      {formik.values.situation === 4 && (
                        <>
                          <Grid item xs={6} />
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <NumericFormat
                                id="cardNumber"
                                value={formik.values.cardNumber}
                                label="Nro de Carnet UTEDYC"
                                disabled={user.clientId}
                                variant="outlined"
                                customInput={TextField}
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                }}
                                type="number"
                                size="small"
                                fullWidth
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.cardNumber &&
                                  Boolean(formik.errors.cardNumber)
                                }
                                helperText={
                                  formik.touched.cardNumber &&
                                  formik.errors.cardNumber
                                }
                              ></NumericFormat>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                <Divider sx={{ marginTop: 2 }} />
                {!user.clientId && (
                  <>
                    <Typography
                      variant="h6"
                      align="center"
                      color="primary"
                      sx={{
                        fontWeight: "700",
                        paddingLeft: 5,
                        paddingRight: 5,
                        paddingBottom: 2,
                        paddingTop: 2,
                      }}
                    >
                      <PermMediaOutlined sx={{ marginRight: 2 }} />
                      Datos adicionales de validación:
                    </Typography>
                    <Grid container xs={12}>
                      {/* DNI FRENTE ****************/}
                      <Grid container xs={4}>
                        <Paper
                          elevation={3}
                          sx={{
                            borderStyle: "dashed",
                            borderColor: "#85BFE6",
                            marginTop: 2,
                            paddingLeft: 1,
                            paddingRight: 1,
                          }}
                        >
                          <section
                            style={
                              fileErrorOn && formik.errors.frontDocument
                                ? { border: "2px solid red" }
                                : {}
                            }
                          >
                            <div {...getRootProps2({ className: "dropzone" })}>
                              <input {...getInputProps2()} />
                              <Grid container xs={12} sx={{ align: "center" }}>
                                <Grid item xs={12} align="center">
                                  <Typography
                                    color="primary"
                                    align="center"
                                    variant="h5"
                                    sx={{
                                      fontWeight: 700,
                                      paddingTop: 1,
                                      paddingBottom: 1,
                                    }}
                                  >
                                    FOTO DNI FRENTE
                                  </Typography>
                                  <Badge
                                    color="primary"
                                    fontSize="large"
                                    align="center"
                                  />
                                  <Typography
                                    color="primary"
                                    align="center"
                                    variant="subtitle2"
                                    sx={{
                                      fontWeight: 700,
                                      paddingTop: 1,
                                      paddingBottom: 1,
                                    }}
                                  >
                                    Seleccione un archivo o arrástrelo aquí
                                  </Typography>
                                  <CloudUploadOutlined
                                    color="primary"
                                    fontSize="large"
                                    align="center"
                                  />
                                  <Typography
                                    color="primary"
                                    align="center"
                                    variant="subtitle2"
                                    sx={{ paddingTop: 1 }}
                                  >
                                    JPG, PNG o PDF con un tamaño no mayor a 10
                                    Mb.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                            <List sx={{ width: "100%" }}>
                              {formik.values.frontDocument && files2}
                            </List>
                          </section>
                        </Paper>

                        {fileErrorOn && (
                          <FormHelperText
                            htmlFor="documentFile"
                            error={formik.errors.frontDocument}
                          >
                            {formik.errors.frontDocument}
                          </FormHelperText>
                        )}
                      </Grid>
                      {/* DNI DORSO ****************/}
                      <Grid container xs={4}>
                        <Paper
                          elevation={3}
                          sx={{
                            borderStyle: "dashed",
                            borderColor: "#85BFE6",
                            marginTop: 2,
                            paddingLeft: 1,
                            paddingRight: 1,
                          }}
                        >
                          <section
                            style={
                              fileErrorOn && formik.errors.backDocument
                                ? { border: "2px solid red" }
                                : {}
                            }
                          >
                            <div {...getRootProps3({ className: "dropzone" })}>
                              <input {...getInputProps3()} />
                              <Grid container xs={12} sx={{ align: "center" }}>
                                <Grid item xs={12} align="center">
                                  <Typography
                                    color="primary"
                                    align="center"
                                    variant="h5"
                                    sx={{
                                      fontWeight: 700,
                                      paddingTop: 1,
                                      paddingBottom: 1,
                                    }}
                                  >
                                    FOTO DNI DORSO
                                  </Typography>
                                  <Badge
                                    color="primary"
                                    fontSize="large"
                                    align="center"
                                  />
                                  <Typography
                                    color="primary"
                                    align="center"
                                    variant="subtitle2"
                                    sx={{
                                      fontWeight: 700,
                                      paddingTop: 1,
                                      paddingBottom: 1,
                                    }}
                                  >
                                    Seleccione un archivo o arrástrelo aquí
                                  </Typography>
                                  <CloudUploadOutlined
                                    color="primary"
                                    fontSize="large"
                                    align="center"
                                  />
                                  <Typography
                                    color="primary"
                                    align="center"
                                    variant="subtitle2"
                                    sx={{ paddingTop: 1 }}
                                  >
                                    JPG, PNG o PDF con un tamaño no mayor a 10
                                    Mb.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                            <List sx={{ width: "100%" }}>
                              {formik.values.backDocument && files3}
                            </List>
                          </section>
                        </Paper>

                        {fileErrorOn && (
                          <FormHelperText
                            htmlFor="documentFile"
                            error={formik.errors.backDocument}
                          >
                            {formik.errors.backDocument}
                          </FormHelperText>
                        )}
                      </Grid>

                      {/* // RECIBO DE SUELDO DEL FAMILIAR */}
                      <Grid container xs={4}>
                        {[4, 5, 6].includes(formik.values.situation) ? (
                          <>
                            <Paper
                              elevation={3}
                              sx={{
                                borderStyle: "dashed",
                                borderColor: "#85BFE6",
                                marginTop: 2,
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <section
                                style={
                                  fileErrorOn && formik.errors.file
                                    ? { border: "2px solid red" }
                                    : {}
                                }
                              >
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                >
                                  <input {...getInputProps()} />
                                  <Grid
                                    container
                                    xs={12}
                                    sx={{ align: "center" }}
                                  >
                                    <Grid item xs={12} align="center">
                                      <Typography
                                        color="primary"
                                        align="center"
                                        variant="h5"
                                        sx={{
                                          fontWeight: 700,
                                          paddingTop: 1,
                                          paddingBottom: 1,
                                        }}
                                      >
                                        RECIBO DE SUELDO
                                      </Typography>
                                      <Receipt
                                        color="primary"
                                        fontSize="large"
                                        align="center"
                                      />
                                      <Typography
                                        color="primary"
                                        align="center"
                                        variant="subtitle2"
                                        sx={{
                                          fontWeight: 700,
                                          paddingTop: 1,
                                          paddingBottom: 1,
                                        }}
                                      >
                                        Seleccione un archivo o arrástrelo aquí
                                      </Typography>
                                      <CloudUploadOutlined
                                        color="primary"
                                        fontSize="large"
                                        align="center"
                                      />
                                      <Typography
                                        color="primary"
                                        align="center"
                                        variant="subtitle2"
                                        sx={{ paddingTop: 1 }}
                                      >
                                        JPG, PNG o PDF con un tamaño no mayor a
                                        10 Mb.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                                <List sx={{ width: "100%" }}>
                                  {formik.values.file && files}
                                </List>
                              </section>
                            </Paper>

                            {fileErrorOn && (
                              <FormHelperText
                                htmlFor="documentFile"
                                error={formik.errors.file}
                              >
                                {formik.errors.file}
                              </FormHelperText>
                            )}
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/upload-files.png"
                                }
                                width="100px"
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid minHeight={160}>
                      <Grid
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          color="primary"
                          align="center"
                          variant="subtitle1"
                          sx={{ fontWeight: 700, paddingTop: 5 }}
                        >
                          Se requiere documentación adicional para poder
                          validarlo
                        </Typography>
                      </Grid>

                      <Grid
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          color="primary"
                          align="center"
                          variant="subtitle1"
                          sx={{ paddingBottom: 2 }}
                        >
                          Por razones de seguridad, debemos pedirle que por
                          favor cargue la correspondiente documentación de tal
                          manera que podamos validarlo dentro de nuestros
                          registros.
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    disabled={isLoading || user.isLoading}
                    sx={{ marginTop: 2, marginBottom: 2, width: 500 }}
                    type="submit"
                    onClick={() => setFileErrorOn(true)}
                  >
                    {user.isLoading ||
                      (isLoading && <Spinner color="primary" />)}
                    Continuar
                  </Button>
                </Grid>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    variant="text"
                    sx={{ marginTop: 1, marginBottom: 2, width: 500 }}
                    disabled={user.isLoading}
                    onClick={() => errorsCleaner()}
                    type="button"
                    style={{ fontWeight: "bold" }}
                  >
                    Volver
                  </Button>
                </Grid>
              </form>
            </Container>
          )}
          <Typography
            color="primary"
            align="center"
            variant="subtitle2"
            sx={{ paddingTop: 5, paddingBottom: 2 }}
          >
            <HelperMail label="¿Tienes alguna duda? Envíanos un mail" />
          </Typography>
        </Box>
        {errors && errors.length > 0 && <ModalMessages errors={errors} />}
      </Grid>
    </Grid>
  );
};

export default PersonalData;
