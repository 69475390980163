import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTeachers,
  tryGetTeacherByBOHeader,
} from "../../store/slices/teacher";
import {
  selectExportedTeachers,
  selectTeacherMails,
  selectTeachersReport,
  selectTreasuryCases,
  tryExportTeachersAll,
  tryGetTeacherMails,
  tryGetTeachersReport,
  trySendCasesToTreasury,
} from "../../store/slices/backoffice-teachers";
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  Card,
  Chip,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import {
  MoreHoriz,
  Download,
  PersonAdd,
  Email,
  Assessment,
  Payments,
} from "@mui/icons-material";
import TeachersListSkeleton from "./skeleton";
import { useNavigate } from "react-router";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";
import ModalMessages from "../layout/modal-messages";
import Spinner from "../layout/spinner";
import TeacherMailsModal from "./modal/teacherMailsModal";

const BackOfficeTeachersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //MANEJO DE ENVIAR PAGOS A TESORERIA
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const treasuryCases = useSelector(selectTreasuryCases);

  //TREA EL DETALLE DE MAILS
  const teacherEmails = useSelector(selectTeacherMails);

  //TRAE EL DETALLE DE LOS REPORTES
  const downloadedReport = useSelector(selectTeachersReport);

  //TRAE EL DETALLE DE LOS PROFESORES
  const teachers = useSelector(selectTeachers);

  //TRAE EL DETALLE DE LOS PROFESORES EXPORTADOS
  const exportedTeachers = useSelector(selectExportedTeachers);

  //MANEJO MODAL MAILS
  const [open, setOpen] = useState(false);
  const [mails, setMails] = useState(null);

  //MANEJO DE LAS COLUMNAS
  const [rows, setRows] = useState([]);

  useEffect(() => {
    dispatch(tryGetTeacherByBOHeader());
  }, []);

  useEffect(() => {
    setRows(
      teachers.data.map((x) => {
        return {
          clientId: x.clientId,
          fullName: x.fullName,
          email: x.email,
          statusCli: x.statusCli,
          cliAdderessId: x.cliAdderessId,
          phoneNumber: x.phoneNumber,
          teacherId: x.teacherId,
          cuil: addDashes(x.cuil),
          dictation: x.dictation,
          userId: x.userId,
          clientId: x.clientId,
          username: x.username,
          unansweredTicket: x.unansweredTicket,
          statusCliId: x.statusCliId,
        };
      })
    );
  }, [teachers]);

  //Exporta el listado de estudiantes
  const handleClickExport = async () => {
    const action = await dispatch(tryExportTeachersAll());
    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //DESCARGA EL REPORTE DE PROFESORES
  const handleClickReport = async () => {
    const action = await dispatch(tryGetTeachersReport());
    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //ENVIA LAS FACTURAS APROBADAS A TESORERIA Y DESCARGA EL REPORTE
  const hanldleCliclTreasuryCases = async () => {
    setOpenAlertDialog(false);
    const request = 0;
    const action = await dispatch(trySendCasesToTreasury(request));
    if (action.payload.data.isValid) setOpenDialog(true);
    window.open(action.payload.data.data.file);
  };

  //AGREGA GUIONES A LOS CUILS
  function addDashes(number) {
    if (typeof number !== "string") {
      number = number.toString();
    }

    if (number.length <= 2) {
      return number;
    }

    const pre = number.slice(0, 2);
    const mid = number.slice(2, -1);
    const last = number.slice(-1);

    return `${pre}-${mid}-${last}`;
  }

  //ACCIONES
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          courseId: false,
          teacherId: false,
          teacherCourseId: false,
          courseGroupId: false,
          amountCommited: false,
          userId: false,
          clientId: false,
          username: false,
        },
      },
      pagination: { paginationModel: { pageSize: 10 } },
    };
  };

  //NAVEGAR A TEACHERS
  const navigateNewTeacher = () => {
    navigate("/backoffice/newteacher");
  };

  const ActionsRow = ({ item }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleMenuItemClick = (event, index) => {
      //ASSIGN TEACHER
      if (index === 0) {
        navigate("/teachers", {
          state: {
            teacherId: item.row.teacherId,
            userId: item.row.userId,
            clientId: item.row.clientId,
            username: item.row.username,
          },
        });
      }

      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    <MenuItem
                      key="adminTeacher"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Administrar Profesor
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const handleClickEmails = async (isActive) => {
    const action = await dispatch(tryGetTeacherMails(isActive));
    if (action.payload.data.isValid) {
      setMails(action.payload.data.data);
      setOpen(true);
    }
  };

  const columns = [
    { field: "username", headerName: "username", flex: 1, filterable: false },
    { field: "clientId", headerName: "clientId", flex: 1, filterable: false },
    { field: "teacherId", headerName: "teacherId", flex: 1, filterable: false },
    { field: "userId", headerName: "userId", flex: 1, filterable: false },
    {
      field: "fullName",
      headerName: "Nombre Completo",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "email",
      headerName: "Correo Electrónico",
      flex: 1,
      headerClassName: "headRow",
    },

    {
      field: "cuil",
      headerName: "CUIL/CUIT",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "phoneNumber",
      headerName: "Teléfono",
      flex: 1,
      headerClassName: "headRow",
    },

    {
      field: "dictation",
      headerAlign: "center",
      align: "center",
      headerName: "Dictando",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        let label = "No";
        let chipColor = "success";

        if (params.value) {
          chipColor = "warning";
          label = "Sí";
        }

        return <Chip label={label} color={chipColor} size="small" />;
      },
    },

    {
      field: "unansweredTicket",
      headerAlign: "center",
      align: "center",
      headerName: "Msj Pend.",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        let label = "No";
        let chipColor = "success";

        if (params.value) {
          chipColor = "warning";
          label = "Sí";
        }

        return <Chip label={label} color={chipColor} size="small" />;
      },
    },

    {
      field: "statusCliId",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      headerName: "Estado",
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";
        let chipColor;
        if (params.value === 1) {
          chipColor = "success";
        }
        if (params.value === 2) {
          chipColor = "error";
        }
        if (params.value === 3) {
          chipColor = "warning";
        }

        return (
          <Chip label={params.row.statusCli} color={chipColor} size="small" />
        );
      },
    },
    {
      field: "actions",
      headerAlign: "center",

      headerName: "",
      flex: 1,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) => <ActionsRow item={params} />,
    },
  ];

  return (
    <>
      {teachers.isLoading ? (
        <TeachersListSkeleton />
      ) : (
        <>
          <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
            <Grid item xs={1}>
              <Typography
                align="left"
                style={{ fontWeight: "bold", fontSize: 18 }}
              >
                Profesores
              </Typography>
            </Grid>
            <Grid item xs={11} sx={{ textAlign: "end" }}>
              <Button
                variant="contained"
                disabled={treasuryCases.isLoading}
                endIcon={<Payments />}
                onClick={() => setOpenAlertDialog(true)}
                sx={{ marginRight: 1 }}
                color="warning"
              >
                {treasuryCases.isLoading && <Spinner color="primary" />}
                ENVIAR PAGOS
              </Button>
              <Button
                variant="contained"
                disabled={teacherEmails.isLoading}
                endIcon={<Email />}
                onClick={() => handleClickEmails(false)}
                sx={{ marginRight: 1 }}
              >
                {teacherEmails.isLoading && <Spinner color="primary" />}
                CORREOS TODOS
              </Button>
              <Button
                variant="contained"
                disabled={teacherEmails.isLoading}
                endIcon={<Email />}
                onClick={() => handleClickEmails(true)}
                sx={{ marginRight: 1 }}
              >
                {teacherEmails.isLoading && <Spinner color="primary" />}
                CORREOS DICTANDO
              </Button>

              <Button
                variant="contained"
                disabled={teachers.isLoading || exportedTeachers.isLoading}
                endIcon={<Download />}
                onClick={() => handleClickExport()}
                sx={{ marginRight: 1 }}
              >
                {exportedTeachers.isLoading && <Spinner color="primary" />}
                Exportar
              </Button>
              <Button
                variant="contained"
                disabled={
                  teachers.isLoading ||
                  exportedTeachers.isLoading ||
                  downloadedReport.isLoading
                }
                endIcon={<Assessment />}
                onClick={() => handleClickReport()}
                sx={{ marginRight: 1 }}
              >
                {downloadedReport.isLoading && <Spinner color="primary" />}
                REPORTE
              </Button>
              <Button
                variant="contained"
                endIcon={<PersonAdd />}
                color="success"
                onClick={() => navigateNewTeacher()}
              >
                CARGAR PROFESOR
              </Button>
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                slots={{
                  columnMenu: CustomColumnMenu,
                }}
                autoHeight={true}
                rowSelection={false}
                getRowId={(row) => row.clientId}
                disableRowSelectionOnClick
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 25, 50]}
                initialState={initializeDataGrid()}
                sx={{
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: 250,
                  },
                }}
              />
            </Grid>
          </Grid>
          {teachers.errors && teachers.errors.length > 0 && (
            <ModalMessages errors={teachers.errors} />
          )}
          {treasuryCases.errors && treasuryCases.errors.length > 0 && (
            <ModalMessages errors={treasuryCases.errors} />
          )}
        </>
      )}
      {openAlertDialog && (
        <>
          <Dialog open={openAlertDialog}>
            <DialogTitle id="alert-dialog-title">
              <Alert severity="warning">
                <AlertTitle>¡Advertencia!</AlertTitle>
                Al continuar con esta acción, se enviarán a tesorería todas las
                facturas que actualmente se encuentran en estado "Aprobado".
                <br />
                Este proceso es irreversible y las facturas enviadas a tesorería
                no podrán ser revertidas.
                <br />
                ¿Estás seguro de que deseas continuar?"
              </Alert>
            </DialogTitle>
            <DialogActions>
              <Button
                sx={{ color: "warning" }}
                onClick={() => hanldleCliclTreasuryCases()}
                autoFocus
              >
                Aceptar
              </Button>
              <Button onClick={() => setOpenAlertDialog(false)} autoFocus>
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
      {openDialog && (
        <>
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"El proceso ha finalizado con éxito"}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenDialog(false);
                }}
                autoFocus
              >
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
      {open && (
        <TeacherMailsModal mails={mails} open={open} setOpen={setOpen} />
      )}
    </>
  );
};

export default BackOfficeTeachersList;
