import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStudentScolarship,
  tryPostScolarship,
} from "../../../store/slices/backoffice-students";
import { Modal, TextField, Typography, Box, Button } from "@mui/material";
import { useFormik } from "formik";
import Spinner from "../../layout/spinner";
import ModalMessages from "../../layout/modal-messages";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  margin: "auto",

  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validate = (values) => {
  const errors = {};

  if (!values.notes) errors.notes = "Requerido";

  return errors;
};

const ScolarshipModal = ({
  open,
  onClose,
  studentCourseScolarshipId,
  setConfirmationDialog,
}) => {
  const dispatch = useDispatch();
  const studentScolarship = useSelector(selectStudentScolarship);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      notes: "",
    },
    validate,
    onSubmit: async (values) => {
      const notes = values.notes;
      const studentCourseId = studentCourseScolarshipId;

      const action = await dispatch(
        tryPostScolarship({ studentCourseId, notes })
      );
      if (action.payload.data.isValid) {
        setConfirmationDialog(true);
        onClose();
      }
    },
  });

  if (!open) return null;
  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={style}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="modalContainer">
                <Typography align="center" color="primary">
                  <strong>
                    <h2>CARGAR BECA</h2>
                  </strong>
                </Typography>
                <TextField
                  id="notes"
                  label="Motivo"
                  multiline
                  rows={5}
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;
                    formik.setFieldValue("notes", value);
                  }}
                  value={formik.values.notes}
                  error={formik.touched.notes && Boolean(formik.errors.notes)}
                  helperText={formik.touched.notes && formik.errors.notes}
                />
                <Button
                  type="submit"
                  variant="outlined"
                  size="large"
                  fullWidth="true"
                  sx={{ marginTop: 3 }}
                  disabled={
                    formik.values.notes === "" || studentScolarship.isLoading
                  }
                >
                  {studentScolarship.isLoading && <Spinner color="primary" />}
                  Aceptar
                </Button>
                <Button
                  onClick={() => onClose()}
                  variant="outlined"
                  color="error"
                  size="large"
                  fullWidth="true"
                  sx={{ marginTop: 1 }}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
      {studentScolarship.errors && studentScolarship.errors.length > 0 && (
        <ModalMessages errors={studentScolarship.errors} />
      )}
    </>
  );
};

export default ScolarshipModal;
