import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectStudentTicketDetails,
  selectStudentTicketsUpdate,
  tryCloseTicket,
  tryCreateStudentTicket,
  tryGetAttachmentByTicketDetail,
  tryGetStudentTicketDetails,
} from "../../../store/slices/student-tickets";
import { selectCurrentUser } from "../../../store/slices/currentUser";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Typography,
  Alert,
  DialogTitle,
} from "@mui/material";
import {
  Close,
  Send,
  Attachment,
  CloudUploadOutlined,
  UploadFileOutlined,
  Lock,
  LockOpen,
} from "@mui/icons-material";
import getExtensionFromBase64 from "../../../services/functions/getExtensionFromBase64";
import { useDropzone } from "react-dropzone";
import StudentTicketsSkeleton from "../skeleton/StudentTicketsSkeleton";
import moment from "moment/moment";

const StudentTicketsDrawer = ({
  openTicketDrawer,
  setOpenTicketDrawer,
  ticketData,
  clientId,
  businessId,
  setDialog,
}) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const messageSent = useSelector(selectStudentTicketsUpdate);

  const data = useSelector(selectStudentTicketDetails);
  const topFocusRef = useRef(null);

  //manejo de confirmacion de cierre de tickets
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  //Manejo del checkbox
  const [closed, setClosed] = useState(false);
  const handleCheckboxChange = (event) => {
    setClosed(event.target.checked);
    setConfirmationDialog(event.target.checked);
  };
  //Si se cancela el cierre del ticket el checkbox vuelve a estar false
  useEffect(() => {
    !confirmationDialog && setClosed(false);
  }, [confirmationDialog]);

  const scrollToBottom = () => {
    topFocusRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const { isLoading } = data;

  const { topicId, subTopicId, statusTicketId, closeDate, subject } = data.item;

  const [attachment, setAttachment] = useState(null);

  const [message, setMessage] = useState("");

  const [openAttachmentLoaderDialog, setOpenAttachmentLoaderDialog] =
    useState(false);

  const [showSkeleton, setShowSkeleton] = useState(false);

  //CERRAR UN TICKET
  const ticketClose = async (ticketId) => {
    const action = await dispatch(tryCloseTicket(ticketId));
    if (action.payload.data.isValid) setOpenTicketDrawer(false);
    setDialog(true);
  };

  const handleOpenAttachmentPreview = async (ticketId, ticketDetaildId) => {
    const action = await dispatch(
      tryGetAttachmentByTicketDetail({ businessId, ticketId, ticketDetaildId })
    );

    if (action.payload.isValid) {
      try {
        var attachmentElement = document.createElement("a");
        const fileExtension = getExtensionFromBase64(action.payload.data.file);
        attachmentElement.href = action.payload.data.file;

        attachmentElement.download = `Archivo-${moment().format(
          "DD-MM-YYYY-HHmmss"
        )}.${fileExtension}`;

        attachmentElement.click();
      } catch (error) {}
    }
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "	": [".pdf"],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 10000000,
    onDropAccepted: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        setAttachment(base64data.toString());
      };
    },
  });

  const files = acceptedFiles.map((filename) => (
    <ListItem key={filename.path}>
      <ListItemIcon>
        <UploadFileOutlined color="primary" />
      </ListItemIcon>
      <ListItemText key={filename.path} primary={filename.path} />
    </ListItem>
  ));

  const handleSendMessage = async () => {
    const request = {};
    request.ticketDetails = [];

    request.ticketDetails.push({
      ticketDetailId: 0,
      ticketId: ticketData.ticketId,
      senderId: currentUser.roleId !== 1 ? clientId : currentUser.clientId,
      message: message,
      attachment: attachment,
      statusMessageId: 1,
    });

    request.ticketId = ticketData.ticketId;
    request.ownerId = 0;
    request.originalSenderId = clientId;
    request.topicId = topicId;
    request.subTopicId = subTopicId;
    request.subject = subject.trim();
    request.statusTicketId = statusTicketId;
    request.closeDate = closeDate;

    const action = await dispatch(
      tryCreateStudentTicket({ businessId, request })
    );

    setMessage("");
    setAttachment(null);
    fetchData();
  };

  const RenderTicketDetails = () => {
    setShowSkeleton(true);
    return !isLoading ? (
      <List>
        {data.item.ticketDetails.map((tDetail, index) => {
          return (
            <ListItem
              key={index}
              sx={{
                border: "1px solid #c3c3c3 ",
                mt: 1,
                mb: 1,
                borderRadius: 5,
                backgroundColor:
                  tDetail.senderId === clientId ? "#dbdbdb" : "white",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <ListItemText
                    align={tDetail.senderId === clientId ? "right" : "left"}
                    primary={tDetail.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ListItemText
                    align={tDetail.senderId === clientId ? "right" : "left"}
                    onClick={() =>
                      handleOpenAttachmentPreview(
                        data.item.ticketId,
                        tDetail.ticketDetailId
                      )
                    }
                    color="red"
                    sx={{
                      ":hover": {
                        cursor: "pointer",
                      },
                      width: "130px",
                      float: "right",
                    }}
                    secondary={tDetail.attachment && "Descargar adjunto"}
                  />
                  <Typography fontSize={10}>
                    {moment(tDetail.messageDate).format("DD/MM/YYYY HH:mm")}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    ) : (
      showSkeleton && <StudentTicketsSkeleton />
    );
  };

  const fetchData = async () => {
    const studentId = businessId;
    const ticketId = ticketData.ticketId;
    await dispatch(tryGetStudentTicketDetails({ studentId, ticketId }));
    scrollToBottom();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Drawer
        anchor="right"
        open={openTicketDrawer}
        PaperProps={{
          sx: {
            zIndex: 1203,
            width: "40%",
            height: "100%",
            boxSizing: "border-box",
          },
        }}
        onClose={() => setOpenTicketDrawer(false)}
        sx={{
          zIndex: 1203,
          width: 500,
          flexShrink: 0,
        }}
      >
        <Grid container p={1}>
          <Grid item xs={10} elevation={3}>
            <Typography
              variant="h4"
              align="left"
              sx={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 2,
                paddingTop: 4,
                fontSize: 20,
              }}
              autoFocus
              ref={topFocusRef}
            >
              {subject}
            </Typography>
            <Typography
              variant="h3"
              align="left"
              sx={{
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              Tópico: {ticketData.topic}
              <br />
              Sub Tópico: {ticketData.subTopic}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setOpenTicketDrawer(false)}
            >
              <Close />
            </IconButton>
          </Grid>

          <Grid item xs={12} sx={{ padding: 3, textAlign: "end" }}></Grid>
        </Grid>
        <Grid
          container
          width="auto"
          rowSpacing={3}
          spacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          p={2}
          sx={{ backgroundColor: "white" }}
        >
          <Grid item xs={12} sx={{ color: "black", minHeight: "595px" }}>
            <RenderTicketDetails />
          </Grid>
        </Grid>

        <Grid
          sx={{
            backgroundColor: "white",
            height: "100%",
            paddingBottom: "1vw",
            p: 1,
          }}
        >
          {statusTicketId !== 3 ? (
            <>
              <FormControl
                sx={{
                  width: "100%",
                }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-message">
                  Mensaje
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-message"
                  type="text"
                  multiline
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setOpenAttachmentLoaderDialog(true)}
                        color={attachment ? "primary" : ""}
                        sx={{ mr: 1 }}
                        aria-label="toggle message visibility"
                        edge="end"
                      >
                        <Attachment />
                      </IconButton>
                      <IconButton
                        disabled={message.length === 0 || messageSent.isLoading}
                        onClick={() => handleSendMessage()}
                        sx={{ mr: 1 }}
                        aria-label="toggle message visibility"
                        edge="end"
                        color="primary"
                      >
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Mensaje"
                />
              </FormControl>{" "}
              {currentUser.roleId == 1 && (
                <>
                  <Grid item xs={12} marginLeft={1} marginTop={1}>
                    {closed ? (
                      <Lock color="secondary" fontSize="large" />
                    ) : (
                      <LockOpen color="secondary" fontSize="large" />
                    )}

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={closed}
                          onChange={handleCheckboxChange}
                          fontSize="large"
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        />
                      }
                      label={
                        ("CERRAR TICKET",
                        (
                          <Alert
                            severity="warning"
                            style={{
                              marginTop: "1rem",
                              maxWidth: "100%",
                              marginBottom: 13,
                            }}
                          >
                            CERRAR TICKET (Si realizas esta acción no podrás
                            deshacer los cambios).
                          </Alert>
                        ))
                      }
                      color="secondary"
                      style={{ color: "black", marginLeft: 2, marginTop: 5 }}
                    />
                  </Grid>
                </>
              )}
            </>
          ) : (
            <>
              {" "}
              <Alert severity="info">
                {currentUser.roleId !== 1
                  ? "Este ticket ha sido cerrado. Si todavía tienes alguna pregunta o inquietud, no dudes en comunicarte con nosotros nuevamente ¡Estamos aquí para ayudarte!"
                  : "Este ticket ha sido cerrado."}
              </Alert>{" "}
            </>
          )}
        </Grid>
      </Drawer>
      <Dialog
        open={openAttachmentLoaderDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                border: "4px",
                borderRadius: "12px",
                borderStyle: "solid",
                borderColor: "#85BFE6",
                marginTop: 2,
              }}
            >
              <section className="container">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid
                      item
                      xs={1}
                      sm={2}
                      md={2}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CloudUploadOutlined color="primary" fontSize="large" />
                    </Grid>
                    <Grid item xs={3} sm={6} md={6} whiteSpace="initial">
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        sx={{ fontWeight: 700, paddingTop: 5 }}
                      >
                        Seleccione un archivo o arrástrelo hasta acá
                      </Typography>

                      <Typography
                        color="primary"
                        variant="subtitle2"
                        sx={{ paddingTop: 1, paddingBottom: 5 }}
                      >
                        JPG, PNG o PDF, tamaño del archivo no mayor a 10MB
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={4}
                      md={4}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Button
                        variant="outline"
                        color="secondary"
                        sx={{ border: 2 }}
                      >
                        Buscar
                      </Button>
                    </Grid>
                  </Grid>
                </div>
                {attachment && <List sx={{ width: "100%" }}>{files}</List>}
              </section>
            </Paper>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttachment(null)} autoFocus>
            Borrar
          </Button>
          <Button onClick={() => setOpenAttachmentLoaderDialog(false)}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {confirmationDialog && (
        <Dialog
          open={confirmationDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Confirmas cerrar el ticket?
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => ticketClose(ticketData.ticketId)} autoFocus>
              Aceptar
            </Button>
            <Button onClick={() => setConfirmationDialog(false)} autoFocus>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default StudentTicketsDrawer;
