import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCourseGroupAttendances,
  selectStudentListByDate,
  selectTickedAttendances,
  tryCleanStudentList,
  tryGetCourseGroupAttendances,
  tryGetStudentListByDate,
  tryTicksStudentsAttendances,
} from "../../../store/slices/teacher-courses";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  Drawer,
  IconButton,
  Switch,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import ModalMessages from "../../layout/modal-messages";
import CustomColumnMenu from "../../layout/grid-column-menu";
import { useFormik } from "formik";
import { DataGrid } from "@mui/x-data-grid";
import Spinner from "../../layout/spinner";
import moment from "moment";
import "moment/locale/es";
import StudentTeachersAttendancesSkeleton from "../skeleton/attendancesSkeleton";

const AttendanceDrawer = ({
  setOpenAttendanceDrawer,
  setOpenDialog,
  openAttendanceDrawer,
  courseGroupId,
  teacherCourseId,
}) => {
  const dispatch = useDispatch();

  //MANEJO DE FILAS
  const [rows, setRows] = useState([]);

  //MANEJO DE FECHA SELECCIONADA
  const [attendanceDate, setAttendanceDate] = useState(undefined);

  //TRAE LAS FECHAS DE UNA COMISION
  const courseGroupAttendances = useSelector(selectCourseGroupAttendances);

  //TRAE LAS ULTIMAS ASISTENCIAS CARGADAS
  const tickedCourseGroupAttendances = useSelector(selectTickedAttendances);

  //TRAE LA LISTA DE ALUMNOS Y ASISTENCIAS DE UNA FECHA
  const studentAttendanceList = useSelector(selectStudentListByDate);

  //MANEJO ASISTENCIA DEL PROFESOR
  const [teacherAttendance, setTeacherAttendance] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (openAttendanceDrawer)
      dispatch(
        tryGetCourseGroupAttendances({ courseGroupId, teacherCourseId })
      );
  }, [openAttendanceDrawer]);

  useEffect(() => {
    fetchData();
  }, [attendanceDate]);

  const fetchData = async () => {
    const action = await dispatch(
      tryGetStudentListByDate({
        courseGroupId,
        attendanceDate,
        teacherCourseId,
      })
    );
    if (action.payload.data.isValid) {
      setRows(
        action.payload.data.data.students.map((x) => {
          return {
            nickName: x.nickName,
            teacherValid: x.teacherValid,
            studentCourseId: x.studentCourseId,
          };
        })
      );
      setTeacherAttendance(action.payload.data.data.isValidTeacher);
      setShow(true);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      courseGroupId: courseGroupId,
      attendanceDate: "",
    },
  });

  //LA API NO NECESITA EL NICKNAME ASI Q LO QUITAMOS DEL REQUEST
  const saveAttendances = async (model) => {
    const request = model.map(({ nickName, ...rest }) => {
      return rest;
    });
    const teacherPresent = teacherAttendance;
    //SE LLAMA A LA API
    const action = await dispatch(
      tryTicksStudentsAttendances({
        attendanceDate,
        request,
        teacherCourseId,
        teacherPresent,
      })
    );

    if (action.payload.data.isValid) {
      setOpenDialog(true);
      handleCleanStudentsOnClose();
      setOpenAttendanceDrawer(false);
    }
  };

  //CUANDO SE INICIA LA GRILLA
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          studentCourseId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 6 } },
    };
  };

  const handleChangeTeacherAttendance = (value) => {
    setTeacherAttendance(value);
  };

  //Manejo de switch
  const handleChange = (params, value) => {
    const updatedRows = rows.map((row) =>
      row.studentCourseId === params.row.studentCourseId
        ? { ...row, teacherValid: value }
        : row
    );
    setRows(updatedRows);
  };

  const handleCleanStudentsOnClose = () => {
    dispatch(tryCleanStudentList());
    setOpenAttendanceDrawer(false);
  };

  //DEFINO LAS COLUMNAS
  const columns = [
    {
      field: "studentCourseId",
      headerName: "studentCourseId",
      filterable: false,
    },
    {
      field: "nickName",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "Alumno",
      headerClassName: "headRow",
      width: 260,
    },
    {
      field: "teacherValid",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "Asistencia",
      headerClassName: "headRow",
      width: 130,

      renderCell: (params) => {
        return (
          <Switch
            label={params.value}
            checked={params.value ?? false}
            color="success"
            onChange={(_, value) => {
              handleChange(params, value);
              if (isFirstTime) {
                setTeacherAttendance(true);
                setIsFirstTime(false);
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Drawer
        anchor="right"
        open={openAttendanceDrawer}
        PaperProps={{
          sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
        }}
        onClose={() => {
          handleCleanStudentsOnClose();
        }}
        sx={{
          zIndex: 1203,
          width: 500,
          flexShrink: 0,
        }}
      >
        <Grid container p={1}>
          <Grid item xs={10}>
            <Typography
              align="left"
              sx={{
                paddingLeft: 2,
                paddingRight: 5,
                paddingTop: 4,
                fontWeight: 700,
                fontSize: 18,
              }}
            >
              Cargar Asistencia
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => {
                handleCleanStudentsOnClose();
              }}
            >
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ paddingLeft: 2, paddingBottom: 5 }}>
            <Typography variant="h7" align="center">
              Por favor, complete información en cada campo que se solicite en
              el formulario
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          spacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          p={2}
          sx={{ backgroundColor: "white" }}
        >
          {courseGroupAttendances.isLoading ||
          tickedCourseGroupAttendances.isLoading ? (
            <StudentTeachersAttendancesSkeleton />
          ) : (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="date-label">Fecha Clase</InputLabel>
                  <Select
                    labelId="date-label"
                    name="date"
                    label="Fecha Clase"
                    size="small"
                    value={formik.values.attendanceDate}
                    onChange={(e) => {
                      const { value } = e.target;
                      formik.setFieldValue("attendanceDate", value);
                      setAttendanceDate(value);
                    }}
                  >
                    {courseGroupAttendances?.data
                      ? courseGroupAttendances.data.map((x) => (
                          <MenuItem
                            key={x.teacherCourseAttendanceId}
                            value={x.attendanceDate}
                          >
                            {
                              (moment.locale("es"),
                              moment(x.attendanceDate).format("ll h:mm a"))
                            }
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  <FormHelperText htmlFor="course"></FormHelperText>
                </FormControl>
              </Grid>
              {show && (
                <>
                  <Grid item xs={8}>
                    <Typography
                      color="black"
                      sx={{ paddingLeft: 2, paddingTop: 1, fontSize: 18 }}
                    >
                      Asistencia Profesor
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Switch
                      label="Asistencia Profesor"
                      checked={teacherAttendance}
                      color="success"
                      onChange={(event) => {
                        const value = event.target.checked;
                        handleChangeTeacherAttendance(value);
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <DataGrid
                  slots={{
                    columnMenu: CustomColumnMenu,
                  }}
                  autoHeight={true}
                  rowSelection={false}
                  getRowId={(row) => row.studentCourseId}
                  rows={rows}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  initialState={initializeDataGrid()}
                />
              </Grid>
              <Grid item sx={{ width: "100%", textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={() => saveAttendances(rows)}
                  disabled={tickedCourseGroupAttendances.isLoading}
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    width: 100,
                    marginRight: 2,
                  }}
                >
                  {tickedCourseGroupAttendances.isLoading && (
                    <Spinner color="primary" />
                  )}
                  Guardar
                </Button>
                <Button
                  variant="outline"
                  color="error"
                  disabled={tickedCourseGroupAttendances.isLoading}
                  sx={{
                    border: 2,
                    marginTop: 2,
                    marginBottom: 2,
                    width: 100,
                    color: "red",
                  }}
                  onClick={() => {
                    handleCleanStudentsOnClose();
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        {tickedCourseGroupAttendances.errors &&
          tickedCourseGroupAttendances.errors.length > 0 && (
            <ModalMessages errors={tickedCourseGroupAttendances.errors} />
          )}
      </Drawer>
    </>
  );
};

export default AttendanceDrawer;
