import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiAuth from "../../../services/apiAuth";
import store from "store";

const nameUserLogged = "sif-dev-userLogged";
const nameUserPermissions = "sif-dev-permissions";

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: {
    user: {
      userName: "",
      fullName: "",
      userId: 0,
      role: "",
      roleId: 0,
      originalUserName: "",
      accessToken: "",
      avatar: "",
      businessId: 0,
      refreshToken: "",
      authorized: false,
      isLoading: false,
      permissions: [],
      clients: null,
      clientId: 0,
      errors: [],
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
      expireAt: Date.now,
    },
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.user = { ...payload };
    },
    setLoading: (state, { payload }) => {
      state.user.isLoading = payload;
    },
    setCleanErrorsCurrentUser: (state) => {
      state.user.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryLogin.pending, (state) => {
      state.user.status = "loading";
      state.user.isLoading = true;
      state.user.errors = [];
    });
    builder.addCase(tryLogin.fulfilled, (state, { payload }) => {
      state.user.status = "succeeded";

      const user = payload.data.data;

      if (!payload.data.isValid) {
        user.authorized = false;
        user.permissions = [];
        user.errors = payload.data.errors;
      }

      if (payload.data.isValid) {
        user.authorized = true;
        user.errors = [];

        store.set(nameUserLogged, user);
      }

      state.isLoading = false;

      state.user = { ...user };
    });
    builder.addCase(tryLogin.rejected, (state, { payload }) => {
      state.user.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.user.errors = errors;
      state.user.isLoading = false;
    });
    builder.addCase(tryCurrentUser.pending, (state) => {
      state.user.status = "loading";
      state.user.isLoading = true;
      state.user.errors = [];
    });
    builder.addCase(tryCurrentUser.fulfilled, (state, { payload }) => {
      state.user.status = "succeeded";

      const user = payload.data.data;

      if (!payload.data.isValid) {
        user.authorized = false;
        user.permissions = [];
        user.errors = payload.data.errors;
      }

      if (payload.data.isValid) {
        user.authorized = true;
        user.errors = [];

        store.set(nameUserLogged, user);
      }

      state.isLoading = false;

      state.user = { ...user };
    });
    builder.addCase(tryCurrentUser.rejected, (state, { payload }) => {
      state.user.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.user.errors = errors;
      state.user.isLoading = false;
    });
    builder.addCase(tryLogout.pending, (state) => {
      state.user.status = "loading";
      state.user.isLoading = true;
      state.user.errors = [];
    });
    builder.addCase(tryLogout.fulfilled, (state, { payload }) => {
      state.user.status = "succeeded";

      // // Se obtienen los permisos de store
      // const storePermisionKey = Buffer.from(nameUserPermissions).toString(
      //   "base64"
      // );
      // // Se eliminan los permisos que existan
      // store.remove(storePermisionKey);

      // // Se obtiene menu de store
      // const storeMenuKey = Buffer.from(nameUserMenu).toString('base64');
      // // Se elimina el menu que exista
      // store.remove(storeMenuKey);

      const user = {
        userName: "",
        fullName: "",
        userId: 0,
        role: "",
        roleId: 0,
        originalUserName: "",
        accessToken: "",
        businessId: 0,
        avatar: "",
        refreshToken: "",
        authorized: false,
        isLoading: false,
        permissions: [],
        clients: null,
        errors: [],
        status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
        expireAt: Date.now,
      };

      store.remove(nameUserLogged);

      state.user = { ...user };
    });
    builder.addCase(tryLogout.rejected, (state, { payload }) => {
      state.user.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.user.errors = errors;
      state.user.isLoading = false;
    });
  },
});

export const { setLoading, setUser, setCleanErrorsCurrentUser } =
  currentUserSlice.actions;

export default currentUserSlice.reducer;

export const tryCleanErrorsCurrentUser = () => (dispatch) => {
  dispatch(setCleanErrorsCurrentUser());
};

export const selectCurrentUser = (state) => state.currentUser.user;

export const getCurrentUser = () => (dispatch) => {
  dispatch(setLoading(true));

  const userLogged = store.get(nameUserLogged);

  if (userLogged) {
    if (new Date(userLogged.expireAt) < new Date())
      userLogged.authorized = false;

    dispatch(setUser(userLogged));

    // Se obtienen los permisos de store
    const storePermisionKey =
      Buffer.from(nameUserPermissions).toString("base64");
    // Se obtienen los permisos que existan
    const userPermissionEncoded = store.get(storePermisionKey);

    if (userPermissionEncoded) {
      const userPermission = Buffer.from(
        userPermissionEncoded,
        "base64"
      ).toString("utf8");
    }
  }
};

export const tryLogout = createAsyncThunk(
  "currentUser/tryLogout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiAuth.logout();
      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryLogin = createAsyncThunk(
  "currentUser/tryLogin",
  async (model, { rejectWithValue }) => {
    try {
      const response = await apiAuth.login(model.username, model.password);
      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryCurrentUser = createAsyncThunk(
  "currentUser/tryCurrentUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiAuth.currentUser();
      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
