import React from "react";
// import { Typography } from "@mui/material";

const LeftSection = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/logo_utedyc_1.png"}
          width="300px"
        />
      </div>
      {/* <Typography
        color="primary.contrastText"
        variant="h5"
        align="center"
        component="h2"
      >
        Cursos Presenciales - 2do cuatrimestre 2022
      </Typography>
      <Typography
        color="primary.contrastText"
        variant="subtitle2"
        align="center"
        sx={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}
      >
        <p>
          <b>
            Requisitos de inscripción para acceder a los cursos de manera
            gratuita:
          </b>
        </p>
        <p>
          1. Ser afiliado/a, aportante o empleado/a de UTEDYC o pertenecer a su
          grupo familiar directo (cónyuge o hijos/as).
        </p>
        <p>2. Tener más de 18 años.</p>
      </Typography>
      <Typography
        color="primary.contrastText"
        variant="subtitle2"
        align="center"
        sx={{ paddingTop: 2, paddingLeft: 10, paddingRight: 10 }}
      >
        <p>
          <b>
            Requisitos de inscripción para acceder a los cursos de manera
            particular:
          </b>
        </p>
        <p>1. Tener más de 18 años. </p>
        <p>2. Abonar la matricula de inscripción.</p>
        <p>
          3. Abonar una mensualidad sujeta a la duración del curso elegido.
          Antes de enviar la solicitud, revisá que hayas escrito bien todos tus
          datos. A continuación, presioná el botón "Seguir" para completar el
          formulario
        </p>
      </Typography>
      <Typography
        color="primary.contrastText"
        align="center"
        sx={{
          fontWeight: "700",
          paddingTop: 2, 
          paddingBottom: 2, 
          paddingLeft: 7,
          paddingRight: 7,
        }}
      >
        A continuación, presioná el botón "Continuar" para completar el formulario
      </Typography> */}
    </>
  );
};

export default LeftSection;
