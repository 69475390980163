import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";

const SettlementAdministratorsSkeleton = () => {
  return (
    <>
      <Grid container spacing={3} sx={{ pt: 3, pb: 3, pl: 10, pr: 10 }}>
        <Grid item align="left" xs={6}>
          <Skeleton
            variant="rectangular"
            sx={{ width: "200px", height: "40px" }}
          />
        </Grid>
        <Grid item xs={6} align="right">
          <Skeleton
            variant="rectangular"
            sx={{ width: "200px", height: "40px", marginLeft: "200px" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1} sx={{ marginTop: "25px" }}>
            <Skeleton variant="rectangular" sx={{ height: "40px" }} />
            <Skeleton variant="rectangular" sx={{ height: "40px" }} />
            <Skeleton variant="rectangular" sx={{ height: "40px" }} />
            <Skeleton variant="rectangular" sx={{ height: "40px" }} />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default SettlementAdministratorsSkeleton;
