import React from "react";
import { Container, Divider, Grid, Skeleton, Typography } from "@mui/material";

const BackCoursesComplementsSkeleton = () => {
  return (
    <Container>
      <Grid
        container
        sx={{
          heigth: "auto",
          width: "auto",
          marginBottom: 1,
          marginTop: 0,
          paddingBottom: 10,
        }}
        rowSpacing={6}
      >
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Datos Complementarios
          </Typography>
          <Divider
            paddingTop={3}
            flexItem
            sx={{ bgcolor: "secondary.light" }}
          />
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={12} marginTop={7}>
            <Skeleton variant="rectangular" height={60} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BackCoursesComplementsSkeleton;
