import apiBase from "./axios/apiBase";
import getQueryString from "./functions/getQueryString";

const controller = "students";

export async function getPatents(queryModel) {
  const query = getQueryString(queryModel);

  return apiBase
    .get(`/${controller}/patents?${query}`)
    .then((response) => {
      if ([200, 201, 202, 400, 401].includes(response.status))
        return response.data;

      return false;
    })
    .catch((err) => {
      return false;
    });
}

export function postPreInscribe(model) {
  return apiBase.post(`/${controller}/preinscribe`, model);
}

export async function getValidateNeo(cuil) {
  return apiBase.get(`/${controller}/validate/${cuil}/neo`);
}

export async function getStudentAttendance(model, beforeToday) {
  const query = getQueryString(beforeToday);
  return apiBase.get(
    `/${controller}/${model.studentId}/courses/${model.courseId}/attendance?${query}`,
    {
      studentId: model.studentId,
      courseId: model.courseId,
    }
  );
}

export async function getCourseCertificate(studentId, courseId) {
  return apiBase.get(
    `/${controller}/${studentId}/courses/${courseId}/download`
  );
}

export async function getStudentCoursesPath(studentId, courseId) {
  return apiBase.get(`/${controller}/${studentId}/courses/${courseId}/Path`);
}

export async function postUnsubscribeCourse(studentId, courseId, model) {
  return apiBase.post(
    `/${controller}/${studentId}/courses/${courseId}/state`,
    model
  );
}

export async function postTickStudentAttendance(studentId, courseId, model) {
  return apiBase.put(
    `/${controller}/students/${studentId}/courses/${courseId}/attendance`,
    model
  );
}

export async function getPaymentSummary(studentId) {
  return apiBase.get(`/${controller}/${studentId}/payments/all/summary`);
}

export async function getPayments(studentId, isFirstOrLastRecords) {
  return apiBase.get(
    `/${controller}/${studentId}/payments/all/header?isFirstOrLastRecords=${isFirstOrLastRecords}`
  );
}

export async function getPaymentNotification(
  studentId,
  courseId,
  coursefeeId,
  paymentId
) {
  return apiBase.get(
    `/${controller}/${studentId}/courses/${courseId}/coursefees/${coursefeeId}/payments/${paymentId}/detail`
  );
}

export async function getCuilById(userId) {
  return apiBase.get(`/${controller}/users/${userId}/CUIL`);
}

export async function postPaymentNotification(
  studentId,
  courseId,
  coursefeeId,
  model
) {
  return apiBase.post(
    `/${controller}/${studentId}/courses/${courseId}/coursefees/${coursefeeId}/payments/notification`,
    model
  );
}

export function postStudentPaymentsExport(studentId) {
  return apiBase.post(`/${controller}/${studentId}/payments/all/export`);
}

export async function getTicketsSummary(studentId) {
  return apiBase.get(`/${controller}/${studentId}/tickets/all/summary`);
}

export async function getStudentTicketsExport(studentId) {
  return apiBase.post(`/${controller}/${studentId}/tickets/all/export`);
}

export function validateStudentData(model, ruleSet) {
  const query = getQueryString(ruleSet);

  return apiBase.post(`/${controller}/validate/data?${query}`, model);
}

export function validateStudentCourses(model, ruleSet) {
  const query = getQueryString(ruleSet);
  return apiBase.post(`/${controller}/validate/data?${query}`, model);
}

export function validateStudentCurrentCuil(cuil) {
  return apiBase.post(`/${controller}/validate/${cuil}/cuilFormat`);
}

export function getStudentById(studentId) {
  return apiBase.get(`/${controller}/${studentId}/courses/all/header`);
}

export function getStudentInformationById(studentId) {
  return apiBase.get(`/${controller}/${studentId}`);
}

export function putStudentInformationById(request) {
  return apiBase.put(`/${controller}/student`, request);
}

export function getStudentCoursesSummary(studentId) {
  return apiBase.get(`/${controller}/${studentId}/courses/all/summary`);
}

export function getStudentCoursesHeader(studentId, isFirstOrLastRecords) {
  return apiBase.get(
    `/${controller}/${studentId}/courses/all/header?isFirstOrLastRecords=${isFirstOrLastRecords}`
  );
}

export function postStudentCoursesExport(studentId) {
  return apiBase.post(`/${controller}/${studentId}/courses/all/export`);
}

export function getTicketsByStudentHeader(studentId, queryModel) {
  const query = getQueryString(queryModel);

  return apiBase.get(
    `/${controller}/${studentId}/tickets​/all​/header?${query}`
  );
}

export function getTicketsTabSummary(studentId, queryModel) {
  const query = getQueryString(queryModel);

  return apiBase.get(
    `/${controller}/${studentId}/tickets​/all​/header?${query}`
  );
}

export function createStudentTicket(businessId, model) {
  return apiBase.post(`/${controller}/${businessId}/ticket`, model);
}

export function getStudentTicketDetails(studentId, ticketId) {
  return apiBase.get(`/${controller}/${studentId}/tickets/${ticketId}/detail`);
}

export async function deleteNotification(studentId, courseId, coursefeeId) {
  return apiBase.delete(
    `/${controller}/${studentId}/courses/${courseId}/coursefees/${coursefeeId}/payments/notification`
  );
}

export async function getAttachmentByTicketDetail(
  studentId,
  ticketId,
  ticketDetailId
) {
  return apiBase.get(
    `/${controller}/${studentId}/tickets/${ticketId}/detail/${ticketDetailId}`
  );
}

export function getStudentsAll() {
  return apiBase.get(`/${controller}/all/header`);
}

export function getExportStudentsAll() {
  return apiBase.get(`/${controller}/all/detail`);
}

export function postInscribe(studentId, courseId, model) {
  return apiBase.post(
    `/${controller}/${studentId}/courses/${courseId}/state`,
    model
  );
}

export function putPaymentApproved(
  studentId,
  courseId,
  paymentId,
  coursefeeId
) {
  return apiBase.put(
    `/${controller}/${studentId}/courses/${courseId}/payments/${paymentId}/coursefees/${coursefeeId}/aproval`
  );
}

export function putPaymentRejected(
  studentId,
  courseId,
  paymentId,
  coursefeeId,
  reasons
) {
  const model = { reasons: reasons };
  return apiBase.put(
    `/${controller}/${studentId}/courses/${courseId}/payments/${paymentId}/coursefees/${coursefeeId}/reject`,
    model
  );
}

export function getDownloadStudentFile(paymentId) {
  return apiBase.get(`/${controller}/courses/payments/${paymentId}/attachment`);
}

export function postCloseTicket(ticketId) {
  return apiBase.post(`/${controller}/tickets/${ticketId}/close`);
}

export function getExportCourseGroup(courseGroupId) {
  return apiBase.get(`/${controller}/all/courseGroup/${courseGroupId}`);
}

export function getStudentMails(isActive) {
  return apiBase.get(`/${controller}/all/emails?isActive=${isActive}`);
}

export function getCourseGroupEmails(courseGroupId) {
  return apiBase.get(`/${controller}/courseGroup/${courseGroupId}/all/emails`);
}

export function getStudentsReport() {
  return apiBase.get(`/${controller}/all/detail/repost`);
}

export async function getAttendanceReport(courseGroupId) {
  return apiBase.get(
    `/${controller}/courseGroup/${courseGroupId}/attendance/all/detail/report`
  );
}

export function getFileDocument(clientId) {
  return apiBase.get(
    `/${controller}/client/clirelations/${clientId}/additionalInfo`
  );
}

export function postScolarship(studentCourseId, notes) {
  return apiBase.post(
    `/${controller}/studentcourse/${studentCourseId}/scolarship?notes=${notes}`
  );
}
