import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiCourses from "../../../services/apiCourses";

export const coursesBackOfficeSlice = createSlice({
  name: "coursesBackOffice",
  initialState: {
    list: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    deleted: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    moodleCourses: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    downloadedCoursesReport: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    moodleCategories: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },

  reducers: {
    setLoading: (state, { payload }) => {
      state.list.isLoading = payload;
    },
    setCleanErrorsBackofficeCourses: (state) => {
      state.list.errors = [];
      state.deleted.errors = [];
      state.moodleCourses.errors = [];
      state.downloadedCoursesReport.errors = [];
      state.moodleCategories.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryGetAllCourses.pending, (state) => {
      state.list.status = "loading";
      state.list.isLoading = true;
      state.list.errors = [];
    });
    builder.addCase(tryGetAllCourses.fulfilled, (state, { payload }) => {
      state.list.status = "succeeded";

      if (payload.data.isValid) {
        state.list = { ...payload.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.list.errors = errors;

      state.list.isLoading = false;
    });

    builder.addCase(tryGetAllCourses.rejected, (state, { payload }) => {
      state.list.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.list.errors = errors;
      state.list.isLoading = false;
    });

    //ELIMINAR UN CURSO
    builder.addCase(tryDeleteCourse.pending, (state) => {
      state.deleted.status = "loading";
      state.deleted.isLoading = true;
      state.deleted.errors = [];
    });
    builder.addCase(tryDeleteCourse.fulfilled, (state, { payload }) => {
      state.deleted.status = "succeeded";

      if (payload.data.isValid) {
        state.deleted = { ...payload.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.deleted.errors = errors;

      state.deleted.isLoading = false;
    });

    builder.addCase(tryDeleteCourse.rejected, (state, { payload }) => {
      state.deleted.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.deleted.errors = errors;
      state.deleted.isLoading = false;
    });

    //TRAE EL DETALLE DE LOS CURSOS DE MOODLE

    builder.addCase(tryGetMoodleCourses.pending, (state) => {
      state.moodleCourses.status = "loading";
      state.moodleCourses.isLoading = true;
      state.moodleCourses.errors = [];
    });
    builder.addCase(tryGetMoodleCourses.fulfilled, (state, { payload }) => {
      state.moodleCourses.status = "succeeded";

      if (payload.data.isValid) {
        state.moodleCourses.data = [...payload.data.data];
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.moodleCourses.errors = errors;

      state.moodleCourses.isLoading = false;
    });

    builder.addCase(tryGetMoodleCourses.rejected, (state, { payload }) => {
      state.moodleCourses.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.moodleCourses.errors = errors;
      state.moodleCourses.isLoading = false;
    });

    //DESCARGA EL REPORTE DE CURSOS
    builder.addCase(tryGetCoursesReport.pending, (state) => {
      state.downloadedCoursesReport.status = "loading";
      state.downloadedCoursesReport.isLoading = true;
      state.downloadedCoursesReport.errors = [];
    });
    builder.addCase(tryGetCoursesReport.fulfilled, (state, { payload }) => {
      state.downloadedCoursesReport.status = "succeeded";

      if (payload.data.isValid) {
        state.downloadedCoursesReport.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.downloadedCoursesReport.errors = errors;

      state.downloadedCoursesReport.isLoading = false;
    });

    builder.addCase(tryGetCoursesReport.rejected, (state, { payload }) => {
      state.downloadedCoursesReport.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.downloadedCoursesReport.errors = errors;
      state.downloadedCoursesReport.isLoading = false;
    });

    //TRAE LAS CATEGORIAS DE MOODLE
    builder.addCase(tryGetMoodleCategories.pending, (state) => {
      state.moodleCategories.status = "loading";
      state.moodleCategories.isLoading = true;
      state.moodleCategories.errors = [];
    });
    builder.addCase(tryGetMoodleCategories.fulfilled, (state, { payload }) => {
      state.moodleCategories.status = "succeeded";

      if (payload.data.isValid) {
        state.moodleCategories.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.moodleCategories.errors = errors;

      state.moodleCategories.isLoading = false;
    });

    builder.addCase(tryGetMoodleCategories.rejected, (state, { payload }) => {
      state.moodleCategories.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.moodleCategories.errors = errors;
      state.moodleCategories.isLoading = false;
    });
  },
});

export const { setLoading, setCleanErrorsBackofficeCourses } =
  coursesBackOfficeSlice.actions;

export default coursesBackOfficeSlice.reducer;

export const tryCleanErrorsBackofficeCourses = () => (dispatch) => {
  dispatch(setCleanErrorsBackofficeCourses());
};

export const selectCoursesBackOffice = (state) => {
  return state.backofficeCourses.list;
};

export const selectDeletedCourse = (state) => {
  return state.backofficeCourses.deleted;
};

export const selectMoodleCourses = (state) => {
  return state.backofficeCourses.moodleCourses;
};

export const selectDownloadedCoursesReport = (state) => {
  return state.backofficeCourses.downloadedCoursesReport;
};

export const tryGetAllCourses = createAsyncThunk(
  "coursesBackOffice/tryGetAllCourses",
  async (_, { rejectWithValue }) => {
    try {
      const query = {
        isPaginated: false,
      };

      const response = await apiCourses.getAllCourses(query);
      return response;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

//ELIMINA UN CURSO
export const tryDeleteCourse = createAsyncThunk(
  "coursesBackOffice/tryDeleteCourse",
  async (courseId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.deleteCourse(courseId);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetMoodleCourses = createAsyncThunk(
  "teachersBack/tryGetMoodleCourses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiCourses.getMoodleCourses();
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetCoursesReport = createAsyncThunk(
  "teachersBack/tryGetCoursesReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiCourses.getCoursesReport();
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetMoodleCategories = createAsyncThunk(
  "teachersBack/tryGetMoodleCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiCourses.getMoodleCategories();
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);
