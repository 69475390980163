import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiCourses from "../../../services/apiCourses";

export const courseDetailSlice = createSlice({
  name: "courseDetail",
  initialState: {
    item: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    newCourse: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    module: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    newModule: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    courseGroup: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    newCourseGroup: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    courseGroupsSchedules: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    newCourseGroupsSchedules: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    courseFees: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    newCourseFees: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    automaticFees: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setCourseDetail: (state, { payload }) => {
      state.item = { ...state.item, ...payload };
    },
    setNewCourse: (state, { payload }) => {
      state.newCourse = { ...state.newCourse, ...payload };
    },
    setNewModule: (state, { payload }) => {
      state.newCourse = { ...state.newCourse, ...payload };
    },
    setCourseGroup: (state, { payload }) => {
      state.courseGroup = { ...state.courseGroup, ...payload };
    },
    setCourseFees: (state, { payload }) => {
      state.courseFees = { ...state.courseFees, ...payload };
    },
    setNewCourseFees: (state, { payload }) => {
      state.newCourseFees = { ...state.newCourseFees, ...payload };
    },
    setCourseGroupsSchedules: (state, { payload }) => {
      state.courseGroupsSchedules = {
        ...state.courseGroupsSchedules,
        ...payload,
      };
    },
    setModule: (state, { payload }) => {
      state.module = { ...state.module, ...payload };
    },
    setLoading: (state, { payload }) => {
      state.item.isLoading = payload;
    },
    setCleanErrorsBackCourse: (state) => {
      state.item.errors = [];
      state.newCourse.errors = [];
      state.module.errors = [];
      state.newModule.errors = [];
      state.courseGroup.errors = [];
      state.newCourseGroup.errors = [];
      state.courseGroupsSchedules.errors = [];
      state.newCourseGroupsSchedules.errors = [];
      state.courseFees.errors = [];
      state.newCourseFees.errors = [];
      state.automaticFees.errors = [];
    },
  },

  // DETALLE DE UN CURSO EXISTENTE
  extraReducers(builder) {
    builder.addCase(tryGetCourseDetail.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(tryGetCourseDetail.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.item.errors = errors;

      if (payload.data.isValid === true) {
        state.item.data = { ...payload.data.data };
      }

      state.item.isLoading = false;
    });
    builder.addCase(tryGetCourseDetail.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];
      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });

    //CREAR O MODIFICAR UN CURSO

    builder.addCase(tryCreateCourse.pending, (state) => {
      state.newCourse.status = "loading";
      state.newCourse.isLoading = true;
      state.newCourse.errors = [];
    });
    builder.addCase(tryCreateCourse.fulfilled, (state, { payload }) => {
      state.newCourse.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.newCourse.errors = errors;

      if (payload.data.isValid) state.newCourse.data = { ...payload.data.data };

      state.newCourse.isLoading = false;
    });
    builder.addCase(tryCreateCourse.rejected, (state, { payload }) => {
      state.newCourse.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newCourse.errors = errors;
      state.newCourse.isLoading = false;
    });

    //TRAE EL DETALLE DE MODULOS

    builder.addCase(tryGetModules.pending, (state) => {
      state.module.status = "loading";
      state.module.isLoading = true;
      state.module.errors = [];
    });
    builder.addCase(tryGetModules.fulfilled, (state, { payload }) => {
      state.module.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.module.errors = errors;

      if (payload.data.isValid) state.module.data = [...payload.data.data];

      state.module.isLoading = false;
    });
    builder.addCase(tryGetModules.rejected, (state, { payload }) => {
      state.module.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.module.errors = errors;
      state.module.isLoading = false;
    });

    //CREA UN NUEVO MODULO

    builder.addCase(tryCreateModule.pending, (state) => {
      state.newModule.status = "loading";
      state.newModule.isLoading = true;
      state.newModule.errors = [];
    });
    builder.addCase(tryCreateModule.fulfilled, (state, { payload }) => {
      state.newModule.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.newModule.errors = errors;

      if (payload.data.isValid) state.newModule.data = { ...payload.data.data };

      state.newModule.isLoading = false;
    });
    builder.addCase(tryCreateModule.rejected, (state, { payload }) => {
      state.newModule.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }
      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newModule.errors = errors;
      state.newModule.isLoading = false;
    });

    //ELIMINA UN CURSO

    builder.addCase(tryDeleteCourse.pending, (state) => {
      state.newCourse.status = "loading";
      state.newCourse.isLoading = true;
      state.newCourse.errors = [];
    });
    builder.addCase(tryDeleteCourse.fulfilled, (state, { payload }) => {
      state.newCourse.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.newCourse.errors = errors;

      if (payload.data.isValid) state.newCourse.data = { ...payload.data.data };

      state.newCourse.isLoading = false;
    });
    builder.addCase(tryDeleteCourse.rejected, (state, { payload }) => {
      state.newCourse.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newCourse.errors = errors;
      state.newCourse.isLoading = false;
    });

    //ELIMINA UN MODULO

    builder.addCase(tryDeleteModule.pending, (state) => {
      state.newModule.status = "loading";
      state.newModule.isLoading = true;
      state.newModule.errors = [];
    });
    builder.addCase(tryDeleteModule.fulfilled, (state, { payload }) => {
      state.newModule.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.newModule.errors = errors;

      if (payload.data.isValid) state.newModule.data = { ...payload.data.data };

      state.newModule.isLoading = false;
    });
    builder.addCase(tryDeleteModule.rejected, (state, { payload }) => {
      state.newModule.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newModule.errors = errors;
      state.newModule.isLoading = false;
    });

    //TRAE EL DETALLE DE LAS COMISIONES

    builder.addCase(tryGetCourseGroup.pending, (state) => {
      state.courseGroup.status = "loading";
      state.courseGroup.isLoading = true;
      state.courseGroup.errors = [];
    });
    builder.addCase(tryGetCourseGroup.fulfilled, (state, { payload }) => {
      state.courseGroup.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.courseGroup.errors = errors;

      if (payload.data.isValid) state.courseGroup.data = [...payload.data.data];

      state.courseGroup.isLoading = false;
    });
    builder.addCase(tryGetCourseGroup.rejected, (state, { payload }) => {
      state.courseGroup.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.courseGroup.errors = errors;
      state.courseGroup.isLoading = false;
    });

    //TRAE EL DETALLE DE LOS DIAS DE CURSADA DE UN CURSO

    builder.addCase(tryGetCourseGroupsSchedules.pending, (state) => {
      state.courseGroupsSchedules.status = "loading";
      state.courseGroupsSchedules.isLoading = true;
      state.courseGroupsSchedules.errors = [];
    });
    builder.addCase(
      tryGetCourseGroupsSchedules.fulfilled,
      (state, { payload }) => {
        state.courseGroupsSchedules.status = "succeeded";

        const errors = payload.data.isValid ? payload.errors : [];
        state.courseGroupsSchedules.errors = errors;

        if (payload.data.isValid)
          state.courseGroupsSchedules.data = [...payload.data.data];

        state.courseGroupsSchedules.isLoading = false;
      }
    );
    builder.addCase(
      tryGetCourseGroupsSchedules.rejected,
      (state, { payload }) => {
        state.courseGroupsSchedules.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.courseGroupsSchedules.errors = errors;
        state.courseGroupsSchedules.isLoading = false;
      }
    );

    //CREA UNA NUEVA COMISION

    builder.addCase(tryCreateCourseGroup.pending, (state) => {
      state.newCourseGroup.status = "loading";
      state.newCourseGroup.isLoading = true;
      state.newCourseGroup.errors = [];
    });
    builder.addCase(tryCreateCourseGroup.fulfilled, (state, { payload }) => {
      state.newCourseGroup.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.newCourseGroup.errors = errors;

      if (payload.data.isValid)
        state.newCourseGroup.data = { ...payload.data.data };

      state.newCourseGroup.isLoading = false;
    });
    builder.addCase(tryCreateCourseGroup.rejected, (state, { payload }) => {
      state.newCourseGroup.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newCourseGroup.errors = errors;
      state.newCourseGroup.isLoading = false;
    });

    //ELIMINA UNA COMISION

    builder.addCase(tryDeleteCourseGroup.pending, (state) => {
      state.newCourseGroup.status = "loading";
      state.newCourseGroup.isLoading = true;
      state.newCourseGroup.errors = [];
    });
    builder.addCase(tryDeleteCourseGroup.fulfilled, (state, { payload }) => {
      state.newCourseGroup.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.newCourseGroup.errors = errors;

      if (payload.data.isValid)
        state.newCourseGroup.data = { ...payload.data.data };

      state.newCourseGroup.isLoading = false;
    });
    builder.addCase(tryDeleteCourseGroup.rejected, (state, { payload }) => {
      state.newCourseGroup.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newCourseGroup.errors = errors;
      state.newCourseGroup.isLoading = false;
    });

    //CREA UN NUEVO HORARIO DE CURSADA PARA UNA COMISION

    builder.addCase(tryCreateCourseGroupsSchedules.pending, (state) => {
      state.newCourseGroupsSchedules.status = "loading";
      state.newCourseGroupsSchedules.isLoading = true;
      state.newCourseGroupsSchedules.errors = [];
    });
    builder.addCase(
      tryCreateCourseGroupsSchedules.fulfilled,
      (state, { payload }) => {
        state.newCourseGroupsSchedules.status = "succeeded";

        const errors = payload.data.isValid ? payload.errors : [];
        state.newCourseGroupsSchedules.errors = errors;

        if (payload.data.isValid)
          state.newCourseGroupsSchedules.data = { ...payload.data.data };

        state.newCourseGroupsSchedules.isLoading = false;
      }
    );
    builder.addCase(
      tryCreateCourseGroupsSchedules.rejected,
      (state, { payload }) => {
        state.newCourseGroupsSchedules.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.newCourseGroupsSchedules.errors = errors;
        state.newCourseGroupsSchedules.isLoading = false;
      }
    );

    //ELIMINA UN HORARIO DE CURSADA PARA UNA COMISION

    builder.addCase(tryDeleteCourseGroupsSchedules.pending, (state) => {
      state.newCourseGroupsSchedules.status = "loading";
      state.newCourseGroupsSchedules.isLoading = true;
      state.newCourseGroupsSchedules.errors = [];
    });
    builder.addCase(
      tryDeleteCourseGroupsSchedules.fulfilled,
      (state, { payload }) => {
        state.newCourseGroupsSchedules.status = "succeeded";

        const errors = payload.data.isValid ? payload.errors : [];
        state.newCourseGroupsSchedules.errors = errors;

        if (payload.data.isValid)
          state.newCourseGroupsSchedules.data = { ...payload.data.data };

        state.newCourseGroupsSchedules.isLoading = false;
      }
    );
    builder.addCase(
      tryDeleteCourseGroupsSchedules.rejected,
      (state, { payload }) => {
        state.newCourseGroupsSchedules.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.newCourseGroupsSchedules.errors = errors;
        state.newCourseGroupsSchedules.isLoading = false;
      }
    );

    //TRAE EL DETALLE DE TARIFAS DE UN CURSO

    builder.addCase(tryGetCourseFees.pending, (state) => {
      state.courseFees.status = "loading";
      state.courseFees.isLoading = true;
      state.courseFees.errors = [];
    });
    builder.addCase(tryGetCourseFees.fulfilled, (state, { payload }) => {
      state.courseFees.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.courseFees.errors = errors;

      if (payload.data.isValid) state.courseFees.data = [...payload.data.data];

      state.courseFees.isLoading = false;
    });
    builder.addCase(tryGetCourseFees.rejected, (state, { payload }) => {
      state.courseFees.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.courseFees.errors = errors;
      state.courseFees.isLoading = false;
    });

    //CREA UN COSTO ASIGNADO A UN CURSO

    builder.addCase(tryCreateCourseFees.pending, (state) => {
      state.newCourseFees.status = "loading";
      state.newCourseFees.isLoading = true;
      state.newCourseFees.errors = [];
    });
    builder.addCase(tryCreateCourseFees.fulfilled, (state, { payload }) => {
      state.newCourseFees.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.newCourseFees.errors = errors;

      if (payload.data.isValid)
        state.newCourseFees.data = { ...payload.data.data };

      state.newCourseFees.isLoading = false;
    });
    builder.addCase(tryCreateCourseFees.rejected, (state, { payload }) => {
      state.newCourseFees.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newCourseFees.errors = errors;
      state.newCourseFees.isLoading = false;
    });

    //ELIMINA UN COSTO ASIGNADO A UN CURSO

    builder.addCase(tryDeleteCourseFee.pending, (state) => {
      state.newCourseFees.status = "loading";
      state.newCourseFees.isLoading = true;
      state.newCourseFees.errors = [];
    });
    builder.addCase(tryDeleteCourseFee.fulfilled, (state, { payload }) => {
      state.newCourseFees.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.newCourseFees.errors = errors;

      if (payload.data.isValid)
        state.newCourseFees.data = { ...payload.data.data };

      state.newCourseFees.isLoading = false;
    });
    builder.addCase(tryDeleteCourseFee.rejected, (state, { payload }) => {
      state.newCourseFees.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newCourseFees.errors = errors;
      state.newCourseFees.isLoading = false;
    });

    //CREA COSTOS AUTOMATICAMENTE

    builder.addCase(tryAutoCourseFees.pending, (state) => {
      state.automaticFees.status = "loading";
      state.automaticFees.isLoading = true;
      state.automaticFees.errors = [];
    });
    builder.addCase(tryAutoCourseFees.fulfilled, (state, { payload }) => {
      state.automaticFees.status = "succeeded";

      const errors = payload.data.isValid ? payload.errors : [];
      state.automaticFees.errors = errors;

      if (payload.data.isValid)
        state.automaticFees.data = { ...payload.data.data };

      state.automaticFees.isLoading = false;
    });
    builder.addCase(tryAutoCourseFees.rejected, (state, { payload }) => {
      state.automaticFees.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.automaticFees.errors = errors;
      state.automaticFees.isLoading = false;
    });
  },
});

export const {
  setCourseDetail,
  setLoading,
  setNewCourse,
  setModule,
  setNewModule,
  courseGroup,
  courseGroupsSchedules,
  setCleanErrorsBackCourse,
} = courseDetailSlice.actions;

export default courseDetailSlice.reducer;

export const tryCleanErrorsBackCourse = () => (dispatch) => {
  dispatch(setCleanErrorsBackCourse());
};

export const selectCourse = (state) => {
  return state.courseDetail.item;
};

export const selectModules = (state) => {
  return state.courseDetail.module;
};

export const selectNewModule = (state) => {
  return state.courseDetail.newModule;
};

export const selectNewCourse = (state) => {
  return state.courseDetail.newCourse;
};

export const selectCourseGroup = (state) => {
  return state.courseDetail.courseGroup;
};

export const selectNewCourseGroup = (state) => {
  return state.courseDetail.newCourseGroup;
};

export const selectCourseGroupsSchedules = (state) => {
  return state.courseDetail.courseGroupsSchedules;
};

export const selectNewCourseGroupsSchedules = (state) => {
  return state.courseDetail.newCourseGroupsSchedules;
};

export const selectCourseFees = (state) => {
  return state.courseDetail.courseFees;
};

export const selectNewCourseFees = (state) => {
  return state.courseDetail.newCourseFees;
};

export const selectNewAutoFees = (state) => {
  return state.courseDetail.automaticFees;
};

//Obtiene el detalle de UN CURSO
export const tryGetCourseDetail = createAsyncThunk(
  "courseDetail/tryGetCourseDetail",
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await apiCourses.getCourse(courseId);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//Crea o modifica UN CURSO
export const tryCreateCourse = createAsyncThunk(
  "courseDetail/tryCreateCourse",
  async ({ model, validateFees }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.createCourse(model, validateFees);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//ELIMINA UN CURSO
export const tryDeleteCourse = createAsyncThunk(
  "courseDetail/tryDeleteCourse",
  async (courseId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.deleteCourse(courseId);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//Obtiene el detalle de los MODULOS DE UN CURSO
export const tryGetModules = createAsyncThunk(
  "courseDetail/tryGetModules",
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await apiCourses.getModules(courseId);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//Crea o modifica un MODULO
export const tryCreateModule = createAsyncThunk(
  "courseDetail/tryCreateModule",
  async (model, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.postModule(model);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//ELIMINA UN MODULO
export const tryDeleteModule = createAsyncThunk(
  "courseDetail/tryDeleteModule",
  async (courseModuleId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.deleteModule(courseModuleId);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//Crea o modifica una COMISION
export const tryCreateCourseGroup = createAsyncThunk(
  "courseDetail/tryCreateCourseGroup",
  async (model, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.postCourseGroup(model);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//ELIMINA UNA COMISION
export const tryDeleteCourseGroup = createAsyncThunk(
  "courseDetail/tryDeleteCourseGroup",
  async (courseGroupId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.deleteCourseGroup(courseGroupId);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//Obtiene el detalle de las COMISIONES de un CURSO
export const tryGetCourseGroup = createAsyncThunk(
  "courseDetail/tryGetCourseGroup",
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await apiCourses.getCourseGroup(courseId);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//Obtiene el detalle de los dias de cursada de una COMISION
export const tryGetCourseFees = createAsyncThunk(
  "courseDetail/tryGetCourseFees",
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await apiCourses.getCourseFees(courseId);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//ELIMINA EL DIA DE CURSADA DE UNA COMISION
export const tryDeleteCourseGroupsSchedules = createAsyncThunk(
  "courseDetail/tryDeleteCourseGroupsSchedules",
  async (courseGroupsSchedulesId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.deleteCourseGroupsSchedules(
        courseGroupsSchedulesId
      );

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//Obtiene el detalle de los DIAS de CURSADA de UN CURSO
export const tryGetCourseGroupsSchedules = createAsyncThunk(
  "courseDetail/tryGetCourseGroupsSchedules",
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await apiCourses.getCourseGroupsSchedules(courseId);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//Crea o modifica el dia de cursada de una comisión
export const tryCreateCourseGroupsSchedules = createAsyncThunk(
  "courseDetail/tryCreateCourseGroupsSchedules",
  async (model, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.postCourseGroupsSchedules(model);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//Crea o modifica el costo de un Curso
export const tryCreateCourseFees = createAsyncThunk(
  "courseDetail/tryCreateCourseFees",
  async (model, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.postCourseFees(model);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//ELIMINA EL COSTO DE UN CURSO
export const tryDeleteCourseFee = createAsyncThunk(
  "courseDetail/tryDeleteCourseFee",
  async (courseFeeId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.deleteCourseFees(courseFeeId);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

//ELIMINA EL COSTO DE UN CURSO
export const tryAutoCourseFees = createAsyncThunk(
  "courseDetail/tryAutoCourseFees",
  async (course, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.getAutoCourseFees(course);

      return response;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
