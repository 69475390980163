import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiTeacher from "../../../services/apiTeachers";
import * as apiCourses from "../../../services/apiCourses";

export const backTeachersSlice = createSlice({
  name: "teachersBack",
  initialState: {
    list: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    assignedTeacher: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    exportedTeachers: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    teacherMails: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    preLiquidation: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    preLiquidationHeader: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    preLiquidationDetail: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    manualPreLiquidation: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    notificationEmails: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    deletedTeacherCourseFee: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    batchProcessFile: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    downloadedReport: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    treasuryCases: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },

  reducers: {
    setLoading: (state, { payload }) => {
      state.list.isLoading = payload;
    },
    setCleanErrorsBackofficeTeachers: (state) => {
      state.list.errors = [];
      state.assignedTeacher.errors = [];
      state.exportedTeachers.errors = [];
      state.teacherMails.errors = [];
      state.preLiquidation.errors = [];
      state.preLiquidationHeader.errors = [];
      state.preLiquidationDetail.errors = [];
      state.manualPreLiquidation.errors = [];
      state.notificationEmails.errors = [];
      state.deletedTeacherCourseFee.errors = [];
      state.batchProcessFile.errors = [];
      state.downloadedReport.errors = [];
      state.treasuryCases.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryGetAllTeachers.pending, (state) => {
      state.list.status = "loading";
      state.list.isLoading = true;
      state.list.errors = [];
    });
    builder.addCase(tryGetAllTeachers.fulfilled, (state, { payload }) => {
      state.list.status = "succeeded";

      if (payload.data.isValid) {
        state.list.data = [...payload.data.data];
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.list.errors = errors;

      state.list.isLoading = false;
    });

    builder.addCase(tryGetAllTeachers.rejected, (state, { payload }) => {
      state.list.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.list.errors = errors;
      state.list.isLoading = false;
    });

    //Asigna un profesor a un curso
    builder.addCase(tryAssignTeacher.pending, (state) => {
      state.assignedTeacher.status = "loading";
      state.assignedTeacher.isLoading = true;
      state.assignedTeacher.errors = [];
    });
    builder.addCase(tryAssignTeacher.fulfilled, (state, { payload }) => {
      state.assignedTeacher.status = "succeeded";

      if (payload.data.isValid) {
        state.assignedTeacher.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.assignedTeacher.errors = errors;

      state.assignedTeacher.isLoading = false;
    });

    builder.addCase(tryAssignTeacher.rejected, (state, { payload }) => {
      state.assignedTeacher.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.assignedTeacher.errors = errors;
      state.assignedTeacher.isLoading = false;
    });

    //EXPORTA EL LISTADO DE PROFESORES

    builder.addCase(tryExportTeachersAll.pending, (state) => {
      state.exportedTeachers.status = "loading";
      state.exportedTeachers.isLoading = true;
      state.exportedTeachers.errors = [];
    });
    builder.addCase(tryExportTeachersAll.fulfilled, (state, { payload }) => {
      state.exportedTeachers.status = "succeeded";

      if (payload.data.isValid) {
        state.exportedTeachers.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.exportedTeachers.errors = errors;

      state.exportedTeachers.isLoading = false;
    });

    builder.addCase(tryExportTeachersAll.rejected, (state, { payload }) => {
      state.exportedTeachers.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.exportedTeachers.errors = errors;
      state.exportedTeachers.isLoading = false;
    });

    //TRAE EL LISTADO DE MAILS DE TODOS LOS PROFESORES
    builder.addCase(tryGetTeacherMails.pending, (state) => {
      state.teacherMails.status = "loading";
      state.teacherMails.isLoading = true;
      state.teacherMails.errors = [];
    });
    builder.addCase(tryGetTeacherMails.fulfilled, (state, { payload }) => {
      state.teacherMails.status = "succeeded";

      if (payload.data.isValid) {
        state.teacherMails.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.teacherMails.errors = errors;

      state.teacherMails.isLoading = false;
    });

    builder.addCase(tryGetTeacherMails.rejected, (state, { payload }) => {
      state.teacherMails.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.teacherMails.errors = errors;
      state.teacherMails.isLoading = false;
    });

    //CREA UNA PRE/LIQUIDACION
    builder.addCase(tryPreliquidation.pending, (state) => {
      state.preLiquidation.status = "loading";
      state.preLiquidation.isLoading = true;
      state.preLiquidation.errors = [];
    });
    builder.addCase(tryPreliquidation.fulfilled, (state, { payload }) => {
      state.preLiquidation.status = "succeeded";

      if (payload.data.isValid) {
        state.preLiquidation.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.preLiquidation.errors = errors;

      state.preLiquidation.isLoading = false;
    });

    builder.addCase(tryPreliquidation.rejected, (state, { payload }) => {
      state.preLiquidation.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.preLiquidation.errors = errors;
      state.preLiquidation.isLoading = false;
    });

    //TRAE EL DETALLE DE LA GRILLA CABECERA DE PRELIQUIDACIONES
    builder.addCase(tryGetBatchProcessHeader.pending, (state) => {
      state.preLiquidationHeader.status = "loading";
      state.preLiquidationHeader.isLoading = true;
      state.preLiquidationHeader.errors = [];
    });
    builder.addCase(
      tryGetBatchProcessHeader.fulfilled,
      (state, { payload }) => {
        state.preLiquidationHeader.status = "succeeded";

        if (payload.data.isValid) {
          state.preLiquidationHeader.data = { ...payload.data.data };
        }

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.preLiquidationHeader.errors = errors;

        state.preLiquidationHeader.isLoading = false;
      }
    );

    builder.addCase(tryGetBatchProcessHeader.rejected, (state, { payload }) => {
      state.preLiquidationHeader.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.preLiquidationHeader.errors = errors;
      state.preLiquidationHeader.isLoading = false;
    });

    //TRAE EL DETALLE DE UNA PRELIQUIDACIÓN
    builder.addCase(tryGetBatchProcessDetail.pending, (state) => {
      state.preLiquidationDetail.status = "loading";
      state.preLiquidationDetail.isLoading = true;
      state.preLiquidationDetail.errors = [];
    });
    builder.addCase(
      tryGetBatchProcessDetail.fulfilled,
      (state, { payload }) => {
        state.preLiquidationDetail.status = "succeeded";

        if (payload.data.isValid) {
          state.preLiquidationDetail.data = [...payload.data.data];
        }

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.preLiquidationDetail.errors = errors;

        state.preLiquidationDetail.isLoading = false;
      }
    );

    builder.addCase(tryGetBatchProcessDetail.rejected, (state, { payload }) => {
      state.preLiquidationDetail.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.preLiquidationDetail.errors = errors;
      state.preLiquidationDetail.isLoading = false;
    });

    //Carga un gasto en una PRELIQUIDACION a nombre de un profesor
    builder.addCase(tryPutManualCliPrecase.pending, (state) => {
      state.manualPreLiquidation.status = "loading";
      state.manualPreLiquidation.isLoading = true;
      state.manualPreLiquidation.errors = [];
    });
    builder.addCase(tryPutManualCliPrecase.fulfilled, (state, { payload }) => {
      state.manualPreLiquidation.status = "succeeded";

      if (payload.data.isValid) {
        state.manualPreLiquidation.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.manualPreLiquidation.errors = errors;

      state.manualPreLiquidation.isLoading = false;
    });

    builder.addCase(tryPutManualCliPrecase.rejected, (state, { payload }) => {
      state.manualPreLiquidation.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.manualPreLiquidation.errors = errors;
      state.manualPreLiquidation.isLoading = false;
    });

    //ENVIA LA PRELIQUIDACION MASIVA POR EMAIL
    builder.addCase(tryPutNotificationEmails.pending, (state) => {
      state.notificationEmails.status = "loading";
      state.notificationEmails.isLoading = true;
      state.notificationEmails.errors = [];
    });
    builder.addCase(
      tryPutNotificationEmails.fulfilled,
      (state, { payload }) => {
        state.notificationEmails.status = "succeeded";

        if (payload.data.isValid) {
          state.notificationEmails.data = { ...payload.data.data };
        }

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.notificationEmails.errors = errors;

        state.notificationEmails.isLoading = false;
      }
    );

    builder.addCase(tryPutNotificationEmails.rejected, (state, { payload }) => {
      state.notificationEmails.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.notificationEmails.errors = errors;
      state.notificationEmails.isLoading = false;
    });

    //ELIMINA UN GASTO DE UNA PRELIQUIDACION
    builder.addCase(tryDeleteTeacherCourseFee.pending, (state) => {
      state.deletedTeacherCourseFee.status = "loading";
      state.deletedTeacherCourseFee.isLoading = true;
      state.deletedTeacherCourseFee.errors = [];
    });
    builder.addCase(
      tryDeleteTeacherCourseFee.fulfilled,
      (state, { payload }) => {
        state.deletedTeacherCourseFee.status = "succeeded";

        if (payload.data.isValid) {
          state.deletedTeacherCourseFee.data = { ...payload.data.data };
        }

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.deletedTeacherCourseFee.errors = errors;

        state.deletedTeacherCourseFee.isLoading = false;
      }
    );

    builder.addCase(
      tryDeleteTeacherCourseFee.rejected,
      (state, { payload }) => {
        state.deletedTeacherCourseFee.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.deletedTeacherCourseFee.errors = errors;
        state.deletedTeacherCourseFee.isLoading = false;
      }
    );
    //EXPORTA LA GRILLA DE UNA PRELIQUIDACION
    builder.addCase(tryDownloadFile.pending, (state) => {
      state.batchProcessFile.status = "loading";
      state.batchProcessFile.isLoading = true;
      state.batchProcessFile.errors = [];
    });
    builder.addCase(tryDownloadFile.fulfilled, (state, { payload }) => {
      state.batchProcessFile.status = "succeeded";

      if (payload.data.isValid) {
        state.batchProcessFile.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.batchProcessFile.errors = errors;

      state.batchProcessFile.isLoading = false;
    });

    builder.addCase(tryDownloadFile.rejected, (state, { payload }) => {
      state.batchProcessFile.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.batchProcessFile.errors = errors;
      state.batchProcessFile.isLoading = false;
    });

    //EXPORTA EL REPORTE DE PROFESORES
    builder.addCase(tryGetTeachersReport.pending, (state) => {
      state.downloadedReport.status = "loading";
      state.downloadedReport.isLoading = true;
      state.downloadedReport.errors = [];
    });
    builder.addCase(tryGetTeachersReport.fulfilled, (state, { payload }) => {
      state.downloadedReport.status = "succeeded";

      if (payload.data.isValid) {
        state.downloadedReport.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.downloadedReport.errors = errors;

      state.downloadedReport.isLoading = false;
    });

    builder.addCase(tryGetTeachersReport.rejected, (state, { payload }) => {
      state.downloadedReport.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.downloadedReport.errors = errors;
      state.downloadedReport.isLoading = false;
    });

    //DESCARGA EL REPORTE DE PAGOS A TESORERIA Y ENVIA EL MAIL
    builder.addCase(trySendCasesToTreasury.pending, (state) => {
      state.treasuryCases.status = "loading";
      state.treasuryCases.isLoading = true;
      state.treasuryCases.errors = [];
    });
    builder.addCase(trySendCasesToTreasury.fulfilled, (state, { payload }) => {
      state.treasuryCases.status = "succeeded";

      if (payload.data.isValid) {
        state.treasuryCases.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.treasuryCases.errors = errors;

      state.treasuryCases.isLoading = false;
    });

    builder.addCase(trySendCasesToTreasury.rejected, (state, { payload }) => {
      state.treasuryCases.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.treasuryCases.errors = errors;
      state.treasuryCases.isLoading = false;
    });
  },
});

export const { setLoading, setCleanErrorsBackofficeTeachers } =
  backTeachersSlice.actions;

export default backTeachersSlice.reducer;

export const tryCleanErrorsBackofficeTeachers = () => (dispatch) => {
  dispatch(setCleanErrorsBackofficeTeachers());
};

export const selectBackTeachers = (state) => state.teachersBack.list;

export const selectPreLiquidation = (state) =>
  state.teachersBack.preLiquidation;

export const selectExportedTeachers = (state) =>
  state.teachersBack.exportedTeachers;

export const selectAssignedTeacher = (state) =>
  state.teachersBack.assignedTeacher;

export const selectTeacherMails = (state) => state.teachersBack.teacherMails;

export const selectBatchProcessData = (state) =>
  state.teachersBack.preLiquidationDetail;

export const selectPreLiquidationHeader = (state) =>
  state.teachersBack.preLiquidationHeader;

export const selectManualPreLiquidation = (state) =>
  state.teachersBack.manualPreLiquidation;

export const selectNotificationEmails = (state) =>
  state.teachersBack.notificationEmails;

export const selectDeletedTeacherCourseFee = (state) =>
  state.teachersBack.deletedTeacherCourseFee;

export const selectBatchProcessFile = (state) =>
  state.teachersBack.batchProcessFile;

export const selectTeachersReport = (state) =>
  state.teachersBack.downloadedReport;

export const selectTreasuryCases = (state) => state.teachersBack.treasuryCases;

export const tryGetAllTeachers = createAsyncThunk(
  "teachersBack/tryGetAllTeachers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getAllTeachers();
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryAssignTeacher = createAsyncThunk(
  "teachersBack/tryAssignTeacher",
  async (model, { rejectWithValue }) => {
    try {
      const response = await apiCourses.postAssignTeacher(model);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryExportTeachersAll = createAsyncThunk(
  "teachersBack/tryExportTeachersAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getExportTeachersAll();
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetTeacherMails = createAsyncThunk(
  "teachersBack/tryGetTeacherMails",
  async (isActive, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getTeacherMails(isActive);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryPreliquidation = createAsyncThunk(
  "teachersBack/tryPreliquidation",
  async (request, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.postPreliquidation(request);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetBatchProcessHeader = createAsyncThunk(
  "teachersBack/tryGetBatchProcessHeader",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getBatchProcessHeader();
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetBatchProcessDetail = createAsyncThunk(
  "teachersBack/tryGetBatchProcessDetail",
  async (batchprocessid, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getBatchProcessDetail(batchprocessid);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryPutManualCliPrecase = createAsyncThunk(
  "teachersBack/tryPutManualCliPrecase",
  async (requestBody, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.putManualCliPrecase(requestBody);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryPutNotificationEmails = createAsyncThunk(
  "teachersBack/tryPutNotificationEmails",
  async ({ batchprocessid, actionType }, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.putNotificationEmails(
        batchprocessid,
        actionType
      );
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryDeleteTeacherCourseFee = createAsyncThunk(
  "teachersBack/tryDeleteTeacherCourseFee",
  async (teacherCourseFeeId, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.deleteTeacherCourseFee(
        teacherCourseFeeId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryDownloadFile = createAsyncThunk(
  "teachersBack/tryDownloadFile",
  async (batchprocessid, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getBatchProcessFile(batchprocessid);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetTeachersReport = createAsyncThunk(
  "teachersBack/tryGetTeachersReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getTeachersReport();
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const trySendCasesToTreasury = createAsyncThunk(
  "teachersBack/trySendCasesToTreasury",
  async (teacherId, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getCasesToTreasury(teacherId);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);
