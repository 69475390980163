import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  tryStudentPaymentsSummary,
  selectStudentPaymentsSummary,
} from "../../store/slices/student-payments";
import { selectCurrentUser } from "../../store/slices/currentUser";
import {
  Alert,
  AlertTitle,
  Chip,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import StudentPaymentSummarySkeleton from "./skeleton";
import ModalMessages from "../layout/modal-messages";
import { Container } from "@mui/system";
import moment from "moment";
import { NumericFormat } from "react-number-format";

const StudentPaymentsSummary = ({ businessId }) => {
  const dispatch = useDispatch();
  const { roleId } = useSelector(selectCurrentUser);

  const paymentSummary = useSelector(selectStudentPaymentsSummary);

  useEffect(() => {
    dispatch(tryStudentPaymentsSummary(businessId));
  }, []);

  const chipColor = () => {
    if (paymentSummary.typeStudentConditionPaymentId === 2) return "error";
    if (paymentSummary.typeStudentConditionPaymentId === 1) return "success";
    if (paymentSummary.typeStudentConditionPaymentId === 3) return "warning";
  };
  return (
    <Container>
      {!paymentSummary.isLoading ? (
        <Grid pt={3} pb={3} container spacing={3}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 20 }}>
              <strong style={{ fontWeight: "bold" }}>Resumen</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {paymentSummary.typeStudentConditionPaymentId !== 1 && (
              <Alert severity="warning">
                {roleId !== 1 ? (
                  <>
                    <AlertTitle>
                      Alumno con pagos pendiente de revision
                    </AlertTitle>
                    Por favor, verificar si tienes pagos rechazados o pagos
                    vencidos pendientes de realizar/informar.
                  </>
                ) : (
                  <>
                    Por favor, verificar si el alumno tiene pagos pendientes de
                    verificar.
                  </>
                )}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Último pago recibido:{" "}
              {paymentSummary.lastPaymentReceived
                ? moment(paymentSummary.lastPaymentReceived).format("ll")
                : "Sin Información"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Próximo compromiso de pago:{" "}
              {paymentSummary.nextPayment
                ? moment(paymentSummary.nextPayment).format("ll")
                : "Sin información"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component={"span"}>
              <strong style={{ fontWeight: "bold" }}>
                Deuda total:{" "}
                <NumericFormat
                  key={`${paymentSummary.amountDebt}`}
                  value={paymentSummary.amountDebt ?? 0}
                  decimalScale="2"
                  thousandSeparator="."
                  displayType="text"
                  decimalSeparator=","
                  prefix="$"
                  fixedDecimalScale="2"
                />{" "}
              </strong>
              {paymentSummary.typeStudentConditionPaymentDescription !== "" && (
                <Chip
                  label={paymentSummary.typeStudentConditionPaymentDescription}
                  color={chipColor()}
                  size="small"
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Pagos {paymentSummary.quantityOfPaymentMade ?? 0} de{" "}
              {paymentSummary.quantityOfPayments ?? 0}
            </Typography>
            <LinearProgress
              sx={{
                fontSize: 60,
                width: "400px",
                height: "10px",
              }}
              variant="determinate"
              value={paymentSummary.progress ?? 0}
            />
            <Typography sx={{ color: "grey" }}>Compromiso de pago</Typography>
          </Grid>
        </Grid>
      ) : (
        <StudentPaymentSummarySkeleton />
      )}
      {paymentSummary.errors && paymentSummary.errors.length > 0 && (
        <ModalMessages errors={paymentSummary.errors} />
      )}
    </Container>
  );
};
export default StudentPaymentsSummary;
