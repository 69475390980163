import React, { useState } from "react";
import { useParams } from "react-router";
import { Card, CardContent, Grid, Tabs, Tab, Box } from "@mui/material";
import {
  AttachMoney,
  MenuBook,
  LibraryBooksOutlined,
  ViewModuleOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import CoursesGeneral from "../../../components/back-courses-general";
import CoursesComplements from "../../../components/back-courses-complements";
import CoursesModules from "../../../components/back-courses-modules";
import CoursesPrices from "../../../components/back-courses-prices";
import CoursesCommissions from "../../../components/back-courses-commission";

const CourseAdministrator = () => {
  const { courseId, methodologyId } = useParams();

  const [course, setCourse] = useState(courseId);

  const [methodStat, setMethodStat] = useState(methodologyId);

  const [outVacancies, setOutVacancies] = useState(undefined);

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid
        container
        pt="0px"
        spacing={2}
        sx={{
          heigth: "auto",
          width: "auto",
          marginBottom: 3,
          marginTop: 0,
          paddingBottom: 10,
        }}
      >
        <Grid item xs={12}>
          <Grid item>
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      backgroundColor: "white",
                    }}
                  >
                    <Tabs value={value} onChange={handleChange} centered>
                      <Tab
                        icon={<MenuBook />}
                        label="GENERAL"
                        iconPosition="start"
                      />
                      <Tab
                        icon={<LibraryBooksOutlined />}
                        label="COMPLEMENTOS"
                        iconPosition="start"
                        disabled={course == 0 || methodStat == 1}
                      />
                      <Tab
                        icon={<ViewModuleOutlined />}
                        label="MODULOS"
                        iconPosition="start"
                        disabled={course == 0 || methodStat == 1}
                      />
                      <Tab
                        icon={<SchoolOutlined />}
                        label="COMISIONES"
                        iconPosition="start"
                        disabled={course == 0}
                      />

                      <Tab
                        icon={<AttachMoney />}
                        label="COSTOS"
                        iconPosition="start"
                        disabled={course == 0 || methodStat == 1}
                      />
                    </Tabs>
                  </Box>
                </Grid>
              </Grid>
              {/* ******** GENERAL ************ */}
              {value === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <CoursesGeneral
                          course={course}
                          setCourse={setCourse}
                          setOutVacancies={setOutVacancies}
                          setMethodStat={setMethodStat}
                          methodStat={methodStat}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* ******** COMPLEMENTOS ************ */}
              {value === 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <CoursesComplements course={course} />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* ******** MODULOS ************ */}
              {value === 2 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <CoursesModules course={course} />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {/* ******** COMISIONES ************ */}
              {value === 3 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <CoursesCommissions
                          course={course}
                          outVacancies={outVacancies}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}

              {/* ******** COSTOS ************ */}
              {value === 4 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <CoursesPrices course={course} />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CourseAdministrator;
