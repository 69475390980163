import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import Theme from "../theme";
import Router from "../router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  tryCurrentUser,
  tryLogout,
} from "../store/slices/currentUser";
import useAuth from "../components/layout/functions/use-auth";
import LinearProgress from "@mui/material/LinearProgress";

const App = () => {
  const dispatch = useDispatch();
  const authorized = useAuth();
  const { isLoading } = useSelector(selectCurrentUser);

  useEffect(() => {
    (async () => {
      const action = await dispatch(tryCurrentUser(0));

      if (action.payload.status === 401 && authorized) dispatch(tryLogout());
    })();
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <Box sx={{ display: { xl: "none", xs: "block" } }}>
        {process.env.REACT_APP_SITE_NAME}
      </Box>
      <Box sx={{ display: "flex" }}>
        {isLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {!isLoading && authorized && (
          <BrowserRouter>
            <Router type="private" />
          </BrowserRouter>
        )}
        {!isLoading && !authorized && (
          <BrowserRouter>
            <Router type="public" />
          </BrowserRouter>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default App;
