import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNewUser, tryPostNewUser } from "../../../store/slices/users";
import {
  Typography,
  Grid,
  Button,
  Drawer,
  IconButton,
  TextField,
  FormControl,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useFormik } from "formik";
import ModalMessages from "../../layout/modal-messages";
import Spinner from "../../layout/spinner";
import { IsValidEmail } from "../../../services/functions/validator";
import validateCuil from "../../layout/functions/validate-cuil";
import { PatternFormat } from "react-number-format";

const validate = (values) => {
  const errors = {};

  if (!values.firstName || values.firstName === "")
    errors.firstName = "Requerido";

  if (values.lastName) {
    if (!values.cuil || values.cuil === "")
      errors.cuil =
        "Si carga una persona, debe introducir nombre, apellido y cuil. Si por el contrario carga un grupo, debe cargar sólo nombre";
  }

  if (values.cuil) {
    if (!validateCuil(values.cuil)) errors.cuil = "Formato de CUIL inválido";

    if (values.cuil.length < 11) errors.cuil = "Longitud de CUIL inválida";
    if (!values.lastName || values.lastName === "")
      errors.lastName =
        "Si carga una persona, debe introducir nombre, apellido y cuil. Si por el contrario carga un grupo, debe cargar sólo nombre";
  }

  if (!values.email || values.email === "") errors.email = "Requerido";

  if (values.email !== values.emailCopy)
    errors.email = "Los correos electrónicos no coinciden";

  if (!IsValidEmail(values.email))
    errors.email = "Formato de correo electrónico invalido";

  return errors;
};

const NewUserDrawer = ({ openDrawer, setOpenDrawer, setOpenDialog }) => {
  const dispatch = useDispatch();

  const newBackoffice = useSelector(selectNewUser);

  //Creo el formik
  const formik = useFormik({
    eneableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      emailCopy: "",
      cuil: "",
    },
    validate,
    onSubmit: async (values) => {
      const request = {};
      request.id = 0;
      request.clientId = 0;
      request.email = values.email;
      request.username = values.email;
      request.avatar = null;
      request.clients = {
        id: 0,
        nationality: 1,
        statusId: 1,
        email: values.email,
        avatar: null,
        cliNaturals: {
          cliNaturalId: 0,
          clientId: 0,
          firstName: values.firstName,
          lastName: values.lastName,
        },
        students: null,
        teachers: null,
      };

      if (values.cuil) {
        // Verifica si values.cuil existe
        request.clients.cliDocuments = [
          {
            cliDocuemtId: 0,
            clientId: 0,
            typeDocumentId: 2,
            document: values.cuil.replace(/-/g, ""),
          },
        ];
      }

      const action = await dispatch(tryPostNewUser(request));
      if (action.payload.isValid) {
        setOpenDialog(true);
        setOpenDrawer(false);
      }
    },
  });

  const onlyLetters = (event) => {
    const onlyLettersPattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/;
    if (!onlyLettersPattern.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      PaperProps={{
        sx: {
          zIndex: 1203,
          width: 500,
          boxSizing: "border-box",
        },
      }}
      onClose={() => setOpenDrawer(false)}
      sx={{
        zIndex: 1203,
        width: 500,
        flexShrink: 0,
      }}
    >
      <Grid container p={1}>
        <Grid item xs={10}>
          <Typography
            align="left"
            sx={{
              paddingLeft: 2,
              paddingRight: 5,
              paddingBottom: 1,
              paddingTop: 4,
              fontSize: "1.5em",
            }}
          >
            Nuevo Usuario
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "end" }}>
          <IconButton
            sx={{ color: "white" }}
            onClick={() => setOpenDrawer(false)}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ paddingLeft: 2, paddingBottom: 3 }}>
          <Typography variant="h7" align="center">
            Por favor, complete información en cada campo que se solicite en el
            formulario
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        spacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        p={2}
        sx={{ backgroundColor: "white" }}
      >
        <>
          <Grid item xs={12}>
            <TextField
              id="firstName"
              label="Nombre"
              variant="outlined"
              size="small"
              fullWidth
              onKeyDown={onlyLetters}
              onChange={formik.handleChange}
              value={formik.values.firstName}
              type="text"
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="lastName"
              label="Apellido"
              variant="outlined"
              size="small"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.lastName}
              onKeyDown={onlyLetters}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <PatternFormat
                id="cuil"
                label="CUIL"
                customInput={TextField}
                format="##-########-#"
                mask="_"
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                value={formik.values.cuil}
                error={formik.touched.cuil && Boolean(formik.errors.cuil)}
                helperText={formik.touched.cuil && formik.errors.cuil}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="email"
              label="Correo Electrónico"
              variant="outlined"
              size="small"
              fullWidth
              onPaste={(event) => event.preventDefault()}
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              type="email"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="emailCopy"
              label="Confirmación Correo Electrónico"
              variant="outlined"
              size="small"
              fullWidth
              onPaste={(event) => event.preventDefault()}
              onChange={formik.handleChange}
              value={formik.values.emailCopy}
              type="email"
              autoComplete="off"
            />
          </Grid>
          <Grid item sx={{ width: "100%", textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={formik.handleSubmit}
              disabled={newBackoffice?.isLoading}
              sx={{
                marginTop: 2,
                marginBottom: 2,
                width: 100,
                marginRight: 2,
              }}
            >
              {newBackoffice?.isLoading && <Spinner color="primary" />}
              Guardar
            </Button>
            <Button
              variant="outline"
              color="error"
              disabled={newBackoffice.isLoading}
              sx={{
                border: 2,
                marginTop: 2,
                marginBottom: 2,
                width: 100,
                color: "red",
              }}
              onClick={() => setOpenDrawer(false)}
            >
              Cancelar
            </Button>
          </Grid>
        </>
      </Grid>
      {newBackoffice.errors && newBackoffice.errors.length > 0 && (
        <ModalMessages errors={newBackoffice.errors} />
      )}{" "}
    </Drawer>
  );
};

export default NewUserDrawer;
