import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTypeTable,
  selectMaintenances,
} from "../../store/slices/maintenances";
import {
  selectBlocked,
  selectCurrentBackoffice,
  selectNewUser,
  tryBlockUser,
  tryGetBackofficeUser,
  tryPostNewUser,
} from "../../store/slices/users";
import {
  Typography,
  Button,
  Grid,
  TextField,
  Chip,
  FormControl,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { IsValidEmail } from "../../services/functions/validator";
import ModalMessages from "../layout/modal-messages";
import { PatternFormat } from "react-number-format";
import { useFormik } from "formik";
import Spinner from "../layout/spinner";
import UsersProfileSkeleton from "./skeleton";

const validate = (values) => {
  const errors = {};

  if (!values.firstName || values.firstName === "")
    errors.firstName = "Requerido";

  if (!values.lastName || values.lastName === "") errors.lastName = "Requerido";

  if (!values.email || values.email === "") errors.email = "Requerido";

  if (values.email !== values.emailCopy)
    errors.email = "Los correos electrónicos no coinciden";

  if (!IsValidEmail(values.email))
    errors.email = "Formato de correo electrónico invalido";

  return errors;
};

const UsersProfile = ({ user }) => {
  const dispatch = useDispatch();
  const { userId } = user;

  //MANEJO DE ELEMENTOS EDITABLES
  const [enableEdit, setEnableEdit] = useState(false);

  //MANEJO DE DIALOGS DE CONFIRMACION
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  //Estados
  const blockedState = useSelector(selectBlocked);
  const { data, isLoading, errors } = useSelector(selectCurrentBackoffice);
  const newUser = useSelector(selectNewUser);
  const typeTables = useSelector(selectMaintenances);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      state: 0,
      username: "",
      firstName: "",
      lastName: "",
      emailCopy: "",
      email: "",
      cuil: "",
      nationality: "",
    },
    validate,
    onSubmit: async (values) => {
      const request = {};
      request.id = data.id;
      request.clientId = data.clientId;
      request.email = values.email;
      request.username = values.username;
      request.avatar = null;
      request.clients = {
        id: data.clients.id,
        nationality: 1,
        statusId: values.state,
        email: values.email,
        avatar: null,
        cliNaturals: {
          cliNaturalId: 0,
          clientId: 0,
          firstName: values.firstName,
          lastName: values.lastName,
        },

        students: null,
        teachers: null,
      };

      const action = await dispatch(tryPostNewUser(request));
      if (action.payload.isValid) {
        setEnableEdit(false);
        setOpenDialog(true);
      }
    },
  });

  const fetchData = async () => {
    await dispatch(fetchTypeTable(23)); // TBLSTATUSCLIS
    const action = await dispatch(tryGetBackofficeUser(userId));
    if (action.payload.isValid) {
      const response = action.payload.data;
      formik.setValues({
        state: response.clients.statusId ?? "sin información",
        username: response.userName ?? "sin información",
        firstName: response.clients.cliNaturals.firstName ?? "sin información",
        lastName: response.clients.cliNaturals.lastName ?? "sin información",
        emailCopy: response.email ?? "sin información",
        email: response.email ?? "sin información",
        cuil:
          response.clients.cliDocuments.find((x) => x.typeDocumentId === 2)
            ?.document || "sin informacion",
        nationality: response.clients.nationality ?? "sin información",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onlyLetters = (event) => {
    const onlyLettersPattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/;
    if (!onlyLettersPattern.test(event.key)) {
      event.preventDefault();
    }
  };

  //BLOQUEAR/DESBLOQUAR USUARIO
  const blockUser = async (userId) => {
    const action = await dispatch(tryBlockUser(userId));
    if (action.payload.isValid) setOpenDialog(true);
  };

  const handleEditDetail = () => {
    setEnableEdit(true);
    if (enableEdit) formik.handleSubmit(); //Si el botón es de guardar se ejecuta el submit
  };

  const handleCancel = () => {
    fetchData();
    setEnableEdit(!enableEdit);
  };

  return (
    <>
      {isLoading || newUser?.isLoading ? (
        <UsersProfileSkeleton enableEdit={enableEdit} />
      ) : (
        <>
          <Grid container>
            <Grid
              container
              pt={2}
              textAlign="left"
              spacing={1}
              rowSpacing={2}
              pl={2}
              direction="row"
            >
              <Grid item xs={4}>
                <Typography fontWeight={700}>Nombre:</Typography>
              </Grid>
              <Grid item xs={8}>
                {enableEdit ? (
                  <TextField
                    id="firstName"
                    label="Nombre"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onKeyDown={onlyLetters}
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    type="text"
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    autoComplete="off"
                  />
                ) : (
                  <Typography fontSize={16}>
                    {formik.values.firstName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight={700}>Apellido:</Typography>
              </Grid>
              <Grid item xs={8}>
                {enableEdit ? (
                  <TextField
                    id="lastName"
                    label="Apellido"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    onKeyDown={onlyLetters}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    autoComplete="off"
                  />
                ) : (
                  <Typography fontSize={16}>
                    {formik.values.lastName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight={700}>Estado:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Chip
                  size="small"
                  label={typeTables.TBLSTATUSCLIS?.data.map((x) => {
                    if (x.id == formik.values.state) return x.description;
                  })}
                  color={formik.values.state == 1 ? "success" : "error"}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography fontWeight={700}>CUIL:</Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <Typography>
                    <PatternFormat
                      id="cuil"
                      customInput={TextField}
                      format="##-########-#"
                      mask="_"
                      size="small"
                      variant="standard"
                      disabled={true}
                      onChange={formik.handleChange}
                      value={formik.values.cuil}
                      required
                    />
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight={700}>Email: *</Typography>
              </Grid>
              <Grid item xs={8}>
                {enableEdit ? (
                  <TextField
                    required
                    fullWidth
                    id="email"
                    size="small"
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values.email}
                    onPaste={(event) => event.preventDefault()}
                    disabled={!enableEdit}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    autoComplete="off"
                  />
                ) : (
                  <Typography>{formik.values.email}</Typography>
                )}
              </Grid>

              {enableEdit ? (
                <>
                  <Grid item xs={4}>
                    <Typography fontWeight={700}>
                      Email Confirmación: *
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      required
                      fullWidth
                      id="emailCopy"
                      type="email"
                      size="small"
                      onPaste={(event) => event.preventDefault()}
                      onChange={formik.handleChange}
                      value={formik.values.emailCopy}
                      disabled={!enableEdit}
                      autoComplete="off"
                    />{" "}
                  </Grid>
                </>
              ) : null}
            </Grid>
            <Grid textAlign="center" container pt={2} spacing={1}>
              <Grid item xs>
                <Button
                  disabled={newUser.isLoading}
                  onClick={() => handleEditDetail()}
                  sx={{ width: "170px", height: "35px" }}
                  variant="contained"
                >
                  {newUser?.isLoading && <Spinner color="primary" />}
                  {enableEdit ? "GUARDAR" : "EDITAR"}
                </Button>
              </Grid>
              {enableEdit && (
                <Grid item xs>
                  <Button
                    onClick={() => handleCancel()}
                    sx={{ width: "170px", height: "35px" }}
                    variant="outlined"
                    color="error"
                  >
                    Cancelar
                  </Button>
                </Grid>
              )}
              {!enableEdit && (
                <Grid item xs>
                  <Button
                    onClick={() => setConfirmationDialog(true)}
                    sx={{ width: "170px", height: "35px" }}
                    disabled={blockedState.isLoading}
                    variant="contained"
                    color={formik.values.state !== 2 ? "error" : "success"}
                  >
                    {blockedState.isLoading && <Spinner color="primary" />}
                    {formik.values.state !== 2 ? "BLOQUEAR" : "HABILITAR"}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {openDialog && (
            <>
              <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"La información ha sido guardada con éxito"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenDialog(false);
                      fetchData();
                    }}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}
          {confirmationDialog && (
            <>
              <Dialog
                open={confirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {formik.values.state !== 2
                    ? "¿Estás seguro que deseas bloquear al usuario?"
                    : "¿Estás seguro que deseas habilitar al usuario?"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      blockUser(userId);
                      setConfirmationDialog(false);
                    }}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                  <Button
                    onClick={() => setConfirmationDialog(false)}
                    autoFocus
                  >
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}
          {errors && errors > 0 && <ModalMessages errors={errors} />}
          {blockedState.errors && blockedState.errors > 0 && (
            <ModalMessages errors={blockedState.errors} />
          )}
          {newUser.errors && newUser.errors > 0 && (
            <ModalMessages errors={newUser.errors} />
          )}
        </>
      )}
    </>
  );
};

export default UsersProfile;
