import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectModules,
  tryDeleteModule,
  tryGetModules,
} from "../../store/slices/back-course";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { MoreHoriz, Assignment } from "@mui/icons-material";
import ModulesDrawer from "./drawer";
import BackCoursesModulesSkeleton from "./skeleton";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";

const CoursesModules = ({ course }) => {
  const dispatch = useDispatch();

  //Select del detalle de modulos
  const modulesDetail = useSelector(selectModules);

  //Setea si se abre el drawer
  const [openDrawer, setOpenDrawer] = useState(false);

  //Setea la información que se envía al drawer
  const [dataDrawer, setDataDrawer] = useState(undefined);

  //Setea el estado de las filas
  const [rows, setRows] = useState([]);

  //Setea si se abre el dialog
  const [openDialog, setOpenDialog] = useState(false);

  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          courseModuleId: false,
        },
      },
      pagination: false,
    };
  };

  //CREAR NUEVO MODULO
  const newModule = () => {
    setDataDrawer({
      courseModuleId: 0,
      title: "",
      description: "",
    });
    setOpenDrawer(true);
  };

  //DETALLE DE LOS MODULOS
  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      const action = await dispatch(tryGetModules(course));
      if (action.payload.data.isValid) {
        if (action.payload.data.data.length > 0) {
          list = action.payload.data.data.map((x) => {
            return {
              courseId: x.courseId,
              courseModuleId: x.courseModuleId,
              title: x.title,
              description: x.description,
            };
          });
        }
      }
      setRows(list);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (openDialog) {
      const fetchData = async () => {
        let list = [];
        const action = await dispatch(tryGetModules(course));
        if (action.payload.data.isValid) {
          if (action.payload.data.data.length > 0) {
            list = action.payload.data.data.map((x) => {
              return {
                courseId: x.courseId,
                courseModuleId: x.courseModuleId,
                title: x.title,
                description: x.description,
              };
            });
          }
        }
        setRows(list);
      };
      fetchData();
    }
  }, [openDialog]);

  //ACCIONES
  const ActionsRow = ({ item }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleMenuItemClick = (event, index) => {
      //EDITAR
      if (index === 0) {
        setDataDrawer({
          courseModuleId: item.row.courseModuleId,
          title: item.row.title,
          description: item.row.description,
        });
        setOpenDrawer(true);
      }
      //ELIMINAR
      if (index === 1) {
        (async () => {
          const action = await dispatch(
            tryDeleteModule(item.row.courseModuleId)
          );
          if (action.payload.data.isValid) {
            setOpenDialog(true);
          }
        })();
      }
    };
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem
                      key="setAttendance"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Editar
                    </MenuItem>

                    <MenuItem
                      key="downloadProgram"
                      onClick={(event) => handleMenuItemClick(event, 1)}
                    >
                      Eliminar
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  //COLUMNAS
  const columns = [
    { field: "courseModuleId", headerName: "Id", filterable: false },
    {
      field: "title",
      headerAlign: "center",
      align: "left",
      flex: 0.3,
      headerName: "TÍTULO",
      minWidth: 250,
      headerClassName: "headRow",
    },
    {
      field: "description",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "DESCRIPCIÓN",
      minWidth: 150,
      headerClassName: "headRow",
    },

    {
      field: "actions",
      headerAlign: "center",

      headerName: "",
      flex: 0.3,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) => <ActionsRow item={params} />,
    },
  ];

  return (
    <>
      {modulesDetail.isLoading ? (
        <BackCoursesModulesSkeleton />
      ) : (
        <>
          <Grid
            container
            pt="0px"
            spacing={2}
            sx={{
              heigth: "auto",
              width: "auto",
              marginBottom: 3,
              marginTop: 0,
              paddingBottom: 10,
              marginLeft: 3,
              marginRight: 3,
            }}
            rowSpacing={6}
          >
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                Contenidos/Módulos
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Button
                variant="contained"
                onClick={() => newModule()}
                endIcon={<Assignment />}
              >
                AGREGAR CONTENIDO
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                slots={{
                  columnMenu: CustomColumnMenu,
                }}
                autoHeight={true}
                rowSelection={false}
                getRowId={(row) => row.courseModuleId}
                rows={rows}
                columns={columns}
                pagination={false}
                hideFooter={true}
                hideFooterRowCount
                hideFooterPagination
                initialState={initializeDataGrid()}
              />
            </Grid>
          </Grid>
          {setOpenDrawer && (
            <ModulesDrawer
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              moduleId={dataDrawer?.courseModuleId}
              moduleTitle={dataDrawer?.title}
              moduleDescription={dataDrawer?.description}
              courseId={course}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
            />
          )}
        </>
      )}
    </>
  );
};

export default CoursesModules;
