import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiStudents from "../../../services/apiStudents";

export const personalDataSlice = createSlice({
  name: "personalData",
  initialState: {
    item: {
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    list: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },

  reducers: {
    setPersonalData: (state, { payload }) => {
      state.list.data = payload;
    },
    setLoading: (state, { payload }) => {
      state.list.isLoading = payload;
    },
    setCleanErrors: (state) => {
      state.item.errors = [];
    },
  },
  //validadorPersonalData
  extraReducers(builder) {
    builder.addCase(tryValidateStudentData.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(tryValidateStudentData.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.item.errors = errors;

      if (payload.data.isValid === true) {
        state.item = { ...payload.data.data };
      }

      state.item.isLoading = false;
    });
    builder.addCase(tryValidateStudentData.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });
  },
});

export const tryCleanErrors = () => (dispatch) => {
  dispatch(setCleanErrors());
};
export const { setPersonalData, setLoading, setCleanErrors } =
  personalDataSlice.actions;
export default personalDataSlice.reducer;
export const selectPersonalData = (state) => state.personalData.item;

export const tryPersonalData = (cuil) => (dispatch) => {
  dispatch(setLoading(true));
  apiStudents
    .getValidateNeo({ cuil })

    .then((resp) => {
      if (resp) {
        dispatch(setPersonalData(resp.data.data));
        dispatch(setLoading(false));
      }
    })
    .catch((err) => console.error(err));
};

export const tryValidateStudentData = createAsyncThunk(
  "personalData/tryValidateStudentData",
  async (user, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.validateStudentData(user, {
        ruleSet: 2,
      });

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
