import apiBase from "./axios/apiBase";
import getQueryString from "./functions/getQueryString";

const controller = "auth";

export async function login(username, password) {
  return apiBase.post(`/${controller}/login`, {
    username,
    password,
  });
}

export async function permissions(id) {
  return apiBase
    .get(`/${controller}/users/${id}/permissions`)
    .then((response) => {
      if ([200, 201, 202, 400, 401].includes(response.status))
        return response.data;

      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function currentUser() {
  return apiBase.get(`/${controller}/current/user`);
}

export async function getUser(userId) {
  return apiBase
    .get(`/${controller}/users/${userId}`)
    .then((response) => {
      if ([200, 201, 202, 400, 401].includes(response.status))
        return response.data;

      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function getSellers(clientId) {
  return apiBase
    .get(`/${controller}/users/${clientId}/sellers`)
    .then((response) => {
      if ([200, 201, 202, 400, 401].includes(response.status))
        return response.data;

      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function users(queryModel) {
  const query = getQueryString(queryModel);

  return apiBase
    .get(`/${controller}/users?${query}`)
    .then((response) => {
      if ([200, 201, 202, 400, 401].includes(response.status))
        return response.data;

      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function logout() {
  return apiBase.post(`/${controller}/logout`);
}

export async function updateAvatar(userId, image) {
  return apiBase.put(`/${controller}/users/${userId}/avatar/update`, {
    avatar: image,
  });
}

export async function changePassword(model) {
  return apiBase.put(`/${controller}/${model.userId}/password`, {
    oldPassword: model.oldPassword,
    newPassword: model.newPassword,
  });
}

export async function recoverPass(queryModel) {
  return apiBase.put(`/${controller}/users/recover?email=${queryModel}`);
}

export async function createUser(model) {
  return apiBase.post(`/${controller}/users`, model);
}

export async function updateUser(model) {
  return apiBase.put(`/${controller}/users/${model.userId}`, model);
}

export async function deleteUser(userId) {
  return apiBase.delete(`/${controller}/users/${userId}`);
}

export async function recoverPassword(eMail) {
  return apiBase.post(`/${controller}/users/recover?email=${eMail}`);
}

export async function blockUser(userId) {
  return apiBase.get(`/${controller}/${userId}/blocked`);
}

export async function getUsersAll() {
  return apiBase.get(`/${controller}/all/header`);
}

export async function postNewBackofficeUser(request) {
  return apiBase.post(`/${controller}/backoffice`, request);
}

export async function getBackofficeUser(userId) {
  return apiBase.get(`/${controller}/${userId}/backoffice`);
}

export async function getExportBackofficeAll() {
  return apiBase.get(`/${controller}/all/detail`);
}

export async function deleteBackoffice(userId) {
  return apiBase.delete(`/${controller}/${userId}/backoffice`);
}
