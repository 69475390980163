import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMaintenances } from "../../store/slices/maintenances";
import {
  selectStudentTickets,
  selectStudentTicketsExport,
  tryStudentTickets,
  tryStudentTicketsExport,
  tryStudentTicketsSummary,
} from "../../store/slices/student-tickets";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Card,
  CardActionArea,
  CardContent,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogActions,
  ListItemButton,
  List,
  ListItemText,
  Box,
  ListItemIcon,
  Divider,
  Pagination,
  ButtonGroup,
} from "@mui/material";
import {
  MarkEmailRead,
  MarkEmailUnread,
  Search,
  Add,
  Inbox,
  Clear,
  Download,
  MailLock,
} from "@mui/icons-material";
import AddStudentTicketDrawer from "./drawers/AddStudentTicketDrawer";
import StudentTicketsDrawer from "./drawers/StudentTicketsDrawer";
import ModalMessages from "../layout/modal-messages";
import StudentTicketMainSkeleton from "./skeleton";
import messageCutter from "../../services/functions/messageCutter";
import Spinner from "../layout/spinner";
import filterByMessageSent from "../../services/functions/filterByMessageSent";

const StudentTickets = ({ clientId, businessId, roleId }) => {
  const dispatch = useDispatch();

  const typeTables = useSelector(selectMaintenances);

  const studentTicketsExport = useSelector(selectStudentTicketsExport);

  const studentTickets = useSelector(selectStudentTickets);

  const { isLoading, errors, data: tickets, totalPages } = studentTickets;

  let [ticketsFiltered, setTicketsFiltered] = useState(tickets);

  //Dialog de confirmación
  const [dialog, setDialog] = useState(false);

  //MANEJO DE DRAWER Y SET DE LA INFO QUE SE LE PASA
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openTicketDrawer, setOpenTicketDrawer] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasTopic, setHasTopic] = useState(false);
  const [topicSelected, setTopicSelected] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [showPagination, setShowPagination] = useState(true);
  const [page, setPage] = useState(1);

  const fetchData = async () => {
    await dispatch(
      tryStudentTickets({ studentId: businessId, pageNumber: page })
    );
  };

  const handleCloseDialog = async () => {
    setOpenDialog(false);
    fetchData();
  };

  const filterTickets = (value) => {
    setShowPagination(false);
    if (value) {
      const ticketsFilter = tickets.filter((ticket) => {
        return filterByMessageSent(ticket.messageSent, value);
      });
      setTicketsFiltered(ticketsFilter);
    }

    if (!value) {
      setTicketsFiltered(tickets);
      setShowPagination(true);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    fetchData();
    setHasTopic(false);
    setTopicSelected(null);
  }, [page]);

  useEffect(() => {
    setTicketsFiltered(tickets);
  }, [tickets]);

  useEffect(() => {
    if (!openTicketDrawer || !openAddDrawer)
      dispatch(tryStudentTicketsSummary(businessId));
  }, [openTicketDrawer, openAddDrawer]);

  const handleOpenTicket = (ticket) => {
    setOpenTicketDrawer(true);
    setTicketData(ticket);
  };

  const RenderTickets = () => {
    return (
      <>
        {ticketsFiltered.map((ticket) => {
          return (
            <Card
              key={ticket.ticketId}
              sx={{ backgroundColor: "rgb(231, 235, 240)", marginBottom: 1 }}
              onClick={() => handleOpenTicket(ticket)}
            >
              <CardActionArea>
                <CardContent>
                  <Grid container pt={2} direction="row">
                    <Grid item xs={1} sx={{ color: "#22577A" }}>
                      {ticket.statusOfMessageId === 2 && <MarkEmailRead />}
                      {ticket.statusOfMessageId === 1 && <MarkEmailUnread />}
                      {ticket.statusOfMessageId === 3 && <MailLock />}
                    </Grid>
                    <Grid item xs={9} rowSpacing={2}>
                      <Typography
                        fontSize={15}
                        fontWeight={700}
                        sx={{ color: "#22577A" }}
                      >
                        {ticket.topic}
                      </Typography>
                      <Typography sx={{ fontSize: 14 }}>
                        {ticket.subTopic}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        {messageCutter(ticket.titleOfTicket)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
        <Pagination
          count={totalPages}
          color="primary"
          defaultPage={page}
          page={page}
          onChange={(e, value) => handleChangePage(e, value)}
        />
      </>
    );
  };

  const RenderTopics = () => {
    return (
      <>
        <List component="nav" aria-label="secondary mailbox folder">
          {typeTables.TBLTOPICS.data.map((topic) => {
            return (
              <>
                <ListItemButton
                  sx={
                    hasTopic && topicSelected === topic.description
                      ? {
                          color: "white",
                          backgroundColor: "#22577A",
                          ":hover": {
                            color: "white",
                            backgroundColor: "#22577A",
                          },
                        }
                      : { color: "black" }
                  }
                  key={topic.id}
                  onClick={() => handleFilter(topic.description)}
                >
                  <ListItemText primary={topic.description} />
                </ListItemButton>
              </>
            );
          })}
        </List>
      </>
    );
  };

  const handleFilter = (description) => {
    setShowPagination(false);
    setTopicSelected(description);
    const ticketsFilter = tickets.filter((ticket) => {
      return ticket.topic === description;
    });
    setTicketsFiltered(ticketsFilter);
    setHasTopic(true);
  };

  const handleClearFilter = () => {
    setShowPagination(true);
    setTicketsFiltered(tickets);
    setHasTopic(false);
  };

  const handleClickExport = async () => {
    const action = await dispatch(tryStudentTicketsExport(businessId));

    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  return (
    <>
      {isLoading && <StudentTicketMainSkeleton />}
      <Grid container spacing={2} pt={2} rowSpacing={2} pl={2} direction="row">
        <Grid item xs={12} textAlign="right">
          <ButtonGroup>
            <Button
              variant="contained"
              endIcon={<Download />}
              onClick={() => handleClickExport()}
              disabled={studentTicketsExport.isLoading}
            >
              {studentTicketsExport.isLoading && <Spinner color="primary" />}
              Exportar
            </Button>
            {roleId !== 1 && (
              <Button
                variant="contained"
                endIcon={<Add />}
                onClick={() => {
                  setOpenAddDrawer(true);
                }}
                sx={{ width: "170px", height: "35px" }}
              >
                <Typography> NUEVO MENSAJE</Typography>
              </Button>
            )}
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} fullWidth>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            type="search"
            label="Buscar sobre el título del mensaje"
            onChange={(event) => {
              filterTickets(event.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <List component="nav" aria-label="main mailbox folders">
              <ListItemButton selected={true}>
                <ListItemIcon>
                  <Inbox />
                </ListItemIcon>
                <ListItemText primary="Tópicos" />
                {hasTopic && (
                  <>
                    <Typography fontSize={12}> Limpiar filtro</Typography>
                    <ListItemIcon onClick={() => handleClearFilter()}>
                      <Clear />
                    </ListItemIcon>
                  </>
                )}
              </ListItemButton>
            </List>
            <Divider />
            <RenderTopics />
          </Box>
        </Grid>
        <Grid item xs={8} sx={{ pb: 2 }}>
          <RenderTickets />
        </Grid>
      </Grid>
      {openAddDrawer && (
        <AddStudentTicketDrawer
          openDrawer={openAddDrawer}
          setOpenDrawer={setOpenAddDrawer}
          setOpenDialog={setOpenDialog}
          businessId={businessId}
          clientId={clientId}
        />
      )}
      {openTicketDrawer && (
        <StudentTicketsDrawer
          openTicketDrawer={openTicketDrawer}
          setOpenTicketDrawer={setOpenTicketDrawer}
          ticketData={ticketData}
          clientId={clientId}
          businessId={businessId}
          roleId={roleId}
          setDialog={setDialog}
        />
      )}
      {dialog && (
        <Dialog
          open={dialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"La información ha sido guardada con éxito"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                fetchData();
                setDialog(false);
              }}
              autoFocus
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <>
        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Se ha enviado con éxito"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => handleCloseDialog()} autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </>
      {errors && errors.length > 0 && <ModalMessages errors={errors} />}
    </>
  );
};

export default StudentTickets;
