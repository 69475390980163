import apiBase from "./axios/apiBase";
import getQueryString from "./functions/getQueryString";

const controller = "maintenance";

export async function getCatalogs(typeTableId, queryModel) {
  const query = getQueryString(queryModel);

  return apiBase.get(`/${controller}/catalogs/${typeTableId}?${query}`);
}

export async function saveCatalog(typeTableId, model) {
  return apiBase.post(`/${controller}/catalogs/${typeTableId}`, model);
}

export async function deleteCatalog(typeTableId, id) {
  return apiBase.delete(`/${controller}/catalogs/${typeTableId}/${id}`);
}

export async function getSubtopics(topicId) {
  return apiBase.get(`/${controller}/subtopics/${topicId}`);
}

export async function getTypeTables() {
  return apiBase.get(`/${controller}/typetable/all`);
}

export async function getCatalog(typeTableId) {
  return apiBase.get(`/${controller}/catalogs/${typeTableId}`);
}

export async function postNewTuple(model) {
  return apiBase.post(`/${controller}`, model);
}
