import React from "react";
import { Routes, Route } from "react-router-dom";
import PageLayout from "./components/layout/page-layout";
import LoginPage from "./pages/auth/Login";
import HomePage from "./pages/general/Home";
import StudentsPage from "./pages/students/Students";
import TeachersPage from "./pages/teachers/Teachers";
import ProfilePage from "./pages/auth/Profile";
import SignUpPage from "./pages/auth/SignUp";
import NotFoundPage from "./pages/auth/NotFound";
import CoursesPage from "./pages/backoffice/courses-list/CoursesList";
import CommunicationsPage from "./pages/backoffice/common/Communications";
import SettingsPage from "./pages/backoffice/common/Settings";
import StudentsListPage from "./pages/backoffice/students-list/StudentsList";
import TeachersListPage from "./pages/backoffice/teacher-list/TeachersList";
import CoursesAvailablesPage from "./pages/auth/CoursesAvailables";
import CompletionPage from "./pages/auth/Completion";
import PagePublicLayout from "./components/layout/page-public-layout";
import CourseAdministrator from "./pages/backoffice/courses-list/CourseAdministrator";
import AddNewTeacher from "./pages/backoffice/teacher-list/AddNewTeacher";
import TypeTables from "./pages/backoffice/type-tables/typeTables";
import BackofficeList from "./pages/backoffice/backoffice-list/BackofficeList";
import Users from "./pages/backoffice/users/users";
import Settlements from "./pages/backoffice/settlements/adminSettlements";
import SettlementAdministrator from "./pages/backoffice/settlements/editSettlements";

const Router = ({ type }) => {
  const publicRoutes = (
    <Routes>
      <Route
        path="/login"
        exact
        element={
          <PagePublicLayout component={<LoginPage />} anonymous={true} />
        }
      />
      <Route
        path="/signup"
        element={
          <PagePublicLayout component={<SignUpPage />} anonymous={true} />
        }
      />
      <Route
        path="/courses-availables"
        element={
          <PagePublicLayout
            component={<CoursesAvailablesPage />}
            anonymous={true}
          />
        }
      />
      <Route
        path="/completion"
        element={
          <PagePublicLayout component={<CompletionPage />} anonymous={true} />
        }
      />
      <Route
        path="*"
        element={
          <PagePublicLayout component={<LoginPage />} anonymous={true} />
        }
      />
    </Routes>
  );

  const privateRoutes = (
    <Routes>
      <Route
        path="/"
        exact
        element={<PageLayout component={<HomePage />} anonymous={false} />}
      />
      <Route
        path="/signup"
        element={<PageLayout component={<SignUpPage />} anonymous={true} />}
      />
      <Route
        path="/home"
        exact
        element={<PageLayout component={<HomePage />} anonymous={false} />}
      />
      <Route
        path="/courses"
        exact
        element={<PageLayout component={<CoursesPage />} anonymous={false} />}
      />
      <Route
        path="/students"
        exact
        element={<PageLayout component={<StudentsPage />} anonymous={false} />}
      />
      <Route
        path="/teachers"
        exact
        element={<PageLayout component={<TeachersPage />} anonymous={false} />}
      />
      <Route
        path="/backoffice/communications"
        exact
        element={
          <PageLayout component={<CommunicationsPage />} anonymous={false} />
        }
      />
      <Route
        path="/backoffice/settings"
        exact
        element={<PageLayout component={<SettingsPage />} anonymous={false} />}
      />
      <Route
        path="/backoffice/students"
        exact
        element={
          <PageLayout component={<StudentsListPage />} anonymous={false} />
        }
      />
      <Route
        path="/backoffice/teachers"
        exact
        element={
          <PageLayout component={<TeachersListPage />} anonymous={false} />
        }
      />

      <Route
        path="/backoffice/newteacher"
        exact
        element={<PageLayout component={<AddNewTeacher />} anonymous={false} />}
      />
      <Route
        path="/backoffice/courses/:courseId/:methodologyId"
        exact
        element={
          <PageLayout component={<CourseAdministrator />} anonymous={false} />
        }
      />
      <Route
        path="/profile"
        exact
        a
        element={<PageLayout component={<ProfilePage />} anonymous={false} />}
      />
      <Route
        path="/backoffice/typetables"
        exact
        element={<PageLayout component={<TypeTables />} anonymous={false} />}
      />

      <Route
        path="/backoffice/userslist"
        exact
        element={
          <PageLayout component={<BackofficeList />} anonymous={false} />
        }
      />

      <Route
        path="/backoffice/usersprofile"
        exact
        element={<PageLayout component={<Users />} anonymous={false} />}
      />

      <Route
        path="/backoffice/settlements"
        exact
        element={<PageLayout component={<Settlements />} anonymous={false} />}
      />
      <Route
        path="/backoffice/AdminSettlements"
        exact
        element={
          <PageLayout
            component={<SettlementAdministrator />}
            anonymous={false}
          />
        }
      />

      <Route
        path="*"
        element={<PageLayout component={<NotFoundPage />} anonymous={false} />}
      />
    </Routes>
  );

  return type === "private" ? privateRoutes : publicRoutes;
};

export default Router;
