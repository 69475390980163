import { Container, Grid } from "@mui/material";
import React from "react";
import LeftSection from "../../components/login/left-section";
import RightSection from "../../components/login/right-section";

const LoginPage = () => {
  return (
    <Container fixed>
      <Grid
        container
        spacing={2}
        sx={{
          margin: 2,
        }}
      >
        <Grid
          sx={{
            background: "linear-gradient(to right bottom, #85BFE6, #22577A)",
          }}
          item
          xs={8}
          md={8}
        >
          <LeftSection />
        </Grid>
        <Grid
          sx={{
            backgroundColor: "primary.contrastText",
          }}
          xs={4}
          md={4}
        >
          <RightSection />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginPage;
