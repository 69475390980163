import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { selectCurrentUser } from "../../../store/slices/currentUser";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  margin: "auto",

  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Modal = ({ open, onClose }) => {
  const { roleId } = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  if (!open) return null;
  return (
    <Box sx={style} display="flex" justifyContent="center" alignItems="center">
      <div>
        <div className="modalContainer">
          <Typography align="center">
            <p>Felicidades!📝</p>
            <strong>
              <h2>PRE INSCRIPCION FINALIZADA</h2>
            </strong>
            {roleId == 1 ? (
              <p>
                Por favor indícale al alumno que revise su casilla de correo con
                las instrucciones a seguir para completar el proceso de adhesión
                a los cursos seleccionados."
              </p>
            ) : (
              <p>
                Revisa tu casilla de correo con los pasos a seguir para
                completar el proceso de adhesión a los cursos seleccionados.
                Sigue las instrucciones recibidas en el correo y recuerda que
                estamos a disposición si tienes alguna duda.
              </p>
            )}
          </Typography>
          <Button
            onClick={() => {
              roleId == 1
                ? navigate("/backoffice/students")
                : window.open(
                    "https://www.utedyc.org.ar/CampusVirtual/",
                    "_self"
                  );
            }}
            variant="outlined"
            color="primary"
            size="large"
            fullWidth="true"
          >
            Aceptar
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Modal;
