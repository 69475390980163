import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/slices/currentUser";

const urlRoles = {
  URL_BACKOFFICE: "/backoffice/usersprofile",
  URL_TEACHER: "/teachers",
  URL_STUDENT: "/students",
};

const roles = {
  ROLE_BACKOFFICE: "ADMINISTRATOR",
  ROLE_TEACHER: "TEACHER",
  ROLE_STUDENT: "STUDENT",
};

const Navigation = ({ open }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [urlRedirect, setUrlRedirect] = useState(null);
  const [listToAdd, setListToAdd] = useState(null);

  let [listMenu, setListMenu] = useState([
    // { id: 1, description: "Inicio", icon: "dashboard", url: "/home" },
    { id: 2, description: "Mi UTEDYC", icon: "school", url: "/home" },
  ]);

  const { roleId } = useSelector(selectCurrentUser);

  const renderListMenu = (listMenu = null) => {
    const listToAllRoles = [
      //  { id: 1, description: "Inicio", icon: "dashboard", url: "/home" },
      { id: 2, description: "Mi UTEDYC", icon: "school", url: urlRedirect },
    ];

    if (listMenu !== null) {
      const listComplete = [...listToAllRoles, ...listMenu];
      setListMenu(listComplete);
    }
    if (listMenu === null) {
      setListMenu([...listToAllRoles]);
    }
  };

  const onClickMenu = (menu) => {
    navigate(menu.url);
  };

  const StyledListItem = styled(ListItem)(({ theme, url }) => ({
    backgroundColor:
      url === location.pathname
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  }));

  const changeUrlRedirect = () => {
    if (roleId === 3) {
      setUrlRedirect(urlRoles.URL_TEACHER);
    }

    if (roleId === 2) {
      setUrlRedirect(urlRoles.URL_STUDENT);
    }

    if (roleId === 1) {
      setListToAdd([
        { id: 4, description: "Cursos", icon: "bookmark", url: "/courses" },
        {
          id: 5,
          description: "Profesores",
          icon: "work",
          url: "/backoffice/teachers",
        },
        {
          id: 6,
          description: "Alumnos",
          icon: "school",
          url: "/backoffice/students",
        },
        {
          id: 8,
          description: "Liquidaciones",
          icon: "payments",
          url: "/backoffice/settlements",
        },
        {
          id: 7,
          description: "Usuarios",
          icon: "groups",
          url: "/backoffice/userslist",
        },
        {
          id: 3,
          description: "Configuración",
          icon: "settings",
          url: "/backoffice/typetables",
        },
      ]);
      setUrlRedirect(urlRoles.URL_BACKOFFICE);
    }
    navigate(urlRedirect);
  };

  useEffect(() => {
    if (roleId !== null) {
      changeUrlRedirect();
      renderListMenu(listToAdd);
    }
  }, [roleId, urlRedirect]);

  return (
    <>
      {listMenu !== null ? (
        <List>
          {listMenu.map((item, index) => (
            <StyledListItem
              key={index}
              url={item.url}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                selected={item.url === location.pathname}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => onClickMenu(item)}
              >
                <Tooltip title={item.description} placement="right" arrow>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    <Icon>{item.icon}</Icon>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={item.description}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </StyledListItem>
          ))}
        </List>
      ) : (
        <h1>Cargando...</h1>
      )}
      <Divider />
    </>
  );
};

export default Navigation;
