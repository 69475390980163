import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectDeleted,
  selectTeacherCliPreCasesHeader,
  tryDeleteTeacherClicase,
  tryGetExportTeacherCliprecases,
  tryGetTeacherCliCasesSummary,
  tryGetTeacherCliprecasesHeader,
} from "../../store/slices/teacher-payments";
import {
  Button,
  ButtonGroup,
  Card,
  Chip,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { MoreHoriz, Assignment } from "@mui/icons-material";
import BillingDrawer from "./drawers/billingDrawer";
import DetailDrawer from "./drawers/detailDrawer";
import { DataGrid } from "@mui/x-data-grid";
import CustomColumnMenu from "../layout/grid-column-menu";
import { NumericFormat } from "react-number-format";
import ModalMessages from "../layout/modal-messages";
import TeacherPaymentsHeaderSkeleton from "./skeleton/paymentsHeaderSkeleton";
import Spinner from "../layout/spinner";

const TeacherPaymentsList = ({ isSummary, businessId, clientId, roleId }) => {
  const dispatch = useDispatch();

  const deletedBill = useSelector(selectDeleted);

  //TRAE EL DETALLE DE LA GRILLA
  const cliprecasesHeader = useSelector(selectTeacherCliPreCasesHeader);

  //Manejo de DIALOG
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  //Manejo Drawer DETALLE
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [cliPrecasesid, setCliPrecasesid] = useState(null);

  //Manejo Drawer FACTURA
  const [openBillingDrawer, setOpenBillingDrawer] = useState(false);
  const [clicaseid, setclicaseid] = useState(null);
  const [month, setMonth] = useState(null);

  //Detalle columnas
  const [rows, setRows] = useState([]);

  //MANEJO ITEM
  const [item, setItem] = useState(null);

  useEffect(() => {
    dispatch(
      tryGetTeacherCliprecasesHeader({
        teacherId: businessId,
        isFirstOrLastRecords: isSummary,
      })
    );
  }, []);

  useEffect(() => {
    setRows(
      cliprecasesHeader.data.map((x) => {
        return {
          cliCaseid: x.cliCaseid,
          month: x.month,
          precaseid: x.precaseid,
          reference: x.reference ?? "",
          state: x.state ?? "",
          taxBase: x.taxBase ?? "",
          taxes: x.taxes ?? "",
          total: x.total,
          stateId: x.stateId,
        };
      })
    );
  }, [cliprecasesHeader]);

  const handleClickExport = async () => {
    const action = await dispatch(tryGetExportTeacherCliprecases(businessId));

    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          actions: !isSummary,
          precaseid: false,
          cliCaseid: false,
          stateId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 10 } },
    };
  };

  //MANEJA EL COLOR DEL CHIP
  const chipColor = (stateCase) => {
    const colorMap = {
      1: "warning",
      2: "success",
      3: "warning",
      4: "success",
      5: "success",
      6: "primary",
      7: "error",
    };

    return colorMap[stateCase] || "default";
  };

  //REFRESCA EL SUMMARY
  const refreshSummary = () => {
    dispatch(tryGetTeacherCliCasesSummary(businessId));
  };

  //ELIMINAR FACTURA
  const deleteBill = (item) => {
    (async () => {
      const action = await dispatch(
        tryDeleteTeacherClicase(item.row.cliCaseid)
      );
      if (action.payload.data.isValid)
        dispatch(
          tryGetTeacherCliprecasesHeader({
            teacherId: businessId,
            isFirstOrLastRecords: isSummary,
          })
        );
      setOpenDialog(true);
    })();
  };

  //MANEJA LAS ACCIONES DE LA GRILLA
  const ActionsRow = ({ item }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleMenuItemClick = (event, index) => {
      //VER DETALLE***
      if (index === 0) {
        setCliPrecasesid(item.row.precaseid);
        setOpenDetailDrawer(true);
      }
      //CARGAR/ MODIFICAR FACTURA
      if (index === 1 || index === 2) {
        setCliPrecasesid(item.row.precaseid);
        setclicaseid(item.row.cliCaseid);
        setMonth(item.row.month);
        setOpenBillingDrawer(true);
      }
      //ELIMINAR FACTURA
      if (index === 3) {
        setItem(item);
        setOpenConfirmationDialog(true);
      }
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    <MenuItem
                      key="seeDetail"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Ver Detalle
                    </MenuItem>

                    {item.row.stateId === 3 && roleId !== 1 && (
                      <MenuItem
                        key="assignBill"
                        onClick={(event) => handleMenuItemClick(event, 1)}
                      >
                        Asignar Factura
                      </MenuItem>
                    )}                 
                    {(item.row.stateId === 6 || item.row.stateId === 7 || item.row.stateId === 9) &&
                      roleId !== 1 && (
                        <MenuItem
                          key="editBill"
                          onClick={(event) => handleMenuItemClick(event, 2)}
                        >
                          Modificar Factura
                        </MenuItem>
                      )}
                    {item.row.stateId === 6 && roleId != 1 && (
                      <MenuItem
                        key="deleteBill"
                        onClick={(event) => handleMenuItemClick(event, 3)}
                      >
                        Eliminar Factura
                      </MenuItem>
                    )}
                    {item.row.stateId !== 3 && roleId == 1 && (
                      <MenuItem
                        key="adminBill"
                        onClick={(event) => handleMenuItemClick(event, 2)}
                      >
                        Administrar Factura
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  //MANEJO DE COLUMNAS
  const columns = [
    {
      field: "cliCaseid",
      headerAlign: "center",
      align: "center",
      headerName: "cliCaseid",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "stateId",
      headerAlign: "center",
      align: "center",
      headerName: "stateId",
      minWidth: 150,
      headerClassName: "headRow",
    },

    {
      field: "precaseid",
      headerAlign: "center",
      align: "center",
      headerName: "precaseid",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "month",
      headerAlign: "center",
      align: "center",
      headerName: "Mes",
      minWidth: 150,
      headerClassName: "headRow",
    },

    {
      field: "reference",
      headerAlign: "center",
      align: "center",
      headerName: "Referencia",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "state",
      headerAlign: "center",
      align: "center",
      headerName: "Estado",
      minWidth: 150,
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={chipColor(params.row.stateId)}
          size="small"
        />
      ),
    },
    {
      field: "taxBase",
      headerAlign: "center",
      align: "center",
      headerName: "Base Imp.",
      minWidth: 120,
      headerClassName: "headRow",
      renderCell: (params) => (
        <NumericFormat
          key={`${params.field}_${params.id}`}
          value={params.value ?? 0}
          decimalScale="2"
          thousandSeparator="."
          displayType="text"
          decimalSeparator=","
          prefix="$"
          fixedDecimalScale="2"
        />
      ),
    },
    {
      field: "taxes",
      headerAlign: "center",
      align: "center",
      headerName: "Impuestos",
      minWidth: 120,
      headerClassName: "headRow",
      renderCell: (params) => (
        <NumericFormat
          key={`${params.field}_${params.id}`}
          value={params.value ?? 0}
          decimalScale="2"
          thousandSeparator="."
          displayType="text"
          decimalSeparator=","
          prefix="$"
          fixedDecimalScale="2"
        />
      ),
    },
    {
      field: "total",
      headerAlign: "center",
      align: "center",
      headerName: "Total",
      minWidth: 120,
      headerClassName: "headRow",
      renderCell: (params) => (
        <NumericFormat
          key={`${params.field}_${params.id}`}
          value={params.value ?? 0}
          decimalScale="2"
          thousandSeparator="."
          displayType="text"
          decimalSeparator=","
          prefix="$"
          fixedDecimalScale="2"
        />
      ),
    },

    {
      field: "actions",
      headerAlign: "center",
      headerName: "",
      flex: 0.3,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) =>
        deletedBill.isLoading ? (
          <Spinner color="primary" />
        ) : (
          <ActionsRow item={params} />
        ),
    },
  ];

  return (
    <>
      <Card>
        <Container>
          <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
            {cliprecasesHeader.isLoading ? (
              <TeacherPaymentsHeaderSkeleton />
            ) : (
              <>
                <Grid item xs={6}>
                  <Typography
                    align="left"
                    style={{ fontWeight: "bold", fontSize: 18 }}
                  >
                    {isSummary ? "Últimos Pagos" : "Pagos"}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "end" }}>
                  {!isSummary && (
                    <Button
                      variant="contained"
                      endIcon={<Assignment />}
                      onClick={() => handleClickExport()}
                    >
                      EXPORTAR
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <DataGrid
                    slots={{
                      columnMenu: CustomColumnMenu,
                    }}
                    autoHeight={true}
                    rowSelection={false}
                    getRowId={(row) => row.precaseid}
                    disableRowSelectionOnClick
                    rows={rows}
                    columns={columns}
                    pageSizeOptions={[5, 10, 25, 50]}
                    initialState={initializeDataGrid()}
                    sx={{
                      "& .MuiDataGrid-virtualScroller": {
                        minHeight: 250,
                      },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>

          {openDialog && (
            <>
              <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"La información ha sido guardada con éxito"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenDialog(false);
                      refreshSummary();
                    }}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}

          {openConfirmationDialog && (
            <>
              <Dialog
                open={openConfirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"¿Confirmas eliminar la factura?"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      deleteBill(item);
                      setOpenConfirmationDialog(false);
                    }}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenConfirmationDialog(false);
                    }}
                    autoFocus
                  >
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}

          {cliprecasesHeader.errors && cliprecasesHeader.errors.length > 0 && (
            <ModalMessages errors={cliprecasesHeader.errors} />
          )}
        </Container>
      </Card>
      {openBillingDrawer && (
        <BillingDrawer
          openBillingDrawer={openBillingDrawer}
          setOpenBillingDrawer={setOpenBillingDrawer}
          setOpenDialog={setOpenDialog}
          cliPrecasesid={cliPrecasesid}
          clientId={clientId}
          clicaseid={clicaseid}
          month={month}
          isSummary={isSummary}
          businessId={businessId}
        />
      )}
      {openDetailDrawer && (
        <DetailDrawer
          openDetailDrawer={openDetailDrawer}
          setOpenDetailDrawer={setOpenDetailDrawer}
          setOpenDialog={setOpenDialog}
          businessId={businessId}
          cliPrecasesid={cliPrecasesid}
        />
      )}
    </>
  );
};

export default TeacherPaymentsList;
