import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDeleted,
  selectStudentPayments,
  selectStudentPaymentsExport,
  tryDeletePayment,
  tryStudentPayments,
  tryStudentPaymentsExport,
} from "../../store/slices/student-payments";
import {
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { Download, MoreHoriz } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import StudentPaymentDrawer from "./drawers/StudentPayment";
import ModalMessages from "../layout/modal-messages";
import CustomColumnMenu from "../layout/grid-column-menu";
import StudentPaymentsSkeleton from "./skeleton";
import Spinner from "../layout/spinner";
import moment from "moment";
import { NumericFormat } from "react-number-format";

const StudentPayments = ({ isSummary, businessId, roleId }) => {
  const dispatch = useDispatch();

  const paymentsHeader = useSelector(selectStudentPayments);

  const studentPaymentsExport = useSelector(selectStudentPaymentsExport);

  const deleted = useSelector(selectDeleted);

  //Dialog confirmacion eliminar pago
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [dataDialog, setDataDialog] = useState(undefined);

  //Drawer PAGOS
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState(undefined);

  const [rows, setRows] = useState([]);

  //Dialog confirmacion modificar o cargar pago
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    dispatch(
      tryStudentPayments({
        studentId: businessId,
        isFirstOrLastRecords: isSummary,
      })
    );
  }, []);

  useEffect(() => {
    const list = paymentsHeader.data.map((x) => {
      return {
        id: Math.floor(Math.random() * 1000) + 1, // TODO: Id autonumerico
        courseDescription: x.courseDescription,
        dateLimit: x.dateLimit,
        wayOfPayment: x.wayOfPayment,
        receptionDate: x.receptionDate,
        stateOfPaymentId: x.stateOfPaymentId,
        state: { id: x.stateOfPaymentId, text: x.stateOfPayment },
        paymentAmount: x.paymentAmount,
        balance: x.balance,
        actions: {
          studentCourseId: x.studentCourseId,
          courseId: x.courseId,
          courseDescription: x.courseDescription,
          coursefeeId: x.nId,
          paymentId: x.paymentId,
          stateOfPaymentId: x.stateOfPaymentId,
        },
        typeFeeDescription: x.typeFeeDescription,
      };
    });
    setRows(list);
  }, [paymentsHeader.data]);

  //Eliminar un Pago
  const deletePayment = async ({ studentId, courseId, coursefeeId }) => {
    setOpenDialog(false);

    await dispatch(tryDeletePayment({ studentId, courseId, coursefeeId }));

    if (deleted.data) {
      setSuccessDialog(true);
      setOpenDialog(true);
    }
  };
  const exitDialog = () => {
    setOpenDialog(false);
    setSuccessDialog(false);
  };

  const refreshTable = async () => {
    await dispatch(
      tryStudentPayments({
        studentId: businessId,
        isFirstOrLastRecords: isSummary,
      })
    );

    setOpenDialog(false);
    setSuccessDialog(false);
  };

  const handleClickExport = async () => {
    const action = await dispatch(tryStudentPaymentsExport(businessId));

    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  const ActionsRow = ({ item }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleMenuItemClick = (event, index) => {
      if (index === 0 || index === 1) {
        setDataDrawer({
          courseId: item.value.courseId,
          course: item.value.courseDescription,
          coursefeeId: item.value.coursefeeId,
          paymentId: item.value.paymentId,
          studentCourseId: item.value.studentCourseId,
        });
        setOpenDrawer(true);
      }
      if (index === 2) {
        setDataDialog({
          studentId: businessId,
          courseId: item.value.courseId,
          coursefeeId: item.value.coursefeeId,
        });
        setOpenDialog(true);
      }
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    const shouldShowActions =
      (item.value.stateOfPaymentId === 0 && roleId !== 1) || // CARGAR PAGO
      item.value.stateOfPaymentId === 1 ||
      item.value.stateOfPaymentId === 2 ||
      item.value.stateOfPaymentId === 3; // ADMINISTRAR PAGO

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            disabled={
              (item.value.stateOfPaymentId === 1 && roleId !== 1) ||
              !shouldShowActions
            }
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>

        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left-start"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {item.value.stateOfPaymentId === 0 && roleId !== 1 && (
                      //CARGAR PAGO
                      <MenuItem
                        key="createPayment"
                        onClick={(event) => handleMenuItemClick(event, 0)}
                      >
                        Cargar Pago
                      </MenuItem>
                    )}
                    {(item.value.stateOfPaymentId === 2 ||
                      item.value.stateOfPaymentId === 3) &&
                      roleId !== 1 && (
                        //MODIFICAR PAGO
                        <MenuItem
                          key="updatePayment"
                          onClick={(event) => handleMenuItemClick(event, 1)}
                        >
                          Modificar Pago
                        </MenuItem>
                      )}
                    {(item.value.stateOfPaymentId === 2 ||
                      item.value.stateOfPaymentId === 3) && (
                      //ELIMINAR PAGO
                      <MenuItem
                        key="deletePayment"
                        onClick={(event) => handleMenuItemClick(event, 2)}
                      >
                        Eliminar Pago
                      </MenuItem>
                    )}
                    {roleId == 1 && (
                      //ADMINISTRAR PAGO
                      <MenuItem
                        key="updatePayment"
                        onClick={(event) => handleMenuItemClick(event, 1)}
                      >
                        Administrar Pago
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const columns = [
    { field: "id", headerName: "Id", filterable: false },
    {
      field: "stateOfPaymentId",
      headerAlign: "center",
      align: "left",
      headerName: "Curso",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "courseDescription",
      headerAlign: "center",
      align: "left",
      headerName: "Curso",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "typeFeeDescription",
      headerAlign: "center",
      align: "center",
      headerName: "Tipo de Compromiso",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";

        return <Chip label={params.value} color="primary" size="small" />;
      },
    },
    {
      field: "dateLimit",
      headerAlign: "center",
      align: "right",
      headerName: "Fecha Vencimiento",
      flex: 1,
      headerClassName: "headRow",
      valueFormatter: (params) => {
        if (params.value === null) return "";
        return moment(params.value).format("ll");
      },
    },
    {
      field: "wayOfPayment",
      headerAlign: "center",
      align: "center",
      headerName: "Medio Pago",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";

        return <Chip label={params.value} color="primary" size="small" />;
      },
    },
    {
      field: "receptionDate",
      headerAlign: "center",
      align: "right",
      headerName: "Fecha Comprobante",
      flex: 1,
      headerClassName: "headRow",
      valueFormatter: (params) => {
        if (params.value === null) return "";
        return moment(params.value).format("ll");
      },
    },
    {
      field: "state",
      headerAlign: "center",
      align: "center",
      headerName: "Estado",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value.text === null) return "";

        return (
          <Chip
            label={params.value.text}
            color={params.row.stateOfPaymentId == 2 ? "error" : "primary"}
            size="small"
          />
        );
      },
    },

    {
      field: "paymentAmount",
      headerAlign: "center",
      align: "right",
      flex: 1,
      headerName: "Importe Pagado",
      headerClassName: "headRow",
      renderCell: (params) => (
        <NumericFormat
          key={`${params.field}_${params.id}`}
          value={params.value ?? 0}
          decimalScale="2"
          thousandSeparator="."
          displayType="text"
          decimalSeparator=","
          prefix="$"
          fixedDecimalScale="2"
        />
      ),
    },
    {
      field: "balance",
      headerAlign: "center",
      align: "right",
      flex: 1,
      headerName: "Importe Adeudado",
      flex: 0.3,
      headerClassName: "headRow",
      hasBeenResized: true,
      computedWidth: true,
      renderCell: (params) => {
        return (
          <NumericFormat
            key={`${params.field}_${params.id}`}
            value={params.value}
            decimalScale="2"
            thousandSeparator="."
            displayType="text"
            decimalSeparator=","
            prefix="$"
            fixedDecimalScale="2"
          />
        );
      },
    },
    {
      field: "actions",
      headerAlign: "center",
      align: "center",
      headerName: "",
      flex: 0.3,
      headerClassName: "headRow",
      filterable: false,
      renderCell: (params) =>
        deleted.isLoading ? (
          <Spinner color="primary" />
        ) : (
          <ActionsRow item={params} />
        ),
    },
  ];

  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          id: false,
          actions: !isSummary,
          stateOfPaymentId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 5 } },
    };
  };

  return (
    <Container>
      <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
        <Grid item xs={6}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            {isSummary ? "Últimos pagos" : "Pagos"}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "end" }}>
          {!isSummary && (
            <Button
              variant="contained"
              disabled={
                paymentsHeader.isLoading || studentPaymentsExport.isLoading
              }
              endIcon={<Download />}
              onClick={() => handleClickExport()}
            >
              {studentPaymentsExport.isLoading && <Spinner color="primary" />}
              Exportar
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          {!paymentsHeader.isLoading ? (
            <DataGrid
              slots={{
                columnMenu: CustomColumnMenu,
              }}
              autoHeight={true}
              rowSelection={false}
              disableRowSelectionOnClick
              columns={columns}
              rows={rows}
              pageSizeOptions={[5, 10, 25, 50]}
              initialState={initializeDataGrid()}
              sx={{
                "& .MuiDataGrid-virtualScroller": {
                  minHeight: 250,
                },
              }}
            />
          ) : (
            <StudentPaymentsSkeleton />
          )}
        </Grid>
      </Grid>
      {paymentsHeader.errors && paymentsHeader.errors.length > 0 && (
        <ModalMessages errors={paymentsHeader.errors} />
      )}
      {studentPaymentsExport.errors &&
        studentPaymentsExport.errors.length > 0 && (
          <ModalMessages errors={studentPaymentsExport.errors} />
        )}
      {deleted.errors && deleted.errors.length > 0 && (
        <ModalMessages errors={deleted.errors} />
      )}
      {openDrawer && (
        <StudentPaymentDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          courseId={dataDrawer.courseId}
          course={dataDrawer.course}
          paymentId={dataDrawer.paymentId}
          coursefeeId={dataDrawer.coursefeeId}
          studentCourseId={dataDrawer.studentCourseId}
          studentId={businessId}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
        />
      )}
      {!successDialog ? (
        <>
          <Dialog
            open={openDialog}
            onClose={exitDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {dataDialog?.title} {"¿Confirma eliminar el pago?"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={exitDialog}>Cancelar</Button>
              <Button
                onClick={() =>
                  deletePayment({
                    studentId: dataDialog.studentId,
                    courseId: dataDialog.courseId,
                    coursefeeId: dataDialog.coursefeeId,
                  })
                }
                autoFocus
              >
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      ) : (
        <>
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"La información ha sido guardada con éxito"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => refreshTable()} autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
      {deleted.errors && deleted.errors.length > 0 && (
        <ModalMessages errors={deleted.errors} />
      )}
      {openConfirmationDialog && (
        <Dialog
          open={openConfirmationDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"La información ha sido guardada con éxito"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpenConfirmationDialog(false)} autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default StudentPayments;
