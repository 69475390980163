import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import Breadcrumb from '../breadcrumb';
import useAuth from '../functions/use-auth';
import Header from '../header';
import Footer from '../footer';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const PageLayout = ({ component, anonymous }) => {
  const navigate = useNavigate();
  const authorized = useAuth();

  useEffect(() => {
    // if (!authorized && !anonymous) navigate("/login");

    if (authorized && anonymous) navigate('/');
  }, []);

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'rgb(231, 235, 240);',
          width: '100%',
          height: '100%',
        }}
      >
        <CssBaseline />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Breadcrumb />
          <Box sx={{ flexGrow: 1, pt: 3 }}>{component}</Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default PageLayout;
