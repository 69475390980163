import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  selectCoursesBackOffice,
  selectDownloadedCoursesReport,
  tryDeleteCourse,
  tryGetAllCourses,
  tryGetCoursesReport,
} from "../../store/slices/backoffice-courses";
import {
  tryGetCourseGroupEmails,
  tryGetCoursesReportAttendance,
} from "../../store/slices/backoffice-students";
import { setCleanErrors } from "../../store/slices/personal-data";
import { tryGetExportCourseGroup } from "../../store/slices/student-courses";
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  Card,
  Chip,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { MoreHoriz, Assignment, Assessment } from "@mui/icons-material";
import CustomColumnMenu from "../layout/grid-column-menu";
import AssignTeacherToCourseDrawer from "./drawer/index";
import Spinner from "../../components/layout/spinner";
import ModalMessages from "../../components/layout/modal-messages";
import CoursesListSkeleton from "./skeleton/CoursesListSkeleton";
import { DataGrid } from "@mui/x-data-grid";
import CourseGroupModalMails from "./modal/courseGroupMails";

const BackOfficeCoursesList = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const coursesList = useSelector(selectCoursesBackOffice);

  const { data: courses, isLoading, errors } = coursesList;

  //DIALOG EXPORTAR COMISIONES
  const [openCourseGroupDialog, setOpenCourseGroupDialog] = useState(false);

  //TRA EL DETALLE DEL REPORTE DE CURSOS
  const downloadedReport = useSelector(selectDownloadedCoursesReport);

  //MANEJO DE DRAWER ASIGNAR PROFESOR Y SET DE LA INFO QUE SE LE PASA
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState(null);

  //MANEJO DEL DRAWER DE CONFIRMACION
  const [openDialog, setOpenDialog] = useState(false);

  //SETEO EL COURSEID A ELIMINAR
  const [courseToDelete, setCourseToDelete] = useState("");

  //MANEJO DIALOG ALERT
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  //MANEJO DE LAS COLUMNAS
  const [rows, setRows] = useState([]);

  //MANEJO MODAL MAILS
  const [open, setOpen] = useState(false);
  const [mails, setMails] = useState(null);

  //Confirmacion de cambios y actualizacion de grilla
  const aceptChanges = () => {
    setOpenDialog(false);
    refreshTable();
  };

  //OBTENER LOS CORREOS DE UNA COMISIÓN
  const getCourseGroupEmails = async (courseGroupId) => {
    const action = await dispatch(tryGetCourseGroupEmails(courseGroupId));
    if (action.payload.data.isValid) {
      setMails(action.payload.data.data);
      setOpen(true);
    }
  };

  //ELIMINAR UN CURSO
  const deleteCourse = async () => {
    const action = await dispatch(tryDeleteCourse(courseToDelete));
    if (action.payload.data.isValid) setOpenAlertDialog(false);
    refreshTable();
    setOpenDialog(true);
  };

  //DESCARGA EL REPORTE DE PROFESORES
  const handleClickReport = async () => {
    const action = await dispatch(tryGetCoursesReport());
    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //DESCARGA EL REPORTE DE ASISTENCIAS
  const handleClickReportAttendance = async () => {
    const request = 0;
    const action = await dispatch(tryGetCoursesReportAttendance(request));
    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //Exporta el listado de estudiantes
  const handleClickExport = async (courseGroupId) => {
    const action = await dispatch(tryGetExportCourseGroup(courseGroupId));
    if (action.payload.data.isValid) {
      if (action.payload.data.data.file)
        window.open(action.payload.data.data.file);
      if (!action.payload.data.data.file) setOpenCourseGroupDialog(true);
    }
  };

  //DESCARGA EL REPORTE DE ASISTENCIAS
  const handleGetStudentsReport = async (request) => {
    const action = await dispatch(tryGetCoursesReportAttendance(request));
    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //ACCIONES
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          courseId: false,
          teacherId: false,
          teacherCourseId: false,
          actions: true,
          courseGroupId: false,
          amountCommited: false,
          methodologyId: false,
          stateCourseId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 10 } },
    };
  };

  const ActionsRow = ({ item }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleMenuItemClick = (event, index) => {
      //ASSIGN TEACHER
      if (index === 0) {
        setDataDrawer({
          courseId: item.row.courseId,
          briefDescription: item.row.courseDescription,
          courseGroupDescription: item.row.courseGroupDescription,
          teacherCourseId: 0,
          teacherId: 0,
          courseGroupId: item.row.courseGroupId,
          amountCommited: "",
        });
        setOpenDrawer(true);
      }
      if (index === 1) {
        setDataDrawer({
          courseId: item.row.courseId,
          briefDescription: item.row.courseDescription,
          courseGroupDescription: item.row.courseGroupDescription,
          teacherCourseId: item.row.teacherCourseId,
          teacherId: item.row.teacherId,

          courseGroupId: item.row.courseGroupId,
          amountCommited: item.row.amountCommited,
        });

        setOpenDrawer(true);
      }
      if (index === 2) {
        const courseId = item.row.courseId;
        const methodologyId = item.row.methodologyId;

        navigate(`/backoffice/courses/${courseId}/${methodologyId}`);
      }
      if (index === 3) {
        const courseId = item.row.courseId;
        setCourseToDelete(courseId);
        setOpenAlertDialog(true);
      }
      if (index === 4) {
        handleClickExport(item.row.courseGroupId);
      }
      if (index === 6) {
        getCourseGroupEmails(item.row.courseGroupId);
      }
      if (index === 7) {
        const request = item.row.courseGroupId;
        handleGetStudentsReport(request);
      }
      setCleanErrors();
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {item.row.courseGroupId > 0 &&
                      item.row.stateCourseId == 1 && (
                        <MenuItem
                          key="assignTeacher"
                          onClick={(event) => handleMenuItemClick(event, 0)}
                        >
                          Asignar Profesor
                        </MenuItem>
                      )}
                    {item.row.courseGroupId > 0 &&
                      item.row.teacherId !== 0 &&
                      item.row.stateCourseId !== 3 && (
                        <MenuItem
                          key="EditAssignTeacher"
                          onClick={(event) => handleMenuItemClick(event, 1)}
                        >
                          Modificar Asignación
                        </MenuItem>
                      )}
                    {item.row.stateCourseId !== 3 && (
                      <MenuItem
                        key="editCourse"
                        onClick={(event) => handleMenuItemClick(event, 2)}
                      >
                        Modificar Curso
                      </MenuItem>
                    )}
                    {item.row.stateCourseId == 2 && (
                      <MenuItem
                        key="deleteCourse"
                        onClick={(event) => handleMenuItemClick(event, 3)}
                      >
                        Eliminar Curso
                      </MenuItem>
                    )}
                    {item.row.stateCourseId !== 2 && (
                      <MenuItem
                        key="deleteCourse"
                        onClick={(event) => handleMenuItemClick(event, 4)}
                      >
                        Exportar Alumnos
                      </MenuItem>
                    )}
                    {item.row.courseGroupId > 0 && (
                      <>
                        <MenuItem
                          key="getCourseGroupEmails"
                          onClick={(event) => handleMenuItemClick(event, 6)}
                        >
                          Correos
                        </MenuItem>
                        <MenuItem
                          key="programDownload"
                          onClick={(event) => handleMenuItemClick(event, 7)}
                        >
                          Reporte de asistencias
                        </MenuItem>
                      </>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const columns = [
    { field: "stateCourseId", headerName: "stateCourseId", filterable: false },
    { field: "courseId", headerName: "courseId", filterable: false },
    {
      field: "amountCommited",
      headerName: "amountCommited",
      filterable: false,
    },

    {
      field: "teacherCourseId",
      headerName: "teacherCourseId",
      filterable: false,
    },
    { field: "courseGroupId", headerName: "courseGroupId", filterable: false },
    { field: "teacherId", headerName: "teacherId", filterable: false },

    {
      field: "courseDescription",
      headerAlign: "center",
      align: "left",
      headerName: "Curso",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "stateCourse",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      headerName: "Estado",
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";
        let chipColor;
        if (params.row.stateCourseId === 3) chipColor = "primary";
        if (params.row.stateCourseId === 1) chipColor = "success";
        if (params.row.stateCourseId === 2) chipColor = "warning";

        return <Chip label={params.value} color={chipColor} size="small" />;
      },
    },

    {
      field: "courseGroupDescription",
      headerAlign: "center",
      align: "center",
      headerName: "Comisión",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "teacherName",
      headerAlign: "center",
      align: "center",
      headerName: "Profesor Asignado",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "maxVacancy",
      headerAlign: "center",
      align: "center",
      headerName: "Vacantes Tot.",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "outVacancies",
      headerAlign: "center",
      align: "center",
      headerName: "Vacantes Dis.",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "methodologyId",
      headerAlign: "center",
      align: "center",
      headerName: "methodologyId",
      flex: 1,
      headerClassName: "headRow",
    },

    {
      field: "methodology",
      headerAlign: "center",
      align: "center",
      headerName: "Modalidad",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";
        let chipColor;
        if (params.row.methodologyId === 2) chipColor = "primary";
        if (params.row.methodologyId === 1) chipColor = "success";

        return <Chip label={params.value} color={chipColor} size="small" />;
      },
    },
    {
      field: "actions",
      headerAlign: "center",

      headerName: "",
      flex: 0.3,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) =>
        isLoading ? <Spinner color="primary" /> : <ActionsRow item={params} />,
    },
  ];
  // Crear nuevo curso
  const navigateNewCourse = () => {
    const courseId = 0;
    const methodologyId = 0;
    navigate(`/backoffice/courses/${courseId}/${methodologyId}`);
  };
  //Funcion para refrescar la tabla
  const refreshTable = async () => {
    await dispatch(tryGetAllCourses());
  };

  //Refrescar la tabla en el inicio
  useEffect(() => {
    refreshTable();
  }, []);

  //seteo de las columnas
  useEffect(() => {
    setRows(
      courses.map((x, index) => {
        return {
          id: index,
          courseId: x.courseId,
          courseDescription: x.briefDescription,
          courseGroupDescription: x.courseGroupDescription,
          stateCourse: x.stateCourse,
          maxVacancy: x.maxVacancy,
          outVacancies: x.outVacancies ?? 0,
          teacherName: x.teacherName,
          teacherCourseId: x.teacherCourseId,
          teacherId: x.teacherId,
          courseGroupId: x.courseGroupId,
          amountCommited: x.amountCommited,
          methodologyId: x.methodologyId,
          methodology: x.methodology,
          stateCourseId: x.stateCourseId,
        };
      })
    );
  }, [courses]);

  return (
    <>
      {selectCoursesBackOffice.isloading ? (
        <CoursesListSkeleton />
      ) : (
        <>
          <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
            <Grid item xs={6}>
              <Typography
                align="left"
                style={{ fontWeight: "bold", fontSize: 18 }}
              >
                Cursos
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <Button
                variant="contained"
                disabled={downloadedReport.isLoading}
                endIcon={<Assessment />}
                onClick={() => handleClickReportAttendance()}
                sx={{ marginRight: 1 }}
              >
                {downloadedReport.isLoading && <Spinner color="primary" />}
                REPORTE ASISTENCIAS
              </Button>

              <Button
                variant="contained"
                disabled={downloadedReport.isLoading}
                endIcon={<Assessment />}
                onClick={() => handleClickReport()}
                sx={{ marginRight: 1 }}
              >
                {downloadedReport.isLoading && <Spinner color="primary" />}
                REPORTE
              </Button>
              <Button
                variant="contained"
                endIcon={<Assignment />}
                onClick={() => navigateNewCourse()}
                color="success"
              >
                CREAR NUEVO CURSO
              </Button>
            </Grid>

            <Grid item xs={12}>
              {!isLoading ? (
                <DataGrid
                  slots={{
                    columnMenu: CustomColumnMenu,
                  }}
                  autoHeight={true}
                  rowSelection={false}
                  getRowId={(row) => row.id}
                  disableRowSelectionOnClick
                  rows={rows}
                  columns={columns}
                  pageSizeOptions={[5, 10, 25, 50]}
                  initialState={initializeDataGrid()}
                  sx={{
                    "& .MuiDataGrid-virtualScroller": {
                      minHeight: 250,
                    },
                  }}
                />
              ) : (
                <CoursesListSkeleton />
              )}
            </Grid>
          </Grid>
          {openCourseGroupDialog && (
            <>
              <Dialog
                open={openCourseGroupDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"La comisión seleccionada no tiene alumnos"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => setOpenCourseGroupDialog(false)}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}
          {openDialog && (
            <>
              <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"La información ha sido guardada con éxito"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={() => aceptChanges()} autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}

          {openAlertDialog && (
            <>
              <Dialog open={openAlertDialog}>
                <DialogTitle id="alert-dialog-title">
                  <Alert severity="warning">
                    <AlertTitle>
                      "¡Advertencia! Estás a punto de eliminar un curso del
                      sistema.{" "}
                    </AlertTitle>
                    Esta acción es irreversible y eliminará permanentemente toda
                    la información relacionada con esta materia, incluyendo
                    datos de estudiantes, horarios y registros académicos.
                    Asegúrate de tomar la decisión correcta y tener la
                    aprobación necesaria antes de proceder.
                    <br />
                    <br />
                    ¿Estás seguro de que deseas eliminar este curso?"
                  </Alert>
                </DialogTitle>
                <DialogActions>
                  <Button
                    sx={{ color: "warning" }}
                    onClick={() => deleteCourse()}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                  <Button onClick={() => setOpenAlertDialog(false)} autoFocus>
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}

          {errors && errors.length > 0 && <ModalMessages errors={errors} />}
        </>
      )}

      {openDrawer && (
        <AssignTeacherToCourseDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          dataDrawer={dataDrawer}
        />
      )}
      {open && (
        <CourseGroupModalMails mails={mails} open={open} setOpen={setOpen} />
      )}
    </>
  );
};

export default BackOfficeCoursesList;
