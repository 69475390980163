import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import { tryPreInscribe } from "../../../store/slices/users";
import "pure-react-carousel/dist/react-carousel.es.css";
import { selectUser } from "../../../store/slices/users";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../completion/modal";
import Spinner from "../../layout/spinner";
import ModalMessages from "../../layout/modal-messages";
import HelperMail from "../../layout/helper-mail";

const Completion = ({ currentStep, setCurrentStep, isMobile }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const user = useSelector(selectUser);

  function IsValidCourseFee(user) {
    if (
      user &&
      user.clients &&
      user.clients.students &&
      user.clients.students.studentCourses
    ) {
      const studentCourses = user.clients.students.studentCourses;
      const typeStudentId = user.clients.students.typeStudentId;

      for (const course of studentCourses) {
        if (course.courseFees && course.courseFees.length > 0) {
          for (const fee of course.courseFees) {
            if (fee.studentTypeId === typeStudentId && fee.feeAmount > 0) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  //PREINSCRIBIR
  const onClickPreInscribe = async () => {
    const action = await dispatch(tryPreInscribe(user));

    if (action.payload.data.isValid) {
      setOpenModal(true);
    }
  };

  return (
    <Container fixed>
      <Grid
        container
        sx={{
          margin: isMobile ? 0 : 2,
        }}
      >
        <Grid
          sx={{
            backgroundColor: "primary.contrastText",
          }}
          item
          xs={12}
          md={12}
        >
          <Box sx={{ width: "100%" }} align="center">
            <Grid display="flex" justifyContent="left" alignItems="left">
              <Button
                variant="text"
                disabled={user.isLoading}
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  marginLeft: 2,
                  width: 100,
                }}
                style={{ fontWeight: "bold" }}
                onClick={() => setCurrentStep(currentStep - 1)}
                type="button"
              >
                Volver
              </Button>
            </Grid>
            <Typography
              variant="h5"
              align="center"
              color="primary"
              sx={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 1,
                marginTop: isMobile ? "5px" : -5,
              }}
            >
              Sistema Integral de Formación
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="primary"
              sx={{
                fontWeight: "700",
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 2,
              }}
            >
              <SchoolOutlinedIcon sx={{ marginRight: 2 }} />
              Confirmación
            </Typography>
            <Typography
              color="primary"
              align="center"
              variant="subtitle2"
              sx={{ paddingBottom: 2 }}
            >
              ¡Ya casi estas confirmado!
            </Typography>
            <Container
              fixed
              sx={{
                paddingTop: 5,
              }}
            >
              {IsValidCourseFee(user) && (
                <Alert severity="warning">
                  <AlertTitle>Importante</AlertTitle>
                  Ten en cuenta que para confirmar tu inscripción deberás
                  <strong> realizar el pago de la matrícula</strong> por los
                  cursos seleccionados y{" "}
                  <strong>enviarnos el comprobante</strong>
                </Alert>
              )}
              <Grid
                minHeight={160}
                sx={{
                  paddingTop: 5,
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs>
                    <Typography variant="h6">
                      <strong>
                        ¡Estás a un paso de finalizar el proceso! 🚧
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle2"
                      pr={13}
                      pl={13}
                    ></Typography>
                  </Grid>
                  <Grid item xs>
                    <img
                      src={process.env.PUBLIC_URL + "/images/completion.png"}
                      style={{ padding: 15, maxWidth: "40%", height: "auto" }}
                      alt="Completion"
                    />
                  </Grid>
                </Grid>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    sx={{ marginTop: 2, marginBottom: 2, width: 500 }}
                    disabled={user.isLoading || openModal}
                    onClick={async () => {
                      await onClickPreInscribe();
                    }}
                    className="modalButton"
                  >
                    {user.isLoading && <Spinner color="primary" />}
                    Finalizar
                  </Button>
                  <Modal open={openModal} onClose={() => setOpenModal(false)} />
                </Grid>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    variant="text"
                    disabled={user.isLoading}
                    sx={{ marginTop: 1, marginBottom: 2, width: 500 }}
                    onClick={() => setCurrentStep(currentStep - 1)}
                    type="button"
                    style={{ fontWeight: "bold" }}
                  >
                    Volver
                  </Button>
                </Grid>
              </Grid>
            </Container>
            <Typography
              color="primary"
              align="center"
              variant="subtitle2"
              sx={{ paddingTop: 5, paddingBottom: 2 }}
            >
              <HelperMail label="¿Tienes alguna duda? Envíanos un mail" />
            </Typography>
          </Box>
          {user.errors && user.errors.length > 0 && (
            <ModalMessages errors={user.errors} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Completion;
