import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiAuth from "../../../services/apiAuth";

export const passSlice = createSlice({
  name: "pass",
  initialState: {
    item: {
      oldPass: "",
      newPass: "",
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    recoverPass: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setPass: (state, { payload }) => {
      state.item = { ...state.item, ...payload };
    },
    setPassLoading: (state, { payload }) => {
      state.item.isLoading = payload;
    },
    setCleanPassErrors: (state) => {
      state.item.errors = [];
      state.recoverPass.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryChangePassword.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(tryChangePassword.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.item.errors = errors;

      if (payload.data.isValid === true) {
        state.item = { ...payload.data.data };
      }

      state.item.isLoading = false;
    });
    builder.addCase(tryChangePassword.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });

    //RESTABLECER CONTRASEÑA

    builder.addCase(tryRecoverPass.pending, (state) => {
      state.recoverPass.status = "loading";
      state.recoverPass.isLoading = true;
      state.recoverPass.errors = [];
    });
    builder.addCase(tryRecoverPass.fulfilled, (state, { payload }) => {
      state.recoverPass.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.recoverPass.errors = errors;

      if (payload.data.isValid === true) {
        state.recoverPass.data = { ...payload.data.data };
      }

      state.recoverPass.isLoading = false;
    });
    builder.addCase(tryRecoverPass.rejected, (state, { payload }) => {
      state.recoverPass.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.recoverPass.errors = errors;
      state.recoverPass.isLoading = false;
    });
  },
});

export const { setPass, setPassLoading, setCleanPassErrors } =
  passSlice.actions;

export default passSlice.reducer;

export const selectPass = (state) => {
  return state.pass.item;
};

export const selectRecoverPass = (state) => {
  return state.pass.recoverPass;
};

export const tryChangePassword = createAsyncThunk(
  "pass/tryChangePassword",
  async (model, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiAuth.changePassword(model);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryCleanPassErrors = () => (dispatch) => {
  dispatch(setCleanPassErrors());
};

export const tryRecoverPass = createAsyncThunk(
  "pass/tryRecoverPass",
  async (queryModel, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiAuth.recoverPass(queryModel);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
