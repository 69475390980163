import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiTeachers from "../../../services/apiTeachers";
import * as apiCourses from "../../../services/apiCourses";

export const teacherCoursesSlice = createSlice({
  name: "teacherCourses",
  initialState: {
    summary: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    header: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    attendances: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    studentListByDate: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    attendancesTicked: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    courseGroupGrades: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    exportedCourses: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    newStudentsGrades: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    disassociatedTeacher: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setTeacherCourses: (state, { payload }) => {
      state.item = { ...state.summary, ...payload };
    },
    setTeacherCoursesLoading: (state, { payload }) => {
      state.item.isLoading = payload;
    },
    setAttendances: (state, { payload }) => {
      state.attendances.data = payload;
    },
    setAttendancesTicked: (state, { payload }) => {
      state.attendancesTicked.data = payload;
    },
    setCourseGroupGrades: (state, { payload }) => {
      state.courseGroupGrades.data = payload;
    },
    setExportedCourses: (state, { payload }) => {
      state.exportedCourses.data = payload;
    },
    setNewStudentsGrades: (state, { payload }) => {
      state.newStudentsGrades.data = payload;
    },
    setCleanStudentListByDate: (state) => {
      state.studentListByDate.data = [];
    },
    setCleanErrorsTeacherCourses: (state) => {
      state.summary.errors = [];
      state.header.errors = [];
      state.attendances.errors = [];
      state.studentListByDate.errors = [];
      state.attendancesTicked.errors = [];
      state.courseGroupGrades.errors = [];
      state.exportedCourses.errors = [];
      state.newStudentsGrades.errors = [];
      state.disassociatedTeacher.errors = [];
    },
  },
  extraReducers(builder) {
    //Traer el SUMMARY de CURSOS
    builder.addCase(tryGetTeacherCoursesSummary.pending, (state) => {
      state.summary.status = "loading";
      state.summary.isLoading = true;
      state.summary.errors = [];
    });
    builder.addCase(
      tryGetTeacherCoursesSummary.fulfilled,
      (state, { payload }) => {
        state.summary.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.summary.errors = errors;

        if (payload.data.isValid === true) {
          state.summary.data = { ...payload.data.data };
        }

        state.summary.isLoading = false;
      }
    );
    builder.addCase(
      tryGetTeacherCoursesSummary.rejected,
      (state, { payload }) => {
        state.summary.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.summary.errors = errors;
        state.summary.isLoading = false;
      }
    );

    //Traer el HEADER de CURSOS
    builder.addCase(tryGetTeacherCoursesHeader.pending, (state) => {
      state.header.status = "loading";
      state.header.isLoading = true;
      state.header.errors = [];
    });
    builder.addCase(
      tryGetTeacherCoursesHeader.fulfilled,
      (state, { payload }) => {
        state.header.status = "succeeded";

        const errors = payload.data.data.isValid
          ? payload.data.data.errors
          : [];
        state.header.errors = errors;

        if (payload.data.data.isValid) {
          state.header.data = [...payload.data.data.data];
        }

        state.header.isLoading = false;
      }
    );
    builder.addCase(
      tryGetTeacherCoursesHeader.rejected,
      (state, { payload }) => {
        state.header.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.data.isValid === false)
            errors = payload.data.data.errors;

          if (payload.data.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.header.errors = errors;
        state.header.isLoading = false;
      }
    );

    //TRAER LISTADO DE FECHAS DE CURSADA DE UN CURSO

    builder.addCase(tryGetCourseGroupAttendances.pending, (state) => {
      state.attendances.status = "loading";
      state.attendances.isLoading = true;
      state.attendances.errors = [];
    });
    builder.addCase(
      tryGetCourseGroupAttendances.fulfilled,
      (state, { payload }) => {
        state.attendances.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.attendances.errors = errors;

        if (payload.data.isValid) {
          state.attendances.data = [...payload.data.data];
        }

        state.attendances.isLoading = false;
      }
    );
    builder.addCase(
      tryGetCourseGroupAttendances.rejected,

      (state, { payload }) => {
        state.attendances.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.attendances.errors = errors;
        state.attendances.isLoading = false;
      }
    );

    //LISTADO DE ALUMNOS POR FECHA

    builder.addCase(tryGetStudentListByDate.pending, (state) => {
      state.studentListByDate.status = "loading";
      state.studentListByDate.isLoading = true;
      state.studentListByDate.errors = [];
    });
    builder.addCase(tryGetStudentListByDate.fulfilled, (state, { payload }) => {
      state.studentListByDate.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.studentListByDate.errors = errors;

      if (payload.data.isValid) {
        state.studentListByDate.data = { ...payload.data.data };
      }

      state.studentListByDate.isLoading = false;
    });
    builder.addCase(
      tryGetStudentListByDate.rejected,

      (state, { payload }) => {
        state.studentListByDate.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.studentListByDate.errors = errors;
        state.studentListByDate.isLoading = false;
      }
    );

    //CARGAR ASISTENCIAS DE LOS ALUMNOS
    builder.addCase(tryTicksStudentsAttendances.pending, (state) => {
      state.attendancesTicked.status = "loading";
      state.attendancesTicked.isLoading = true;
      state.attendancesTicked.errors = [];
    });
    builder.addCase(
      tryTicksStudentsAttendances.fulfilled,
      (state, { payload }) => {
        state.attendancesTicked.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.attendancesTicked.errors = errors;

        if (payload.data.isValid) {
          state.attendancesTicked.data = { ...payload.data.data };
        }

        state.attendancesTicked.isLoading = false;
      }
    );
    builder.addCase(
      tryTicksStudentsAttendances.rejected,

      (state, { payload }) => {
        state.attendancesTicked.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.attendancesTicked.errors = errors;
        state.attendancesTicked.isLoading = false;
      }
    );

    //TRAE LAS NOTAS DE LOS ESTUDIANTES
    builder.addCase(tryGetStudentGrades.pending, (state) => {
      state.courseGroupGrades.status = "loading";
      state.courseGroupGrades.isLoading = true;
      state.courseGroupGrades.errors = [];
    });
    builder.addCase(tryGetStudentGrades.fulfilled, (state, { payload }) => {
      state.courseGroupGrades.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.courseGroupGrades.errors = errors;

      if (payload.data.isValid) {
        state.courseGroupGrades.data = [...payload.data.data];
      }

      state.courseGroupGrades.isLoading = false;
    });
    builder.addCase(
      tryGetStudentGrades.rejected,

      (state, { payload }) => {
        state.courseGroupGrades.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.courseGroupGrades.errors = errors;
        state.courseGroupGrades.isLoading = false;
      }
    );

    //TRAE LOS ULTIMOS CURSOS EXPORTADOS
    builder.addCase(tryGetExportTeacherCourses.pending, (state) => {
      state.exportedCourses.status = "loading";
      state.exportedCourses.isLoading = true;
      state.exportedCourses.errors = [];
    });
    builder.addCase(
      tryGetExportTeacherCourses.fulfilled,
      (state, { payload }) => {
        state.exportedCourses.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.exportedCourses.errors = errors;

        if (payload.data.isValid) {
          state.exportedCourses.data = { ...payload.data.data };
        }

        state.exportedCourses.isLoading = false;
      }
    );
    builder.addCase(
      tryGetExportTeacherCourses.rejected,

      (state, { payload }) => {
        state.exportedCourses.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.exportedCourses.errors = errors;
        state.exportedCourses.isLoading = false;
      }
    );

    //CARGA LAS CALIFICACIONES DE UN CURSO
    builder.addCase(tryTicksStudentsGrades.pending, (state) => {
      state.newStudentsGrades.status = "loading";
      state.newStudentsGrades.isLoading = true;
      state.newStudentsGrades.errors = [];
    });
    builder.addCase(tryTicksStudentsGrades.fulfilled, (state, { payload }) => {
      state.newStudentsGrades.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.newStudentsGrades.errors = errors;

      if (payload.data.isValid) {
        state.newStudentsGrades.data = { ...payload.data.data };
      }

      state.newStudentsGrades.isLoading = false;
    });
    builder.addCase(
      tryTicksStudentsGrades.rejected,

      (state, { payload }) => {
        state.newStudentsGrades.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.newStudentsGrades.errors = errors;
        state.newStudentsGrades.isLoading = false;
      }
    );

    //DESASIGNA UN PROFESOR DE UN CURSO
    builder.addCase(tryDisassociateTeacher.pending, (state) => {
      state.disassociatedTeacher.status = "loading";
      state.disassociatedTeacher.isLoading = true;
      state.disassociatedTeacher.errors = [];
    });
    builder.addCase(tryDisassociateTeacher.fulfilled, (state, { payload }) => {
      state.disassociatedTeacher.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.disassociatedTeacher.errors = errors;

      if (payload.data.isValid) {
        state.disassociatedTeacher.data = { ...payload.data.data };
      }

      state.disassociatedTeacher.isLoading = false;
    });
    builder.addCase(
      tryDisassociateTeacher.rejected,

      (state, { payload }) => {
        state.disassociatedTeacher.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.disassociatedTeacher.errors = errors;
        state.disassociatedTeacher.isLoading = false;
      }
    );
  },
});

export const {
  setTeacherCourses,
  setTeacherCoursesLoading,
  setAttendances,
  setAttendancesTicked,
  setExportedCourses,
  setNewStudentsGrades,
  setCleanErrorsTeacherCourses,
  setCleanStudentListByDate,
} = teacherCoursesSlice.actions;

export default teacherCoursesSlice.reducer;

export const tryCleanErrorsTeacherCourses = () => (dispatch) => {
  dispatch(setCleanErrorsTeacherCourses());
};

export const tryCleanStudentList = () => (dispatch) => {
  dispatch(setCleanStudentListByDate());
};
export const selectTeacherCoursesSummary = (state) =>
  state.teacherCourses.summary;

export const selectTeacherCoursesHeader = (state) =>
  state.teacherCourses.header;

export const selectCourseGroupAttendances = (state) =>
  state.teacherCourses.attendances;

export const selectStudentListByDate = (state) =>
  state.teacherCourses.studentListByDate.data;

export const selectTickedAttendances = (state) =>
  state.teacherCourses.attendancesTicked;

export const selectCourseGroupGrades = (state) =>
  state.teacherCourses.courseGroupGrades;

export const selectExportedCourses = (state) =>
  state.teacherCourses.exportedCourses.data;

export const selectNewStudentsGrades = (state) =>
  state.teacherCourses.newStudentsGrades;

export const tryGetTeacherCoursesSummary = createAsyncThunk(
  "teacherCourses/tryGetTeacherSummary",
  async (teacherId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getCoursesSummary(teacherId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetTeacherCoursesHeader = createAsyncThunk(
  "teacherCourses/tryGetTeacherCoursesHeader",
  async ({ teacherId, isFirstOrLastRecords }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getCoursesHeader(
        teacherId,
        isFirstOrLastRecords
      );

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetCourseGroupAttendances = createAsyncThunk(
  "teacherCourses/tryGetCourseGroupAttendances",
  async ({ courseGroupId, teacherCourseId }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getCourseGroupAttendances(
        courseGroupId,
        teacherCourseId
      );

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetStudentListByDate = createAsyncThunk(
  "teacherCourses/tryGetStudentListByDate",
  async (
    { courseGroupId, attendanceDate, teacherCourseId },
    { rejectWithValue }
  ) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getStudentListByDate(
        courseGroupId,
        attendanceDate,
        teacherCourseId
      );

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryTicksStudentsAttendances = createAsyncThunk(
  "teacherCourses/tryTickStudentsAttendances",
  async (
    { attendanceDate, request, teacherCourseId, teacherPresent },
    { rejectWithValue }
  ) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.postTicksStudentsAttendances({
        attendanceDate,
        request,
        teacherCourseId,
        teacherPresent,
      });

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetStudentGrades = createAsyncThunk(
  "teacherCourses/tryGetStudentGrades",
  async (courseGroupId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getStudentGrades(courseGroupId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetExportTeacherCourses = createAsyncThunk(
  "teacherCourses/tryGetExportTeacherCourses",
  async ({ teacherid }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getStudentCourses(teacherid);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryTicksStudentsGrades = createAsyncThunk(
  "teacherCourses/tryTicksStudentsGrades",
  async (request, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.putStudentCourseGrades(request);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryDisassociateTeacher = createAsyncThunk(
  "teacherCourses/tryDisassociateTeacher",
  async (teacherCourseId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.putDisassociateTeacher(teacherCourseId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
