import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTypeTable,
  selectMaintenances,
} from "../../../store/slices/maintenances";
import {
  selectStudentTicketsUpdate,
  selectSubTopics,
  tryCreateStudentTicket,
  tryGetSubtopics,
} from "../../../store/slices/student-tickets";
import { selectCurrentUser } from "../../../store/slices/currentUser";
import {
  Button,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  Close,
  UploadFileOutlined,
  CloudUploadOutlined,
} from "@mui/icons-material";
import ModalMessages from "../../layout/modal-messages";
import AddStudentTicketSkeleton from "../skeleton/AddStudentTicketSkeleton";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import convertToSelectList from "../../layout/functions/convert-to-select-list";

const validate = (values) => {
  const errors = {};

  const { topic, subtopic, subject, message } = values;

  if (!topic) {
    errors.topic = "Requerido";
  }
  if (!subtopic) {
    errors.subtopic = "Requerido";
  }
  if (!subject) {
    errors.subject = "Requerido";
  }
  if (!message) {
    errors.message = "Requerido";
  }

  return errors;
};

const AddStudentTicketDrawer = ({
  openDrawer,
  setOpenDrawer,
  setOpenDialog,
  businessId,
  clientId,
}) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  //Trae el detalle de las tablas catalogo
  const typeTables = useSelector(selectMaintenances);

  //Trae el detalle de los tickets
  const updateStudentTickets = useSelector(selectStudentTicketsUpdate);
  const { isLoading, errors } = updateStudentTickets;

  //Maneja el estado del valor del topic seleccionado
  const [topicId, setTopicId] = useState("");

  //Trae el detalle de los subtopics
  const subtopics = useSelector(selectSubTopics);

  useEffect(() => {
    dispatch(fetchTypeTable(51)); //TBLTOPICS
  }, []);

  useEffect(() => {
    dispatch(tryGetSubtopics(topicId));
  }, [topicId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      topic: 0,
      subtopic: 0,
      subject: "",
      message: "",
      attachment: "",
    },
    validate,
    onSubmit: async (values) => {
      const request = {};
      request.ticketDetails = [];

      request.ticketDetails.push({
        ticketDetailId: 0,
        ticketId: 0,
        senderId: currentUser.roleId !== 1 ? clientId : currentUser.clientId,
        message: values.message,
        attachment: values.attachment,
        statusMessageId: 1,
      });

      request.ticketId = 0;
      request.ownerId = 0;
      request.originalSenderId = clientId;
      request.topicId = values.topic;
      request.subTopicId = values.subtopic;
      request.subject = values.subject;
      request.statusTicketId = 1;
      request.closeDate = null;

      const action = await dispatch(
        tryCreateStudentTicket({ businessId, request })
      );

      if (action.payload.data.isValid) {
        setOpenDrawer(false);
        setOpenDialog(true);
      }
    },
  });

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 10000000,
    onDropAccepted: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        formik.setFieldValue("attachment", base64data);
      };
    },
  });

  const files = acceptedFiles.map((filename) => (
    <ListItem>
      <ListItemIcon>
        <UploadFileOutlined color="primary" />
      </ListItemIcon>
      <ListItemText key={filename.path} primary={filename.path} />
    </ListItem>
  ));

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        PaperProps={{
          sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
        }}
        onClose={() => setOpenDrawer(false)}
        sx={{
          zIndex: 1203,
          width: 500,
          flexShrink: 0,
        }}
      >
        <Grid container p={1}>
          <Grid item xs={10}>
            <Typography
              variant="h4"
              align="left"
              sx={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 1,
                paddingTop: 4,
              }}
            >
              Agregar nuevo mensaje
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setOpenDrawer(false)}
            >
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ padding: 3 }}></Grid>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          spacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          p={2}
          sx={{ backgroundColor: "white" }}
        >
          {isLoading ? (
            <AddStudentTicketSkeleton />
          ) : (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="course-label">
                    Tópico asociado al mensaje
                  </InputLabel>
                  <Select
                    labelId="topic-label"
                    name="topic"
                    label="Tópico asociado al mensaje"
                    size="small"
                    value={formik.values.topic}
                    onChange={(e) => {
                      const { value } = e.target;
                      setTopicId(value);
                      formik.setFieldValue("topic", value);
                    }}
                    error={Boolean(formik.touched.topic && formik.errors.topic)}
                  >
                    {typeTables.TBLTOPICS &&
                      convertToSelectList(typeTables.TBLTOPICS.data).map(
                        (x) => (
                          <MenuItem key={x.value} value={x.value}>
                            {x.text}
                          </MenuItem>
                        )
                      )}
                  </Select>
                  <FormHelperText
                    htmlFor="topic"
                    error={formik.touched.topic && !!formik.errors.topic}
                  >
                    {formik.errors.topic}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="subtopic-label">
                    SubTópico asociado al mensaje
                  </InputLabel>
                  <Select
                    labelId="subtopic-label"
                    name="subtopic"
                    label="SubTópico asociado al mensaje"
                    size="small"
                    value={formik.values.subtopic}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.subtopic && formik.errors.subtopic
                    )}
                  >
                    {subtopics &&
                      subtopics.data.map((x) => (
                        <MenuItem key={x.subTopicId} value={x.subTopicId}>
                          {x.description}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText
                    htmlFor="subtopic"
                    error={formik.touched.subtopic && !!formik.errors.subtopic}
                  >
                    {formik.errors.subtopic}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="subject"
                  label="Asunto"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                  error={Boolean(
                    formik.touched.subject && formik.errors.subject
                  )}
                  helperText={formik.touched.subject && formik.errors.subject}
                />
              </Grid>
              <Grid item xs={12} whiteSpace="normal">
                <TextField
                  name="message"
                  label="Mensaje"
                  multiline
                  rows={5}
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  error={Boolean(
                    formik.touched.message && formik.errors.message
                  )}
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    border: "4px",
                    borderRadius: "12px",
                    borderStyle: "solid",
                    borderColor: "#85BFE6",
                    marginTop: 2,
                  }}
                >
                  <section className="container">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid
                          item
                          xs={1}
                          sm={2}
                          md={2}
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CloudUploadOutlined
                            color="primary"
                            fontSize="large"
                          />
                        </Grid>
                        <Grid item xs={3} sm={6} md={6} whiteSpace="initial">
                          <Typography
                            color="primary"
                            variant="subtitle2"
                            sx={{ fontWeight: 700, paddingTop: 5 }}
                          >
                            Seleccione un archivo o arrástrelo hasta acá
                          </Typography>

                          <Typography
                            color="primary"
                            variant="subtitle2"
                            sx={{ paddingTop: 1, paddingBottom: 5 }}
                          >
                            JPG, PNG o PDF, tamaño del archivo no mayor a 10MB
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sm={4}
                          md={4}
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Button
                            variant="outline"
                            color="secondary"
                            sx={{ border: 2 }}
                          >
                            Buscar
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                    <List sx={{ width: "100%" }}>{files}</List>
                  </section>
                </Paper>
              </Grid>
              <Grid item sx={{ width: "100%", textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={formik.handleSubmit}
                  disabled={updateStudentTickets.isLoading}
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    width: 100,
                    marginRight: 2,
                  }}
                >
                  Enviar
                </Button>
                <Button
                  variant="outline"
                  color="error"
                  sx={{
                    border: 2,
                    marginTop: 2,
                    marginBottom: 2,
                    width: 100,
                    color: "red",
                  }}
                  onClick={() => setOpenDrawer(false)}
                >
                  Cancelar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Drawer>
      {errors && errors.length > 0 && <ModalMessages errors={errors} />}
    </>
  );
};

export default AddStudentTicketDrawer;
