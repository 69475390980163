import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  tryCertificateDownload,
  tryCleanCertificateErrors,
  tryProgramDownload,
} from "../../store/slices/student-courses";
import {
  selectStudentCuil,
  tryStudentCuil,
} from "../../store/slices/student-cuil";
import {
  selectStudentCoursesHeader,
  selectStudentCoursesExport,
  tryGetStudentCoursesHeader,
  tryStudentCoursesExport,
  selectCertificate,
  tryUnubscribeCourse,
  selectUnsubscribe,
} from "../../store/slices/student-courses";
import {
  selectInscribed,
  tryInscribe,
} from "../../store/slices/backoffice-students";
import { tryGetStudent } from "../../store/slices/students";
import {
  Typography,
  Button,
  LinearProgress,
  Grid,
  Box,
  Stack,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { Download, Assignment, MoreHoriz } from "@mui/icons-material";
import ModalMessages from "../layout/modal-messages";
import { Container } from "@mui/system";
import CoursesDrawer from "./drawers";
import StudentPathDrawer from "./drawers/path";
import StudentCoursesSkeleton from "./skeleton";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";
import Spinner from "../layout/spinner";
import { useNavigate } from "react-router-dom";
import ScolarshipModal from "./modal";

const getLinearProgressColor = (value) => {
  if (value > 70) return "success";

  if (value > 20) return "primary";

  return "error";
};

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{ minWidth: 100 }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color={getLinearProgressColor(props.value)}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const StudentCourses = ({ isSummary, businessId, userId, roleId }) => {
  const dispatch = useDispatch();

  const coursesHeader = useSelector(selectStudentCoursesHeader);

  const unsubscribeCourse = useSelector(selectUnsubscribe);

  const studentCoursesExport = useSelector(selectStudentCoursesExport);

  const { data: studentCuil } = useSelector(selectStudentCuil);

  const certificate = useSelector(selectCertificate);

  const inscribed = useSelector(selectInscribed);

  const navigate = useNavigate();

  //MANEJO DE DRAWER CARGAR ASISTENCIA Y SET DE LA INFO QUE SE LE PASA
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState(undefined);

  //MANEJO DE DRAWER TRAYECTORIA Y SET DE LA INFO QUE SE LE PASA
  const [openPathDrawer, setOpenPathDrawer] = useState(false);
  const [dataPathDrawer, setPathDataDrawer] = useState(undefined);

  //MANEJO DE DIALOG DESISTIR Y SET DE LA INFO QUE SE LE PASA
  const [dataDialog, setDataDialog] = useState(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  //MANEJO DE BECAR
  const [openModal, setOpenModal] = useState(false);
  const [studentCourseScolarshipId, setStudenCourseScolarshipId] =
    useState(null);

  const [rows, setRows] = useState([]);

  const handleClose = () => {
    dispatch(tryCleanCertificateErrors());
  };

  const fetchData = async () => {
    const action = await dispatch(
      tryGetStudentCoursesHeader({
        studentId: businessId,
        isFirstOrLastRecords: isSummary,
      })
    );
    if (action.payload.data.data.isValid) {
      setRows(
        action.payload.data.data.data.map((x) => {
          return {
            id: x.courseId,
            courseDescription: x.courseDescription,
            groupDescription: x.groupDescription,
            totalClassCourse: x.totalClassCourse,
            progress: x.progress,
            schedule: x.schedule,
            stateCourse: x.stateCourse,
            stateCourseId: x.stateCourseId,
            studentCourseId: x.studentCourseId,
          };
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
    dispatch(tryStudentCuil(userId));
  }, []);

  //descargar Programa
  const programDownload = async (courseId) => {
    const action = await dispatch(tryProgramDownload(courseId));
    if (action.payload.isValid) window.open(action.payload.data.file);
  };

  //descargar certificado
  const certificateDownload = async (studentId, courseId) => {
    const action = await dispatch(
      tryCertificateDownload({ studentId, courseId })
    );

    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //INSCRIBIR A UN CURSO
  const inscribeCourse = async (studentId, courseId) => {
    const model = { stateId: 2, typeMovId: 1 };
    const action = await dispatch(tryInscribe({ studentId, courseId, model }));
    if (action.payload.data.isValid) {
      setConfirmationDialog(true);
      fetchData();
    }
  };

  //desistir

  const model = {
    stateId: dataDialog?.stateId,
    typemovId: dataDialog?.typemovId,
  };

  const unsubscribe = async (model) => {
    setOpenDialog(false);
    const action = await dispatch(
      tryUnubscribeCourse({
        studentId: dataDialog.studentId,
        courseId: dataDialog.courseId,
        model,
      })
    );

    if (action.payload.isValid) {
      setSuccessDialog(true);
      setOpenDialog(true);
      dispatch(tryGetStudent(userId));
      fetchData();
    }
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const refreshTable = async () => {
    await dispatch(
      tryGetStudentCoursesHeader({
        studentId: businessId,
        isFirstOrLastRecords: isSummary,
      })
    );

    setOpenDialog(false);
    setSuccessDialog(false);
  };

  //ACCIONES
  const ActionsRow = ({ item }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const { id: courseId } = item.row;
    const { courseDescription: course } = item.row;

    const handleMenuItemClick = (event, index) => {
      //MARCAR ASISTENCIA
      if (index === 0) {
        setDataDrawer({
          courseId: courseId,
          studentId: businessId,
          course: course,
        });
        setOpenDrawer(true);
      }
      //DESCARGAR PROGRAMA
      if (index === 1) {
        programDownload(courseId);
      }

      //MI TRAYECTORIA
      if (index === 2) {
        setPathDataDrawer({
          courseId: courseId,
          studentId: businessId,
          title: course,
        });
        setOpenPathDrawer(true);
      }

      //DESCARGAR CERTIFICADO
      if (index === 3) {
        certificateDownload(businessId, courseId);
      }

      //DESISTIR DE UN CURSO
      if (index === 4) {
        setDataDialog({
          courseId: courseId,
          studentId: businessId,
          title: course,
          stateId: 4,
          typemovId: 9,
        });
        setOpenDialog(true);
      }

      //INSCRIBIR A UN CURSO A UN ALUMNO EN LISTA DE ESPERA O PREINSCRIPTO
      if (index === 5) {
        inscribeCourse(businessId, courseId);
      }
      //BECAN A UN ALUMNO EN ESTE CURSO
      if (index === 6) {
        setStudenCourseScolarshipId(item.row.studentCourseId);
        setOpenModal(true);
      }
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left-start"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {/* ESTADO INSCRIPTO */}
                    {item.row.stateCourseId == 2 ? (
                      <MenuItem
                        key="setAttendance"
                        onClick={(event) => handleMenuItemClick(event, 0)}
                      >
                        Cargar Asistencia
                      </MenuItem>
                    ) : null}

                    <MenuItem
                      key="downloadProgram"
                      onClick={(event) => handleMenuItemClick(event, 1)}
                    >
                      Descargar Programa
                    </MenuItem>
                    {/* ESTADO DISTINTO A LISTA DE ESPERA */}
                    {item.row.stateCourseId !== 5 && (
                      <MenuItem
                        key="myProgress"
                        onClick={(event) => handleMenuItemClick(event, 2)}
                      >
                        Trayectoria
                      </MenuItem>
                    )}
                    {/* ESTADO FINALIZADO */}
                    {item.row.stateCourseId == 6 && (
                      <MenuItem
                        key="downloadCertificate"
                        onClick={(event) => handleMenuItemClick(event, 3)}
                      >
                        Descargar Certificado
                      </MenuItem>
                    )}
                    {/* ESTADO DISTINTO DE RECHAZADO, DESISTIDO Y FINALIZADO */}
                    {(item.row.stateCourseId !== 4) &
                    (item.row.stateCourseId !== 3) &
                    (item.row.stateCourseId !== 6) ? (
                      <MenuItem
                        key="desist"
                        onClick={(event) => handleMenuItemClick(event, 4)}
                      >
                        Desistir
                      </MenuItem>
                    ) : null}
                    {/* ESTADO PREINSCRIPTO O LISTA DE ESPERA Y ROLEID DE BACKOFFICE */}
                    {(roleId === 1) &
                    (item.row.stateCourseId == 1 ||
                      item.row.stateCourseId == 5) ? (
                      <MenuItem
                        key="inscribe"
                        onClick={(event) => handleMenuItemClick(event, 5)}
                        sx={{
                          backgroundColor: "#BFFEA0",
                        }}
                      >
                        Pre/Inscribir
                      </MenuItem>
                    ) : null}
                    {roleId === 1 &&
                    (item.row.stateCourseId === 2 ||
                      item.row.stateCourseId === 1) ? (
                      <MenuItem
                        key="desist"
                        onClick={(event) => handleMenuItemClick(event, 6)}
                      >
                        Becar
                      </MenuItem>
                    ) : null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const columns = [
    { field: "id", headerName: "Id", filterable: false },
    { field: "stateCourseId", headerName: "stateCourseId", filterable: false },
    {
      field: "studentCourseId",
      headerName: "studentCourseId",
      filterable: false,
    },
    {
      field: "courseDescription",
      headerAlign: "center",
      align: "left",
      headerName: "Curso",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "groupDescription",
      headerAlign: "center",
      align: "center",
      headerName: "Comisión",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "totalClassCourse",
      headerAlign: "center",
      align: "center",
      headerName: "Clases",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "progress",
      headerAlign: "center",
      align: "center",
      headerName: "Progreso",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgressWithLabel
              value={params.value}
              variant="determinate"
              color={getLinearProgressColor(params.value ?? 0)}
            />
          </Box>
        </Box>
      ),
    },
    {
      field: "schedule",
      headerAlign: "center",
      align: "left",
      flex: 1,
      headerName: "Horario",
      headerClassName: "headRow",
    },
    {
      field: "stateCourse",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerName: "Estado",
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";

        return (
          <Tooltip title={params.value}>
            <Chip label={params.value} color="primary" size="small" />
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerAlign: "center",

      headerName: "",
      flex: 0.3,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) =>
        unsubscribeCourse.isLoading || inscribed.isLoading ? (
          <Spinner color="primary" />
        ) : (
          <ActionsRow item={params} />
        ),
    },
  ];

  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          id: false,
          stateCourseId: false,
          actions: !isSummary,
          studentCourseId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 5 } },
    };
  };

  const handleClickExport = async () => {
    const action = await dispatch(tryStudentCoursesExport(businessId));

    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //Inscripcion
  const tryInscription = () => {
    //navegar a personalData con el cuil
    navigate("/signup", {
      state: { cuil: studentCuil, returnToStudents: true },
    });
  };

  return (
    <Container>
      <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
        <Grid item xs={6}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            {isSummary ? "Últimos Cursos" : "Cursos"}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "end" }}>
          {!isSummary && (
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                endIcon={<Assignment />}
                onClick={() => tryInscription()}
              >
                INSCRIBIRME
              </Button>

              <Button
                variant="contained"
                disabled={
                  coursesHeader.isLoading || studentCoursesExport.isLoading
                }
                endIcon={<Download />}
                onClick={() => handleClickExport()}
              >
                {studentCoursesExport.isLoading && <Spinner color="primary" />}
                Exportar
              </Button>
            </Stack>
          )}
        </Grid>
        <Grid item xs={12}>
          {!coursesHeader.isLoading ? (
            <DataGrid
              slots={{
                columnMenu: CustomColumnMenu,
              }}
              autoHeight={true}
              rowSelection={false}
              disableRowSelectionOnClick
              getRowId={(row) => row.studentCourseId}
              rows={rows}
              columns={columns}
              pageSizeOptions={[5, 10, 25, 50]}
              initialState={initializeDataGrid()}
              sx={{
                "& .MuiDataGrid-virtualScroller": {
                  minHeight: 250,
                },
              }}
            />
          ) : (
            <StudentCoursesSkeleton />
          )}
        </Grid>
      </Grid>

      {openModal && (
        <ScolarshipModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          studentCourseScolarshipId={studentCourseScolarshipId}
          setConfirmationDialog={setConfirmationDialog}
        />
      )}

      {certificate.errors && certificate.errors.length > 0 && (
        <ModalMessages errors={certificate.errors} handleClose={handleClose} />
      )}

      {coursesHeader.errors && coursesHeader.errors.length > 0 && (
        <ModalMessages errors={coursesHeader.errors} />
      )}
      {studentCoursesExport.errors &&
        studentCoursesExport.errors.length > 0 && (
          <ModalMessages errors={studentCoursesExport.errors} />
        )}
      {inscribed.errors && inscribed.errors.length > 0 && (
        <ModalMessages errors={inscribed.errors} />
      )}

      {openDrawer && (
        <CoursesDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          courseId={dataDrawer.courseId}
          studentId={dataDrawer.studentId}
          course={dataDrawer.course}
          setConfirmationDialog={setConfirmationDialog}
        />
      )}

      {!successDialog ? (
        <>
          <Dialog
            open={openDialog}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {dataDialog?.title} {"¿Confirma desistir del curso?"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={onCloseDialog}>Cancelar</Button>
              <Button onClick={() => unsubscribe(model)} autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      ) : (
        <>
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Se ha desistido con éxito"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => refreshTable()} autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}

      {confirmationDialog && (
        <>
          <Dialog
            open={confirmationDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"La información ha sido guardada con éxito"}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  fetchData();
                  setConfirmationDialog(false);
                }}
                autoFocus
              >
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
      {openPathDrawer && (
        <StudentPathDrawer
          openPathDrawer={openPathDrawer}
          setOpenPathDrawer={setOpenPathDrawer}
          courseId={dataPathDrawer.courseId}
          studentId={dataPathDrawer.studentId}
          title={dataPathDrawer.title}
        />
      )}
      {unsubscribeCourse.errors && unsubscribeCourse.errors.length > 0 && (
        <ModalMessages errors={unsubscribeCourse.errors} />
      )}
    </Container>
  );
};

export default StudentCourses;
