import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCourseGroupGrades,
  selectNewStudentsGrades,
  tryGetStudentGrades,
  tryTicksStudentsGrades,
} from "../../../store/slices/teacher-courses";
import {
  Typography,
  Grid,
  Button,
  Drawer,
  IconButton,
  Input,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { IsValidZeroToTen } from "../../../services/functions/validator";
import CustomColumnMenu from "../../layout/grid-column-menu";
import StudentTeachersGradesSkeleton from "../skeleton/gradesSkeleton";
import ModalMessages from "../../layout/modal-messages";
import Spinner from "../../layout/spinner";
import { DataGrid } from "@mui/x-data-grid";

const GradesDrawer = ({
  setOpenGradesDrawer,
  setOpenDialog,
  openGradesDrawer,
  courseGroupId,
  gradesDrawerData,
}) => {
  //MANEJO DE LA GRILLA
  const [rows, setRows] = useState([]);

  const handleScoreChange = (e, row) => {
    const { value } = e.target;
    if (!IsValidZeroToTen(value)) {
      row.score = null;
    }
    if (IsValidZeroToTen(value)) {
      row.score = parseFloat(value);
    }

    setRows([...rows]); // Actualiza el estado de las filas
  };

  const handleScoreChangeNoNumeric = (e, row) => {
    const { value } = e.target;
    const finalValue =
      value === 1 ? 0 : value === 2 ? 1 : value === 3 ? 10 : "";
    if (!IsValidZeroToTen(finalValue)) {
      row.score = null;
    }
    if (IsValidZeroToTen(finalValue)) {
      row.score = parseFloat(finalValue);
    }

    setRows([...rows]); // Actualiza el estado de las filas
  };

  const dispatch = useDispatch();
  const courseGroupGrades = useSelector(selectCourseGroupGrades);
  const newCourseGroupGrades = useSelector(selectNewStudentsGrades);

  useEffect(() => {
    (async () => {
      await dispatch(tryGetStudentGrades(courseGroupId));
    })();
  }, []);

  useEffect(() => {
    setRows(
      courseGroupGrades.data.map((x) => {
        return {
          nickName: x.nickName,
          score: x.score,
          studentCourseId: x.studentCourseId,
          totalClassesAttended: x.totalClassesAttended,
        };
      })
    );
  }, [courseGroupGrades]);

  const saveAttendances = async (model) => {
    const request = rows.map(({ nickName, totalClassesAttended, ...rest }) => {
      return rest;
    });
    //LLAMADO A LA API
    const action = await dispatch(tryTicksStudentsGrades(request));

    if (action.payload.data.isValid) {
      setOpenDialog(true);
      setOpenGradesDrawer(false);
    }
  };

  // Se inicializa la grilla
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          studentCourseId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 6 } },
    };
  };

  //Defino Columnas
  const columns = [
    {
      field: "studentCourseId",
      headerName: "studentCourseId",
      filterable: false,
    },
    {
      field: "nickName",
      headerAlign: "center",
      align: "left",
      headerName: "Alumno",
      headerClassName: "headRow",
      width: 150,
    },
    {
      field: "totalClassesAttended",
      headerAlign: "center",
      align: "left",
      headerName: "Asistencia",
      headerClassName: "headRow",
      width: 50,
    },
    {
      field: "score",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerName: "Nota",
      headerClassName: "headRow",
      width: 300,
      renderCell: (params) => {
        if (
          gradesDrawerData.additionalDocs ||
          gradesDrawerData.methodologyId === 1
        ) {
          return (
            <Input
              type="number"
              name="scoreInput"
              size="small"
              value={params.row.score || ""}
              onChange={(e) => handleScoreChange(e, params.row)}
              inputProps={{
                type: "number",
              }}
            />
          );
        }
        if (
          !gradesDrawerData.additionalDocs &&
          gradesDrawerData.methodologyId === 2
        ) {
          return (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={
                  params.row.score === 0
                    ? 1
                    : params.row.score === 1
                    ? 2
                    : params.row.score === 10
                    ? 3
                    : ""
                }
                onChange={(e) => handleScoreChangeNoNumeric(e, params.row)}
                fullWidth
                style={{ padding: 1 }}
              >
                <MenuItem value={1}>SIN CALIFICAR</MenuItem>
                <MenuItem value={2}>REPROBADO</MenuItem>
                <MenuItem value={3}>APROBADO</MenuItem>
              </Select>
            </FormControl>
          );
        }
      },
    },
  ];

  return (
    <>
      <Drawer
        anchor="right"
        open={openGradesDrawer}
        PaperProps={{
          sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
        }}
        onClose={() => setOpenGradesDrawer(false)}
        sx={{
          zIndex: 1203,
          width: 500,
          flexShrink: 0,
        }}
      >
        <Grid container p={1}>
          <Grid item xs={10}>
            <Typography
              align="left"
              sx={{
                paddingLeft: 2,
                paddingRight: 5,
                paddingTop: 4,
                fontWeight: 700,
                fontSize: 18,
              }}
            >
              Calificaciones
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setOpenGradesDrawer(false)}
            >
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ paddingLeft: 2, paddingBottom: 5 }}>
            <Typography variant="h7" align="center">
              Por favor, complete información en cada campo que se solicite en
              el formulario
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={3}
          spacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          p={2}
          sx={{ backgroundColor: "white" }}
        >
          {courseGroupGrades.isLoading || newCourseGroupGrades.isLoading ? (
            <StudentTeachersGradesSkeleton />
          ) : (
            <>
              <Grid item xs={12}>
                <DataGrid
                  slots={{
                    columnMenu: CustomColumnMenu,
                  }}
                  autoHeight={true}
                  rowSelection={false}
                  getRowId={(row) => row.studentCourseId}
                  rows={rows}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  initialState={initializeDataGrid()}
                />
              </Grid>
              <Grid item sx={{ width: "100%", textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={() => saveAttendances(rows)}
                  disabled={
                    courseGroupGrades.isLoading ||
                    newCourseGroupGrades.isLoading
                  }
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    width: 100,
                    marginRight: 2,
                  }}
                >
                  {(courseGroupGrades.isLoading ||
                    newCourseGroupGrades.isLoading) && (
                    <Spinner color="primary" />
                  )}
                  Guardar
                </Button>
                <Button
                  variant="outline"
                  color="error"
                  disabled={
                    courseGroupGrades.isLoading ||
                    newCourseGroupGrades.isLoading
                  }
                  sx={{
                    border: 2,
                    marginTop: 2,
                    marginBottom: 2,
                    width: 100,
                    color: "red",
                  }}
                  onClick={() => setOpenGradesDrawer(false)}
                >
                  Cancelar
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        {newCourseGroupGrades.errors &&
          newCourseGroupGrades.errors.length > 0 && (
            <ModalMessages errors={newCourseGroupGrades.errors} />
          )}
        {courseGroupGrades.errors && courseGroupGrades.errors.length > 0 && (
          <ModalMessages errors={courseGroupGrades.errors} />
        )}
      </Drawer>
    </>
  );
};

export default GradesDrawer;
