export default function convertToSelectList(data) {
  if (!data || data.length === 0)
    return [{ value: -1, text: "Sin Información" }];

  return data.map((x) => {
    return {
      value: x.id,
      text: x.description,
    };
  });
}
