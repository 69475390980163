import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Container, Paper, Typography } from "@mui/material";
// import Image from 'mui-image'

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  marginTop: "calc(10% + 40px)",
  width: "100%",
  position: "fixed",
  bottom: 0,
}));

const Footer = () => {
  return (
    <StyledPaper component="footer" square variant="outlined">
      <Container maxWidth="false">
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "right",
            display: "flex",
            mb: 2,
            my: 1,
          }}
        >
          <Typography variant="caption" color="white">
            UTEDYC - Copyright ©2023.
          </Typography>
        </Box>
      </Container>
    </StyledPaper>
  );
};

export default Footer;
