import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTeacherCoursesHeader,
  tryGetExportTeacherCourses,
  tryGetTeacherCoursesHeader,
  tryGetTeacherCoursesSummary,
} from "../../store/slices/teacher-courses";
import { tryGetCoursesReportAttendance } from "../../store/slices/backoffice-students";
import {
  tryGetExportCourseGroup,
  tryProgramDownload,
} from "../../store/slices/student-courses";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  LinearProgress,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { MoreHoriz, Assignment } from "@mui/icons-material";
import GradesDrawer from "./drawers/gradesDrawer";
import AttendanceDrawer from "./drawers/attendanceDrawer";
import TeacherCoursesSkeleton from "./skeleton/teacherCoursesSkeleton";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";
import ModalMessages from "../layout/modal-messages";

const getLinearProgressColor = (value) => {
  if (value > 70) return "success";

  if (value > 20) return "primary";

  return "error";
};

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{ minWidth: 100 }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color={getLinearProgressColor(props.value)}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const TeacherCourses = ({ isSummary, businessId }) => {
  const dispatch = useDispatch();

  const coursesHeader = useSelector(selectTeacherCoursesHeader);

  //Manejo de DIALOGS
  const [openDialog, setOpenDialog] = useState(false);
  const [openCourseGroupDialog, setOpenCourseGroupDialog] = useState(false);

  //Manejo Drawer CALIFICACIONES
  const [openGradesDrawer, setOpenGradesDrawer] = useState(false);
  const [gradesDrawerData, setGradesDrawerData] = useState(null);
  const [courseGroupId, setCourseGroupId] = useState(undefined);

  //Manejo Drawer ASISTENCIAS
  const [openAttendanceDrawer, setOpenAttendanceDrawer] = useState(false);
  const [teacherCourseId, setTeacherCourseId] = useState(null);

  //Detalle columnas
  const [rows, setRows] = useState([]);

  useEffect(() => {
    dispatch(
      tryGetTeacherCoursesHeader({
        teacherId: businessId,
        isFirstOrLastRecords: isSummary,
      })
    );
  }, []);

  useEffect(() => {
    if (!openAttendanceDrawer) {
      dispatch(tryGetTeacherCoursesSummary(businessId));
    }
  }, [openAttendanceDrawer]);

  useEffect(() => {
    setRows(
      coursesHeader.data.map((x) => {
        return {
          teacherCourseId: x.teacherCourseId,
          courseDescription: x.courseDescription ?? "",
          groupDescription: x.groupDescription ?? "",
          schedule: x.schedule ?? "",
          progress: x.progress ?? "",
          courseId: x.courseId ?? "",
          courseGroupId: x.courseGroupId,
          stateCourse: x.stateCourse,
          stateCourseId: x.stateCourseId,
          additionalDocs: x.additionalDocs,
          methodologyId: x.methodologyId,
        };
      })
    );
  }, [coursesHeader]);

  //DESCARGA EL PROGAMA DE UN CURSO
  const programDownload = async (courseId) => {
    const action = await dispatch(tryProgramDownload(courseId));
    if (action.payload.isValid) window.open(action.payload.data.file);
  };

  //DESCARGA EL REPORTE DE ASISTENCIAS
  const handleGetStudentsReport = async (request) => {
    const action = await dispatch(tryGetCoursesReportAttendance(request));
    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //EXPORTA LA GRILLA DE CURSOS
  const handleClickExport = async () => {
    const action = await dispatch(
      tryGetExportTeacherCourses({ teacherid: businessId })
    );

    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //Exporta el listado de estudiantes
  const handleClickStudentListExport = async (courseGroupId) => {
    const action = await dispatch(tryGetExportCourseGroup(courseGroupId));
    if (action.payload.data.isValid) {
      if (action.payload.data.data.file)
        window.open(action.payload.data.data.file);
      if (!action.payload.data.data.file) setOpenCourseGroupDialog(true);
    }
  };

  //CUANDO SE INICIA LA GRILLA
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          teacherCourseId: false,
          courseId: false,
          actions: !isSummary,
          courseGroupId: false,
          methodologyId: false,
          additionalDocs: false,
        },
      },
      pagination: { paginationModel: { pageSize: 10 } },
    };
  };

  //DEFINE LAS ACCIONES
  const ActionsRow = ({ item }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { courseGroupId, courseId } = item.row;

    const handleMenuItemClick = (event, index) => {
      //CARGAR ASISTENCIA***
      if (index === 0) {
        setCourseGroupId(courseGroupId);
        setTeacherCourseId(item.row.teacherCourseId);
        setOpenAttendanceDrawer(true);
      }
      //CARGAR CALIFICACIONES***
      if (index === 1) {
        setCourseGroupId(courseGroupId);
        setGradesDrawerData({
          methodologyId: item.row.methodologyId,
          additionalDocs: item.row.additionalDocs,
        });
        setOpenGradesDrawer(true);
      }
      //DESCARGAR EL PROGRAMA***
      if (index === 2) {
        programDownload(courseId);
      }
      //EXPORTAR ALUMNOS***
      if (index === 3) {
        handleClickStudentListExport(item.row.courseGroupId);
      }
      //EXPORTAR ASISTENCIA DE ALUMNOS EN EL CURSO***
      if (index === 4) {
        const request = item.row.courseGroupId;
        handleGetStudentsReport(request);
      }
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    <MenuItem
                      key="markAttendance"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Cargar Asistencia
                    </MenuItem>

                    <MenuItem
                      key="studentsGrades"
                      onClick={(event) => handleMenuItemClick(event, 1)}
                    >
                      Cargar Calificaciones
                    </MenuItem>
                    <MenuItem
                      key="programDownload"
                      onClick={(event) => handleMenuItemClick(event, 2)}
                    >
                      Descargar Programa
                    </MenuItem>
                    <MenuItem
                      key="programDownload"
                      onClick={(event) => handleMenuItemClick(event, 3)}
                    >
                      Exportar Alumnos
                    </MenuItem>
                    <MenuItem
                      key="programDownload"
                      onClick={(event) => handleMenuItemClick(event, 4)}
                    >
                      Reporte de asistencias
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const columns = [
    {
      field: "teacherCourseId",
      headerName: "teacherCourseId",
      filterable: false,
    },
    {
      field: "additionalDocs",
      headerName: "additionalDocs",
      filterable: false,
    },
    {
      field: "methodologyId",
      headerName: "methodologyId",
      filterable: false,
    },
    {
      field: "courseDescription",
      headerAlign: "center",
      align: "left",
      headerName: "Curso",
      minWidth: 250,
      headerClassName: "headRow",
    },
    {
      field: "courseId",
      headerAlign: "center",
      align: "left",
      headerName: "courseId",
      minWidth: 250,
      headerClassName: "headRow",
    },
    {
      field: "courseGroupId",
      headerAlign: "center",
      align: "left",
      headerName: "courseGroupId",
      minWidth: 250,
      headerClassName: "headRow",
    },

    {
      field: "groupDescription",
      headerAlign: "center",
      align: "center",
      headerName: "Comisión",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "schedule",
      headerAlign: "center",
      align: "center",
      headerName: "Horario",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "progress",
      headerAlign: "center",
      align: "center",
      headerName: "Progreso",
      minWidth: 120,
      headerClassName: "headRow",
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgressWithLabel
              value={params.value}
              variant="determinate"
              color={getLinearProgressColor(params.value ?? 0)}
            />
          </Box>
        </Box>
      ),
    },
    {
      field: "stateCourse",
      headerAlign: "center",
      align: "center",
      headerName: "Estado",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";
        let chipColor;
        if (params.row.stateCourseId === 3) chipColor = "primary";
        if (params.row.stateCourseId === 1) chipColor = "success";
        if (params.row.stateCourseId === 2) chipColor = "warning";

        return <Chip label={params.value} color={chipColor} size="small" />;
      },
    },
    {
      field: "actions",
      headerAlign: "center",
      headerName: "",
      flex: 0.3,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) => <ActionsRow item={params} />,
    },
  ];

  return (
    <>
      <Card>
        <Container>
          <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
            {coursesHeader.isLoading ? (
              <TeacherCoursesSkeleton />
            ) : (
              <>
                <Grid item xs={6}>
                  <Typography
                    align="left"
                    style={{ fontWeight: "bold", fontSize: 18 }}
                  >
                    {isSummary ? "Últimos Cursos" : "Cursos"}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "end" }}>
                  {!isSummary && (
                    <Button
                      variant="contained"
                      endIcon={<Assignment />}
                      onClick={() => handleClickExport()}
                    >
                      EXPORTAR
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <DataGrid
                    slots={{
                      columnMenu: CustomColumnMenu,
                    }}
                    autoHeight={true}
                    rowSelection={false}
                    getRowId={(row) => row.teacherCourseId}
                    disableRowSelectionOnClick
                    rows={rows}
                    columns={columns}
                    pageSizeOptions={[5, 10, 25, 50]}
                    initialState={initializeDataGrid()}
                    sx={{
                      "& .MuiDataGrid-virtualScroller": {
                        minHeight: 250,
                      },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {openCourseGroupDialog && (
            <>
              <Dialog
                open={openCourseGroupDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"La comisión seleccionada no tiene alumnos"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => setOpenCourseGroupDialog(false)}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}

          {openDialog && (
            <>
              <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"La información ha sido guardada con éxito"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={() => setOpenDialog(false)} autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}

          {coursesHeader.errors && coursesHeader.errors.length > 0 && (
            <ModalMessages errors={coursesHeader.errors} />
          )}
        </Container>
      </Card>
      {openAttendanceDrawer && (
        <AttendanceDrawer
          openAttendanceDrawer={openAttendanceDrawer}
          setOpenAttendanceDrawer={setOpenAttendanceDrawer}
          setOpenDialog={setOpenDialog}
          courseGroupId={courseGroupId}
          teacherCourseId={teacherCourseId}
        />
      )}
      {openGradesDrawer && (
        <GradesDrawer
          openGradesDrawer={openGradesDrawer}
          setOpenGradesDrawer={setOpenGradesDrawer}
          setOpenDialog={setOpenDialog}
          courseGroupId={courseGroupId}
          gradesDrawerData={gradesDrawerData}
        />
      )}
    </>
  );
};

export default TeacherCourses;
