import { Card, CardContent, Grid } from "@mui/material";
import BackOfficeTeachersList from "../../../components/backoffice-teachers";

const TeachersList = () => {
  return (
    <>
      <Grid
        container
        pt="0px"
        spacing={2}
        sx={{
          heigth: "auto",
          width: "auto",
          marginBottom: 3,
          marginTop: 0,
          paddingBottom: 22,
        }}
      >
        <Grid item xs={12}>
          <Card
            elevation={3}
            align="center"
            sx={{ heigth: "auto", width: "auto", padding: 2 }}
          >
            <CardContent>
              <BackOfficeTeachersList />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default TeachersList;
