import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiTeachers from "../../../services/apiTeachers";
import * as apiAuth from "../../../services/apiAuth";

export const teacherSlice = createSlice({
  name: "teacher",
  initialState: {
    item: {
      id: 0,
      clientId: 0,
      username: "",
      email: "",
      avatar: "",
      clients: {
        id: 0,
        nationality: 0,
        statusId: 0,
        email: "",
        avatar: "",
        cliNaturals: {
          cliNartularId: 0,
          clientId: 0,
          firstName: "",
          lastName: "",
        },
        cliAddress: [],
        cliContacts: [],
        cliDocuments: [],
        cliRelations: [],
        cliSocialEconomics: {
          cliSocialEconomicId: 0,
          clientId: 0,
          sectionalId: 0,
          situationId: 0,
          academicId: 0,
          labouralSituationId: 0,
          genderId: 0,
          birthDate: "",
          sectional: "",
          situation: {},
          academid: {},
          labouralSituation: {},
          gender: {},
        },
        students: null,
        teachers: {
          teacherId: 0,
          clientId: 0,
          nickName: "",
          teacherTypeId: 0,
          rating: null,
          tblTeacherType: {},
        },
        cliExternal: {},
        nationalities: {},
        statuscli: {},
      },
      isLoading: false,
      errors: [],
      status: "idle",
      expireAt: Date.Now, // 'idle', 'loading', 'succeeded', 'failed'
    },
    teachers: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    newTeacher: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    teacherTaxes: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    teacherBankData: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setLoading(state, { payload }) {
      state.item.isLoading = payload;
    },
    setCleanErrorsTeacher: (state) => {
      state.item.errors = [];
      state.teachers.errors = [];
      state.newTeacher.errors = [];
      state.teacherTaxes.errors = [];
      state.teacherBankData.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryGetTeacher.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });

    builder.addCase(tryGetTeacher.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      if (payload.isValid === true) {
        state.item = { ...payload.data };
      }

      state.item.errors = payload.isValid ? payload.errors : [];

      state.item.isLoading = false;
    });

    builder.addCase(tryGetTeacher.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });
    builder.addCase(tryUpdateAvatar.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });

    builder.addCase(tryUpdateAvatar.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      if (payload.isValid) {
        state.item.avatar = payload.data.avatar;
        state.item.clients.avatar = payload.data.avatar;
      }

      state.item.errors = payload.isValid ? payload.errors : [];

      state.item.isLoading = false;
    });

    builder.addCase(tryUpdateAvatar.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });
    builder.addCase(tryUpdateTeacherData.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });

    builder.addCase(tryUpdateTeacherData.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      if (payload.isValid) state.item = { ...payload.data };

      state.item.errors = payload.isValid ? payload.errors : [];

      state.item.isLoading = false;
    });

    builder.addCase(tryUpdateTeacherData.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });

    //TRAE EL DETALLE DE TODOS LOS PROFESORES

    builder.addCase(tryGetTeacherByBOHeader.pending, (state) => {
      state.teachers.status = "loading";
      state.teachers.isLoading = true;
      state.teachers.errors = [];
    });

    builder.addCase(tryGetTeacherByBOHeader.fulfilled, (state, { payload }) => {
      state.teachers.status = "succeeded";

      if (payload.isValid) state.teachers.data = [...payload.data];

      state.teachers.errors = payload.isValid ? payload.errors : [];

      state.teachers.isLoading = false;
    });

    builder.addCase(tryGetTeacherByBOHeader.rejected, (state, { payload }) => {
      state.teachers.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.teachers.errors = errors;
      state.teachers.isLoading = false;
    });

    //CREA UN NUEVO PROFESOR Y TRAE SU DETALLE

    builder.addCase(tryPostNewTeacher.pending, (state) => {
      state.newTeacher.status = "loading";
      state.newTeacher.isLoading = true;
      state.newTeacher.errors = [];
    });

    builder.addCase(tryPostNewTeacher.fulfilled, (state, { payload }) => {
      state.newTeacher.status = "succeeded";

      if (payload.isValid) state.newTeacher.data = { ...payload.data };

      state.newTeacher.errors = payload.isValid ? payload.errors : [];

      state.newTeacher.isLoading = false;
    });

    builder.addCase(tryPostNewTeacher.rejected, (state, { payload }) => {
      state.newTeacher.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newTeacher.errors = errors;
      state.newTeacher.isLoading = false;
    });

    ////
    //CREA O MODIFICA LA PARTE IMPOSITIVA DE UN PROFESOR

    builder.addCase(tryPostClitaxvat.pending, (state) => {
      state.teacherTaxes.status = "loading";
      state.teacherTaxes.isLoading = true;
      state.teacherTaxes.errors = [];
    });

    builder.addCase(tryPostClitaxvat.fulfilled, (state, { payload }) => {
      state.teacherTaxes.status = "succeeded";

      if (payload.isValid) state.teacherTaxes.data = { ...payload.data };

      state.teacherTaxes.errors = payload.isValid ? payload.errors : [];

      state.teacherTaxes.isLoading = false;
    });

    builder.addCase(tryPostClitaxvat.rejected, (state, { payload }) => {
      state.teacherTaxes.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.data.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.teacherTaxes.errors = errors;
      state.teacherTaxes.isLoading = false;
    });

    //TRAE EL DETALLE DE LA INFORMACION BANCARIA DE UN PROFESOR

    builder.addCase(tryGetTeacherBankData.pending, (state) => {
      state.teacherBankData.status = "loading";
      state.teacherBankData.isLoading = true;
      state.teacherBankData.errors = [];
    });

    builder.addCase(tryGetTeacherBankData.fulfilled, (state, { payload }) => {
      state.teacherBankData.status = "succeeded";

      if (payload.isValid) state.teacherBankData.data = { ...payload.data };

      state.teacherBankData.errors = payload.isValid ? payload.errors : [];

      state.teacherBankData.isLoading = false;
    });

    builder.addCase(tryGetTeacherBankData.rejected, (state, { payload }) => {
      state.teacherBankData.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.data.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.teacherBankData.errors = errors;
      state.teacherBankData.isLoading = false;
    });
  },
});

export const { setLoading, setCleanErrorsTeacher } = teacherSlice.actions;

export default teacherSlice.reducer;

export const tryCleanErrorsTeacher = () => (dispatch) => {
  dispatch(setCleanErrorsTeacher());
};

export const selectTeacher = (state) => state.teacher.item;

export const selectTeachers = (state) => state.teacher.teachers;

export const selectNewTeacher = (state) => state.teacher.newTeacher;

export const selectTeacherTaxes = (state) => state.teacher.teacherTaxes;

export const selectTeacherBankData = (state) => state.teacher.teacherBankData;

export const tryGetTeacher = createAsyncThunk(
  "teacher/tryGetTeacher",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await apiTeachers.tryGetTeacherData(userId);
      return response.data;
    } catch (err) {
      console.err(err);
      return rejectWithValue(err);
    }
  }
);

export const tryUpdateTeacherData = createAsyncThunk(
  "teacher/tryUpdateTeacherData",
  async (request, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiTeachers.tryUpdateTeacherData(request);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryUpdateAvatar = createAsyncThunk(
  "teacher/tryUpdateAvatar",
  async ({ userId, image }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiAuth.updateAvatar(userId, image);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetTeacherByBOHeader = createAsyncThunk(
  "teacher/tryGetTeacherByBOHeader",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiTeachers.getTeacherByBOHeader();

      return response.data;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryPostNewTeacher = createAsyncThunk(
  "teacher/tryPostNewTeacher",
  async (request, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiTeachers.postNewTeacher(request);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryPostClitaxvat = createAsyncThunk(
  "teacher/tryPostClitaxvat",
  async (request, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiTeachers.postClitaxvat(request);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetTeacherBankData = createAsyncThunk(
  "teacher/tryGetTeacherBankData",
  async (userId, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiTeachers.getTeacherBankData(userId);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
