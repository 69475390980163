import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiTeacher from "../../../services/apiTeachers";

export const teacherTicketsSlice = createSlice({
  name: "teacherTickets",
  initialState: {
    item: {
      isLoading: false,
      errors: [],
    },
    summary: {
      haveTicketsNotRead: true,
      lastTicketSubmitted: undefined,
      subjectLastTicketSubmitted: "",
      lastTicketAnswered: undefined,
      subjectLastTicketAnswered: "",
      ticketsNotAnsweredNotRead: 0,
      totalTickets: 0,
      progress: 0,
      isLoading: false,
      errors: [],
      status: "idle",
    },
    list: {
      data: [],
      totalPages: 1,
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    updated: {
      data: false,
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    details: {
      item: {
        ticketId: 0,
        ownerId: null,
        originalSenderId: 0,
        topicId: 0,
        subTopicId: 0,
        subject: null,
        statusTicketId: 0,
        closeDate: null,
        ticketDetails: [],
      },
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    export: {
      data: false,
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    ticketsTabSummary: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    closedTicket: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setTeacherTicketLoading(state, { payload }) {
      state.summary.isLoading = payload;
    },
    setTeacherTicketsListLoading: (state, { payload }) => {
      state.list.isLoading = payload;
    },
    setCleanErrorsTeacherTickets: (state) => {
      state.item.errors = [];
      state.summary.errors = [];
      state.list.errors = [];
      state.updated.errors = [];
      state.details.errors = [];
      state.export.errors = [];
      state.ticketsTabSummary.errors = [];
      state.closedTicket.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(
      tryGetTeacherTicketsSummary.fulfilled,
      (state, { payload }) => {
        state.summary.status = "succeeded";
        const errors = payload.data.isValid ? payload.data.errors : [];
        state.summary.errors = errors;
        if (payload.data.isValid === true) {
          state.summary = { ...payload.data.data };
        }
        state.summary.isLoading = false;
      }
    );
    builder.addCase(
      tryGetTeacherTicketsSummary.rejected,
      (state, { payload }) => {
        state.summary.status = "failed";
        let errors = [];
        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;
          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }
        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];
        state.summary.errors = errors;
        state.summary.isLoading = false;
      }
    );
    builder.addCase(tryTeacherTickets.pending, (state) => {
      state.list.status = "loading";
      state.list.isLoading = true;
      state.list.errors = [];
    });
    builder.addCase(tryTeacherTickets.fulfilled, (state, { payload }) => {
      state.list.status = "succeeded";

      if (payload.data.isValid) {
        state.list = { ...payload.data };
        state.list.totalPages = payload.meta.totalPages;
      }

      const errors = payload.isValid ? payload.errors : [];
      state.list.errors = errors;

      state.list.isLoading = false;
    });
    builder.addCase(tryTeacherTickets.rejected, (state, { payload }) => {
      state.list.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.list.errors = errors;
      state.list.isLoading = false;
    });
    builder.addCase(tryCreateTeacherTicket.pending, (state) => {
      state.updated.status = "loading";
      state.updated.isLoading = true;
      state.updated.errors = [];
    });
    builder.addCase(tryCreateTeacherTicket.fulfilled, (state, { payload }) => {
      state.updated.status = "succeeded";

      if (payload.data.isValid) {
        state.updated = { ...payload.data };
      }

      const errors = payload.isValid ? payload.errors : [];
      state.updated.errors = errors;

      state.updated.isLoading = false;
    });
    builder.addCase(tryCreateTeacherTicket.rejected, (state, { payload }) => {
      state.updated.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.data.status !== 401) {
        if (payload.data.isValid === false) errors = payload.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.updated.errors = errors;
      state.updated.isLoading = false;
    });
    builder.addCase(tryGetTeacherTicketDetails.pending, (state) => {
      state.details.status = "loading";
      state.details.isLoading = true;
      state.details.errors = [];
    });
    builder.addCase(
      tryGetTeacherTicketDetails.fulfilled,
      (state, { payload }) => {
        state.details.status = "succeeded";

        if (payload.data.isValid) {
          state.details.item = { ...payload.data.data };
        }

        const errors = payload.isValid ? payload.errors : [];
        state.details.errors = errors;

        state.details.isLoading = false;
      }
    );
    builder.addCase(
      tryGetTeacherTicketDetails.rejected,
      (state, { payload }) => {
        state.details.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.data.status !== 401) {
          if (payload.data.isValid === false) errors = payload.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.details.errors = errors;
        state.details.isLoading = false;
      }
    );

    builder.addCase(tryTeacherTicketsExport.pending, (state) => {
      state.export.status = "loading";
      state.export.isLoading = true;
      state.export.errors = [];
    });
    builder.addCase(tryTeacherTicketsExport.fulfilled, (state, { payload }) => {
      state.export.status = "succeeded";

      if (payload.data.isValid) {
        state.export.item = { ...payload.data.data };
      }

      const errors = payload.isValid ? payload.errors : [];
      state.export.errors = errors;

      state.export.isLoading = false;
    });
    builder.addCase(tryTeacherTicketsExport.rejected, (state, { payload }) => {
      state.export.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.data.status !== 401) {
        if (payload.data.isValid === false) errors = payload.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.export.errors = errors;
      state.export.isLoading = false;
    });

    builder.addCase(tryGetTeacherTicketsTabSummary.pending, (state) => {
      state.ticketsTabSummary.status = "loading";
      state.ticketsTabSummary.isLoading = true;
      state.ticketsTabSummary.errors = [];
    });
    builder.addCase(
      tryGetTeacherTicketsTabSummary.fulfilled,
      (state, { payload }) => {
        state.ticketsTabSummary.status = "succeeded";

        if (payload.data.isValid) {
          state.ticketsTabSummary.data = [...payload.data.data];
        }

        const errors = payload.isValid ? payload.errors : [];
        state.ticketsTabSummary.errors = errors;

        state.ticketsTabSummary.isLoading = false;
      }
    );
    builder.addCase(
      tryGetTeacherTicketsTabSummary.rejected,
      (state, { payload }) => {
        state.ticketsTabSummary.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.data.status !== 401) {
          if (payload.data.isValid === false) errors = payload.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.ticketsTabSummary.errors = errors;
        state.ticketsTabSummary.isLoading = false;
      }
    );

    //CIERRA EL TICKET DE UN PROFESOR
    builder.addCase(tryCloseTeacherTicket.pending, (state) => {
      state.closedTicket.status = "loading";
      state.closedTicket.isLoading = true;
      state.closedTicket.errors = [];
    });
    builder.addCase(tryCloseTeacherTicket.fulfilled, (state, { payload }) => {
      state.closedTicket.status = "succeeded";

      if (payload.data.isValid) {
        state.closedTicket.data = { ...payload.data.data };
      }

      const errors = payload.isValid ? payload.errors : [];
      state.closedTicket.errors = errors;

      state.closedTicket.isLoading = false;
    });
    builder.addCase(tryCloseTeacherTicket.rejected, (state, { payload }) => {
      state.closedTicket.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.data.status !== 401) {
        if (payload.data.isValid === false) errors = payload.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.closedTicket.errors = errors;
      state.closedTicket.isLoading = false;
    });
  },
});

export const {
  setTeacherTicketLoading,
  setTeacherTicketsListLoading,
  setCleanErrorsTeacherTickets,
} = teacherTicketsSlice.actions;

export default teacherTicketsSlice.reducer;

export const tryCleanErrorsTeacherTickets = () => (dispatch) => {
  dispatch(setCleanErrorsTeacherTickets());
};

export const selectTeacherTicketsSummary = (state) => state.teacherTickets.summary; //prettier-ignore

export const selectTeacherTickets = (state) => state.teacherTickets.list;

export const selectTeacherTicketsUpdate = (state) => state.teacherTickets.updated; //prettier-ignore

export const selectTeacherTicketDetails = (state) => state.teacherTickets.details; //prettier-ignore

export const selectTeacherTicketsExport = (state) => state.teacherTickets.export; //prettier-ignore

export const selectTeacherTicketsTabSummary = (state) => state.teacherTickets.ticketsTabSummary; //prettier-ignore

export const tryTeacherTickets = createAsyncThunk(
  "teacherTickets/tryTeacherTickets",
  async ({ teacherId, pageNumber }, { rejectWithValue }) => {
    try {
      const query = {
        isPaginated: true,
        Sort: "Desc",
        PageNumber: pageNumber,
        PageSize: 5,
      };

      const response = await apiTeacher.getTicketsByTeacherHeader(
        teacherId,
        query
      );

      return response.data;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetTeacherTicketsSummary = createAsyncThunk(
  "teacherTickets/tryGetTeacherTicketsSummary",
  async (teacherId, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getTeacherTicketsSummary(teacherId);

      return response;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryCreateTeacherTicket = createAsyncThunk(
  "teacherTickets/tryCreateTeacherTicket",
  async ({ request }, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.createTeacherTicket(request);
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetTeacherTicketDetails = createAsyncThunk(
  "teacherTickets/tryGetTeacherTicketDetails",
  async ({ ticketId }, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getTeacherTicketDetails(ticketId);
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetAttachmentByTicketDetail = createAsyncThunk(
  "teacherTickets/tryGetAttachmentByTicketDetail",
  async ({ ticketId, ticketDetaildId }, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getAttachmentByTicketDetail(
        ticketId,
        ticketDetaildId
      );
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryTeacherTicketsExport = createAsyncThunk(
  "teacherTickets/tryTeacherTicketsExport",
  async (businessId, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.getTeacherTicketsExport(businessId);
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetTeacherTicketsTabSummary = createAsyncThunk(
  "studentTickets/tryGetTeacherTicketsTabSummary",
  async ({ teacherId }, { rejectWithValue }) => {
    try {
      const queryModel = {
        isPaginated: true,
        Sort: "Desc",
        PageNumber: 1,
        PageSize: 5,
      };

      const response = await apiTeacher.getTicketsByTeacherHeader(
        teacherId,
        queryModel
      );

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryCloseTeacherTicket = createAsyncThunk(
  "teacherTickets/tryCloseTeacherTicket",
  async (ticketId, { rejectWithValue }) => {
    try {
      const response = await apiTeacher.postCloseTeacherTicket(ticketId);
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
