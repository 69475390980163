import { Grid, Skeleton } from "@mui/material";

const TeacherTicketMainSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" sx={{ height: "54px" }} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton
            variant="rectangular"
            sx={{ height: "500px", width: "350px" }}
          />
        </Grid>
        <Grid item xs={8}>
          <Skeleton
            variant="rectangular"
            sx={{ height: "127px", width: 650 }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TeacherTicketMainSkeleton;
