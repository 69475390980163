import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AlertMessages from "../alert-messages";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import Spinner from "../spinner";
import { CleanAllErrors } from "../../../services/functions/cleanAllErrors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalMessages = ({ errors, handleClose }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await CleanAllErrors(dispatch);
    setLoading(false);
    if (handleClose) handleClose();

    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        if (handleClose) {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <AlertMessages type="error" data={errors} />
        <Button
          sx={{ marginTop: 3 }}
          fullWidth
          color="error"
          onClick={() => handleClick()}
        >
          {loading && <Spinner color="primary" />}
          Aceptar
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalMessages;
