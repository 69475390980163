import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStudentTicketsTabSummary,
  tryGetTicketsTabSummary,
} from "../../store/slices/student-tickets";
import { Card, Chip, Container, Grid, Typography } from "@mui/material";
import TeacherTicketsTabSummary from "./skeleton";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";
import ModalMessages from "../layout/modal-messages";

import timeFromNow from "../../services/functions/timeFromNow";

const StudentTagSummary = ({ businessId }) => {
  const dispatch = useDispatch();

  //Trae el detalle del usuario
  const ticketsTabSummary = useSelector(selectStudentTicketsTabSummary);

  //Detalle columnas
  const [rows, setRows] = useState([]);

  useEffect(() => {
    dispatch(tryGetTicketsTabSummary(businessId));
  }, []);

  useEffect(() => {
    setRows(
      ticketsTabSummary.data.map((x) => {
        return {
          ticketId: x.ticketId,
          nameOfSender: x.nameOfSender,
          titleOfTicket: x.titleOfTicket,
          topic: x.topic,
          subTopic: x.subTopic,
          statusOfMessage: x.statusOfMessage,
          timeDifference: x.timeDifference,
          statusOfMessageId: x.statusOfMessageId,
        };
      })
    );
  }, [ticketsTabSummary]);

  //CUANDO SE INICIA LA GRILLA
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          teacherCourseId: false,
          ticketId: false,
          statusOfMessageId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 5 } },
    };
  };

  const columns = [
    {
      field: "ticketId",
      headerAlign: "center",
      align: "center",
      headerName: "ticketId",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "nameOfSender",
      headerAlign: "center",
      align: "center",
      headerName: "Remitente",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "titleOfTicket",
      headerAlign: "center",
      align: "center",
      headerName: "Título",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "topic",
      headerAlign: "center",
      align: "center",
      headerName: "Tópico",
      flex: 1,
      headerClassName: "headRow",
    },

    {
      field: "subTopic",
      headerAlign: "center",
      align: "center",
      headerName: "Sub Tópico",
      flex: 1,
      headerClassName: "headRow",
    },

    {
      field: "statusOfMessageId",
      headerAlign: "center",
      align: "center",
      headerName: "statusOfMessageId",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "statusOfMessage",
      headerAlign: "center",
      align: "center",
      headerName: "Estado",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        let chipColor;
        if (params.row.statusOfMessageId === 1) chipColor = "warning";
        if (params.row.statusOfMessageId === 2) chipColor = "success";
        if (params.row.statusOfMessageId === 3) chipColor = "default";

        return <Chip label={params.value} color={chipColor} size="small" />;
      },
    },
    {
      field: "timeDifference",
      headerAlign: "center",
      align: "center",
      headerName: "Enviado Hace",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        return timeFromNow(params.value);
      },
    },
  ];

  return (
    <>
      <Card>
        <Container>
          <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
            {ticketsTabSummary.isLoading ? (
              <TeacherTicketsTabSummary />
            ) : (
              <>
                <Grid item xs={6}>
                  <Typography
                    align="left"
                    style={{ fontWeight: "bold", fontSize: 18 }}
                  >
                    Últimas Comunicaciones
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <DataGrid
                    slots={{
                      columnMenu: CustomColumnMenu,
                    }}
                    autoHeight={true}
                    rowSelection={false}
                    getRowId={(row) => row.ticketId}
                    disableRowSelectionOnClick
                    rows={rows}
                    columns={columns}
                    pageSizeOptions={[5]}
                    initialState={initializeDataGrid()}
                    sx={{
                      "& .MuiDataGrid-virtualScroller": {
                        minHeight: 250,
                      },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {ticketsTabSummary.errors && ticketsTabSummary.errors.length > 0 && (
            <ModalMessages errors={ticketsTabSummary.errors} />
          )}
        </Container>
      </Card>
    </>
  );
};

export default StudentTagSummary;
