import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAssignedTeacher,
  selectBackTeachers,
  tryAssignTeacher,
  tryGetAllTeachers,
} from "../../../store/slices/backoffice-teachers";
import {
  Grid,
  Typography,
  Drawer,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Chip,
  MenuItem,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useFormik } from "formik";
import ModalMessages from "../../layout/modal-messages";
import Spinner from "../../layout/spinner";

const validate = (values) => {
  const errors = {};
  const { teacherCuil, amountCommited } = values;
  let cuilReplaced = values.teacherCuil
    .replace("-", "")
    .replace(/_/g, "")
    .replace("-", "");

  if (cuilReplaced.toString().length !== 11)
    errors.cuil = "Longitud de CUIL inválida";

  if (!teacherCuil) {
    errors.teacherCuil = "Requerido";
  }
  if (!amountCommited) {
    errors.amountCommited = "Requerido";
  }
  return errors;
};

const AssignTeacherToCourseDrawer = ({
  openDrawer,
  setOpenDrawer,
  dataDrawer,
  setOpenDialog,
}) => {
  const selectTeachers = useSelector(selectBackTeachers);
  const assignedTeacher = useSelector(selectAssignedTeacher);
  const { data: teachers } = selectTeachers;
  const [cuil, setCuil] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const dispatch = useDispatch();
  const formik = useFormik({
    eneableReinitialize: true,
    initialValues: {
      teacherCourseId:
        dataDrawer.teacherCourseId !== 0 ? dataDrawer.teacherCourseId : 0,
      teacherId: dataDrawer.teacherId ?? "",
      teacherCuil: null,
      courseId: dataDrawer.courseId,
      courseGroupId: dataDrawer.courseGroupId,
      amountCommited: dataDrawer.amountCommited ?? "",
    },
    validate,
    onSubmit: async (values) => {
      //Destructuring de las props que necesito
      const {
        teacherCourseId,
        teacherId,
        courseId,
        courseGroupId,
        amountCommited,
      } = values;
      //Genero el request
      const request = {
        teacherCourseId,
        teacherId,
        courseId,
        courseGroupId,
        amountCommited,
      };

      //Asigno el teacherId seguin el CUIL
      let cuilReplaced = values.teacherCuil
        .replace("-", "")
        .replace(/_/g, "")
        .replace("-", "");
      teachers.map((x) => {
        if (x.shortDescription === cuilReplaced) request.teacherId = x.id;
      });

      //Hago el llamado a la api

      const action = await dispatch(tryAssignTeacher(request));
      if (action.payload.data.isValid) {
        setOpenDrawer(false);
        setOpenDialog(true);
      }
    },
  });

  useEffect(() => {
    if (cuil !== null) {
      formik.setFieldValue("teacherCuil", cuil);
    }
  }, [cuil]);

  useEffect(() => {
    const fetchData = async () => {
      if (openDrawer) {
        try {
          await dispatch(tryGetAllTeachers());
        } catch (error) {}
      }
    };

    fetchData();
  }, [openDrawer]);

  useEffect(() => {
    if (teachers) {
      const fetchData = async (formik) => {
        if (openDrawer && dataDrawer.teacherId !== 0) {
          try {
            teachers.map((x) => {
              if (x.id === dataDrawer.teacherId) setCuil(x.shortDescription);
            });
          } catch (error) {}
        }
      };
      fetchData();
    }
  }, [teachers]);

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      PaperProps={{
        sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
      }}
      onClose={() => setOpenDrawer(false)}
      sx={{
        zIndex: 1203,
        width: 500,
        flexShrink: 0,
      }}
    >
      <Grid container p={1}>
        <Grid item xs={10}>
          <Typography
            align="left"
            sx={{
              paddingLeft: 1,
              paddingRight: 5,

              paddingTop: 4,
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Asignar profesor
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "end" }}>
          <IconButton
            sx={{ color: "white" }}
            onClick={() => setOpenDrawer(false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: 1,
            paddingBottom: 3,
          }}
        >
          <Typography sx={{ fontSize: "12px" }} align="left">
            Por favor, complete los campos a continuación para asignar un
            profesor al curso.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        spacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        p={2}
        sx={{ backgroundColor: "white" }}
      >
        <Grid item xs={12} marginLeft={1}>
          <Typography>
            <Chip
              label={dataDrawer.briefDescription}
              color="primary"
              style={{ fontSize: 18 }}
            />
          </Typography>
          <Typography
            marginLeft={1}
            marginTop={1}
            style={{ color: "black", fontSize: 16 }}
          >
            Comisión:
            {dataDrawer.courseGroupDescription}
          </Typography>
        </Grid>

        <Grid item xs={12} marginLeft={1} marginRight={1}>
          <InputLabel id="teacher-label">Seleccione Profesor</InputLabel>
          <Select
            labelId="teacher-label"
            name="teacher"
            label="Seleccione Profesor"
            size="small"
            fullWidth
            value={formik.values.teacherCuil}
            onChange={(e) => {
              const { value } = e.target;

              formik.setFieldValue("teacherCuil", value);
            }}
            error={formik.touched.teacherCuil && formik.errors.teacherCuil}
            helperText={formik.touched.teacherCuil && formik.errors.teacherCuil}
          >
            {teachers &&
              teachers.map((x) => {
                return (
                  <MenuItem key={x.id} value={x.shortDescription}>
                    {x.description}
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
        <Grid item xs={12} marginLeft={1} marginRight={1}>
          <FormControl fullWidth>
            <PatternFormat
              variant="outlined"
              labelId="teacherCuil-label"
              name="teacherCuil"
              label="CUIL del Profesor"
              size="small"
              value={formik.values.teacherCuil}
              format="##-########-#"
              mask="_"
              customInput={TextField}
              onChange={(e) => {
                const { value } = e.target;
                let cuilReplaced = value
                  .replace("-", "")
                  .replace(/_/g, "")
                  .replace("-", "");

                formik.setFieldValue("teacherCuil", cuilReplaced);
              }}
              NumericFormat
              error={
                formik.touched.teacherCuil &&
                (formik.errors.teacherCuil || formik.errors.cuil)
              }
              helperText={
                formik.touched.teacherCuil &&
                (formik.errors.teacherCuil || formik.errors.cuil)
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} marginLeft={1} marginRight={1}>
          <FormControl fullWidth>
            <NumericFormat
              variant="outlined"
              label="Monto asignado al Profesor"
              size="small"
              name="teacherSalary"
              thousandSeparator="."
              decimalSeparator=","
              isNumericString
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale
              prefix="$"
              InputLabelProps={{ shrink: true }}
              customInput={TextField}
              value={formik.values.amountCommited}
              onValueChange={(values) => {
                const { floatValue } = values;
                formik.setFieldValue("amountCommited", floatValue);
              }}
              error={
                formik.touched.amountCommited && formik.errors.amountCommited
              }
              helperText={
                formik.touched.amountCommited && formik.errors.amountCommited
              }
            />
          </FormControl>
        </Grid>

        <Grid item sx={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={() => setOpenConfirmationDialog(true)}
            disabled={assignedTeacher.isLoading}
            sx={{
              marginTop: 2,
              marginBottom: 2,
              width: 100,
              marginRight: 2,
            }}
          >
            {assignedTeacher.isLoading && <Spinner color="primary" />}
            Guardar
          </Button>
          <Button
            variant="outline"
            color="error"
            disabled={assignedTeacher.isLoading}
            sx={{
              border: 2,
              marginTop: 2,
              marginBottom: 2,
              width: 100,
              color: "red",
            }}
            onClick={() => setOpenDrawer(false)}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
      {assignedTeacher.errors && assignedTeacher.errors.length > 0 && (
        <ModalMessages errors={assignedTeacher.errors} />
      )}
      {openConfirmationDialog && (
        <>
          <Dialog
            open={openConfirmationDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"¿Confirmas asignar al profesor?"}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  formik.handleSubmit();
                  setOpenConfirmationDialog(false);
                }}
                autoFocus
              >
                Aceptar
              </Button>
              <Button
                onClick={() => {
                  setOpenConfirmationDialog(false);
                }}
                autoFocus
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
    </Drawer>
  );
};

export default AssignTeacherToCourseDrawer;
