import React from "react";
import { Button, Container, Divider, Grid, Skeleton } from "@mui/material";

const NewTeacherSkeleton = () => {
  return (
    <Container
      fixed
      sx={{
        paddingTop: 5,
      }}
    >
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>

        <Grid item xs={2}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>

        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
    </Container>
  );
};

export default NewTeacherSkeleton;
