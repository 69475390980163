import React from "react";
import { Typography, Grid } from "@mui/material";
import { AdminPanelSettings } from "@mui/icons-material";

import Lottie from "lottie-react";
import backofficeAnimation from "../../animations/backofficeAnimation.json";

const ProfileTitle = ({ isOther }) => {
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={8} align="left">
          {isOther ? (
            <>
              <Typography sx={{ fontSize: 25, paddingTop: 1 }} color="primary">
                <strong style={{ fontWeight: "bold" }}>Perfil Usuario</strong>{" "}
                <AdminPanelSettings fontSize="large" color="warning" />
              </Typography>
            </>
          ) : (
            <>
              <Typography sx={{ fontSize: 25, paddingTop: 1 }} color="primary">
                <strong style={{ fontWeight: "bold" }}>Mi Perfil</strong>{" "}
                <AdminPanelSettings fontSize="large" color="primary" />
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={4} align="center">
          {" "}
          <div style={{ width: "100px", height: "100px" }}>
            <Lottie animationData={backofficeAnimation} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileTitle;
