const regexValidateDate =
  /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/; // Regex para validar el formato de fecha (DD MM AAAA)

const regexValidateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Regex para validar el formato de email

const regexValidatePassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$/; // Regex para validar el formato de contraseña

const regexValidateHour = /^([01]\d|2[0-3]):([0-5]\d)$/; // Regex para validar el formato de hora (HH:MM)

const regexZeroToTen = /^(10(\.0{1,2})?|[0-9](\.[0-9]{1,2})?)$/; // Regex para validar números entre cero y diez (con decimales)

export const IsValidDate = (date) => regexValidateDate.test(date);

export const IsValidEmail = (email) => regexValidateEmail.test(email);

export const IsValidPassword = (password) => regexValidatePassword.test(password); //prettier-ignore

export const IsValidHour = (hour) => regexValidateHour.test(hour);

export const IsValidZeroToTen = (number) => regexZeroToTen.test(number);
