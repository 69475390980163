import { Grid, Skeleton, Typography } from "@mui/material";
import React from "react";

const StudentCoursesSummarySkeleton = () => {
  return (
    <Grid sx={{ pt: 3, pb: 3 }} container spacing={3}>
      <Grid item xs={12}>
          <Typography sx={{ fontSize: 20 }}>
            <strong style={{ fontWeight: "bold" }}>Resumen</strong>
          </Typography>
        </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          sx={{ height: "40px" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          sx={{ height: "40px" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          sx={{ height: "40px" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          sx={{ height: "40px" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          sx={{ height: "40px" }}
        />
      </Grid>
    </Grid>
  );
};

export default StudentCoursesSummarySkeleton;
