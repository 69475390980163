import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectExportedBackoffice,
  selectUsersAll,
  tryDeleteBackoffice,
  tryExportBackofficeAll,
  tryGetUsersAll,
} from "../../store/slices/users";
import {
  Button,
  ButtonGroup,
  Card,
  Chip,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { MoreHoriz, Download, PersonAdd } from "@mui/icons-material";
import { useNavigate } from "react-router";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";
import ModalMessages from "../layout/modal-messages";
import Spinner from "../layout/spinner";
import NewUserDrawer from "./drawer";
import UsersSkeleton from "./skeletons";

const BackOfficeUsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Estados
  const usersAll = useSelector(selectUsersAll);
  const exported = useSelector(selectExportedBackoffice);
  const deleted = useSelector(selectExportedBackoffice);

  //Manejo ELIMINAR USUARIO
  const [toDelete, setToDelete] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  //MANEJO DEL DRAWER
  const [openDrawer, setOpenDrawer] = useState(false);

  //MANEJO DIALOG DE CONFIRMACINO
  const [openDialog, setOpenDialog] = useState(false);

  //MANEJO DE LAS COLUMNAS
  const [rows, setRows] = useState([]);

  const fetchData = async () => {
    const action = await dispatch(tryGetUsersAll());
    if (action.payload.isValid) {
      setRows(
        action.payload.data.map((x) => {
          return {
            fullName: x.fullName,
            cuil: addDashes(x.cuil),
            email: x.email,
            statusCli: x.statusCli,
            statusCliId: x.statusCliId,
            userId: x.userId,
            username: x.username,
            clientId: x.clientId,
          };
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    !openDialog && fetchData();
  }, [openDialog]);

  //ELIMINA UN USUARIO DE BACKOFFICE
  const tryDeleteUser = async (userId) => {
    const action = await dispatch(tryDeleteBackoffice(userId));
    if (action.payload.isValid) {
      fetchData();
      setConfirmationDialog(false);
      setOpenDialog(true);
    }
  };

  //Exporta el listado de usuarios
  const handleClickExport = async () => {
    const action = await dispatch(tryExportBackofficeAll());
    if (action.payload.isValid) window.open(action.payload.data.file);
  };

  //AGREGA GUIONES A LOS CUILS
  function addDashes(number) {
    if (typeof number !== "string") {
      number = number.toString();
    }

    if (number.length <= 2) {
      return number;
    }

    const pre = number.slice(0, 2);
    const mid = number.slice(2, -1);
    const last = number.slice(-1);

    return `${pre}-${mid}-${last}`;
  }

  //ACCIONES
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          statusCli: false,
          clientId: false,
          username: false,
          userId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 10 } },
    };
  };

  const ActionsRow = ({ item }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleMenuItemClick = (event, index) => {
      //ADMINISTRAR USUARIO
      if (index === 0) {
        navigate("/backoffice/usersprofile", {
          state: {
            userId: item.row.userId,
            username: item.row.username,
            otherUser: true,
          },
        });
      }
      // ELIMINAR USUARIO
      if (index === 1) {
        setConfirmationDialog(true);
        setToDelete(item.row.userId);
      }

      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    <MenuItem
                      key="navigateBackoffice"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Administrar Usuario
                    </MenuItem>
                    <MenuItem
                      key="deleteBackoffice"
                      onClick={(event) => handleMenuItemClick(event, 1)}
                    >
                      Eliminar Usuario
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const columns = [
    {
      field: "userId",
      headerName: "userId",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "clientId",
      headerName: "clientId",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "username",
      headerName: "username",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "statusCli",
      headerName: "statusCli",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "fullName",
      headerName: "Nombre Completo",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "cuil",
      headerName: "CUIL/CUIT",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "email",
      headerName: "E-Mail",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "statusCliId",
      headerName: "Estado",
      headerAlign: "left",
      align: "left",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";
        let chipColor;
        if (params.value === 1) {
          chipColor = "success";
        }
        if (params.value === 2) {
          chipColor = "error";
        }
        if (params.value === 3) {
          chipColor = "warning";
        }

        return (
          <Chip label={params.row.statusCli} color={chipColor} size="small" />
        );
      },
    },
    {
      field: "actions",
      headerAlign: "center",
      headerName: "",
      flex: 1,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) =>
        deleted.isLoading ? (
          <Spinner color="primary" />
        ) : (
          <ActionsRow item={params} />
        ),
    },
  ];

  return (
    <>
      <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
        <Grid item xs={6}>
          <Typography align="left" style={{ fontWeight: "bold", fontSize: 18 }}>
            Usuarios BackOffice
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "end" }}>
          <ButtonGroup>
            <Button
              variant="contained"
              disabled={
                usersAll.isLoading || exported.isLoading || deleted.isLoading
              }
              endIcon={<Download />}
              onClick={() => handleClickExport()}
            >
              {(usersAll.isLoading || exported.isLoading) && (
                <Spinner color="primary" />
              )}
              Exportar
            </Button>
            <Button
              variant="contained"
              endIcon={<PersonAdd />}
              disabled={
                usersAll.isLoading || exported.isLoading || deleted.isLoading
              }
              onClick={() => setOpenDrawer(true)}
            >
              {(usersAll.isLoading || exported.isLoading) && (
                <Spinner color="primary" />
              )}
              CARGAR USUARIO
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12}>
          {usersAll.isLoading ? (
            <UsersSkeleton />
          ) : (
            <>
              <DataGrid
                slots={{
                  columnMenu: CustomColumnMenu,
                }}
                autoHeight={true}
                rowSelection={false}
                getRowId={(row) => row.userId}
                disableRowSelectionOnClick
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 25, 50]}
                initialState={initializeDataGrid()}
                sx={{
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: 250,
                  },
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
      {openDrawer && (
        <NewUserDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setOpenDialog={setOpenDialog}
        />
      )}
      {openDialog && (
        <>
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"La información ha sido guardada con éxito"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
      {confirmationDialog && (
        <>
          <Dialog
            open={confirmationDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"¿Estás seguro que deseas eliminar al usuario?"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => tryDeleteUser(toDelete)} autoFocus>
                Aceptar
              </Button>
              <Button onClick={() => setConfirmationDialog(false)} autoFocus>
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
      {exported.errors && exported.errors > 0 && (
        <ModalMessages errors={exported.errors} />
      )}
      {usersAll.errors && usersAll.errors > 0 && (
        <ModalMessages errors={usersAll.errors} />
      )}
      {deleted.errors && deleted.errors > 0 && (
        <ModalMessages errors={deleted.errors} />
      )}
    </>
  );
};

export default BackOfficeUsersList;
