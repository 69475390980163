import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTeacherCliPreCasesDetail,
  tryGetTeacherCliprecasesSubdetail,
} from "../../../store/slices/teacher-payments";
import {
  Grid,
  Button,
  Drawer,
  Typography,
  IconButton,
  Chip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CustomColumnMenu from "../../layout/grid-column-menu";
import ModalMessages from "../../layout/modal-messages";
import TeachersPaymentsDetailSkeleton from "../skeleton/detailDrawerSkeleton";
import { NumericFormat } from "react-number-format";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import Spinner from "../../layout/spinner";

const DetailDrawer = ({
  openDetailDrawer,
  setOpenDetailDrawer,
  businessId,
  cliPrecasesid,
}) => {
  const dispatch = useDispatch();

  //MANEJO DE LAS FILAS
  const [rows, setRows] = useState([]);

  //TRAE EL DETALLE DE UN CASO
  const precasesSubdetail = useSelector(selectTeacherCliPreCasesDetail);

  const fetchData = async () => {
    const action = await dispatch(
      tryGetTeacherCliprecasesSubdetail({
        teacherId: businessId,
        cliPrecasesid,
      })
    );

    if (action.payload.data.isValid) {
      setRows(
        action.payload.data.data.map((x) => {
          return {
            clientId: x.clientId,
            processType: x.processType ?? "",
            notes: x.notes ?? "",
            totalHours: x.totalHours ?? "",
            amount: x.amount ?? "",
          };
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //CNDO SE INICIA LA GRILLA
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          clientId: false,
        },
      },

      pagination: { paginationModel: { pageSize: 6 } },
    };
  };

  //DEFINO COLUMNAS
  const columns = [
    { field: "clientId", headerName: "clientId", filterable: false },
    {
      field: "processType",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "TIPO",
      headerClassName: "headRow",
      width: 260,
    },
    {
      field: "notes",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "DETALLE",
      headerClassName: "headRow",
      width: 130,
    },

    {
      field: "totalHours",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "HORAS",
      headerClassName: "headRow",
      width: 130,
      renderCell: (params) => <Chip label={params.value} size="small" />,
    },
    {
      field: "amount",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "MONTO",
      headerClassName: "headRow",
      width: 130,
      renderCell: (params) => (
        <NumericFormat
          key={`${params.field}_${params.id}`}
          value={params.value ?? 0}
          decimalScale="2"
          thousandSeparator="."
          displayType="text"
          decimalSeparator=","
          prefix="$"
          fixedDecimalScale="2"
        />
      ),
    },
  ];

  return (
    <>
      <Drawer
        anchor="right"
        open={openDetailDrawer}
        PaperProps={{
          sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
        }}
        onClose={() => setOpenDetailDrawer(false)}
        sx={{
          zIndex: 1203,
          width: 500,
          flexShrink: 0,
        }}
      >
        <Grid container p={1}>
          <Grid item xs={10}>
            <Typography
              align="left"
              sx={{
                paddingLeft: 2,
                paddingRight: 5,
                paddingTop: 4,
                fontWeight: 700,
                fontSize: 18,
                paddingBottom: 5,
              }}
            >
              Detalle del caso
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setOpenDetailDrawer(false)}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          container
          rowSpacing={3}
          spacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          p={2}
          sx={{ backgroundColor: "white" }}
        >
          {precasesSubdetail.isLoading ? (
            <TeachersPaymentsDetailSkeleton />
          ) : (
            <>
              <Grid item xs={12}>
                <DataGrid
                  slots={{
                    columnMenu: CustomColumnMenu,
                  }}
                  autoHeight={true}
                  rowSelection={false}
                  getRowId={(row) => uuidv4()}
                  rows={rows}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  initialState={initializeDataGrid()}
                />
              </Grid>
              <Grid item sx={{ width: "100%", textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={() => setOpenDetailDrawer(false)}
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    width: 100,
                    marginRight: 2,
                  }}
                >
                  {precasesSubdetail.isLoading && <Spinner color="primary" />}
                  Aceptar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        {precasesSubdetail.errors && precasesSubdetail.errors.length > 0 && (
          <ModalMessages errors={precasesSubdetail.errors} />
        )}
      </Drawer>
    </>
  );
};

export default DetailDrawer;
