import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCatalog,
  selectNewTuple,
  selectTypeTables,
  tryCleanCatalog,
  tryGetCatalog,
  tryGetTypeTables,
  tryNewTuple,
} from "../../store/slices/maintenances";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { Assignment, MoreHoriz } from "@mui/icons-material";
import ModalMessages from "../layout/modal-messages";
import CustomColumnMenu from "../layout/grid-column-menu";
import TupleAdminDrawer from "./drawer";
import TypeTablesSkeleton from "./skeletons";
import Spinner from "../layout/spinner";
import { DataGrid } from "@mui/x-data-grid";

const TypeTablesEditor = () => {
  const dispatch = useDispatch();

  //Drawer ALTA Y EDICION DE TUPLAS
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState(undefined);

  //Controla el manejo de autocomplete
  const [selectedTypeTable, setselectedTypeTable] = useState(null);

  //Setea si se abre el dialog
  const [openDialog, setOpenDialog] = useState(false);

  const typeTables = useSelector(selectTypeTables);
  const selectedCatalog = useSelector(selectCatalog);
  const newRow = useSelector(selectNewTuple);

  //MANEJO DE LAS COLUMNAS
  const [rows, setRows] = useState([]);

  //Manejo eliminar fila
  const [model, setModel] = useState({});
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const fetchData = async () => {
    setselectedTypeTable(null);
    setRows([]);
    dispatch(tryGetTypeTables());
    dispatch(tryCleanCatalog());
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    !openDialog &&
      selectedTypeTable &&
      (async () => {
        const action = await dispatch(
          tryGetCatalog(selectedTypeTable.typeTableId)
        );
        if (action.payload.data.data.isValid) {
          setRows(
            action.payload.data.data.data.map((x) => {
              return {
                description: x.description,
                shortDescription: x.shortDescription,
                id: x.id,
              };
            })
          );
        }
      })();
  }, [selectedTypeTable, openDialog]);

  //Eliminar una tupla
  const deleteTuple = async () => {
    const action = await dispatch(tryNewTuple({ model }));
    if (action.payload.data.isValid) {
      setOpenDialog(true);
    }
  };

  //ACCIONES
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,

        columnVisibilityModel: {
          id: false,
        },
      },
      pagination: { paginationModel: { pageSize: 10 } },
    };
  };

  const ActionsRow = ({ item }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleMenuItemClick = (event, index) => {
      //EDITAR TUPLA
      if (index === 0) {
        setDataDrawer({
          description: item.row.description,
          shortDescription: item.row.shortDescription,
          id: item.row.id,
        });
        setOpenDrawer(true);
      }
      //ELIMINAR TUPLA
      if (index === 1) {
        setModel({
          typeTableId: selectedTypeTable.typeTableId,
          key: item.row.id,
          isDeleted: true,
          isActive: false,
          description: item.row.description,
          shortDesc: item.row.shortDescription,
        });
        setConfirmationDialog(true);
      }

      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    <MenuItem
                      key="assignTeacher"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      EDITAR
                    </MenuItem>
                    <MenuItem
                      key="assignTeacher"
                      onClick={(event) => handleMenuItemClick(event, 1)}
                    >
                      ELIMINAR
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      filterable: false,
      headerClassName: "headRow",
    },
    {
      field: "description",
      headerClassName: "headRow",
      headerName: "DESCRIPCION",
      flex: 1,
      filterable: false,
    },
    {
      field: "shortDescription",
      headerName: "DESCRIPCION CORTA",
      headerClassName: "headRow",
      flex: 1,
      filterable: false,
    },

    {
      field: "actions",
      headerAlign: "center",

      headerName: "",
      flex: 1,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) =>
        newRow.isLoading ? (
          <Spinner color="primary" />
        ) : (
          <ActionsRow item={params} />
        ),
    },
  ];

  return (
    <>
      <Card>
        <Container>
          <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
            <Grid item xs={6}>
              <Typography
                align="left"
                style={{ fontWeight: "bold", fontSize: 18 }}
              >
                Mantenimiento de Catálogos
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="warning" sx={{ textAlign: "left" }}>
                <AlertTitle>ATENCIÓN</AlertTitle>
                Usted está a punto de modificar datos de catálogos del sistema.
                <br />
                <strong>
                  NO modifique, elimine, ni agregue registros a un catálogo a
                  menos que esté seguro de no afectar el funcionamiento.
                </strong>
              </Alert>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal={false}
                  size="small"
                  onChange={(e, newV) => setselectedTypeTable(newV)}
                  value={selectedTypeTable}
                  loading={typeTables.isLoading}
                  options={typeTables?.data || []}
                  getOptionLabel={(option) => option.description ?? ""}
                  renderInput={(params) => (
                    <TextField {...params} label="Tablas Catálogo" />
                  )}
                />
              </FormControl>
            </Grid>
            {selectedCatalog.data.length !== 0 && (
              <>
                {selectedCatalog.isLoading ? (
                  <TypeTablesSkeleton />
                ) : (
                  <>
                    <Grid item xs={8} sx={{ textAlign: "end" }}>
                      <Button
                        variant="contained"
                        endIcon={<Assignment />}
                        onClick={() => {
                          setDataDrawer({
                            description: "",
                            shortDescription: "",
                            id: 0,
                          });
                          setOpenDrawer(true);
                        }}
                      >
                        AGREGAR FILA
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <DataGrid
                        slots={{
                          columnMenu: CustomColumnMenu,
                        }}
                        autoHeight={true}
                        rowSelection={false}
                        getRowId={(row) => row.id}
                        disableRowSelectionOnClick
                        rows={rows}
                        columns={columns}
                        pageSizeOptions={[5, 10, 25, 50]}
                        initialState={initializeDataGrid()}
                        sx={{
                          "& .MuiDataGrid-virtualScroller": {
                            minHeight: 250,
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Container>
      </Card>
      {openDrawer && (
        <TupleAdminDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setOpenDialog={setOpenDialog}
          description={dataDrawer.description}
          shortDescription={dataDrawer.shortDescription}
          id={dataDrawer.id}
          typeTable={selectedTypeTable.description}
          typeTableId={selectedTypeTable.typeTableId}
        />
      )}
      {confirmationDialog && (
        <>
          <Dialog
            open={confirmationDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"¿Confirmas eliminar la fila?"}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  deleteTuple();
                  setConfirmationDialog(false);
                }}
                autoFocus
              >
                Aceptar
              </Button>
              <Button onClick={() => setConfirmationDialog(false)} autoFocus>
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
      {openDialog && (
        <>
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"La información ha sido guardada con éxito"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
      {typeTables.errors && typeTables.errors.length > 0 && (
        <ModalMessages errors={typeTables.errors} />
      )}
      {selectedCatalog.errors && selectedCatalog.errors.length > 0 && (
        <ModalMessages errors={selectedCatalog.errors} />
      )}
      {newRow.errors && newRow.errors.length > 0 && (
        <ModalMessages errors={newRow.errors} />
      )}
    </>
  );
};

export default TypeTablesEditor;
