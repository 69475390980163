import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiStudents from "../../../services/apiStudents";

export const studentPaymentsSlice = createSlice({
  name: "studentPayments",
  initialState: {
    item: {
      paymentId: 0,
      studentCourseId: 0,
      paymentCode: "",
      paymentAmount: 0,
      wayOfPayment: 1,
      complement: "",
      paymentDate: undefined,
      fileOfPayment: "",
      stateOfPayment: 3,
      wayOfPayments: undefined,
      statusStudentCoursePayments: undefined,
      isValid: false,
      isLoading: false,
      errors: [],
    },
    summary: {
      lastPaymentReceived: "",
      nextPayment: "",
      typeStudentConditionPaymentId: 0,
      typeStudentConditionPaymentDescription: "",
      amountDebt: 0,
      quantityOfPayments: 0,
      quantityOfPaymentMade: 0,
      isLoading: false,
      errors: [],
      status: "idle",
    },
    list: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    export: {
      id: 0,
      name: "",
      file: "",
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    deleted: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    paymentApproved: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    paymentRejected: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    downloaded: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setStudentPayments: (state, { payload }) => {
      state.item = { ...state.item, ...payload };
    },

    setStudentPaymentsLoading: (state, { payload }) => {
      state.item.isLoading = payload;
    },
    setDeleted: (state, { payload }) => {
      state.deleted = { ...state.deleted, ...payload };
    },
    setCleanErrorsStudentPayments: (state) => {
      state.item.errors = [];
      state.summary.errors = [];
      state.list.errors = [];
      state.export.errors = [];
      state.deleted.errors = [];
      state.paymentApproved.errors = [];
      state.paymentRejected.errors = [];
      state.downloaded.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryStudentPayments.pending, (state) => {
      state.list.status = "loading";
      state.list.isLoading = true;
      state.list.errors = [];
    });
    builder.addCase(tryStudentPayments.fulfilled, (state, { payload }) => {
      state.list.status = "succeeded";

      const errors = payload.data.data.isValid ? payload.data.data.errors : [];
      state.list.errors = errors;

      if (payload.data.data.isValid === true) {
        state.list = payload.data.data;
      }

      state.list.isLoading = false;
    });
    builder.addCase(tryStudentPayments.rejected, (state, { payload }) => {
      state.list.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.list.errors = errors;
      state.list.isLoading = false;
    });
    builder.addCase(tryStudentPaymentsSummary.pending, (state) => {
      state.summary.status = "loading";
      state.summary.isLoading = true;
      state.summary.errors = [];
    });
    builder.addCase(
      tryStudentPaymentsSummary.fulfilled,
      (state, { payload }) => {
        state.summary.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.summary.errors = errors;

        if (payload.data.isValid === true) {
          state.summary = { ...payload.data.data };
        }

        state.summary.isLoading = false;
      }
    );
    builder.addCase(
      tryStudentPaymentsSummary.rejected,
      (state, { payload }) => {
        state.summary.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.summary.errors = errors;
        state.summary.isLoading = false;
      }
    );
    builder.addCase(tryStudentPaymentDetail.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(tryStudentPaymentDetail.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      const errors = !payload.data.isValid ? payload.data.errors : [];
      state.item.errors = errors;

      if (payload.data.isValid === true) {
        state.item = { ...payload.data.data };
      }

      state.item.isLoading = false;
    });
    builder.addCase(tryStudentPaymentDetail.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });
    builder.addCase(trySaveStudentPaymentDetail.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(
      trySaveStudentPaymentDetail.fulfilled,

      (state, { payload }) => {
        state.item.status = "succeeded";

        const errors = !payload.data.isValid ? payload.data.errors : [];
        state.item.errors = errors;

        // if (payload.data.isValid === true) {
        //   state.item = payload.data;
        // }

        state.item.isLoading = false;
      }
    );
    builder.addCase(
      trySaveStudentPaymentDetail.rejected,
      (state, { payload }) => {
        state.item.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.item.errors = errors;
        state.item.isLoading = false;
      }
    );
    builder.addCase(tryStudentPaymentsExport.pending, (state) => {
      state.export.status = "loading";
      state.export.isLoading = true;
      state.export.errors = [];
    });
    builder.addCase(
      tryStudentPaymentsExport.fulfilled,
      (state, { payload }) => {
        state.export.status = "succeeded";

        if (payload.data.isValid) {
          state.export = { ...payload.data };
        }
        state.export.isLoading = false;
      }
    );
    builder.addCase(tryStudentPaymentsExport.rejected, (state, { payload }) => {
      state.export.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.export.errors = errors;
      state.export.isLoading = false;
    });

    //Eliminar un pago
    builder.addCase(tryDeletePayment.pending, (state) => {
      state.deleted.status = "loading";
      state.deleted.isLoading = true;
      state.deleted.errors = [];
    });
    builder.addCase(tryDeletePayment.fulfilled, (state, { payload }) => {
      state.deleted.status = "succeeded";

      if (payload.data.isValid) {
        state.deleted = { ...payload.data };
      }
      state.deleted.isLoading = false;
    });
    builder.addCase(tryDeletePayment.rejected, (state, { payload }) => {
      state.deleted.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.deleted.errors = errors;
      state.deleted.isLoading = false;
    });

    //APRUEBA UN PAGO
    builder.addCase(tryPaymentApproved.pending, (state) => {
      state.paymentApproved.status = "loading";
      state.paymentApproved.isLoading = true;
      state.paymentApproved.errors = [];
    });
    builder.addCase(tryPaymentApproved.fulfilled, (state, { payload }) => {
      state.paymentApproved.status = "succeeded";

      if (payload.data.isValid) {
        state.paymentApproved = { ...payload.data };
      }
      state.paymentApproved.isLoading = false;
    });
    builder.addCase(tryPaymentApproved.rejected, (state, { payload }) => {
      state.paymentApproved.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.paymentApproved.errors = errors;
      state.paymentApproved.isLoading = false;
    });

    //RECHAZA UN PAGO
    builder.addCase(tryPaymentRejected.pending, (state) => {
      state.paymentRejected.status = "loading";
      state.paymentRejected.isLoading = true;
      state.paymentRejected.errors = [];
    });
    builder.addCase(tryPaymentRejected.fulfilled, (state, { payload }) => {
      state.paymentRejected.status = "succeeded";

      if (payload.data.isValid) {
        state.paymentRejected = { ...payload.data };
      }
      state.paymentRejected.isLoading = false;
    });
    builder.addCase(tryPaymentRejected.rejected, (state, { payload }) => {
      state.paymentRejected.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.paymentRejected.errors = errors;
      state.paymentRejected.isLoading = false;
    });

    // Descarga el archivo asociado a un pago
    builder.addCase(tryDownloadStudentFile.pending, (state) => {
      state.downloaded.status = "loading";
      state.downloaded.isLoading = true;
      state.downloaded.errors = [];
    });
    builder.addCase(tryDownloadStudentFile.fulfilled, (state, { payload }) => {
      state.downloaded.status = "succeeded";

      if (payload.data.isValid) {
        state.downloaded = { ...payload.data };
      }
      state.downloaded.isLoading = false;
    });
    builder.addCase(tryDownloadStudentFile.rejected, (state, { payload }) => {
      state.downloaded.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.downloaded.errors = errors;
      state.downloaded.isLoading = false;
    });
  },
});

export const {
  setStudentPayments,
  setStudentPaymentsLoading,
  setDeleted,
  setCleanErrorsStudentPayments,
} = studentPaymentsSlice.actions;

export default studentPaymentsSlice.reducer;

export const tryCleanErrorsStudentPayments = () => (dispatch) => {
  dispatch(setCleanErrorsStudentPayments());
};

export const selectStudentPayments = (state) => state.studentPayments.list;

export const selectStudentPaymentsSummary = (state) =>
  state.studentPayments.summary;

export const selectStudentPaymentsExport = (state) =>
  state.studentPayments.export;

export const selectStudentPaymentsDetail = (state) =>
  state.studentPayments.item;

export const selectDeleted = (state) => state.studentPayments.deleted;

export const selectApproved = (state) => state.studentPayments.paymentApproved;

export const selectRejected = (state) => state.studentPayments.paymentRejected;

export const selectDownloaded = (state) => state.studentPayments.downloaded;

export const selectNewPayment = (state) => state.studentPayments.list;

export const tryStudentPayments = createAsyncThunk(
  "studentPayments/tryStudentPayments",
  async ({ studentId, isFirstOrLastRecords }, { rejectWithValue }) => {
    try {
      const response = await apiStudents.getPayments(
        studentId,
        isFirstOrLastRecords
      );
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryStudentPaymentsSummary = createAsyncThunk(
  "studentPayments/tryStudentPaymentsSummary",
  async (studentId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getPaymentSummary(studentId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryStudentPaymentDetail = createAsyncThunk(
  "studentPayments/tryStudentPaymentDetail",
  async (
    { studentId, courseId, coursefeeId, paymentId },
    { rejectWithValue }
  ) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getPaymentNotification(
        studentId,
        courseId,
        coursefeeId,
        paymentId
      );
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const trySaveStudentPaymentDetail = createAsyncThunk(
  "studentPayments/trySaveStudentPaymentDetail",
  async ({ studentId, courseId, coursefeeId, model }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.postPaymentNotification(
        studentId,
        courseId,
        coursefeeId,
        model
      );
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryStudentPaymentsExport = createAsyncThunk(
  "studentPayments/tryStudentPaymentsExport",
  async (bussinessId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.postStudentPaymentsExport(bussinessId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryDeletePayment = createAsyncThunk(
  "studentPayments/tryDeletePayment",
  async ({ studentId, courseId, coursefeeId }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.deleteNotification(
        studentId,
        courseId,
        coursefeeId
      );
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryPaymentApproved = createAsyncThunk(
  "studentPayments/tryPaymentApproved",
  async (
    { studentId, courseId, paymentId, coursefeeId },
    { rejectWithValue }
  ) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.putPaymentApproved(
        studentId,
        courseId,
        paymentId,
        coursefeeId
      );
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryPaymentRejected = createAsyncThunk(
  "studentPayments/tryPaymentRejected",
  async (
    { studentId, courseId, paymentId, coursefeeId, reasons },
    { rejectWithValue }
  ) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.putPaymentRejected(
        studentId,
        courseId,
        paymentId,
        coursefeeId,
        reasons
      );
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryDownloadStudentFile = createAsyncThunk(
  "studentPayments/tryDownloadStudentFile",
  async ({ paymentId }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getDownloadStudentFile(paymentId);
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
