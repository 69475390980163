export default function validateCuil(cuil) {
  let rv = false;
  let verified,
    result = 0;
  let cuilNro = cuil.replace(/-/g, "");
  const codes = "6789456789";

  if (cuilNro.length === 11 && /^\d+$/.test(cuilNro)) {
    verified = parseInt(cuilNro[cuilNro.length - 1]);
    let x = 0;
    while (x < 10) {
      let digitValidator = parseInt(codes.substring(x, x + 1));
      let digit = parseInt(cuilNro.substring(x, x + 1));
      let validatorDigit = digitValidator * digit;
      result += validatorDigit;
      x++;
    }
    result = result % 11;
    rv = result === verified;
  }

  return rv;
}
