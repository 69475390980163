import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectTeacherCoursesSummary,
  tryGetTeacherCoursesSummary,
} from "../../store/slices/teacher-courses";
import { selectCurrentUser } from "../../store/slices/currentUser";
import {
  Alert,
  AlertTitle,
  Chip,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import ModalMessages from "../layout/modal-messages";
import { Container } from "@mui/system";
import moment from "moment";
import TeacherCoursesSummarySkeleton from "./skeleton/coursesSummary";

const TeacherCoursesSummary = ({ businessId }) => {
  const dispatch = useDispatch();
  const { roleId } = useSelector(selectCurrentUser);

  //TRAE EL DETALLE DEL RESUMEN
  const coursesSummary = useSelector(selectTeacherCoursesSummary);
  const { data } = coursesSummary;

  useEffect(() => {
    dispatch(tryGetTeacherCoursesSummary(businessId));
  }, []);

  return (
    <Container>
      <Grid sx={{ pt: 3, pb: 3 }} container spacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 20 }}>
            <strong style={{ fontWeight: "bold" }}>Resumen</strong>
          </Typography>
        </Grid>
        {coursesSummary.isLoading ? (
          <TeacherCoursesSummarySkeleton />
        ) : (
          <>
            <Grid item xs={12}>
              <Alert severity="warning">
                <AlertTitle>Asistencia</AlertTitle>
                {roleId !== 1 ? (
                  <>
                    Por favor, verificar si tienes asistencia aún no registrada
                    en alguno de los Cursos que estás dictando.
                    <br />
                    Recuerda que la información se utiliza para conocer la
                    asistencia mínima de cada alumno, y también para calcular
                    las horas que debemos abonarte en la próxima liquidación.
                  </>
                ) : (
                  <>
                    Por favor verificar si el profesor tiene asistencia aún no
                    registrada en alguno de los cursos que está dictando.
                    <br /> Recuerda que la información se utiliza para conocer
                    la asistencia mínima de cada alumno, y también para calcular
                    las horas que deben abonarse al profesor en la próxima
                    liquidación.
                  </>
                )}{" "}
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                Última Clase{" "}
                {data.lastEdition
                  ? moment(data.lastEdition).format("ll")
                  : "Sin información"}
              </Typography>
              <Typography sx={{ color: "grey" }}>
                {data.lastCourse ?? "Sin información"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                Próxima Clase{" "}
                {data.nextCourse
                  ? moment(data.nextCourse).format("ll")
                  : "Sin información"}
              </Typography>
              <Typography sx={{ color: "grey" }}>
                {data.comingCourse ?? "Sin información"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                Clases hasta la fecha: {data.classesTaught ?? "0"} Total de
                Clases: {data.totalClasses ?? "0"}
              </Typography>
              <Typography>
                <strong>{data.summary ?? "0"}</strong> clases completadas y
                clases impartidas{" "}
                {data.stateOfAttendance !== "" && (
                  <Chip
                    label={data.stateOfAttendance}
                    color={data.conditioningType === 1 ? "success" : "error"}
                    size="small"
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                Porcentaje de Clases Dictadas sobre Clases Totales:{" "}
                {data.progress}%
              </Typography>
              <LinearProgress
                sx={{
                  fontSize: 60,
                  width: "400px",
                  height: "10px",
                }}
                variant="determinate"
                value={data.progress}
              />
              <Typography sx={{ color: "grey" }}></Typography>
            </Grid>
          </>
        )}
      </Grid>

      {coursesSummary.errors && coursesSummary.errors.length > 0 && (
        <ModalMessages errors={coursesSummary.errors} />
      )}
    </Container>
  );
};

export default TeacherCoursesSummary;
