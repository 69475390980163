import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCourse,
  tryCreateCourse,
  tryGetCourseDetail,
} from "../../store/slices/back-course";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import {
  CheckBoxOutlined,
  MoreHoriz,
  CheckBoxOutlineBlankOutlined,
} from "@mui/icons-material";
import ComplementsDrawer from "./drawer";
import BackCoursesComplementsSkeleton from "./skeleton";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";

const CoursesComplements = ({ course }) => {
  const dispatch = useDispatch();

  //Setea si se abre el drawer
  const [openDrawer, setOpenDrawer] = useState(false);

  //Setea la información que se envía al drawer
  const [dataDrawer, setDataDrawer] = useState(undefined);

  //Setea si se abre el dialog
  const [openDialog, setOpenDialog] = useState(false);

  //Se obtiene el estado de un curso
  const courseDetail = useSelector(selectCourse);

  //Contiene el detalle del curso
  const request = { ...courseDetail.data };

  //DEFINO LAS FILAS
  const [rows, setRows] = useState([
    {
      id: 1,
      title: "Presentación",
      values: "",
    },
    {
      id: 2,
      title: "Bibliografia",
      values: "",
    },
    {
      id: 3,
      title: "Fundamentos",
      values: "",
    },
    {
      id: 4,
      title: "Objetivos",
      values: "",
    },
    {
      id: 5,
      title: "Metodología",
      values: "",
    },
    {
      id: 6,
      title: "Requerimientos",
      values: "",
    },
    {
      id: 7,
      title: "Condición de Aprobación",
      values: "",
    },
  ]);

  //Cargo los campos en la grilla al cargar la pagina
  useEffect(() => {
    const fetchData = async () => {
      const action = await dispatch(tryGetCourseDetail(course));
      if (action.payload.data.isValid) {
        setRows([
          {
            id: 1,
            title: "Presentación",
            values: action.payload.data.data.program,
            fieldName: "program",
          },
          {
            id: 2,
            title: "Bibliografia",
            values: action.payload.data.data.bibilography,
            fieldName: "bibilography",
          },
          {
            id: 3,
            title: "Fundamentos",
            values: action.payload.data.data.fundaments,
            fieldName: "fundaments",
          },
          {
            id: 4,
            title: "Objetivos",
            values: action.payload.data.data.objectives,
            fieldName: "objectives",
          },
          {
            id: 5,
            title: "Metodología",
            values: action.payload.data.data.methodologyDescription,
            fieldName: "methodologyDescription",
          },
          {
            id: 6,
            title: "Requerimientos",
            values: action.payload.data.data.requirements,
            fieldName: "requirements",
          },
          {
            id: 7,
            title: "Condición de Aprobación",
            values: action.payload.data.data.approvalConditions,
            fieldName: "approvalConditions",
          },
        ]);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (openDialog) {
      const fetchData = async () => {
        const action = await dispatch(tryGetCourseDetail(course));
        if (action.payload.data.isValid) {
          setRows([
            {
              id: 1,
              title: "Presentación",
              values: action.payload.data.data.program ?? "",
              fieldName: "program",
            },
            {
              id: 2,
              title: "Bibliografia",
              values: action.payload.data.data.bibilography ?? "",
              fieldName: "bibilography",
            },
            {
              id: 3,
              title: "Fundamentos",
              values: action.payload.data.data.fundaments ?? "",
              fieldName: "fundaments",
            },
            {
              id: 4,
              title: "Objetivos",
              values: action.payload.data.data.objectives ?? "",
              fieldName: "objectives",
            },
            {
              id: 5,
              title: "Metodología",
              values: action.payload.data.data.methodologyDescription ?? "",
              fieldName: "methodologyDescription",
            },
            {
              id: 6,
              title: "Requerimientos",
              values: action.payload.data.data.requirements ?? "",
              fieldName: "requirements",
            },
            {
              id: 7,
              title: "Condición de Aprobación",
              values: action.payload.data.data.approvalConditions ?? "",
              fieldName: "approvalConditions",
            },
          ]);
        }
      };
      fetchData();
    }
  }, [openDialog]);

  //Tiene valor
  const IsChecked = ({ item }) => {
    if ((item.row.values !== "") & (item.row.values !== null))
      return <CheckBoxOutlined color="success" fontSize="large" />;
    if (item.row.values === "" || item.row.values === null)
      return <CheckBoxOutlineBlankOutlined color="success" fontSize="large" />;
  };

  //ACCIONES
  const ActionsRow = ({ item }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleMenuItemClick = (event, index) => {
      //EDITAR
      if (index === 0) {
        setDataDrawer({
          title: item.row.title,
          id: item.row.id,
          values: item.row.values,
        });

        setOpenDrawer(true);
      }
      //ELIMINAR
      if (index === 1) {
        switch (item.row.id) {
          case 1:
            request.program = " ";
            break;
          case 2:
            request.bibilography = " ";
            break;
          case 3:
            request.fundaments = " ";
            break;
          case 4:
            request.objectives = " ";
            break;
          case 5:
            request.methodologyDescription = " ";
            break;
          case 6:
            request.requirements = " ";
            break;
          case 7:
            request.approvalConditions = " ";
            break;
        }
        (async () => {
          const action = await dispatch(tryCreateCourse(request));
          if (action.payload.data.isValid) {
            setOpenDialog(true);
          }
        })();
      }

      setOpen(false);
    };
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem
                      key="setAttendance"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Editar
                    </MenuItem>

                    <MenuItem
                      key="downloadProgram"
                      onClick={(event) => handleMenuItemClick(event, 1)}
                    >
                      Limpiar
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  //COLUMNAS
  const columns = [
    { field: "id", headerName: "Id", filterable: false },
    {
      field: "title",
      headerAlign: "center",
      align: "left",
      flex: 0.3,
      headerName: "TÍTULO",
      minWidth: 250,
      headerClassName: "headRow",
    },
    {
      field: "values",
      headerAlign: "center",
      align: "left",
      flex: 0.3,
      headerName: "VALOR",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "content",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "CONTENIDO",
      headerClassName: "headRow",
      renderCell: (params) => <IsChecked item={params} />,
    },

    {
      field: "actions",
      headerAlign: "center",

      headerName: "",
      flex: 0.3,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) => <ActionsRow item={params} />,
    },
  ];

  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          id: false,
        },
      },
      pagination: false,
    };
  };

  return (
    <>
      {courseDetail.isLoading ? (
        <BackCoursesComplementsSkeleton />
      ) : (
        <>
          <Grid
            container
            pt="0px"
            spacing={2}
            sx={{
              heigth: "auto",
              width: "auto",
              marginBottom: 3,
              marginTop: 0,
              paddingBottom: 10,
              marginLeft: 3,
              marginRight: 3,
            }}
            rowSpacing={6}
          >
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                Datos Complementarios
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                slots={{
                  columnMenu: CustomColumnMenu,
                }}
                autoHeight={true}
                rowSelection={false}
                rows={rows}
                columns={columns}
                pagination={false}
                hideFooter={true}
                hideFooterRowCount
                hideFooterPagination
                initialState={initializeDataGrid()}
              />
            </Grid>
          </Grid>
          {setOpenDrawer && (
            <ComplementsDrawer
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              title={dataDrawer?.title}
              id={dataDrawer?.id}
              values={dataDrawer?.values}
              courseDetail={courseDetail}
            />
          )}
        </>
      )}
    </>
  );
};

export default CoursesComplements;
