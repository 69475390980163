import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNewCourseGroup,
  tryCreateCourseGroup,
} from "../../../store/slices/back-course";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ModalMessages from "../../layout/modal-messages";
import BackCoursesCommissionCourseGroupsDrawerSkeleton from "../skeleton/courseGroupsDrawer";
import { useFormik } from "formik";

const validate = (values) => {
  const errors = {};

  if (!values.title || values.title === "") errors.title = "Requerido";

  if (!values.description || values.description === "")
    errors.description = "Requerido";

  if (!values.classRoom || values.classRoom === "")
    errors.classRoom = "Requerido";

  if (!values.maxVacancy || values.maxVacancy === "")
    errors.maxVacancy = "Requerido";

  return errors;
};

const CommissionsDrawer = ({
  openDrawer,
  setOpenDrawer,
  outVacanciesGroup,
  titleGroup,
  descriptionGroup,
  classRoomGroup,
  maxVacancyGroup,
  openDialog,
  setOpenDialog,
  courseGroupId,
  course,
}) => {
  const dispatch = useDispatch();
  const newCourseGroup = useSelector(selectNewCourseGroup);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      courseGroupId: "",
      courseId: "",
      title: "",
      description: "",
      maxVacancy: "",
      outVacancies: "",
      classRoom: "",
    },
    validate,
    onSubmit: () => {
      const trySubmit = async () => {
        const action = await dispatch(tryCreateCourseGroup(formik.values));

        if (action.payload.data.isValid) {
          setOpenDrawer(false);
          setOpenDialog(true);
        }
      };
      trySubmit();
    },
  });

  useEffect(() => {
    formik.setValues({
      courseGroupId: courseGroupId,
      courseId: course,
      title: titleGroup,
      description: descriptionGroup,
      maxVacancy: maxVacancyGroup,
      outVacancies: outVacanciesGroup,
      classRoom: classRoomGroup,
    });
  }, [openDrawer]);

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        PaperProps={{
          sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
        }}
        onClose={() => setOpenDrawer(false)}
        sx={{
          zIndex: 1203,
          width: 500,
          flexShrink: 0,
        }}
      >
        <Grid container p={1}>
          <Grid item xs={10}>
            <Typography
              variant="h4"
              align="left"
              sx={{
                paddingLeft: 1,
                paddingRight: 5,
                paddingBottom: 1,
                paddingTop: 4,
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              Comisiones
            </Typography>
            <Typography
              sx={{
                paddingLeft: 1,
                paddingRight: 5,
                paddingBottom: 3,
                paddingTop: 1,
              }}
            >
              Por favor, complete información del campo requerido
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setOpenDrawer(false)}
            >
              <GridCloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {newCourseGroup.isLoading ? (
          <BackCoursesCommissionCourseGroupsDrawerSkeleton />
        ) : (
          <Grid
            container
            rowSpacing={3}
            spacing={3}
            paddingTop={6}
            paddingLeft={4}
            paddingRight={4}
            paddingBottom={6}
            sx={{ backgroundColor: "white" }}
          >
            <Grid item xs={12}>
              <TextField
                id="title"
                label="Titulo"
                value={formik.values.title}
                onChange={formik.handleChange}
                multiline
                autoSize
                rows={1}
                variant="outlined"
                sx={{ width: "100%" }}
                error={formik.touched.title && formik.errors.title}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                label="Descripción"
                value={formik.values.description}
                onChange={formik.handleChange}
                multiline
                autoSize
                rows={5}
                variant="outlined"
                sx={{ width: "100%" }}
                error={formik.touched.description && formik.errors.description}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="classRoom"
                label="Aula"
                value={formik.values.classRoom}
                onChange={formik.handleChange}
                multiline
                autoSize
                rows={1}
                variant="outlined"
                sx={{ width: "100%" }}
                error={formik.touched.classRoom && formik.errors.classRoom}
                helperText={formik.touched.classRoom && formik.errors.classRoom}
              />
            </Grid>

            <Grid item xs={6} align="center">
              <Typography
                sx={{ color: "black", fontWeight: "bold", fontSize: 14 }}
              >
                Vacantes Max.
              </Typography>
              <Input
                id="maxVacancies"
                value={formik.values.maxVacancy}
                size="small"
                onChange={(e) => {
                  const { value } = e.target;

                  formik.setFieldValue("maxVacancy", value);
                }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 200,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
                error={formik.touched.maxVacancy && formik.errors.maxVacancy}
                helperText={
                  formik.touched.maxVacancy && formik.errors.maxVacancy
                }
              />
            </Grid>

            <Grid item xs={6} align="center">
              <Typography
                sx={{ color: "black", fontWeight: "bold", fontSize: 14 }}
              >
                Vacantes Act.
              </Typography>
              <Chip
                size="small"
                color="primary"
                label={formik.values.outVacancies}
              />
            </Grid>

            <Grid item xs={6} align="right" marginTop={3}>
              <Button variant="contained" onClick={() => formik.handleSubmit()}>
                GUARDAR
              </Button>
            </Grid>
            <Grid item xs={6} marginTop={3}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenDrawer(false)}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        )}
        {newCourseGroup.errors && newCourseGroup.errors.length > 0 && (
          <ModalMessages errors={newCourseGroup.errors} />
        )}
      </Drawer>
      {openDialog && (
        <>
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"La información ha sido guardada con éxito"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
    </>
  );
};

export default CommissionsDrawer;
