import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNewTuple,
  tryNewTuple,
} from "../../../store/slices/maintenances";
import {
  Typography,
  Grid,
  Button,
  Drawer,
  IconButton,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useFormik } from "formik";
import ModalMessages from "../../layout/modal-messages";
import Spinner from "../../layout/spinner";

const validate = (values) => {
  const errors = {};

  if (!values.description) errors.description = "Requerido";
  if (!values.shortDesc) errors.shortDesc = "Requerido";

  return errors;
};

const TupleAdminDrawer = ({
  openDrawer,
  setOpenDrawer,
  description,
  shortDescription,
  id,
  setOpenDialog,
  typeTable,
  typeTableId,
}) => {
  const dispatch = useDispatch();

  //Trae el estado de una fila nueva/editada/eliminada
  const newRow = useSelector(selectNewTuple);

  //Creo el formik
  const formik = useFormik({
    eneableReinitialize: true,
    initialValues: {
      typeTableId: 0,
      key: 0,
      isDeleted: false,
      isActive: true,
      description: "",
      shortDesc: "",
    },
    validate,
    onSubmit: async (values) => {
      const action = await dispatch(tryNewTuple({ model: values }));
      if (action.payload.data.isValid) {
        setOpenDialog(true);
        setOpenDrawer(false);
      }
    },
  });

  //Actualizo el formik
  useEffect(() => {
    openDrawer &&
      formik.setValues({
        typeTableId: typeTableId,
        key: id,
        isDeleted: false,
        isActive: true,
        description: description,
        shortDesc: shortDescription,
      });
  }, [openDrawer]);

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      PaperProps={{
        sx: {
          zIndex: 1203,
          width: 500,
          boxSizing: "border-box",
        },
      }}
      onClose={() => setOpenDrawer(false)}
      sx={{
        zIndex: 1203,
        width: 500,
        flexShrink: 0,
      }}
    >
      <Grid container p={1}>
        <Grid item xs={10}>
          <Typography
            align="left"
            sx={{
              paddingLeft: 2,
              paddingRight: 5,
              paddingBottom: 1,
              paddingTop: 4,
              fontSize: "1.5em",
            }}
          >
            {formik.values.key == 0 ? "Agregar fila" : "Editar fila"}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "end" }}>
          <IconButton
            sx={{ color: "white" }}
            onClick={() => setOpenDrawer(false)}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ paddingLeft: 2, paddingBottom: 3 }}>
          <Typography variant="h7" align="center">
            Por favor, complete información en cada campo que se solicite en el
            formulario
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        spacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        p={2}
        sx={{ backgroundColor: "white" }}
      >
        <>
          <Grid item xs={12} sx={{ paddingLeft: 2 }}>
            <Typography
              align="left"
              color="red"
              sx={{
                fontSize: "1.3em",
                fontWeight: "bold",
              }}
            >
              {typeTable}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              label="Descripción"
              value={formik.values.description}
              onChange={formik.handleChange}
              multiline
              autoSize
              rows={1}
              variant="outlined"
              sx={{ width: "100%" }}
              error={formik.touched.description && formik.errors.description}
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="shortDesc"
              label="Descripción Corta"
              value={formik.values.shortDesc}
              onChange={formik.handleChange}
              multiline
              autoSize
              rows={1}
              variant="outlined"
              sx={{ width: "100%" }}
              error={formik.touched.shortDesc && formik.errors.shortDesc}
              helperText={formik.touched.shortDesc && formik.errors.shortDesc}
            />
          </Grid>
          <Grid item sx={{ width: "100%", textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={formik.handleSubmit}
              disabled={newRow?.isLoading}
              sx={{
                marginTop: 2,
                marginBottom: 2,
                width: 100,
                marginRight: 2,
              }}
            >
              {newRow?.isLoading && <Spinner color="primary" />}
              Guardar
            </Button>
            <Button
              variant="outline"
              color="error"
              disabled={newRow.isLoading}
              sx={{
                border: 2,
                marginTop: 2,
                marginBottom: 2,
                width: 100,
                color: "red",
              }}
              onClick={() => setOpenDrawer(false)}
            >
              Cancelar
            </Button>
          </Grid>
        </>
      </Grid>
      {newRow.errors && newRow.errors.length > 0 && (
        <ModalMessages errors={newRow.errors} />
      )}{" "}
    </Drawer>
  );
};

export default TupleAdminDrawer;
