import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalRegistrationLimit = ({}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography align="center">
          <p size="8px">ADVERTENCIA 🚩</p>
          <strong>
            <h6>Se ha alcanzado el límite de inscripciones</h6>
          </strong>
        </Typography>
        <Button fullWidth color="error" onClick={handleClose}>
          Aceptar
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalRegistrationLimit;
