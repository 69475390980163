import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiStudents from "../../../services/apiStudents";
import * as apiAuth from "../../../services/apiAuth";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    item: {
      id: 0,
      clientId: 0,
      username: "",
      email: "",
      clients: {
        id: 0,
        nationality: 1,
        statusId: 1,
        email: "",
        cliNaturals: [],
        cliSocialEconomics: [],
        cliContacts: [],
        cliAddress: [],
        cliRelations: [],
        cliExternals: [],
        students: [],
      },
      errors: [],
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
      expireAt: Date.now,
    },
    list: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    blockUnblock: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    backofficeAll: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    newBackoffice: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    currentBackoffice: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    exportedBackoffie: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    deletedBackoffie: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.item = { ...state.item, ...payload };
    },
    setUserLoading: (state, { payload }) => {
      state.item.isLoading = payload;
    },
    setCleanErrorsUsers: (state) => {
      state.item.errors = [];
      state.list.errors = [];
      state.blockUnblock.errors = [];
      state.backofficeAll.errors = [];
      state.newBackoffice.errors = [];
      state.currentBackoffice.errors = [];
      state.exportedBackoffie.errors = [];
      state.deletedBackoffie.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryValidateNeo.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(tryValidateNeo.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.item.errors = errors;

      if (payload.data.isValid === true) {
        state.item = { ...payload.data.data };
      }

      state.item.isLoading = false;
    });
    builder.addCase(tryValidateNeo.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });
    builder.addCase(tryPreInscribe.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(tryPreInscribe.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.item.errors = errors;

      if (payload.data.isValid === true) {
        state.item = { ...payload.data.data };
      }

      state.item.isLoading = false;
    });
    builder.addCase(tryPreInscribe.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });

    //Validar Cuil
    builder.addCase(tryValidateStudentCurrentCuil.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(
      tryValidateStudentCurrentCuil.fulfilled,
      (state, { payload }) => {
        state.item.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.item.errors = errors;

        if (payload.data.isValid === true) {
          state.item = { ...payload.data.data };
        }

        state.item.isLoading = false;
      }
    );
    builder.addCase(
      tryValidateStudentCurrentCuil.rejected,
      (state, { payload }) => {
        state.item.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.item.errors = errors;
        state.item.isLoading = false;
      }
    );

    //BLOQUEAR O DESBLOQUEAR UN USUARIO

    builder.addCase(tryBlockUser.pending, (state) => {
      state.blockUnblock.status = "loading";
      state.blockUnblock.isLoading = true;
      state.blockUnblock.errors = [];
    });
    builder.addCase(tryBlockUser.fulfilled, (state, { payload }) => {
      state.blockUnblock.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.blockUnblock.errors = errors;

      if (payload.data.isValid === true) {
        state.blockUnblock = { ...payload.data.data };
      }

      state.blockUnblock.isLoading = false;
    });
    builder.addCase(tryBlockUser.rejected, (state, { payload }) => {
      state.blockUnblock.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.blockUnblock.errors = errors;
      state.blockUnblock.isLoading = false;
    });

    //TRAE EL DETALLE DE LOS USUARIOS DE BACKOFFICE
    builder.addCase(tryGetUsersAll.pending, (state) => {
      state.backofficeAll.status = "loading";
      state.backofficeAll.isLoading = true;
      state.backofficeAll.errors = [];
    });
    builder.addCase(tryGetUsersAll.fulfilled, (state, { payload }) => {
      state.backofficeAll.status = "succeeded";

      const errors = payload.isValid ? payload.errors : [];
      state.backofficeAll.errors = errors;

      if (payload.isValid === true) {
        state.backofficeAll.data = [...payload.data];
      }

      state.backofficeAll.isLoading = false;
    });
    builder.addCase(tryGetUsersAll.rejected, (state, { payload }) => {
      state.backofficeAll.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.backofficeAll.errors = errors;
      state.backofficeAll.isLoading = false;
    });

    //CARGA UN NUEVO USUARIO DE BACKOFFICE
    builder.addCase(tryPostNewUser.pending, (state) => {
      state.newBackoffice.status = "loading";
      state.newBackoffice.isLoading = true;
      state.newBackoffice.errors = [];
    });
    builder.addCase(tryPostNewUser.fulfilled, (state, { payload }) => {
      state.newBackoffice.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.newBackoffice.errors = errors;

      if (payload.data.isValid === true) {
        state.newBackoffice.data = { ...payload.data.data };
      }

      state.newBackoffice.isLoading = false;
    });
    builder.addCase(tryPostNewUser.rejected, (state, { payload }) => {
      state.newBackoffice.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.newBackoffice.errors = errors;
      state.newBackoffice.isLoading = false;
    });

    //Trae el detalle de un usuario
    builder.addCase(tryGetBackofficeUser.pending, (state) => {
      state.currentBackoffice.status = "loading";
      state.currentBackoffice.isLoading = true;
      state.currentBackoffice.errors = [];
    });
    builder.addCase(tryGetBackofficeUser.fulfilled, (state, { payload }) => {
      state.currentBackoffice.status = "succeeded";

      const errors = payload.isValid ? payload.errors : [];
      state.currentBackoffice.errors = errors;

      if (payload.isValid === true) {
        state.currentBackoffice.data = { ...payload.data };
      }

      state.currentBackoffice.isLoading = false;
    });
    builder.addCase(tryGetBackofficeUser.rejected, (state, { payload }) => {
      state.currentBackoffice.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.currentBackoffice.errors = errors;
      state.currentBackoffice.isLoading = false;
    });

    //EXPORTA EL DETALLE DE TODOS LOS USUARIOS DE BACKOFFICE

    builder.addCase(tryExportBackofficeAll.pending, (state) => {
      state.exportedBackoffie.status = "loading";
      state.exportedBackoffie.isLoading = true;
      state.exportedBackoffie.errors = [];
    });
    builder.addCase(tryExportBackofficeAll.fulfilled, (state, { payload }) => {
      state.exportedBackoffie.status = "succeeded";

      const errors = payload.isValid ? payload.errors : [];
      state.exportedBackoffie.errors = errors;

      if (payload.isValid === true) {
        state.exportedBackoffie.data = { ...payload.data };
      }

      state.exportedBackoffie.isLoading = false;
    });
    builder.addCase(tryExportBackofficeAll.rejected, (state, { payload }) => {
      state.exportedBackoffie.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.exportedBackoffie.errors = errors;
      state.exportedBackoffie.isLoading = false;
    });

    //ELIMINA UN USUARIO DE BACKOFFICE
    builder.addCase(tryDeleteBackoffice.pending, (state) => {
      state.deletedBackoffie.status = "loading";
      state.deletedBackoffie.isLoading = true;
      state.deletedBackoffie.errors = [];
    });
    builder.addCase(tryDeleteBackoffice.fulfilled, (state, { payload }) => {
      state.deletedBackoffie.status = "succeeded";

      const errors = payload.isValid ? payload.errors : [];
      state.deletedBackoffie.errors = errors;

      if (payload.isValid === true) {
        state.deletedBackoffie.data = { ...payload.data };
      }

      state.deletedBackoffie.isLoading = false;
    });
    builder.addCase(tryDeleteBackoffice.rejected, (state, { payload }) => {
      state.deletedBackoffie.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.deletedBackoffie.errors = errors;
      state.deletedBackoffie.isLoading = false;
    });
  },
});

export const { setUser, setUserLoading, setCleanErrorsUsers } =
  usersSlice.actions;

export default usersSlice.reducer;

export const tryCleanErrorsUsers = () => (dispatch) => {
  dispatch(setCleanErrorsUsers());
};

export const selectUser = (state) => state.users.item;

export const selectUsers = (state) => state.users.list;

export const selectBlocked = (state) => state.users.blockUnblock;

export const selectUsersAll = (state) => state.users.backofficeAll;

export const selectNewUser = (state) => state.users.newBackoffice;

export const selectCurrentBackoffice = (state) => state.users.currentBackoffice;

export const selectExportedBackoffice = (state) =>
  state.users.exportedBackoffie;

export const selectDeletedBackoffice = (state) => state.users.deletedBackoffie;

export const tryNewUser = () => (dispatch) => {
  dispatch(setUserLoading(true));

  const user = {
    id: 0,
    clientId: 0,
    username: "",
    email: "",
    clients: {
      id: 0,
      nationality: 1,
      statusId: 1,
      email: "",
      cliNaturals: [],
      cliSocialEconomics: [],
      cliContacts: [],
      cliAddress: [],
      cliRelations: [],
      cliExternals: [],
      students: [],
    },
    errors: [],
  };

  dispatch(setUser(user));
  dispatch(setUserLoading(false));
};

export const tryUpdateUser = (user) => (dispatch) => {
  dispatch(setUserLoading(true));
  dispatch(setUser(user));
  dispatch(setUserLoading(false));
};

export const tryValidateNeo = createAsyncThunk(
  "users/tryValidateNeo",
  async (cuil, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getValidateNeo(cuil);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryPreInscribe = createAsyncThunk(
  "users/tryPreInscribe",
  async (user, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.postPreInscribe(user);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryValidateStudentCurrentCuil = createAsyncThunk(
  "users/tryValidateStudentCurrentCuil",
  async (cuil, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.validateStudentCurrentCuil(cuil);

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryBlockUser = createAsyncThunk(
  "users/tryBlockUser",
  async (userId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiAuth.blockUser(userId);

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetUsersAll = createAsyncThunk(
  "users/tryGetUsersAll",
  async (_, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiAuth.getUsersAll();

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryPostNewUser = createAsyncThunk(
  "users/tryPostNewUser",
  async (request, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiAuth.postNewBackofficeUser(request);

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetBackofficeUser = createAsyncThunk(
  "users/tryGetUser",
  async (userId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiAuth.getBackofficeUser(userId);

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryExportBackofficeAll = createAsyncThunk(
  "users/tryExportBackofficeAll",
  async (_, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiAuth.getExportBackofficeAll();

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryDeleteBackoffice = createAsyncThunk(
  "users/tryDeleteBackoffice",
  async (userId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiAuth.deleteBackoffice(userId);

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
