import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Menu,
  Typography,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  MenuItem,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { tryLogout } from "../../../store/slices/currentUser";

const UserInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [open, setOpen] = React.useState(false);

  const onCloseDialog = () => {
    setOpen(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onClickProfile = () => {
    navigate("/profile");

    setAnchorElUser(null);
  };

  const onClickLogOut = () => {
    setOpen(true);
    setAnchorElUser(null);
  };

  const logOut = async () => {
    const action = await dispatch(tryLogout());

    if (action.payload.data.isValid) {
      navigate("/login");
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 0 }}>
        <IconButton
          onClick={() => onClickLogOut()}
          sx={{ p: 0, color: "white" }}
        >
          <LogoutIcon />
        </IconButton>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem key="profile" onClick={() => onClickProfile()}>
            <Typography textAlign="center">Perfil</Typography>
          </MenuItem>
          <MenuItem key="logout" onClick={() => onClickLogOut()}>
            <Typography textAlign="center">Salir</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Dialog
        open={open}
        onClose={onCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Confirma salir del sistema?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={onCloseDialog}>Cancelar</Button>
          <Button onClick={() => logOut()} autoFocus>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserInfo;
