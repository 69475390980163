import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiStudents from "../../../services/apiStudents";
import * as apiAuth from "../../../services/apiAuth";

export const studentSlice = createSlice({
  name: "student",
  initialState: {
    item: {
      studentId: 0,
      clientId: 0,
      nickName: "",
      typeStudentId: 0,
      clients: {
        id: 0,
        nationality: 1,
        statusId: 1,
        email: "",
        cliNaturals: [],
        cliSocialEconomics: [],
        cliContacts: [],
        cliAddress: [],
        cliRelations: [],
        cliExternals: [],
        students: [],
      },
      isLoading: false,
      errors: [],
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
      expireAt: Date.now,
    },
  },
  reducers: {
    setStudent: (state, { payload }) => {
      state.item = { ...payload };
    },
    setLoading: (state, { payload }) => {
      state.item.isLoading = payload;
    },
    setCleanErrorsStudents: (state) => {
      state.item.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryGetStudent.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(tryGetStudent.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      if (payload.isValid === true) {
        state.item = { ...payload.data };
      }

      state.item.errors = payload.isValid ? payload.errors : [];

      state.item.isLoading = false;
    });
    builder.addCase(tryGetStudent.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });

    builder.addCase(tryUpdateStudentData.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });

    builder.addCase(tryUpdateStudentData.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      if (payload.isValid) state.item = { ...payload.data };

      state.item.errors = payload.isValid ? payload.errors : [];

      state.item.isLoading = false;
    });

    builder.addCase(tryUpdateStudentData.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });

    builder.addCase(tryUpdateAvatar.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });

    builder.addCase(tryUpdateAvatar.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      if (payload.isValid) {
        state.item.avatar = payload.data.avatar;
        state.item.clients.avatar = payload.data.avatar;
      }

      state.item.errors = payload.isValid ? payload.errors : [];

      state.item.isLoading = false;
    });

    builder.addCase(tryUpdateAvatar.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.item.errors = errors;
      state.item.isLoading = false;
    });
  },
});

export const { setStudent, setLoading, setCleanErrorsStudents } =
  studentSlice.actions;

export default studentSlice.reducer;

export const tryCleanErrorsStudents = () => (dispatch) => {
  dispatch(setCleanErrorsStudents());
};

export const selectStudent = (state) => state.student.item;

export const tryGetStudent = createAsyncThunk(
  "student/tryGetStudent",
  async (businessId, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiStudents.getStudentInformationById(businessId);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryUpdateStudentData = createAsyncThunk(
  "student/tryUpdateStudentData",
  async (request, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiStudents.putStudentInformationById(request);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryUpdateAvatar = createAsyncThunk(
  "student/tryUpdateAvatar",
  async ({ userId, image }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiAuth.updateAvatar(userId, image);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
