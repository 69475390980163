import moment from "moment";

export const FormatDate = (date, especification = null, format = null) => {
  if (format && especification) return moment(date, especification).format(format); //prettier-ignore

  if(!format && especification) return moment(date, especification); //prettier-ignore

  if(format && !especification) return moment(date).format(format); //prettier-ignore

  return moment();
};
