import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTypeTable,
  selectMaintenances,
} from "../../store/slices/maintenances";
import { selectCurrentUser } from "../../store/slices/currentUser";
import { selectBlocked, tryBlockUser } from "../../store/slices/users";
import {
  tryGetStudent,
  selectStudent,
  tryUpdateStudentData,
  tryUpdateAvatar,
} from "../../store/slices/students";
import {
  selectFileDocument,
  tryGetFileDocument,
} from "../../store/slices/backoffice-students";
import { useDropzone } from "react-dropzone";
import {
  Divider,
  CardContent,
  Typography,
  Avatar,
  Button,
  Grid,
  TextField,
  Chip,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  CloudUploadOutlined,
  Cancel,
  Save,
  CameraAlt,
  Receipt,
  Badge,
  UploadFileOutlined,
} from "@mui/icons-material";
import { IsValidEmail } from "../../services/functions/validator";
import ModalMessages from "../layout/modal-messages";
import StudentSkeleton from "./skeleton";
import { NumericFormat, PatternFormat } from "react-number-format";
import convertToSelectList from "../layout/functions/convert-to-select-list";
import { useFormik } from "formik";
import Spinner from "../layout/spinner";

const validate = (values) => {
  const errors = {};

  const {
    email,
    fullAddress,
    cellPhone,
    telPhone,
    cellPhoneAreaCode,
    telPhoneAreaCode,
    emailCopy,
    unionCard,
    isFamiliar,
  } = values;

  if (!email) errors.email = "Requerido";

  if (email !== emailCopy)
    errors.email = "Los correos electrónicos no coinciden";

  if (!IsValidEmail(email))
    errors.email = "Formato de correo electrónico invalido";

  if (!fullAddress) errors.fullAddress = "Requerido";

  if (!cellPhone) errors.cellPhone = "Requerido";

  if (cellPhone.length < 6) 
    errors.cellPhone ="El número debe estar comprendido entre 6 y 10 caracteres"; //prettier-ignore

  if (!telPhone) errors.telPhone = "Requerido";

  if (telPhone.length < 6)
    errors.telPhone = "El número debe estar comprendido entre 6 y 10 caracteres"; //prettier-ignore

  if (!cellPhoneAreaCode) errors.cellPhoneAreaCode = "Requerido";

  if (!telPhoneAreaCode) errors.telPhoneAreaCode = "Requerido";

  if (isFamiliar && !!!unionCard) errors.unionCard = "Requerido";

  return errors;
};

const StudentData = ({ userId }) => {
  const { roleId } = useSelector(selectCurrentUser);
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [enableEdit, setEnableEdit] = useState(false);
  const [enableEditProfilePicture, setEnableEditProfilePicture] =
    useState(false);
  const fileDocument = useSelector(selectFileDocument);

  const dispatch = useDispatch();

  //MANEJO DE DIALOGS DE CONFIRMACION
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const typeTables = useSelector(selectMaintenances);
  const user = useSelector(selectStudent);
  const blockedState = useSelector(selectBlocked);

  const { isLoading, errors } = user;

  const {
    cliSocialEconomics,
    cliNaturals,
    cliAddress,
    cliRelations,
    nationalities,
    students,
  } = user.clients;

  const fullName = `${cliNaturals.firstName} ${cliNaturals.lastName}`;

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      state: 0,
      type: "",
      username: "",
      emailCopy: "",
      email: "",
      firstName: "",
      lastName: "",
      cuit: "",
      cellPhoneAreaCode: 0,
      cellPhone: 0,
      telPhoneAreaCode: 0,
      telPhone: 0,
      gender: 0,
      nationality: 0,
      sectional: 0,
      conditionUTEDYC: 0,
      conditionWork: 0,
      relationAffiliate: 0,
      relationSectional: 0,
      academicLevel: 0,
      province: 0,
      fullAddress: "",
      notes: "",
      statusDoc: "",
      unionCard: "S/I",
      isFamiliar: "",
      file: null,
      frontDocument: "",
      backDocument: "",
    },
    validate,
    onSubmit: async (values) => {
      let request = {};

      request.id = user.id;
      request.clientId = user.clientId;
      request.email = values.email;
      request.avatar = user.avatar ?? file;
      request.username = user.username;

      request.clients = {};
      request.clients.email = values.email;
      request.clients.notes = values.notes;
      request.clients.nationality = values.nationality;
      request.clients.cliContacts = [];
      request.clients.cliDocuments = [];
      request.clients.cliRelations = [];

      cliRelations.map((x) => {
        let relation = { ...x };
        //DNI FRENTE
        if (x.clientIdRel === -999996) {
          if (values.frontDocument)
            relation.additionalInfo = values.frontDocument;
        }
        //DNI DORSO
        if (x.clientIdRel === -999995) {
          if (values.backDocument)
            relation.additionalInfo = values.backDocument;
        }
        if (
          x.clientIdRel === -999997 ||
          x.clientIdRel === -999998 ||
          x.clientIdRel === -999999
        ) {
          relation.relSectional = [4, 5].includes(values.conditionUTEDYC)
            ? values.sectional
            : 0;
          relation.unionCard = [4].includes(values.conditionUTEDYC)
            ? String(values.cardNumber) ?? "S/I"
            : "S/I";
          values.file && (relation.additionalInfo = values.file);
        }

        request.clients.cliRelations.push(relation);
      });

      request.clients.cliSocialEconomics = {};
      request.clients.cliNaturals = {
        ...cliNaturals,
        firstName: values.firstName,
        lastName: values.lastName,
      };
      request.clients.students = { ...students };
      request.clients.students.statusDoc = values.statusDoc;
      request.clients.cliAddress = cliAddress.map((x) => {
        let address = { ...x };

        if (x.typeAddress === 2) {
          address.fullAddress = values.fullAddress;
          address.province = values.province;
          address.phone = values.cellPhone;
          address.areaCode = values.cellPhoneAreaCode;
        }

        if (x.typeAddress === 1) {
          address.fullAddress = values.fullAddress;
          address.province = values.province;
          address.phone = values.telPhone;
          address.areaCode = values.telPhoneAreaCode;
        }
        return address;
      });

      request.clients.cliSocialEconomics.socialEconomicsId =
        cliSocialEconomics.socialEconomicsId;
      request.clients.cliSocialEconomics.sectional = null;
      request.clients.cliSocialEconomics.sectionalId = null;
      request.clients.cliSocialEconomics.academicId = values.academicLevel;
      request.clients.cliSocialEconomics.birthDate =
        cliSocialEconomics.birthDate;
      request.clients.cliSocialEconomics.clientId = cliSocialEconomics.clientId;
      request.clients.cliSocialEconomics.genderId = values.gender;
      request.clients.cliSocialEconomics.situationId =
        cliSocialEconomics.situationId;
      request.clients.cliSocialEconomics.labouralSituationId =
        values.conditionWork;

      await dispatch(tryUpdateStudentData(request));
    },
  });

  const handleCancel = () => {
    initializeForm();
    setEnableEdit(!enableEdit);
    setFile();
    setPreview();
  };

  const initializeForm = () => {
    formik.setValues({
      state: user.clients.statuscli.id,
      type: user.clients.students.typeStudent.description,
      username: user.username,
      firstName: cliNaturals.firstName,
      lastName: cliNaturals.lastName,
      emailCopy: user.clients.email,
      email: user.clients.email,
      cuit: user.clients.cliDocuments?.find((x) => x.typeDocumentId === 2)
        .document,
      cellPhoneAreaCode: cliAddress?.find((x) => x.tblTypeAddress.id === 2)
        .areaCode,
      cellPhone: cliAddress?.find((x) => x.tblTypeAddress.id === 2).phone,
      telPhoneAreaCode: cliAddress?.find((x) => x.tblTypeAddress.id === 1)
        .areaCode,
      telPhone: cliAddress?.find((x) => x.tblTypeAddress.id === 1).phone,
      gender: cliSocialEconomics.genderId,
      nationality: user.clients.nationality,
      sectional: user.sectional,
      conditionUTEDYC: cliSocialEconomics.situation.description,
      conditionWork: cliSocialEconomics.labouralSituationId,
      academicLevel: cliSocialEconomics.academicId,
      province: cliAddress[0]?.province,
      fullAddress: cliAddress[0]?.fullAddress,
      notes: user.clients.notes ? user.clients.notes : "",
      statusDoc: user.clients.students.statusDoc,
      unionCard: [4].includes(cliSocialEconomics.situation.id)
        ? cliRelations.find((x) => x.clientIdRel === -999999)?.unionCard ??
          "S/I"
        : "S/I",
      isFamiliar: [4].includes(cliSocialEconomics.situation.id) ? true : false,
    });
  };

  const fetchData = async () => {
    await dispatch(tryGetStudent(userId));
  };

  useEffect(() => {
    dispatch(fetchTypeTable(49)); // Gender
    dispatch(fetchTypeTable(45)); // Academic
    dispatch(fetchTypeTable(17)); // Provinces
    dispatch(fetchTypeTable(19)); // Sectionals
    dispatch(fetchTypeTable(16)); // Nationalities
    dispatch(fetchTypeTable(20)); // Situation
    dispatch(fetchTypeTable(14)); // LabouralSituation
    dispatch(fetchTypeTable(18)); // Relationship
    dispatch(fetchTypeTable(51)); // Topics
    dispatch(fetchTypeTable(23)); // TBLSTATUSCLIS
    dispatch(fetchTypeTable(62)); // TBLSTATUSADDITIONALDOCS
    fetchData();
  }, []);

  useEffect(() => {
    user.clientId !== 0 && initializeForm(user);
  }, [user]);

  //DESCARGA EL DOCUMENTO ADJUNTO DE UN FAMILIAR O EMPLEADO
  const getFileDocument = async () => {
    const action = await dispatch(tryGetFileDocument(user.clientId));
    if (action.payload.data.isValid) {
      action.payload.data.data.map((x) => {
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='" + x.file + "'></iframe>"
        );
      });
    }
  };

  //BLOQUEAR/DESBLOQUAR USUARIO
  const blockUser = async (userId) => {
    const action = await dispatch(tryBlockUser(userId));
    if (action.payload.isValid) setOpenDialog(true);
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 5000000,
    onDropAccepted: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        setFile(base64data);
        setPreview(URL.createObjectURL(file));
      };
    },
  });

  const useCustomDropzone = (fieldName, onDropAccepted) => {
    const {
      getRootProps,
      getInputProps,
      acceptedFiles, // Agrega acceptedFiles aquí
    } = useDropzone({
      noKeyboard: true,
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpeg", ".jpg"],
        "application/pdf": [".pdf"],
      },
      maxFiles: 1,
      multiple: false,
      maxSize: 10000000,
      onDropAccepted: onDropAccepted,
    });

    const files = acceptedFiles.map((file) => (
      <ListItem key={file.path}>
        <ListItemIcon>
          <UploadFileOutlined color="primary" />
        </ListItemIcon>
        <ListItemText primary={file.path} />
      </ListItem>
    ));

    return {
      getRootProps,
      getInputProps,
      files,
    };
  };

  const {
    getRootProps: getRootProps1,
    getInputProps: getInputProps1,
    files: files1,
  } = useCustomDropzone("file", (acceptedFiles1) => {
    const file = acceptedFiles1[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result;
      formik.setFieldValue("file", base64data);
    };
  });

  const {
    getRootProps: getRootProps2,
    getInputProps: getInputProps2,
    files: files2,
  } = useCustomDropzone("frontDocument", (acceptedFiles2) => {
    const file2 = acceptedFiles2[0];
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    reader.onloadend = () => {
      const base64data = reader.result;
      formik.setFieldValue("frontDocument", base64data);
    };
  });

  const {
    getRootProps: getRootProps3,
    getInputProps: getInputProps3,
    files: files3,
  } = useCustomDropzone("backDocument", (acceptedFiles3) => {
    const file3 = acceptedFiles3[0];
    const reader = new FileReader();
    reader.readAsDataURL(file3);
    reader.onloadend = () => {
      const base64data = reader.result;
      formik.setFieldValue("backDocument", base64data);
    };
  });

  const thumbs = preview && (
    <div style={thumb}>
      <div style={thumbInner}>
        <img
          src={preview}
          style={img}
          alt="Imagen de perfil"
          onLoad={() => {
            URL.revokeObjectURL(preview);
          }}
        />
      </div>
    </div>
  );

  //OBTIENE EL NUMERO DE AFILIADO
  const affiliateNumber =
    user.clients.cliDocuments?.filter((x) => x.typeDocumentId == 4) || false;

  const handleEditDetail = () => {
    if (enableEdit) formik.handleSubmit(); //Si el botón es de guardar se ejecuta el submit
    if (formik.isValid) setEnableEdit(!enableEdit); //Si el formulario es válido se cambia el estado del formulario, caso contrario se sigue en modo de edición para mostrar los errores
  };

  const handleEditProfilePicture = () => {
    enableEditProfilePicture &&
      dispatch(tryUpdateAvatar({ userId: user.id, image: file }));
    setEnableEditProfilePicture(!enableEditProfilePicture);
  };

  const handleCancelEditProfile = () => {
    setEnableEditProfilePicture(!enableEditProfilePicture);
    setFile();
    setPreview();
  };

  //Obtiene el detalle de la documentacion pendiente
  const getStatusDoc = () => {
    const status = typeTables.TBLSTATUSADDITIONALDOCS.data.find(
      (x) => x.id == user.clients.students.statusDoc
    );
    if (status) return status.description;
    if (!status) return "";
  };

  //Obtiene el color del chip
  const getColorStatusDoc = () => {
    if (
      user.clients.students.statusDoc == 1 ||
      user.clients.students.statusDoc == 3
    ) {
      return "success";
    }
    if (user.clients.students.statusDoc == 2) {
      return "error";
    }
    return "primary";
  };

  return (
    <>
      {!isLoading && formik.values.state !== 0 ? (
        <>
          {user.clientId !== 0 && (
            <>
              <CardContent>
                {enableEditProfilePicture ? (
                  <Box
                    sx={{
                      border: "2px #1d4d72 solid",
                      borderRadius: 2,
                    }}
                  >
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <Grid
                          container
                          sx={{
                            ":hover": {
                              cursor: "pointer",
                            },
                          }}
                          // direction={["row", "column"]}
                          columns={{ xs: 12, sm: 8, md: 4 }}
                        >
                          <Grid
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CloudUploadOutlined
                              color="primary"
                              fontSize="large"
                            />
                          </Grid>
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              color="primary"
                              align="center"
                              variant="subtitle2"
                              sx={{ fontWeight: 700, paddingTop: 5 }}
                            >
                              Seleccione un archivo o arrástrelo aquí
                            </Typography>
                            <Typography
                              color="primary"
                              align="center"
                              variant="subtitle2"
                              sx={{ paddingTop: 5, paddingBottom: 2 }}
                            >
                              Imagen con un tamaño no mayor a 5 MB.
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ marginTop: 2 }}
                          />
                        </Grid>
                      </div>
                      <aside style={thumbsContainer}>{thumbs}</aside>
                    </section>
                  </Box>
                ) : (
                  <>
                    <Avatar
                      sx={{
                        width: 135,
                        height: 135,
                        margin: "25px 0px 25px",
                      }}
                      alt={`Foto de Perfil de ${cliNaturals.firstName} ${cliNaturals.lastName}`}
                      src={user.avatar}
                    >{`${cliNaturals.firstName} ${cliNaturals.lastName}`}</Avatar>
                  </>
                )}
                <Grid
                  container
                  spacing={1}
                  rowSpacing={2}
                  pb={2}
                  direction="row"
                >
                  <Grid item xs>
                    <IconButton
                      onClick={() => handleEditProfilePicture()}
                      sx={{ width: "170px", height: "35px" }}
                      variant="contained"
                      color="primary"
                      disabled={enableEditProfilePicture && !preview}
                      disableRipple
                    >
                      {enableEditProfilePicture ? <Save /> : <CameraAlt />}
                    </IconButton>
                    {enableEditProfilePicture && (
                      <IconButton
                        onClick={() => handleCancelEditProfile()}
                        sx={{ width: "170px", height: "35px" }}
                        variant="outlined"
                        color="error"
                        disableRipple
                      >
                        <Cancel />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <Typography sx={{ fontSize: 25, paddingTop: 1 }}>
                  <strong style={{ fontWeight: "bold" }}>{fullName}</strong>
                </Typography>
                <Divider light sx={{ borderColor: "secondary.light" }} />
                <form onSubmit={formik.handleSubmit}>
                  <Grid
                    container
                    pt={2}
                    textAlign="left"
                    spacing={1}
                    direction={["column", "column", "row", "row"]}
                    justifyContent="flex-start"
                  >
                    <Grid item xs={12} md={6} lg={4} p={0}>
                      {enableEdit && (
                        <>
                          <Box display="flex" pb={2}>
                            <Typography fontWeight={700} mr={2} width="150px">
                              Nombre: *
                            </Typography>

                            <TextField
                              required
                              fullWidth
                              id="firstName"
                              size="small"
                              onChange={formik.handleChange}
                              value={formik.values.firstName}
                              error={
                                formik.touched.firstName &&
                                Boolean(formik.errors.firstName)
                              }
                              helperText={
                                formik.touched.firstName &&
                                formik.errors.firstName
                              }
                              autoComplete="off"
                            />
                          </Box>
                          <Box display="flex" pb={2}>
                            <Typography fontWeight={700} mr={2} width="150px">
                              Apellido: *
                            </Typography>
                            <TextField
                              required
                              fullWidth
                              id="lastName"
                              size="small"
                              onChange={formik.handleChange}
                              value={formik.values.lastName}
                              error={
                                formik.touched.lastName &&
                                Boolean(formik.errors.lastName)
                              }
                              helperText={
                                formik.touched.lastName &&
                                formik.errors.lastName
                              }
                              autoComplete="off"
                            />
                          </Box>
                        </>
                      )}

                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Estado:
                        </Typography>
                        <Chip
                          size="small"
                          label={typeTables.TBLSTATUSCLIS.data.map((x) => {
                            if (x.id == formik.values.state)
                              return x.description;
                          })}
                          color={formik.values.state == 1 ? "success" : "error"}
                        />
                      </Box>
                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Tipo de Alumno:
                        </Typography>
                        <Chip
                          size="small"
                          label={formik.values.type}
                          color="success"
                        />
                      </Box>
                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Cond. frente UTEDYC:
                        </Typography>
                        <Typography>{formik.values.conditionUTEDYC}</Typography>
                      </Box>
                      {[4].includes(cliSocialEconomics.situation.id) && (
                        <>
                          <Box display="flex" pb={2}>
                            <Typography fontWeight={700} mr={2} width="150px">
                              Nro carnet del familiar afiliado:
                            </Typography>
                            {roleId == 1 && enableEdit ? (
                              <TextField
                                required
                                fullWidth
                                id="unionCard"
                                size="small"
                                onChange={formik.handleChange}
                                value={formik.values.unionCard}
                                disabled={!enableEdit}
                                error={
                                  formik.touched.unionCard &&
                                  Boolean(formik.errors.unionCard)
                                }
                                helperText={
                                  formik.touched.unionCard &&
                                  formik.errors.unionCard
                                }
                                autoComplete="off"
                              />
                            ) : (
                              <Typography>{formik.values.unionCard}</Typography>
                            )}
                          </Box>
                        </>
                      )}
                      {[4, 5].includes(cliSocialEconomics.situation.id) && (
                        <>
                          <Box display="flex" pb={2}>
                            <Typography fontWeight={700} mr={2} width="150px">
                              Relación con el afiliado:
                            </Typography>

                            <Typography>
                              {[4].includes(cliSocialEconomics.situation.id) &&
                                cliRelations.find(
                                  (x) => x.clientIdRel === -999999
                                )?.tblRelationship?.description}
                              {[5].includes(cliSocialEconomics.situation.id) &&
                                cliRelations.find(
                                  (x) => x.clientIdRel === -999998
                                )?.tblRelationship?.description}
                            </Typography>
                          </Box>
                          <Box display="flex" pb={2}>
                            <Typography fontWeight={700} mr={2} width="150px">
                              Seccional Relacionado:
                            </Typography>
                            <Typography>
                              {[4].includes(cliSocialEconomics.situation.id) &&
                                cliRelations.find(
                                  (x) => x.clientIdRel === -999999
                                )?.tblSectional?.description}
                              {[5].includes(cliSocialEconomics.situation.id) &&
                                cliRelations.find(
                                  (x) => x.clientIdRel === -999998
                                )?.tblSectional?.description}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Nacionalidad:
                        </Typography>

                        {enableEdit ? (
                          <FormControl fullWidth>
                            <Select
                              name="nationality"
                              id="nationality"
                              value={formik.values.nationality}
                              size="small"
                              onChange={formik.handleChange}
                              disabled={!enableEdit}
                              error={
                                formik.touched.nationality &&
                                Boolean(formik.errors.nationality)
                              }
                            >
                              {typeTables.TBLNATIONALITYS
                                ? convertToSelectList(
                                    typeTables.TBLNATIONALITYS.data
                                  ).map((x) => (
                                    <MenuItem key={x.value} value={x.value}>
                                      {x.text}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          </FormControl>
                        ) : (
                          <Typography>{nationalities.description}</Typography>
                        )}
                      </Box>
                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          CUIL:
                        </Typography>

                        <Typography>
                          <PatternFormat
                            id="cuil"
                            customInput={TextField}
                            format="##-########-#"
                            mask="_"
                            size="small"
                            variant="standard"
                            disabled={true}
                            onChange={formik.handleChange}
                            value={formik.values.cuit}
                            required
                          />
                        </Typography>
                      </Box>
                      {affiliateNumber.length !== 0 && (
                        <>
                          <Box display="flex" pb={2}>
                            <Typography fontWeight={700} mr={2} width="150px">
                              Nro. de afiliado:
                            </Typography>
                            <Typography>
                              {affiliateNumber[0].document}
                            </Typography>
                          </Box>
                        </>
                      )}
                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Email: *
                        </Typography>

                        {enableEdit ? (
                          <TextField
                            required
                            fullWidth
                            id="email"
                            size="small"
                            onChange={formik.handleChange}
                            type="email"
                            value={formik.values.email}
                            onPaste={(event) => event.preventDefault()}
                            disabled={!enableEdit}
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                            autoComplete="off"
                          />
                        ) : (
                          <Typography>{formik.values.email}</Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      {enableEdit && (
                        <>
                          <Box display="flex" pb={2}>
                            <Typography fontWeight={700} mr={2} width="150px">
                              Email Confirmación: *
                            </Typography>

                            <TextField
                              required
                              fullWidth
                              id="emailCopy"
                              type="email"
                              size="small"
                              onPaste={(event) => event.preventDefault()}
                              onChange={formik.handleChange}
                              value={formik.values.emailCopy}
                              disabled={!enableEdit}
                              autoComplete="off"
                            />
                          </Box>
                        </>
                      )}

                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Dirección: *
                        </Typography>

                        {enableEdit ? (
                          <TextField
                            id="fullAddress"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.fullAddress}
                            error={
                              formik.touched.fullAddress &&
                              Boolean(formik.errors.fullAddress)
                            }
                            helperText={
                              formik.touched.fullAddress &&
                              formik.errors.fullAddress
                            }
                            autoComplete="off"
                          />
                        ) : (
                          <Typography>{formik.values.fullAddress}</Typography>
                        )}
                      </Box>
                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Provincia:
                        </Typography>

                        {enableEdit ? (
                          <FormControl fullWidth>
                            <Select
                              name="province"
                              id="province"
                              value={formik.values.province}
                              size="small"
                              disabled={!enableEdit}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.province &&
                                Boolean(formik.errors.province)
                              }
                            >
                              {typeTables.TBLPROVINCES &&
                                convertToSelectList(
                                  typeTables.TBLPROVINCES.data
                                ).map((x) => (
                                  <MenuItem key={x.value} value={x.value}>
                                    {x.text}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Typography>
                            {cliAddress[0].tblProvinces.description}
                          </Typography>
                        )}
                      </Box>
                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Celular: *
                        </Typography>

                        {enableEdit ? (
                          <>
                            <FormControl>
                              <TextField
                                name="cellPhoneAreaCode"
                                label="Cód. Área"
                                variant="outlined"
                                size="small"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxlength: "4",
                                }}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  if (value.length > 0 && value[0] === "0") {
                                    formik.setFieldValue(
                                      "cellPhoneAreaCode",
                                      value.substring(1)
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      "cellPhoneAreaCode",
                                      value
                                    );
                                  }
                                }}
                                value={formik.values.cellPhoneAreaCode}
                                error={
                                  formik.touched.cellPhoneAreaCode &&
                                  Boolean(formik.errors.cellPhoneAreaCode)
                                }
                                helperText={
                                  formik.touched.cellPhoneAreaCode &&
                                  formik.errors.cellPhoneAreaCode
                                }
                                autoComplete="off"
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <NumericFormat
                                id="cellPhone"
                                label="Número"
                                variant="outlined"
                                size="small"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxlength: "10",
                                }}
                                customInput={TextField}
                                onChange={formik.handleChange}
                                value={formik.values.cellPhone}
                                error={
                                  formik.touched.cellPhone &&
                                  Boolean(formik.errors.cellPhone)
                                }
                                helperText={
                                  formik.touched.cellPhone &&
                                  formik.errors.cellPhone
                                }
                                autoComplete="off"
                              />
                            </FormControl>
                          </>
                        ) : (
                          <Typography>
                            {`${formik.values.cellPhoneAreaCode} ${formik.values.cellPhone}`}
                          </Typography>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Teléfono: *
                        </Typography>

                        {enableEdit ? (
                          <>
                            <FormControl>
                              <TextField
                                id="telPhoneAreaCode"
                                label="Cód. Área"
                                variant="outlined"
                                size="small"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxlength: "4",
                                }}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  if (value.length > 0 && value[0] === "0") {
                                    formik.setFieldValue(
                                      "telPhoneAreaCode",
                                      value.substring(1)
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      "telPhoneAreaCode",
                                      value
                                    );
                                  }
                                }}
                                value={formik.values.telPhoneAreaCode}
                                error={
                                  formik.touched.telPhoneAreaCode &&
                                  Boolean(formik.errors.telPhoneAreaCode)
                                }
                                helperText={
                                  formik.touched.telPhoneAreaCode &&
                                  formik.errors.telPhoneAreaCode
                                }
                                autoComplete="off"
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <NumericFormat
                                id="telPhone"
                                label="Número"
                                variant="outlined"
                                size="small"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxlength: "10",
                                }}
                                fullWidth
                                customInput={TextField}
                                onChange={formik.handleChange}
                                value={formik.values.telPhone}
                                error={
                                  formik.touched.telPhone &&
                                  Boolean(formik.errors.telPhone)
                                }
                                helperText={
                                  formik.touched.telPhone &&
                                  formik.errors.telPhone
                                }
                                autoComplete="off"
                              />
                            </FormControl>
                          </>
                        ) : (
                          <Typography>
                            {`${formik.values.telPhoneAreaCode} ${formik.values.telPhone}`}
                          </Typography>
                        )}
                      </Box>

                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Género:
                        </Typography>

                        {enableEdit ? (
                          <FormControl fullWidth>
                            <Select
                              name="gender"
                              id="gender"
                              value={formik.values.gender}
                              a
                              disabled={!enableEdit}
                              size="small"
                              onChange={formik.handleChange}
                            >
                              {typeTables.TBLGENDER &&
                                convertToSelectList(
                                  typeTables.TBLGENDER.data
                                ).map((x) => (
                                  <MenuItem key={x.value} value={x.value}>
                                    {x.text}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Typography>
                            {cliSocialEconomics.gender.description}
                          </Typography>
                        )}
                      </Box>

                      {formik.values.sectional === 0 && (
                        <Box display="flex" pb={2}>
                          <Typography fontWeight={700} mr={2} width="150px">
                            Seccional:
                          </Typography>
                          <FormControl fullWidth>
                            <Select
                              name="sectional"
                              id="sectional"
                              value={formik.values.sectional}
                              disabled={!enableEdit}
                              size="small"
                              onChange={formik.handleChange}
                              error={
                                formik.touched.sectional &&
                                Boolean(formik.errors.sectional)
                              }
                            >
                              {typeTables.TBLSECTIONALS &&
                                convertToSelectList(
                                  typeTables.TBLSECTIONALS.data
                                ).map((x) => (
                                  <MenuItem key={x.value} value={x.value}>
                                    {x.text}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Cond. Laboral:
                        </Typography>
                        {enableEdit ? (
                          <FormControl fullWidth>
                            <Select
                              name="conditionWork"
                              id="conditionWork"
                              value={formik.values.conditionWork}
                              disabled={!enableEdit}
                              size="small"
                              onChange={formik.handleChange}
                            >
                              {typeTables.TBLLABOURSITUATIONS &&
                                convertToSelectList(
                                  typeTables.TBLLABOURSITUATIONS.data
                                ).map((x) => (
                                  <MenuItem key={x.value} value={x.value}>
                                    {x.text}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Typography>
                            {cliSocialEconomics.labouralSituation.description}
                          </Typography>
                        )}
                      </Box>

                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Nivel Académico:
                        </Typography>

                        {enableEdit ? (
                          <FormControl fullWidth>
                            <Select
                              name="academicLevel"
                              id="academicLevel"
                              value={formik.values.academicLevel}
                              disabled={!enableEdit}
                              size="small"
                              onChange={formik.handleChange}
                            >
                              {typeTables.TBLACADEMICS &&
                                convertToSelectList(
                                  typeTables.TBLACADEMICS.data
                                ).map((x) => (
                                  <MenuItem key={x.value} value={x.value}>
                                    {x.text}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Typography>
                            {cliSocialEconomics.academic.description}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Información de emergencia:
                        </Typography>

                        {enableEdit ? (
                          <TextField
                            id="notes"
                            multiline
                            rows={5}
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.notes}
                          />
                        ) : (
                          <Typography>{user.clients.notes}</Typography>
                        )}
                      </Box>

                      <Box display="flex" pb={2}>
                        <Typography fontWeight={700} mr={2} width="150px">
                          Doc. Adicional:
                        </Typography>

                        {roleId == 1 && enableEdit ? (
                          <FormControl fullWidth>
                            <Select
                              name="statusDoc"
                              id="statusDoc"
                              value={formik.values.statusDoc}
                              disabled={!enableEdit}
                              size="small"
                              onChange={formik.handleChange}
                            >
                              {typeTables.TBLSTATUSADDITIONALDOCS &&
                                convertToSelectList(
                                  typeTables.TBLSTATUSADDITIONALDOCS.data
                                ).map((x) => (
                                  <MenuItem key={x.value} value={x.value}>
                                    {x.text}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        ) : (
                          user.clients.students.statusDoc && (
                            <>
                              <Chip
                                size="small"
                                label={getStatusDoc()}
                                color={getColorStatusDoc()}
                              />
                            </>
                          )
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      {roleId === 1 && enableEdit && (
                        <Grid container xs={12}>
                          {/* DNI FRENTE ****************/}
                          <Grid container xs={4}>
                            <Paper
                              elevation={3}
                              sx={{
                                borderStyle: "dashed",
                                borderColor: "#85BFE6",
                                marginTop: 2,
                                paddingLeft: 1,
                                paddingRight: 1,
                                height: "100%",
                                overflow: "auto",
                              }}
                            >
                              <section>
                                <div
                                  {...getRootProps2({ className: "dropzone" })}
                                >
                                  <input {...getInputProps2()} />
                                  <Grid
                                    container
                                    xs={12}
                                    sx={{ align: "center" }}
                                  >
                                    <Grid item xs={12} align="center">
                                      <Typography
                                        color="primary"
                                        align="center"
                                        variant="h5"
                                        sx={{
                                          fontWeight: 700,
                                          paddingTop: 1,
                                          paddingBottom: 1,
                                        }}
                                      >
                                        FOTO DNI FRENTE
                                      </Typography>
                                      <Badge
                                        color="primary"
                                        fontSize="large"
                                        align="center"
                                      />
                                      <Typography
                                        color="primary"
                                        align="center"
                                        variant="subtitle2"
                                        sx={{
                                          fontWeight: 700,
                                          paddingTop: 1,
                                          paddingBottom: 1,
                                        }}
                                      >
                                        Seleccione un archivo o arrástrelo aquí
                                      </Typography>
                                      <CloudUploadOutlined
                                        color="primary"
                                        fontSize="large"
                                        align="center"
                                      />
                                      <Typography
                                        color="primary"
                                        align="center"
                                        variant="subtitle2"
                                        sx={{ paddingTop: 1 }}
                                      >
                                        JPG, PNG o PDF con un tamaño no mayor a
                                        10 Mb.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                                <List sx={{ width: "100%" }}>
                                  {formik.values.frontDocument && files2}
                                </List>
                              </section>
                            </Paper>
                          </Grid>
                          {/* DNI DORSO ****************/}
                          <Grid container xs={4}>
                            <Paper
                              elevation={3}
                              sx={{
                                borderStyle: "dashed",
                                borderColor: "#85BFE6",
                                marginTop: 2,
                                paddingLeft: 1,
                                paddingRight: 1,
                                height: "100%",
                                overflow: "auto",
                              }}
                            >
                              <section>
                                <div
                                  {...getRootProps3({ className: "dropzone" })}
                                >
                                  <input {...getInputProps3()} />
                                  <Grid
                                    container
                                    xs={12}
                                    sx={{ align: "center" }}
                                  >
                                    <Grid item xs={12} align="center">
                                      <Typography
                                        color="primary"
                                        align="center"
                                        variant="h5"
                                        sx={{
                                          fontWeight: 700,
                                          paddingTop: 1,
                                          paddingBottom: 1,
                                        }}
                                      >
                                        FOTO DNI DORSO
                                      </Typography>
                                      <Badge
                                        color="primary"
                                        fontSize="large"
                                        align="center"
                                      />
                                      <Typography
                                        color="primary"
                                        align="center"
                                        variant="subtitle2"
                                        sx={{
                                          fontWeight: 700,
                                          paddingTop: 1,
                                          paddingBottom: 1,
                                        }}
                                      >
                                        Seleccione un archivo o arrástrelo aquí
                                      </Typography>
                                      <CloudUploadOutlined
                                        color="primary"
                                        fontSize="large"
                                        align="center"
                                      />
                                      <Typography
                                        color="primary"
                                        align="center"
                                        variant="subtitle2"
                                        sx={{ paddingTop: 1 }}
                                      >
                                        JPG, PNG o PDF con un tamaño no mayor a
                                        10 Mb.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                                <List sx={{ width: "100%" }}>
                                  {formik.values.backDocument && files3}
                                </List>
                              </section>
                            </Paper>
                          </Grid>

                          {/* // RECIBO DE SUELDO DEL FAMILIAR */}
                          <Grid container xs={4}>
                            {[4, 5, 6].includes(
                              cliSocialEconomics.situationId
                            ) ? (
                              <>
                                <Paper
                                  elevation={3}
                                  sx={{
                                    borderStyle: "dashed",
                                    borderColor: "#85BFE6",
                                    marginTop: 2,
                                    paddingLeft: 1,
                                    paddingRight: 1,
                                    height: "100%",
                                    overflow: "auto",
                                  }}
                                >
                                  <section>
                                    <div
                                      {...getRootProps1({
                                        className: "dropzone",
                                      })}
                                    >
                                      <input {...getInputProps1()} />
                                      <Grid
                                        container
                                        xs={12}
                                        sx={{ align: "center" }}
                                      >
                                        <Grid item xs={12} align="center">
                                          <Typography
                                            color="primary"
                                            align="center"
                                            variant="h5"
                                            sx={{
                                              fontWeight: 700,
                                              paddingTop: 1,
                                              paddingBottom: 1,
                                            }}
                                          >
                                            RECIBO DE SUELDO
                                          </Typography>
                                          <Receipt
                                            color="primary"
                                            fontSize="large"
                                            align="center"
                                          />
                                          <Typography
                                            color="primary"
                                            align="center"
                                            variant="subtitle2"
                                            sx={{
                                              fontWeight: 700,
                                              paddingTop: 1,
                                              paddingBottom: 1,
                                            }}
                                          >
                                            Seleccione un archivo o arrástrelo
                                            aquí
                                          </Typography>
                                          <CloudUploadOutlined
                                            color="primary"
                                            fontSize="large"
                                            align="center"
                                          />
                                          <Typography
                                            color="primary"
                                            align="center"
                                            variant="subtitle2"
                                            sx={{ paddingTop: 1 }}
                                          >
                                            JPG, PNG o PDF con un tamaño no
                                            mayor a 10 Mb.
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <List sx={{ width: "100%" }}>
                                      {formik.values.file && files1}
                                    </List>
                                  </section>
                                </Paper>
                              </>
                            ) : (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ textAlign: "center", paddingTop: 6 }}
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/images/upload-files.png"
                                    }
                                    width="100px"
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {roleId === 1 && (
                        <Grid item xs={12} pt={3} align="center">
                          <Button
                            onClick={() => getFileDocument()}
                            sx={{ height: "35px" }}
                            variant="text"
                            color="primary"
                          >
                            {"<< Descargar documentos del alumno >>"}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </form>
                <Grid textAlign="center" container pt={2} spacing={1}>
                  <Grid item xs>
                    <Button
                      disabled={!formik.dirty}
                      onClick={() => handleEditDetail()}
                      sx={{ width: "170px", height: "35px" }}
                      variant="contained"
                    >
                      {enableEdit ? "GUARDAR" : "EDITAR"}
                    </Button>
                  </Grid>
                  {enableEdit && (
                    <Grid item xs>
                      <Button
                        onClick={() => handleCancel()}
                        sx={{ width: "170px", height: "35px" }}
                        variant="outlined"
                        color="error"
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  )}
                  {!enableEdit && roleId == 1 && (
                    <Grid item xs>
                      <Button
                        onClick={() => setConfirmationDialog(true)}
                        sx={{ width: "170px", height: "35px" }}
                        disabled={blockedState.isLoading}
                        variant="contained"
                        color={formik.values.state == 1 ? "error" : "success"}
                      >
                        {blockedState.isLoading && <Spinner color="primary" />}
                        {formik.values.state == 1 ? "BLOQUEAR" : "HABILITAR"}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </>
          )}
          {openDialog && (
            <>
              <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"La información ha sido guardada con éxito"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenDialog(false);
                      fetchData();
                    }}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {confirmationDialog && (
            <>
              <Dialog
                open={confirmationDialog}
                onClose={confirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {formik.values.state == 1
                    ? "¿Estás seguro que deseas bloquear al usuario?"
                    : "¿Estás seguro que deseas habilitar al usuario?"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      blockUser(userId);
                      setConfirmationDialog(false);
                    }}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                  <Button
                    onClick={() => setConfirmationDialog(false)}
                    autoFocus
                  >
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </>
      ) : (
        <StudentSkeleton />
      )}
      {errors && errors.length > 0 && <ModalMessages errors={errors} />}
      {fileDocument.errors && fileDocument.errors.length > 0 && (
        <ModalMessages errors={fileDocument.errors} />
      )}
    </>
  );
};

export default StudentData;
