import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBatchProcessData,
  selectBatchProcessFile,
  selectDeletedTeacherCourseFee,
  selectNotificationEmails,
  tryDeleteTeacherCourseFee,
  tryDownloadFile,
  tryGetBatchProcessDetail,
  tryPutNotificationEmails,
} from "../../store/slices/backoffice-teachers";
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  Card,
  Chip,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { MoreHoriz, Download, ForwardToInbox } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";
import ModalMessages from "../layout/modal-messages";
import NewChargeDrawer from "./drawer";
import { NumericFormat } from "react-number-format";
import SettlementAdministratorsSkeleton from "./skeleton";
import { v4 as uuidv4 } from "uuid";
import Spinner from "../layout/spinner";

const BackofficeSettlementAdministrator = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const batchprocessid = queryParams.get("batchprocessid");
  const isEditable = queryParams.get("isEditable") === "true";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //TRAE EL EL DETALLE DE UNA PRELIQUIDACIÓN
  const selectedBatchProcessData = useSelector(selectBatchProcessData);
  //TRAE EL DETALLE DE LA NOTIFICACION MASIVA
  const notificationEmails = useSelector(selectNotificationEmails);
  //TRAE EL DETALLE DEL EXPORTAR GRILLA
  const batchProcessFile = useSelector(selectBatchProcessFile);

  //MANEJO DE LA CONFIRMACION DE ENVIO DE MAILS
  const [openSuccessMailsDialog, setOpenSuccessMailsDialog] = useState(false);
  const [openConfirmationMailsDialog, setOpenConfirmationMailsDialog] =
    useState(false);
  //MANEJO DE LAS COLUMNAS
  const [rows, setRows] = useState([]);

  //MANEJO DEL DRAWER
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dataDrawer, setDataDrawer] = useState(undefined);

  //MANEJO DE ELIMINACION DE GASTOS
  const deletedTeacherCourseFee = useSelector(selectDeletedTeacherCourseFee);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);
  const [teacherCourseFeeId, setTeacherCourseFeeId] = useState(null);

  const fetchData = async () => {
    const action = await dispatch(tryGetBatchProcessDetail(batchprocessid));

    if (action.payload.data.isValid) {
      setRows(
        action.payload.data.data.map((x) => {
          return {
            teacher: x.teacher,
            month: x.month,
            processType: x.processType,
            totalHours: x.totalHours,
            amount: x.amount,
            cliPrecaseId: x.cliPrecaseId,
            notes: x.notes,
            teacherCourseFeeId: x.teacherCourseFeeId,
          };
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    !openConfirmationDialog && fetchData();
  }, [openConfirmationDialog]);

  // ELIMINAR GASTO
  const deleteTeacherCourseFees = async (teacherCourseFeeId) => {
    const action = await dispatch(
      tryDeleteTeacherCourseFee(teacherCourseFeeId)
    );
    if (action.payload.data.isValid) setOpenConfirmationDialog(true);
  };

  //ENVIO DE NOTIFICACIONES MASIVAS VIA MAIL

  const sendNotificationEmails = async () => {
    const actionType = 2;
    const action = await dispatch(
      tryPutNotificationEmails({ batchprocessid, actionType })
    );

    if (action.payload.data.isValid) setOpenConfirmationMailsDialog(false);
  };

  //EXPORTA LA GRILLA
  const downloadFile = async () => {
    const action = await dispatch(tryDownloadFile(batchprocessid));
    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //ACCIONES
  const initializeDataGrid = () => {
    return {
      columns: {
        ...rows,
        ...columns,
        columnVisibilityModel: {
          cliPrecaseId: false,
          actions: isEditable,
          teacherCourseFeeId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 10 } },
    };
  };

  const ActionsRow = ({ item }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleMenuItemClick = (event, index) => {
      //GENERAR GASTO
      if (index === 0) {
        setDataDrawer({
          batchprocessid: batchprocessid,
          cliPrecaseId: item.row.cliPrecaseId,
          teacher: item.row.teacher,
        });
        setOpenDrawer(true);
      }
      //ELIMINAR GASTO
      if (index === 1) {
        setTeacherCourseFeeId(item.row.teacherCourseFeeId);
        setOpenDeleteConfirmationDialog(true);
      }

      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    <MenuItem
                      key="postTeacherCourseFees"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Generar gasto
                    </MenuItem>

                    {item.row.teacherCourseFeeId !== 0 && (
                      <MenuItem
                        key="deleteTeacherCourseFees"
                        onClick={(event) => handleMenuItemClick(event, 1)}
                      >
                        Eliminar
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const columns = [
    {
      field: "cliPrecaseId",
      headerName: "cliPrecaseId",
      headerAlign: "center",
      align: "center",
      headerClassName: "headRow",
      flex: 1,
      filterable: false,
    },
    {
      field: "teacher",
      headerName: "Profesor",
      headerAlign: "center",
      align: "center",
      headerClassName: "headRow",
      flex: 1,
      filterable: false,
    },
    {
      field: "teacherCourseFeeId",
      headerName: "teacherCourseFeeId",
      headerAlign: "center",
      align: "center",
      headerClassName: "headRow",
      flex: 1,
      filterable: false,
    },
    {
      field: "month",
      headerName: "Mes",
      headerAlign: "center",
      align: "center",
      headerClassName: "headRow",
      flex: 1,
      filterable: false,
    },
    {
      field: "processType",
      headerName: "Tipo",
      headerAlign: "center",
      align: "center",
      headerClassName: "headRow",
      flex: 1,
      filterable: false,
    },
    {
      field: "totalHours",
      headerName: "Horas",
      headerAlign: "center",
      align: "center",
      flex: 1,
      filterable: false,
      headerClassName: "headRow",
    },
    {
      field: "notes",
      headerName: "Comentarios",
      headerAlign: "center",
      align: "center",
      flex: 1,
      filterable: false,
      headerClassName: "headRow",
    },
    {
      field: "amount",
      headerName: "Monto",
      headerAlign: "center",
      align: "center",
      flex: 1,
      filterable: false,
      headerClassName: "headRow",
      renderCell: (params) => (
        <NumericFormat
          key={`${params.field}_${params.id}`}
          value={params.value ?? 0}
          decimalScale="2"
          thousandSeparator="."
          displayType="text"
          decimalSeparator=","
          prefix="$"
          fixedDecimalScale="2"
        />
      ),
    },

    {
      field: "actions",
      headerAlign: "center",
      align: "center",
      headerName: "",
      flex: 1,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) =>
        deletedTeacherCourseFee.isLoading ? (
          <Spinner color="primary" />
        ) : (
          <ActionsRow item={params} />
        ),
    },
  ];

  return (
    <>
      {selectedBatchProcessData.isLoading ? (
        <SettlementAdministratorsSkeleton />
      ) : (
        <Card>
          <Container>
            <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
              <Grid item xs={12} sx={{ textAlign: "start" }}>
                <Button
                  variant="text"
                  size="large"
                  style={{ fontWeight: "bold" }}
                  sx={{ marginLeft: -3, marginBottom: 2, width: 100 }}
                  onClick={() => navigate("/backoffice/settlements")}
                >
                  VOLVER
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  align="left"
                  style={{ fontWeight: "bold", fontSize: 18 }}
                >
                  {isEditable
                    ? `Administrar Preliquidación ${batchprocessid}`
                    : ""}
                </Typography>
              </Grid>

              {selectedBatchProcessData.data.length !== 0 ? (
                <>
                  <Grid item xs={6} sx={{ textAlign: "end" }}>
                    <Button
                      variant="contained"
                      disabled={!isEditable || batchProcessFile.isLoading}
                      endIcon={<Download />}
                      sx={{ marginRight: "8px" }}
                      onClick={() => downloadFile()}
                    >
                      {batchProcessFile.isLoading && (
                        <Spinner color="primary" />
                      )}
                      Exportar
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <DataGrid
                      slots={{
                        columnMenu: CustomColumnMenu,
                      }}
                      autoHeight={true}
                      rowSelection={false}
                      getRowId={(row) => uuidv4()}
                      disableRowSelectionOnClick
                      rows={rows}
                      columns={columns}
                      pageSizeOptions={[5, 10, 25, 50]}
                      initialState={initializeDataGrid()}
                      sx={{
                        "& .MuiDataGrid-virtualScroller": {
                          minHeight: 250,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "end" }}>
                    <Button
                      variant="contained"
                      disabled={!isEditable || notificationEmails.isLoading}
                      endIcon={<ForwardToInbox />}
                      onClick={() => {
                        setOpenSuccessMailsDialog(true);
                        setOpenConfirmationMailsDialog(true);
                      }}
                    >
                      {notificationEmails.isLoading && (
                        <Spinner color="primary" />
                      )}
                      Notificación Masiva{" "}
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Alert
                    severity="info"
                    sx={{ textAlign: "left", marginBottom: 5 }}
                  >
                    La preliquidacion seleccionada no ha generado información
                  </Alert>
                </Grid>
              )}
            </Grid>
            {openDrawer && (
              <NewChargeDrawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                setOpenConfirmationDialog={setOpenConfirmationDialog}
                dataDrawer={dataDrawer}
              />
            )}
            {openConfirmationDialog && (
              <Dialog
                open={openConfirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"La información ha sido guardada con éxito"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => setOpenConfirmationDialog(false)}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {openSuccessMailsDialog && (
              <Dialog
                open={openSuccessMailsDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {!openConfirmationMailsDialog ? (
                    "La notificación ha sido enviada con éxito"
                  ) : (
                    <Alert severity="warning" sx={{ textAlign: "left" }}>
                      <AlertTitle>
                        ¿Confirmas realizar una notificación masiva?
                      </AlertTitle>
                      Este proceso enviará un correo electrónico a todos los
                      profesores/as involucrados/as en la preliquidación con el
                      detalle de la misma.
                    </Alert>
                  )}
                </DialogTitle>
                <DialogActions>
                  <Button
                    disabled={notificationEmails.isLoading}
                    onClick={() =>
                      openConfirmationMailsDialog
                        ? sendNotificationEmails()
                        : setOpenSuccessMailsDialog(false)
                    }
                    autoFocus
                  >
                    {notificationEmails.isLoading && (
                      <Spinner color="primary" />
                    )}
                    Aceptar
                  </Button>
                  {openConfirmationMailsDialog && (
                    <Button
                      disabled={notificationEmails.isLoading}
                      onClick={() => setOpenSuccessMailsDialog(false)}
                      autoFocus
                    >
                      Cancelar
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            )}
            {openDeleteConfirmationDialog && (
              <Dialog
                open={openDeleteConfirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"¿Confirmas eliminar el gasto?"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      deleteTeacherCourseFees(teacherCourseFeeId);
                      setOpenDeleteConfirmationDialog(false);
                    }}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                  <Button
                    onClick={() => setOpenDeleteConfirmationDialog(false)}
                    autoFocus
                  >
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {selectedBatchProcessData.errors &&
              selectedBatchProcessData.errors.length > 0 && (
                <ModalMessages errors={selectedBatchProcessData.errors} />
              )}
            {deletedTeacherCourseFee.errors &&
              deletedTeacherCourseFee.errors.length > 0 && (
                <ModalMessages errors={deletedTeacherCourseFee.errors} />
              )}
            {notificationEmails.errors &&
              notificationEmails.errors.length > 0 && (
                <ModalMessages errors={notificationEmails.errors} />
              )}
            {batchProcessFile.errors && batchProcessFile.errors.length > 0 && (
              <ModalMessages errors={batchProcessFile.errors} />
            )}
          </Container>
        </Card>
      )}
    </>
  );
};

export default BackofficeSettlementAdministrator;
