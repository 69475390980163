import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNotificationEmails,
  selectPreLiquidationHeader,
  tryGetBatchProcessHeader,
  tryPutNotificationEmails,
} from "../../store/slices/backoffice-teachers";
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  Card,
  Chip,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { MoreHoriz, AddCard } from "@mui/icons-material";
import { useNavigate } from "react-router";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";
import NewSettlementDrawer from "./drawer";
import ModalMessages from "../layout/modal-messages";
import Spinner from "../layout/spinner";
import SettlementsSkeleton from "./skeleton";
import moment from "moment";

const BackofficeSettlements = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //TRAE EL ESTADO DE LAS PRELIQUIDACIONES
  const preliquidations = useSelector(selectPreLiquidationHeader);

  //MANEJO DE LAS COLUMNAS
  const [rows, setRows] = useState([]);

  //MANEJO DEL DRAWER
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  //MANEJO APROBACIÓN
  const [finalLiquidationDialog, setFinalLiquidationDialog] = useState(false);
  const [processId, setProcessId] = useState(null);
  const notificationEmails = useSelector(selectNotificationEmails);

  const fetchData = async () => {
    const action = await dispatch(tryGetBatchProcessHeader());

    if (action.payload.data.isValid) {
      setRows(
        action.payload.data.data.map((x) => {
          return {
            processId: x.processId,
            processType: x.processType,
            dateFrom: x.dateFrom,
            dateTo: x.dateTo,
            userPreliquidation: x.userPreliquidation,
            userAproval: x.userAproval,
            statusProc: x.statusProc,
            statusProcId: x.statusProcId,
            startDate: x.startDate,
            message: x.message,
          };
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    !openConfirmationDialog && fetchData();
  }, [openConfirmationDialog]);

  const approvePreliquidation = async (batchprocessid) => {
    const actionType = 3;
    const action = await dispatch(
      tryPutNotificationEmails({ batchprocessid, actionType })
    );

    if (action.payload.data.isValid) setOpenConfirmationDialog(true);
  };

  //ACCIONES
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          statusProcId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 10 } },
    };
  };

  const ActionsRow = ({ item }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleMenuItemClick = (event, index) => {
      //ADMINISTRAR PRELIQUIDACION
      if (index === 0) {
        const queryParams = new URLSearchParams({
          batchprocessid: item.row.processId,
          isEditable: true,
        }).toString();
        navigate(`/backoffice/AdminSettlements/?${queryParams}`);
      }

      //APROBAR PRELIQUIDACION
      if (index === 1) {
        setProcessId(item.row.processId);
        setFinalLiquidationDialog(true);
      }
      //VER PRELIQUIDACION
      if (index === 2) {
        const queryParams = new URLSearchParams({
          batchprocessid: item.row.processId,
          isEditable: false,
        }).toString();
        navigate(`/backoffice/AdminSettlements/?${queryParams}`);
      }

      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {/* ITEM SOLO TIENE QUE SER VISIBLE PARA ESTADO PRELIQUIDADO O NOTIFICADO */}
                    {(item.row.statusProcId == 3 ||
                      item.row.statusProcId == 4) && (
                      <MenuItem
                        key="adminStudent"
                        onClick={(event) => handleMenuItemClick(event, 0)}
                      >
                        Administrar
                      </MenuItem>
                    )}
                    {/*  ITEM SOLO TIENE QUE SER VISIBLE PARA ESTADO NOTIFICADO */}
                    {item.row.statusProcId == 4 && (
                      <MenuItem
                        key="adminStudent"
                        onClick={(event) => handleMenuItemClick(event, 1)}
                      >
                        Aprobar
                      </MenuItem>
                    )}
                    {/* ITEM SOLO TIENE QUE SER VISIBLE PARA ESTADO LIQUIDADO O ERROR */}
                    {(item.row.statusProcId == 5 ||
                      item.row.statusProcId == 1) && (
                      <MenuItem
                        key="adminStudent"
                        onClick={(event) => handleMenuItemClick(event, 2)}
                      >
                        Ver
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const columns = [
    {
      field: "processId",
      headerName: "Liquidación",
      headerAlign: "center",
      headerClassName: "headRow",
      flex: 1,
      align: "center",
      filterable: false,
    },

    {
      field: "statusProcId",
      headerName: "statusProcId",
      headerAlign: "center",
      headerClassName: "headRow",
      flex: 1,
      align: "center",
      filterable: false,
    },
    {
      field: "message",
      headerName: "Descripción",
      headerAlign: "center",
      align: "center",
      headerClassName: "headRow",
      flex: 2,
      filterable: false,
    },
    {
      field: "processType",
      headerName: "Tipo",
      headerAlign: "center",
      align: "center",
      headerClassName: "headRow",
      flex: 1,
      filterable: false,
    },
    {
      field: "startDate",
      headerName: "Fecha Creación",
      headerAlign: "center",
      align: "center",
      headerClassName: "headRow",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        const formatedDate = moment(params.value).format("DD/MM/YYYY");

        return <Chip label={formatedDate} size="small" />;
      },
    },

    {
      field: "dateFrom",
      headerName: "Fecha desde",
      headerAlign: "center",
      align: "center",
      headerClassName: "headRow",
      flex: 1,
      filterable: false,
      valueFormatter: (params) => {
        if (params.value === null) return "";
        return moment(params.value).format("DD/MM/YYYY");
      },
    },
    {
      field: "dateTo",
      headerName: "Fecha hasta",
      headerAlign: "center",
      align: "center",
      flex: 1,
      filterable: false,
      headerClassName: "headRow",
      valueFormatter: (params) => {
        if (params.value === null) return "";
        return moment(params.value).format("DD/MM/YYYY");
      },
    },
    {
      field: "userPreliquidation",
      headerName: "usuarioPre",
      headerAlign: "center",
      align: "center",
      flex: 1,
      filterable: false,
      align: "center",
      headerClassName: "headRow",
    },
    {
      field: "userAproval",
      headerName: "UsuarioLiquid",
      headerAlign: "center",
      align: "center",
      flex: 1,
      filterable: false,
      headerClassName: "headRow",
    },
    {
      field: "statusProc",
      headerName: "Estado",
      headerAlign: "center",
      align: "center",
      flex: 1,
      filterable: false,
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";
        let chipColor;
        if (params.row.statusProcId === 2) {
          chipColor = "error";
        }
        if (params.row.statusProcId === 3) {
          chipColor = "primary";
        }
        if (params.row.statusProcId === 4) {
          chipColor = "warning";
        }
        if (params.row.statusProcId === 5) {
          chipColor = "success";
        }

        return (
          <Chip label={params.row.statusProc} color={chipColor} size="small" />
        );
      },
    },

    {
      field: "actions",
      headerAlign: "center",
      align: "center",
      headerName: "",
      flex: 1,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) =>
        notificationEmails.isLoading ? (
          <Spinner color="primary" />
        ) : (
          <ActionsRow item={params} />
        ),
    },
  ];

  return (
    <>
      {preliquidations.isLoading ? (
        <SettlementsSkeleton />
      ) : (
        <>
          <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
            <Grid item xs={6}>
              <Typography
                align="left"
                style={{ fontWeight: "bold", fontSize: 18 }}
              >
                Liquidaciones
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <Button
                variant="contained"
                disabled={preliquidations.isLoading}
                endIcon={<AddCard />}
                onClick={() => setOpenDrawer(true)}
              >
                {preliquidations.isLoading && <Spinner color="primary" />}
                GENERAR PRELIQUIDACIÓN
              </Button>
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                slots={{
                  columnMenu: CustomColumnMenu,
                }}
                autoHeight={true}
                rowSelection={false}
                getRowId={(row) => row.processId}
                disableRowSelectionOnClick
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 25, 50]}
                initialState={initializeDataGrid()}
                sx={{
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: 250,
                  },
                }}
              />
            </Grid>
          </Grid>
          {openDrawer && (
            <NewSettlementDrawer
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              setOpenConfirmationDialog={setOpenConfirmationDialog}
            />
          )}
          {openConfirmationDialog && (
            <Dialog
              open={openConfirmationDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"La información ha sido guardada con éxito"}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => setOpenConfirmationDialog(false)}
                  autoFocus
                >
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {finalLiquidationDialog && (
            <>
              <Dialog
                open={finalLiquidationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <Alert severity="warning" sx={{ textAlign: "left" }}>
                    <AlertTitle>
                      ¿Confirmas aprobar la preliquidación?
                    </AlertTitle>
                    Este proceso es irreversible, generará la liquidación final
                    para todos los profesores y enviará un correo electrónico a
                    los mismos notificándolos.
                  </Alert>
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      approvePreliquidation(processId);
                      setFinalLiquidationDialog(false);
                    }}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                  <Button
                    onClick={() => {
                      setFinalLiquidationDialog(false);
                    }}
                    autoFocus
                  >
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}
          {preliquidations.errors && preliquidations.errors.length > 0 && (
            <ModalMessages errors={preliquidations.errors} />
          )}
          {notificationEmails.errors &&
            notificationEmails.errors.length > 0 && (
              <ModalMessages errors={notificationEmails.errors} />
            )}
        </>
      )}
    </>
  );
};

export default BackofficeSettlements;
