import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBackTeachers,
  tryGetAllTeachers,
  tryPreliquidation,
  selectPreLiquidation,
} from "../../../store/slices/backoffice-teachers";
import {
  Typography,
  Grid,
  Button,
  Drawer,
  IconButton,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  FormLabel,
  MenuItem,
  FormHelperText,
  DialogActions,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useFormik } from "formik";
import ModalMessages from "../../layout/modal-messages";
import Spinner from "../../layout/spinner";
import { IsValidDate } from "../../../services/functions/validator";
import { PatternFormat } from "react-number-format";
import { FormatDate } from "../../../services/functions/formatDate";

const validate = (values) => {
  //Obtengo la fecha actual
  const today = FormatDate();
  const newDateFrom = FormatDate(values.dateFrom, "DD/MM/YYYY", null);
  const newDateTo = FormatDate(values.dateTo, "DD/MM/YYYY", null);

  const errors = {};

  if (
    (!values.teacherId || values.teacherId === "") &&
    values.teacherId !== 0
  ) {
    errors.teacherId = "Requerido";
  }

  //Fecha DESDE
  const isValidDateFrom = IsValidDate(values.dateFrom);
  if (!isValidDateFrom) {
    errors.dateFrom = "Ingrese una fecha válida en formato DD MM AAAA";
  }
  if (isValidDateFrom) {
    if (!values.dateFrom || values.dateFrom === "")
      errors.dateFrom = "Requerido";
    if (newDateFrom.isAfter(today))
      errors.dateFrom =
        "La fecha de inicio seleccionada no puede ser posterior a la fecha actual";
  }

  //Fecha HASTA
  const isValidDateTo = IsValidDate(values.dateTo);
  if (!isValidDateTo) {
    errors.dateTo = "Ingrese una fecha válida en formato DD MM AAAA";
  }
  if (isValidDateTo) {
    if (!values.dateTo || values.dateTo === "") errors.dateTo = "Requerido";
    if (newDateTo.isBefore(newDateFrom))
      errors.dateTo = "La Fecha de Fin no puede ser anterior a Fecha de Inicio";
  }

  if (
    !(
      newDateFrom.month() === newDateTo.month() &&
      newDateFrom.year() === newDateTo.year()
    )
  )
    errors.dateTo =
      "Fecha de Fin debe pertenecer al mismo mes que Fecha de Inicio";

  return errors;
};

const NewSettlementDrawer = ({
  openDrawer,
  setOpenDrawer,
  setOpenConfirmationDialog,
}) => {
  const dispatch = useDispatch();
  const selectTeachers = useSelector(selectBackTeachers);
  const { data: teachers } = selectTeachers;
  const newPreliquidation = useSelector(selectPreLiquidation);

  const [
    preliquidationConfirmationDialog,
    setPreliquidationConfirmationDialog,
  ] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (openDrawer) {
        try {
          await dispatch(tryGetAllTeachers());
        } catch (error) {}
      }
    };

    fetchData();
  }, [openDrawer]);

  //Creo el formik
  const formik = useFormik({
    eneableReinitialize: true,
    initialValues: {
      teacherId: "",
      dateFrom: "",
      dateTo: "",
      isPreliminary: "",
    },
    validate,
    onSubmit: async (values) => {
      const request = {};
      request.teacherId = values.teacherId;
      request.dateFrom=FormatDate(values.dateFrom, "DD/MM/YYYY", "YYYY-MM-DDTHH:mm:ss.sssZ") //prettier-ignore
      request.dateTo=FormatDate(values.dateTo, "DD/MM/YYYY", "YYYY-MM-DDTHH:mm:ss.sssZ") //prettier-ignore
      request.isPreliminary = values.isPreliminary;

      const action = await dispatch(tryPreliquidation(request));
      if (action.payload.data.isValid) {
        setOpenConfirmationDialog(true);
        window.open(action.payload.data.data.file);
        setOpenDrawer(false);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      teacherId: "",
      dateFrom: "",
      dateTo: "",
      isPreliminary: true,
    });
  }, []);

  const settlementOptions = () => {
    let teachersAll = [...teachers];
    teachersAll.push({
      id: 0,
      description: "PRELIQUIDACIÓN MASIVA  🌐",
      shortDescription: "globalSettlement",
    });

    return teachersAll;
  };

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      PaperProps={{
        sx: {
          zIndex: 1203,
          width: 500,
          boxSizing: "border-box",
        },
      }}
      onClose={() => setOpenDrawer(false)}
      sx={{
        zIndex: 1203,
        width: 500,
        flexShrink: 0,
      }}
    >
      <Grid container p={1}>
        <Grid item xs={10}>
          <Typography
            align="left"
            sx={{
              paddingLeft: 2,
              paddingRight: 5,
              paddingBottom: 1,
              paddingTop: 4,
              fontSize: "1.5em",
            }}
          >
            Nueva Preliquidación
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "end" }}>
          <IconButton
            sx={{ color: "white" }}
            onClick={() => setOpenDrawer(false)}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ paddingLeft: 2, paddingBottom: 3 }}>
          <Typography variant="h7" align="center">
            Por favor, complete información en cada campo que se solicite en el
            formulario
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        spacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        p={2}
        sx={{ backgroundColor: "white" }}
      >
        <>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="settlementTo">Profesores</InputLabel>
              <Select
                name="settlementTo"
                label="Profesores"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => {
                  const { value } = e.target;

                  formik.setFieldValue("teacherId", value);
                }}
                value={formik.values.teacherId}
                error={formik.touched.teacherId && formik.errors.teacherId}
                helperText={formik.touched.teacherId && formik.errors.teacherId}
                autoComplete="off"
              >
                {teachers &&
                  settlementOptions()
                    .sort((a, b) => a.id - b.id)
                    .map((x) => {
                      return (
                        <MenuItem key={x.id} value={x.id}>
                          {x.description}
                        </MenuItem>
                      );
                    })}
              </Select>
              {formik.touched.teacherId && (
                <FormHelperText
                  htmlFor="teacherId"
                  error={formik.touched.teacherId && formik.errors.teacherId}
                >
                  {formik.errors.teacherId}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="courseType">Fecha de Inicio</InputLabel>
            <PatternFormat
              id="initDate"
              label=""
              variant="outlined"
              size="small"
              fullWidth
              autoComplete="off"
              onChange={(e) => {
                const { value } = e.target;
                formik.setFieldValue("dateFrom", value);
              }}
              value={formik.values.dateFrom}
              error={formik.touched.dateFrom && formik.errors.dateFrom != null}
              helperText={formik.touched.dateFrom && formik.errors.dateFrom}
              customInput={TextField}
              format="##/##/####"
              mask="_"
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="courseType">Fecha de Fin</InputLabel>
            <PatternFormat
              id="endDate"
              label=""
              variant="outlined"
              size="small"
              autoComplete="off"
              fullWidth
              onChange={(e) => {
                const { value } = e.target;
                formik.setFieldValue("dateTo", value);
              }}
              value={formik.values.dateTo}
              error={formik.touched.dateTo && formik.errors.dateTo != null}
              helperText={formik.touched.dateTo && formik.errors.dateTo}
              customInput={TextField}
              format="##/##/####"
              mask="_"
            />
          </Grid>
          <Grid item xs={12} align="center">
            <FormControl>
              <FormLabel id="t">TIPO DE PRELIQUIDACION</FormLabel>
              <RadioGroup row aria-labelledby="settlement-label" name="aproval">
                <FormControlLabel
                  value="Preliminar"
                  onChange={() => formik.setFieldValue("isPreliminary", true)}
                  control={<Radio />}
                  label="Preliminar"
                  sx={{ color: "black" }}
                  checked={formik.values.isPreliminary}
                />
                <FormControlLabel
                  value="Definitiva"
                  onChange={() => formik.setFieldValue("isPreliminary", false)}
                  control={<Radio />}
                  label="Definitiva"
                  sx={{ color: "black" }}
                  checked={!formik.values.isPreliminary}
                />
              </RadioGroup>
              {formik.touched.isPreliminary && (
                <FormHelperText
                  htmlFor="isPreliminary"
                  error={
                    formik.touched.isPreliminary && formik.errors.isPreliminary
                  }
                >
                  {formik.errors.isPreliminary}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item sx={{ width: "100%", textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={() => setPreliquidationConfirmationDialog(true)}
              disabled={newPreliquidation?.isLoading}
              sx={{
                marginTop: 2,
                marginBottom: 2,
                width: 100,
                marginRight: 2,
              }}
            >
              {newPreliquidation?.isLoading && <Spinner color="primary" />}
              Generar
            </Button>
            <Button
              variant="outline"
              color="error"
              disabled={newPreliquidation.isLoading}
              sx={{
                border: 2,
                marginTop: 2,
                marginBottom: 2,
                width: 100,
                color: "red",
              }}
              onClick={() => setOpenDrawer(false)}
            >
              Cancelar
            </Button>
          </Grid>
        </>
      </Grid>
      {newPreliquidation.errors && newPreliquidation.errors.length > 0 && (
        <ModalMessages errors={newPreliquidation.errors} />
      )}{" "}
      {preliquidationConfirmationDialog && (
        <>
          <Dialog
            open={preliquidationConfirmationDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {
                "¿Confirmas que los datos ingresados son correctos para iniciar el proceso de preliquidación?"
              }
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  formik.handleSubmit();
                  setPreliquidationConfirmationDialog(false);
                }}
                autoFocus
              >
                Aceptar
              </Button>
              <Button
                onClick={() => {
                  setPreliquidationConfirmationDialog(false);
                }}
                autoFocus
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>{" "}
        </>
      )}
    </Drawer>
  );
};

export default NewSettlementDrawer;
