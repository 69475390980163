import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import currentUser from "./slices/currentUser";
import users from "./slices/users";
import coursesAvailables from "./slices/courses-availables";
import catalogs from "./slices/catalogs";
import maintenances from "./slices/maintenances";
import student from "./slices/students";
import studentCourses from "./slices/student-courses";
import personalData from "./slices/personal-data";
import studentPayments from "./slices/student-payments";
import studentTickets from "./slices/student-tickets";
import studentCuil from "./slices/student-cuil";
import pass from "./slices/password";
import studentAttendance from "./slices/student-courses-attendances";
import teacher from "./slices/teacher";
import backofficeCourses from "./slices/backoffice-courses";
import teachersBack from "./slices/backoffice-teachers";
import teacherTickets from "./slices/teacher-tickets";
import courseDetail from "./slices/back-course";
import teacherCourses from "./slices/teacher-courses";
import teacherClicases from "./slices/teacher-payments";
import studentsBack from "./slices/backoffice-students";

export const store = configureStore({
  reducer: {
    currentUser,
    users,
    coursesAvailables,
    catalogs,
    maintenances,
    student,
    studentCourses,
    personalData,
    studentPayments,
    studentTickets,
    studentCuil,
    pass,
    studentAttendance,
    teacher,
    backofficeCourses,
    teachersBack,
    teacherTickets,
    courseDetail,
    teacherCourses,
    teacherClicases,
    studentsBack,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(logger),
  devTools: process.env.NODE_ENV !== "production",
});
