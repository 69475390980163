import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNewCourseFees,
  tryCreateCourseFees,
  tryGetCourseFees,
} from "../../../store/slices/back-course";
import {
  fetchTypeTable,
  selectMaintenances,
} from "../../../store/slices/maintenances";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import convertToSelectList from "../../layout/functions/convert-to-select-list";
import ModalMessages from "../../layout/modal-messages";
import BackCoursesPicesDrawerSkeleton from "../skeleton/courseFeesDrawerSkeleton";
import { useFormik } from "formik";
import { NumericFormat } from "react-number-format";

const validate = (values) => {
  const errors = {};

  if (!values.studentTypeId || values.studentTypeId === "")
    errors.studentTypeId = "Requerido";

  if (!values.feeType || values.feeType === "") errors.feeType = "Requerido";

  if (!values.feeAmount || values.feeAmount === "")
    errors.feeAmount = "Requerido";

  if (!values.frequencyTypeId || values.frequencyTypeId === "")
    errors.frequencyTypeId = "Requerido";

  return errors;
};

const FeesDrawer = ({
  openDrawer,
  setOpenDrawer,
  dataDrawer,
  openDialog,
  setOpenDialog,
}) => {
  const dispatch = useDispatch();
  const typeTables = useSelector(selectMaintenances);
  const newCourseFees = useSelector(selectNewCourseFees);

  useEffect(() => {
    dispatch(fetchTypeTable(24)); //TBLTYPESTUDENTS
    dispatch(fetchTypeTable(47)); //TBLTYPEFEES
    dispatch(fetchTypeTable(50)); //TBLFRECUENCYFEES
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      courseId: "",
      feesId: "",
      studentTypeId: "",
      feeType: "",
      feeAmount: "",
      frequencyTypeId: "",
    },
    validate,
    onSubmit: async (values) => {
      const action = await dispatch(tryCreateCourseFees(values));
      if (action.payload.data.isValid) {
        setOpenDialog(true);
        setOpenDrawer(false);
        await dispatch(tryGetCourseFees(values.courseId));
      }
    },
  });

  useEffect(() => {
    dataDrawer &&
      formik.setValues({
        courseId: dataDrawer.courseId,
        feesId: dataDrawer.feesId,
        studentTypeId: dataDrawer.studentTypeId,
        feeType: dataDrawer.feeType,
        feeAmount: dataDrawer.feeAmount,
        frequencyTypeId: dataDrawer.frequencyTypeId,
      });
  }, [dataDrawer]);

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        PaperProps={{
          sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
        }}
        onClose={() => setOpenDrawer(false)}
        sx={{
          zIndex: 1203,
          width: 500,
          flexShrink: 0,
        }}
      >
        <Grid container p={1}>
          <Grid item xs={10}>
            <Typography
              variant="h4"
              align="left"
              sx={{
                paddingLeft: 1,
                paddingRight: 5,
                paddingBottom: 1,
                paddingTop: 4,
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              Costos
            </Typography>
            <Typography
              sx={{
                paddingLeft: 1,
                paddingRight: 5,
                paddingBottom: 3,
                paddingTop: 1,
              }}
            >
              Por favor, complete información del campo requerido
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setOpenDrawer(false)}
            >
              <GridCloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {newCourseFees.isLoading ? (
          <BackCoursesPicesDrawerSkeleton />
        ) : (
          <Grid
            container
            rowSpacing={3}
            spacing={3}
            paddingTop={6}
            paddingLeft={4}
            paddingRight={4}
            paddingBottom={6}
            sx={{ backgroundColor: "white" }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="studentType">Tipo de Estudiante</InputLabel>
                <Select
                  name="studentType"
                  label="Tipo de Estudiante"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;
                    formik.setFieldValue("studentTypeId", value);
                  }}
                  value={formik.values.studentTypeId}
                  error={
                    formik.touched.studentTypeId &&
                    formik.errors.studentTypeId != null
                  }
                  helperText={
                    formik.touched.studentTypeId && formik.errors.studentTypeId
                  }
                  autoComplete="off"
                >
                  {typeTables.TBLTYPESTUDENTS
                    ? convertToSelectList(typeTables.TBLTYPESTUDENTS.data).map(
                        (x) => (
                          <MenuItem key={x.value} value={x.value}>
                            {x.text}
                          </MenuItem>
                        )
                      )
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="feeType">Tipo de Cargo</InputLabel>
                <Select
                  name="feeType"
                  label="Tipo de Cargo"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.feeType}
                  error={
                    formik.touched.feeType && formik.errors.feeType != null
                  }
                  helperText={formik.touched.feeType && formik.errors.feeType}
                  autoComplete="off"
                >
                  {typeTables.TBLTYPEFEES
                    ? convertToSelectList(typeTables.TBLTYPEFEES.data).map(
                        (x) => (
                          <MenuItem key={x.value} value={x.value}>
                            {x.text}
                          </MenuItem>
                        )
                      )
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <NumericFormat
                  variant="outlined"
                  label="Monto"
                  size="small"
                  name="feeAmount"
                  thousandSeparator="."
                  decimalSeparator=","
                  isNumericString
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  InputLabelProps={{ shrink: true }}
                  customInput={TextField}
                  value={formik.values.feeAmount}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    formik.setFieldValue("feeAmount", floatValue);
                  }}
                  error={formik.touched.feeAmount && formik.errors.feeAmount}
                  helperText={
                    formik.touched.feeAmount && formik.errors.feeAmount
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="studentType">Frecuencia</InputLabel>
                <Select
                  name="frecuencyFee"
                  label="Frecuencia"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;

                    formik.setFieldValue("frequencyTypeId", value);
                  }}
                  value={formik.values.frequencyTypeId}
                  error={
                    formik.touched.frequencyTypeId &&
                    formik.errors.frequencyTypeId != null
                  }
                  helperText={
                    formik.touched.frequencyTypeId &&
                    formik.errors.frequencyTypeId
                  }
                  autoComplete="off"
                >
                  {typeTables.TBLFREQUENCYFEES
                    ? convertToSelectList(typeTables.TBLFREQUENCYFEES.data).map(
                        (x) => (
                          <MenuItem key={x.value} value={x.value}>
                            {x.text}
                          </MenuItem>
                        )
                      )
                    : null}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} align="right" marginTop={3}>
              <Button variant="contained" onClick={() => formik.handleSubmit()}>
                GUARDAR
              </Button>
            </Grid>
            <Grid item xs={6} marginTop={3}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenDrawer(false)}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        )}
        {newCourseFees.errors && newCourseFees.errors.length > 0 && (
          <ModalMessages errors={newCourseFees.errors} />
        )}
      </Drawer>
      <>
        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"La información ha sido guardada con éxito"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>{" "}
      </>
    </>
  );
};

export default FeesDrawer;
