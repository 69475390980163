import { Card, CardContent, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProfileTitle from "../../../components/backoffice-users-profile/title";
import UsersProfile from "../../../components/backoffice-users-profile/userProfile";
import UserSecurity from "../../../components/backoffice-users-profile/userSecurity";
import { selectCurrentUser } from "../../../store/slices/currentUser";

const Users = () => {
  const user = useSelector(selectCurrentUser);
  const location = useLocation();
  const state = location.state;

  return (
    <>
      <Grid
        container
        pt="0px"
        spacing={2}
        sx={{
          heigth: "auto",
          width: "auto",
          marginBottom: 3,
          marginTop: 0,
          paddingBottom: 22,
        }}
      >
        <Grid item xs={4}>
          <Card
            elevation={3}
            align="center"
            sx={{ heigth: "auto", width: "auto", mb: 1.7 }}
          >
            <CardContent>
              {state ? (
                <ProfileTitle isOther={state.otherUser} />
              ) : (
                <ProfileTitle isOther={false} />
              )}
            </CardContent>
          </Card>

          <Card
            elevation={3}
            align="center"
            sx={{ heigth: "auto", width: "auto" }}
          >
            <CardContent>
              {state ? (
                <UsersProfile user={state} />
              ) : (
                <UsersProfile user={user} />
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={8}>
          <Card
            elevation={3}
            align="center"
            sx={{ heigth: "auto", width: "auto" }}
          >
            <CardContent>
              {state ? (
                <UserSecurity userId={state.userId} userName={state.username} />
              ) : (
                <UserSecurity userId={user.userId} userName={user.userName} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Users;
