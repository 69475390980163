import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiCourses from "../../../services/apiCourses";
import * as apiStudents from "../../../services/apiStudents";

export const coursesAvailablesSlice = createSlice({
  name: "coursesAvailables",
  initialState: {
    item: {
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    list: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },

  reducers: {
    setCoursesAvailables: (state, { payload }) => {
      state.list.data = payload.map((course) => {
        return { ...course, isSelected: false };
      });
    },
    setSelectedCourse: (state, { payload }) => {
      const { index, isSelected } = payload;

      state.list.data = state.list.data.map((course) => {
        if (index === state.list.data.indexOf(course))
          return { ...course, isSelected: isSelected };

        return course;
      });
    },
    setLoading: (state, { payload }) => {
      state.list.isLoading = payload;
    },
    setLoadingCourse: (state, { payload }) => {
      state.item.isLoading = payload;
    },
    setCleanErrorsCoursesAvailables: (state) => {
      state.item.errors = [];
      state.list.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryValidateStudentCourses.pending, (state) => {
      state.list.status = "loading";
      state.list.isLoading = true;
      state.list.errors = [];
    });
    builder.addCase(
      tryValidateStudentCourses.fulfilled,
      (state, { payload }) => {
        state.list.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.list.errors = errors;

        state.list.isLoading = false;
      }
    );

    builder.addCase(
      tryValidateStudentCourses.rejected,
      (state, { payload }) => {
        state.list.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.list.errors = errors;
        state.list.isLoading = false;
      }
    );
    builder.addCase(
      tryDownloadCourseInformation.rejected,
      (state, { payload }) => {
        state.list.status = "failed";
        let errors = [];
        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.list.errors = errors;
        state.list.isLoading = false;
      }
    );
  },
});

export const {
  setCoursesAvailables,
  setSelectedCourse,
  setLoading,
  setLoadingCourse,
  setCleanErrorsCoursesAvailables,
} = coursesAvailablesSlice.actions;

export default coursesAvailablesSlice.reducer;

export const tryCleanErrorsCoursesAvailables = () => (dispatch) => {
  dispatch(setCleanErrorsCoursesAvailables());
};

export const selectCoursesAvailables = (state) => state.coursesAvailables.list;
export const selectedCourses = (state) =>
  state.coursesAvailables.list.data.filter((x) => x.isSelected).length;
export const selectCourseAvailable = (state) => state.coursesAvailables.item;

export const selectedCourse = (course) => (dispatch) => {
  dispatch(setLoadingCourse(true));

  dispatch(setSelectedCourse(course));

  dispatch(setLoadingCourse(false));
};

export const tryCoursesAvailables =
  ({ isVirtual, studentId }) =>
  (dispatch) => {
    dispatch(setLoading(true));
    apiCourses
      .getAvailableCourses({ virtual: isVirtual, studentId })

      .then((resp) => {
        if (resp) {
          dispatch(setCoursesAvailables(resp.data.data));
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(setLoading(false));
      });
  };

export const tryValidateStudentCourses = createAsyncThunk(
  "coursesAvailables/tryValidateStudentCourses",
  async (user, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.validateStudentCourses(user, {
        ruleSet: 3,
      });

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryDownloadCourseInformation = createAsyncThunk(
  "coursesAvailables/tryDownloadCourseInformation",
  async (courseId, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      /* Calling an API to get a file. */
      const response = await apiCourses.getCourseInformationFile(courseId);
      dispatch(setLoading(false));

      return response;
    } catch (err) {
      console.error(err);
      dispatch(setLoading(false));
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
