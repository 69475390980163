import React from "react";
import { Grid, Skeleton } from "@mui/material";

const AddTeacherTicketSkeleton = () => {
  return (
    <>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ height: "40px" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ height: "40px" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ height: "40px" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ height: "40px" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ height: "40px" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ height: "250px" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ height: "40px" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ height: "250px" }} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" sx={{ height: "40px" }} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" sx={{ height: "40px" }} />
      </Grid>
    </>
  );
};

export default AddTeacherTicketSkeleton;
