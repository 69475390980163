import { Divider, Grid, Skeleton } from "@mui/material";
import React from "react";

const TeacherDataSkeleton = () => {
  return (
    <Grid
      container
      rowSpacing={2}
      spacing={3}
      paddingTop={6}
      paddingLeft={4}
      paddingRight={4}
      paddingBottom={6}
      sx={{ backgroundColor: "white" }}
    >
      <Grid item xs={12}>
        <Skeleton
          variant="circular"
          width={135}
          height={135}
          sx={{ margin: "25px 0px 25px" }}
        />
      </Grid>
      <Grid item xs={5}></Grid>
      <Grid item xs={2}>
        <Skeleton variant="rectangular" height={25} />
      </Grid>
      <Grid item xs={5}></Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Divider light sx={{ borderColor: "secondary.light" }} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>
  );
};

export default TeacherDataSkeleton;
