import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPass,
  selectRecoverPass,
  tryChangePassword,
  tryRecoverPass,
} from "../../store/slices/password";
import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Spinner from "../layout/spinner";
import { useFormik } from "formik";
import ModalMessages from "../layout/modal-messages";

const regex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,15}$/;

const validate = (values) => {
  const errors = {};

  if (!values.oldPass || values.oldPass <= 0)
    errors.oldPass = "Contraseña actual no puede estar vacío";

  if (!regex.test(values.newPass) || values.oldPass === values.newPass)
    errors.newPass = "La contraseña no cumple con los requerimientos";

  return errors;
};

const TeacherSecurity = ({ userId, username, roleId }) => {
  const dispatch = useDispatch();
  const { errors, isLoading } = useSelector(selectPass);

  const selectedRecover = useSelector(selectRecoverPass);

  //Dialog para controlar la confimación del cambio de contraseña
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);

  //Dialogs para controlar la confirmacion de restablecer contraseña
  const [recoverDialog, setRecoverDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  //Manejo si se muestra o no la contraseña
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const handleClickShowPasswordOld = () => setShowPasswordOld((show) => !show);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const handleClickShowPasswordNew = () => setShowPasswordNew((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setSuccessDialog(false);
    formik.handleReset();
  };

  //Restablecer clave
  const recoverPass = async (queryModel) => {
    const action = await dispatch(tryRecoverPass(queryModel));
    if (action.payload.data.isValid) {
      setConfirmationDialog(true);
    }
  };

  //Cambiar Clave

  const changePass = async (model) => {
    setOpenDialog(false);
    const action = await dispatch(tryChangePassword(model));

    if (action.payload.data.isValid) {
      setSuccessDialog(true);
      setOpenDialog(true);
    }
  };
  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPass: "",
      newPass: "",
    },

    validate,
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  const model = {
    userId: userId,
    oldPassword: formik.values.oldPass,
    newPassword: formik.values.newPass,
  };

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Grid container textAlign="center" spacing={3} sx={{ pt: 3, pb: 3 }}>
          {roleId == 1 && (
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                disabled={selectedRecover.isLoading}
                color="error"
                onClick={() => setRecoverDialog(true)}
              >
                {selectedRecover.isLoading && <Spinner color="primary" />}
                Restablecer contraseña
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
              Cambio de contraseña
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="left">
            <Alert severity="warning">
              <AlertTitle>
                Asegúrate de cumplir con los requerimientos de seguridad
              </AlertTitle>
              - De 8 a 15 caracteres <br />- La nueva contraseña debe incluir al
              menos un dígito, una letra minúscula, una letra mayúscula y un
              caracter no alfanumérico. <br />- La nueva contraseña no podrá ser
              igual a la contraseña actual
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel htmlFor="component-outline">
                Contraseña Actual
              </InputLabel>
              <OutlinedInput
                name="oldPass"
                label="Contraseña Actual"
                variant="outlined"
                size="small"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.oldPass}
                type={showPasswordOld ? "text" : "password"}
                inputProps={{
                  maxlength: "15",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordOld}
                      onKeyDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordOld ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                error={formik.touched.oldPass && Boolean(formik.errors.oldPass)}
              />
              <FormHelperText>
                {formik.touched.oldPass && formik.errors.oldPass}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel htmlFor="component-outline">
                Contraseña Nueva
              </InputLabel>
              <OutlinedInput
                name="newPass"
                label="Contraseña Nueva"
                variant="outlined"
                size="small"
                type={showPasswordNew ? "text" : "password"}
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.newPass}
                inputProps={{
                  maxlength: "15",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordNew}
                      onKeyDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                error={formik.touched.newPass && Boolean(formik.errors.newPass)}
              />
              <FormHelperText>
                {formik.touched.newPass && formik.errors.newPass}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button variant="contained" disabled={isLoading} type="submit">
              {isLoading && <Spinner color="primary" />}
              Confirmar
            </Button>

            {/* Dialog de CONFIRMACION */}
            {!successDialog ? (
              <>
                <Dialog
                  open={openDialog}
                  onClose={onCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"¿Confirma cambiar la contraseña?"}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={onCloseDialog}>Cancelar</Button>
                    <Button onClick={() => changePass(model)} autoFocus>
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <>
                <Dialog
                  open={openDialog}
                  onClose={onCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"La contraseña ha sido modificada con éxito"}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={() => closeDialog()} autoFocus>
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>{" "}
              </>
            )}
            {recoverDialog && (
              <>
                <Dialog
                  open={recoverDialog}
                  onClose={onCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"¿Confirma restablecer la contraseña?"}
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        recoverPass(username);
                        setRecoverDialog(false);
                      }}
                      autoFocus
                    >
                      Aceptar
                    </Button>
                    <Button onClick={() => setRecoverDialog(false)} autoFocus>
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>{" "}
              </>
            )}
            {confirmationDialog && (
              <>
                <Dialog
                  open={confirmationDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"La contraseña ha sido restablecida"}
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setConfirmationDialog(false);
                      }}
                      autoFocus
                    >
                      Aceptar
                    </Button>
                    <Button
                      onClick={() => setConfirmationDialog(false)}
                      autoFocus
                    >
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>{" "}
              </>
            )}
          </Grid>
          {errors && errors.length > 0 && <ModalMessages errors={errors} />}

          {selectedRecover.errors && selectedRecover.errors.length > 0 && (
            <ModalMessages errors={selectedRecover.errors} />
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default TeacherSecurity;
