import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectManualPreLiquidation,
  tryPutManualCliPrecase,
} from "../../../store/slices/backoffice-teachers";
import {
  Typography,
  Grid,
  Button,
  Drawer,
  IconButton,
  TextField,
  FormControl,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useFormik } from "formik";
import ModalMessages from "../../layout/modal-messages";
import Spinner from "../../layout/spinner";
import { NumericFormat } from "react-number-format";

const validate = (values) => {
  const errors = {};

  if (!values.amount || values.amount === "") errors.amount = "Requerido";

  if (!values.coment || values.coment === "") errors.coment = "Requerido";

  return errors;
};

const NewChargeDrawer = ({
  openDrawer,
  setOpenDrawer,
  setOpenConfirmationDialog,
  dataDrawer,
}) => {
  const dispatch = useDispatch();
  const manualPreLiquidation = useSelector(selectManualPreLiquidation);

  const formik = useFormik({
    eneableReinitialize: true,
    initialValues: {
      batchprocessid: dataDrawer.batchprocessid,
      cliPrecaseId: dataDrawer.cliPrecaseId,
      amount: "",
      coment: "",
    },
    validate,
    onSubmit: async (values) => {
      const action = await dispatch(tryPutManualCliPrecase(values));
      if (action.payload.data.isValid) {
        setOpenConfirmationDialog(true);
        setOpenDrawer(false);
      }
    },
  });

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      PaperProps={{
        sx: {
          zIndex: 1203,
          width: 500,
          boxSizing: "border-box",
        },
      }}
      onClose={() => setOpenDrawer(false)}
      sx={{
        zIndex: 1203,
        width: 500,
        flexShrink: 0,
      }}
    >
      <Grid container p={1}>
        <Grid item xs={10}>
          <Typography
            align="left"
            sx={{
              paddingLeft: 2,
              paddingRight: 5,
              paddingBottom: 1,
              paddingTop: 4,
              fontSize: "1.5em",
            }}
          >
            Nuevo Gasto
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "end" }}>
          <IconButton
            sx={{ color: "white" }}
            onClick={() => setOpenDrawer(false)}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ paddingLeft: 2, paddingBottom: 3 }}>
          <Typography variant="h7" align="center">
            Por favor, complete información en cada campo que se solicite en el
            formulario
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        spacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        p={2}
        sx={{ backgroundColor: "white" }}
      >
        <>
          <Grid item xs={12}>
            <Typography
              sx={{
                paddingLeft: 1,
                paddingRight: 5,
                paddingBottom: 1,
                fontSize: "1.5em",
              }}
              align="left"
              color="black"
            >
              Prof. {dataDrawer.teacher}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <NumericFormat
                variant="outlined"
                label="Monto del pago"
                size="small"
                name="amount"
                thousandSeparator="."
                decimalSeparator=","
                isNumericString
                allowNegative={true}
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                InputLabelProps={{ shrink: true }}
                customInput={TextField}
                value={formik.values.amount}
                onValueChange={(values) => {
                  const { floatValue } = values;
                  formik.setFieldValue("amount", floatValue);
                }}
                error={formik.touched.amount && formik.errors.amount}
                helperText={formik.touched.amount && formik.errors.amount}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} whiteSpace="normal">
            <TextField
              id="coment"
              label="Comentarios"
              multiline
              rows={5}
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.coment}
              error={formik.touched.coment && formik.errors.coment}
              helperText={formik.touched.coment && formik.errors.coment}
            />
          </Grid>

          <Grid item sx={{ width: "100%", textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={formik.handleSubmit}
              disabled={manualPreLiquidation.isLoading}
              sx={{
                marginTop: 2,
                marginBottom: 2,
                width: 100,
                marginRight: 2,
              }}
            >
              {manualPreLiquidation?.isLoading && <Spinner color="primary" />}
              Generar
            </Button>
            <Button
              variant="outline"
              color="error"
              disabled={manualPreLiquidation.isLoading}
              sx={{
                border: 2,
                marginTop: 2,
                marginBottom: 2,
                width: 100,
                color: "red",
              }}
              onClick={() => setOpenDrawer(false)}
            >
              Cancelar
            </Button>
          </Grid>
        </>
      </Grid>
      {manualPreLiquidation.errors &&
        manualPreLiquidation.errors.length > 0 && (
          <ModalMessages errors={manualPreLiquidation.errors} />
        )}{" "}
    </Drawer>
  );
};

export default NewChargeDrawer;
