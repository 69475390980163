import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTypeTable,
  selectMaintenances,
} from "../../../store/slices/maintenances";
import {
  selectCourseGroup,
  selectNewCourseGroupsSchedules,
  tryCreateCourseGroupsSchedules,
} from "../../../store/slices/back-course";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ModalMessages from "../../layout/modal-messages";
import BackCoursesCommissionCourseGroupsSchedulesDrawerSkeleton from "../skeleton/courseGroupsSchedulesDrawer";
import { useFormik } from "formik";
import { PatternFormat } from "react-number-format";
import convertToSelectList from "../../layout/functions/convert-to-select-list";
import { IsValidHour } from "../../../services/functions/validator";
import { FormatDate } from "../../../services/functions/formatDate";

const validate = (values) => {
  const errors = {};
  const newStartHour = FormatDate(values.startHour, "HH:mm");
  const newEndHour = FormatDate(values.endHour, "HH:mm");

  if (!IsValidHour(values.startHour)) {
    errors.startHour = "Ingrese una hora válida en formato HH:MM";
  }
  if (!IsValidHour(values.endHour)) {
    errors.endHour = "Ingrese una hora válida en formato HH:MM";
  }

  if (newStartHour.isSameOrAfter(newEndHour)) {
    errors.endHour = "Incongruencia en los horarios";
  }

  if (!values.courseGroupId || values.courseGroupId === "")
    errors.courseGroupId = "Requerido";

  if (!values.weekDayId || values.weekDayId === "")
    errors.weekDayId = "Requerido";

  if (!values.startHour || values.startHour === "")
    errors.startHour = "Requerido";

  if (!values.endHour || values.endHour === "") errors.endHour = "Requerido";

  return errors;
};

const ClassDaysDrawer = ({
  openDrawer,
  setOpenDrawer,
  dataClassDaysDrawer,
  setOpenDialog,
}) => {
  const dispatch = useDispatch();
  const typeTables = useSelector(selectMaintenances);

  const newCourseGroupsSchedules = useSelector(selectNewCourseGroupsSchedules);

  //Trae los datos de las comisiones
  const courseGroupDetail = useSelector(selectCourseGroup);

  //Carga los datos del combo dias de la semana
  useEffect(() => {
    dispatch(fetchTypeTable(48)); //TBLWEEKDAYS
  }, []);

  //Carga los datos del combo comisiones
  const [courseGroupsList, setCourseGroupsList] = useState(undefined);

  useEffect(() => {
    let list = [];
    list = courseGroupDetail.data.map((x) => {
      return {
        title: x.title,
        courseGroupId: x.courseGroupId,
      };
    });
    setCourseGroupsList(list);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      courseGroupsSchedulesId: 0,
      courseGroupId: 0,
      weekDayId: "",
      startHour: "",
      endHour: "",
    },
    validate,
    onSubmit: () => {
      const trySubmit = async () => {
        formik.values.startHour = FormatDate(formik.values.startHour, "HH:mm", "HH:mm:ss") //prettier-ignore

        formik.values.endHour = FormatDate(formik.values.endHour, "HH:mm", "HH:mm:ss") //prettier-ignore

        const action = await dispatch(
          tryCreateCourseGroupsSchedules(formik.values)
        );
        if (action.payload.data.isValid) {
          setOpenDrawer(false);
          setOpenDialog(true);
        }
      };
      trySubmit();
    },
  });

  useEffect(() => {
    formik.setValues({
      courseGroupsSchedulesId: dataClassDaysDrawer.courseGroupsSchedulesId,
      courseGroupId: dataClassDaysDrawer.courseGroupId,
      weekDayId: dataClassDaysDrawer.weekDayId,
      startHour: FormatDate(dataClassDaysDrawer.startHour, "HH:mm:ss", "HH:mm"),
      endHour: FormatDate(dataClassDaysDrawer.endHour, "HH:mm:ss", "HH:mm"),
    });
  }, [openDrawer]);

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        PaperProps={{
          sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
        }}
        onClose={() => setOpenDrawer(false)}
        sx={{
          zIndex: 1203,
          width: 500,
          flexShrink: 0,
        }}
      >
        <Grid container p={1}>
          <Grid item xs={10}>
            <Typography
              variant="h4"
              align="left"
              sx={{
                paddingLeft: 1,
                paddingRight: 5,
                paddingBottom: 1,
                paddingTop: 4,
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              Cursada
            </Typography>
            <Typography
              sx={{
                paddingLeft: 1,
                paddingRight: 5,
                paddingBottom: 3,
                paddingTop: 1,
              }}
            >
              Por favor, complete información del campo requerido
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setOpenDrawer(false)}
            >
              <GridCloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {newCourseGroupsSchedules.isLoading ? (
          <BackCoursesCommissionCourseGroupsSchedulesDrawerSkeleton />
        ) : (
          <Grid
            container
            rowSpacing={3}
            spacing={3}
            paddingTop={6}
            paddingLeft={4}
            paddingRight={4}
            paddingBottom={6}
            sx={{ backgroundColor: "white" }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="commission">Comisión</InputLabel>
                <Select
                  name="commission"
                  label="Comisión"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;

                    formik.setFieldValue("courseGroupId", value);
                  }}
                  value={formik.values.courseGroupId}
                  error={
                    formik.touched.courseGroupId && formik.errors.courseGroupId
                  }
                  helperText={
                    formik.touched.courseGroupId && formik.errors.courseGroupId
                  }
                  autoComplete="off"
                >
                  {courseGroupsList &&
                    courseGroupsList.map((x) => {
                      return (
                        <MenuItem key={x.courseGroupId} value={x.courseGroupId}>
                          {x.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="day">Día</InputLabel>
                <Select
                  name="day"
                  label="Día"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;

                    formik.setFieldValue("weekDayId", value);
                  }}
                  value={formik.values.weekDayId}
                  error={formik.touched.weekDayId && formik.errors.weekDayId}
                  helperText={
                    formik.touched.weekDayId && formik.errors.weekDayId
                  }
                  autoComplete="off"
                >
                  {typeTables.TBLWEEKDAYS
                    ? convertToSelectList(typeTables.TBLWEEKDAYS.data).map(
                        (x) => (
                          <MenuItem key={x.value} value={x.value}>
                            {x.text}
                          </MenuItem>
                        )
                      )
                    : null}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} align="center">
              <Typography
                sx={{ color: "black", fontWeight: "bold", fontSize: 14 }}
              >
                Hora Desde
              </Typography>
              <PatternFormat
                id="startHour"
                label=""
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => {
                  const { value } = e.target;

                  formik.setFieldValue("startHour", value);
                }}
                value={formik.values.startHour}
                error={formik.touched.startHour && formik.errors.startHour}
                helperText={formik.touched.startHour && formik.errors.startHour}
                customInput={TextField}
                format="##:##"
                mask="_"
              />
            </Grid>

            <Grid item xs={6} align="center">
              <Typography
                sx={{ color: "black", fontWeight: "bold", fontSize: 14 }}
              >
                Hora Hasta
              </Typography>
              <PatternFormat
                id="endHour"
                label=""
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => {
                  const { value } = e.target;

                  formik.setFieldValue("endHour", value);
                }}
                value={formik.values.endHour}
                error={formik.touched.endHour && formik.errors.endHour != null}
                helperText={formik.touched.endHour && formik.errors.endHour}
                customInput={TextField}
                format="##:##"
                mask="_"
              />
            </Grid>

            <Grid item xs={6} align="right" marginTop={3}>
              <Button variant="contained" onClick={() => formik.handleSubmit()}>
                GUARDAR
              </Button>
            </Grid>
            <Grid item xs={6} marginTop={3}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenDrawer(false)}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        )}
        {newCourseGroupsSchedules.errors &&
          newCourseGroupsSchedules.errors.length > 0 && (
            <ModalMessages errors={newCourseGroupsSchedules.errors} />
          )}
      </Drawer>
    </>
  );
};

export default ClassDaysDrawer;
