import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Drawer,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import {
  selectStudentAttendance,
  tryGetStudentAttendance,
  tryTickStudentAttendance,
} from "../../../store/slices/student-courses-attendances";
import ModalMessages from "../../layout/modal-messages";
import CustomColumnMenu from "../../layout/grid-column-menu";
import Spinner from "../../layout/spinner";
import moment from "moment";

const CoursesDrawer = ({
  openDrawer,
  setOpenDrawer,
  courseId,
  studentId,
  course,
  setConfirmationDialog,
}) => {
  const dispatch = useDispatch();

  const attendanceTable = useSelector(selectStudentAttendance);
  const [rows, setRows] = useState([]);

  const model = {
    studentId: studentId,
    courseId: courseId,
  };

  const saveAttendances = async (model) => {
    const action = await dispatch(
      tryTickStudentAttendance({ studentId, courseId, model })
    );

    if (action.payload.isValid) {
      setConfirmationDialog(true);
      setOpenDrawer(false);
    }
  };

  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          id: false,
        },
      },
      pagination: { paginationModel: { pageSize: 6 } },
    };
  };

  //Manejo de switch
  const handleChange = (params, value) => {
    const updatedRows = rows.map((row) =>
      row.id === params.id ? { ...row, studentValid: value } : row
    );
    setRows(updatedRows);
  };

  //beforeToday false indica que SI se filtren las clases hasta el dia de hoy
  const beforeToday = true;

  useEffect(() => {
    dispatch(tryGetStudentAttendance({ model, beforeToday }));
  }, []);

  useEffect(() => {
    setRows(
      attendanceTable.data.map((x) => {
        return {
          id: x.attendanceId,
          attendanceId: x.attendanceId,
          studentCourseId: x.studentCourseId,
          attendanceDate: x.attendanceDate,
          studentValid: x.studentValid ?? false,
          teacherValid: x.teacherValid ?? false,
        };
      })
    );
  }, [attendanceTable.data]);

  const columns = [
    { field: "id", headerName: "Id", filterable: false },
    {
      field: "attendanceDate",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "Clase",
      headerClassName: "headRow",
      width: 260,

      valueFormatter: (params) => {
        if (params.value === null) return "";
        return moment(params.value).format("ll");
      },
    },
    {
      field: "studentValid",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "Asistencia",
      headerClassName: "headRow",
      width: 130,

      renderCell: (params) => {
        return (
          <Switch
            label={params.value}
            checked={params.value ?? false}
            color="success"
            onChange={(_, value) => {
              handleChange(params, value);
            }}
          />
        );
      },
    },
  ];

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      PaperProps={{
        sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
      }}
      onClose={() => setOpenDrawer(false)}
      sx={{
        zIndex: 1203,
        width: 500,
        flexShrink: 0,
      }}
    >
      <Grid container p={1}>
        <Grid item xs={10}>
          <Typography
            variant="h4"
            align="left"
            sx={{
              paddingLeft: 1,
              paddingRight: 5,
              paddingBottom: 1,
              paddingTop: 4,
            }}
          >
            Marcar Asistencia
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "end" }}>
          <IconButton
            sx={{ color: "white" }}
            onClick={() => setOpenDrawer(false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: 1,
            paddingBottom: 3,
          }}
        >
          <Typography variant="h7" align="center">
            La siguiente grilla muestra un detalle de la asistencia que usted
            mantiene en la actualidad con este curso.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        spacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        p={2}
        sx={{ backgroundColor: "white" }}
      >
        <Grid item xs={12}>
          <Typography
            style={{ color: "black", fontWeight: "bold", fontSize: 18 }}
          >
            {course}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            slots={{
              columnMenu: CustomColumnMenu,
            }}
            autoHeight={true}
            rowSelection={false}
            getRowId={(row) => row.id}
            rows={rows}
            columns={columns}
            rowsPerPageOptions={[5]}
            initialState={initializeDataGrid()}
          />
        </Grid>

        <Grid item sx={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={() => saveAttendances(rows)}
            disabled={attendanceTable.isLoading}
            sx={{
              marginTop: 2,
              marginBottom: 2,
              width: 100,
              marginRight: 2,
            }}
          >
            {attendanceTable.isLoading && <Spinner color="primary" />}
            Guardar
          </Button>
          <Button
            variant="outline"
            color="error"
            disabled={attendanceTable.isLoading}
            sx={{
              border: 2,
              marginTop: 2,
              marginBottom: 2,
              width: 100,
              color: "red",
            }}
            onClick={() => setOpenDrawer(false)}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
      {attendanceTable.errors && attendanceTable.errors.length > 0 && (
        <ModalMessages errors={attendanceTable.errors} />
      )}
    </Drawer>
  );
};

export default CoursesDrawer;
