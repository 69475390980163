import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCourse,
  selectCourseGroup,
  selectCourseGroupsSchedules,
  tryDeleteCourseGroup,
  tryDeleteCourseGroupsSchedules,
  tryGetCourseGroup,
  tryGetCourseGroupsSchedules,
} from "../../store/slices/back-course";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { MoreHoriz, Assignment } from "@mui/icons-material";
import CommissionsDrawer from "./drawers";
import ClassDaysDrawer from "./drawers/classDaysDrawer";
import BackCoursesCommissionSkeleton from "./skeleton";
import BackCoursesSchedulesSkeleton from "./skeleton";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";

const CoursesCommissions = ({ course }) => {
  const dispatch = useDispatch();
  const courseGroupDetail = useSelector(selectCourseGroup);
  const courseGroupsSchedulesDetail = useSelector(selectCourseGroupsSchedules);

  //Setea si el drawer esta abierto [courseGroup]
  const [openDrawer, setOpenDrawer] = useState(false);

  //Setea si el drawer esta abierto [courseGroupsSchedules]
  const [openClassDaysDrawer, setOpenClassDaysDrawer] = useState(false);

  //Setea la informacion que se le pasa a los drawers
  const [dataDrawer, setDataDrawer] = useState(undefined);
  const [dataClassDaysDrawer, setDataClassDaysDrawer] = useState(undefined);

  //Setea la info de las filas de comisiones
  const [commissionsRows, setCommissionsRows] = useState([]);

  //Trae el estado del curso
  const courseDetail = useSelector(selectCourse);

  //Setea las filas de la grilla de
  const [classDaysRows, setClassDaysRows] = useState([]);

  //Setea si se abre el dialog
  const [openDialog, setOpenDialog] = useState(false);

  //DETALLE DE LAS COMISIONES
  useEffect(() => {
    let list = [];
    (async () => {
      const action = await dispatch(tryGetCourseGroup(course));
      if (action.payload.data.isValid) {
        if (action.payload.data.data.length > 0) {
          list = action.payload.data.data.map((x) => {
            return {
              courseId: x.courseId,
              courseGroupId: x.courseGroupId,
              title: x.title,
              description: x.description,
              maxVacancy: x.maxVacancy,
              outVacancies: x.outVacancies,
              classRoom: x.classRoom,
            };
          });
        }
      }
      setCommissionsRows(list);
    })();
  }, []);

  //REFRESCO EL DETALLE
  useEffect(() => {
    if (openDialog) {
      let list = [];
      (async () => {
        const action = await dispatch(tryGetCourseGroup(course));
        if (action.payload.data.isValid) {
          if (action.payload.data.data.length > 0) {
            list = action.payload.data.data.map((x) => {
              return {
                courseId: x.courseId,
                courseGroupId: x.courseGroupId,
                title: x.title,
                description: x.description,
                maxVacancy: x.maxVacancy,
                outVacancies: x.outVacancies,
                classRoom: x.classRoom,
              };
            });
          }
        }
        setCommissionsRows(list);
      })();
    }
  }, [openDialog]);

  //DETALLE DE LOS DIAS DE CURSADA
  useEffect(() => {
    let list = [];
    (async () => {
      const action = await dispatch(tryGetCourseGroupsSchedules(course));
      if (action.payload.data.isValid) {
        if (action.payload.data.data.length > 0) {
          list = action.payload.data.data.map((x) => {
            return {
              courseGroupId: x.courseGroupId,
              title: x.courseGroups?.title,
              weekDay: x.weekDay?.description,
              courseGroupsSchedulesId: x.courseGroupsSchedulesId,
              endHour: x.endHour,
              startHour: x.startHour,
              weekDayId: x.weekDayId,
            };
          });
        }
      }
      setClassDaysRows(list);
    })();
  }, []);

  //Refresco el detalle cndo hay cambios
  useEffect(() => {
    if (openDialog) {
      let list = [];
      (async () => {
        const action = await dispatch(tryGetCourseGroupsSchedules(course));
        if (action.payload.data.isValid) {
          if (action.payload.data.data.length > 0) {
            list = action.payload.data.data.map((x) => {
              return {
                courseGroupId: x.courseGroupId,
                title: x.courseGroups?.title,
                courseGroupsSchedulesId: x.courseGroupsSchedulesId,
                endHour: x.endHour,
                startHour: x.startHour,
                weekDay: x.weekDay?.description,
                weekDayId: x.weekDayId,
              };
            });
          }
        }
        setClassDaysRows(list);
      })();
    }
  }, [openDialog]);

  //Inic de la grilla de comisiones
  const initializeCommissionsDataGrid = () => {
    return {
      ...commissionsRows,
      columns: {
        ...commissionsColumns,
        columnVisibilityModel: {
          courseGroupId: false,
        },
      },
      pagination: false,
    };
  };

  //Inic de la grilla de cursada
  const initializeClassDaysDataGrid = () => {
    return {
      ...commissionsRows,
      columns: {
        ...commissionsColumns,
        columnVisibilityModel: {
          courseGroupsSchedulesId: false,
          weekDayId: false,
          courseGroupId: false,
        },
      },
      pagination: false,
    };
  };

  //AGREGAR COMISION
  const newCourseGroup = () => {
    setDataDrawer({
      title: "",
      id: course,
      description: "",
      classRoom: "",
      maxVacancy: "",
      outVacancies: !courseGroupDetail.data
        ? courseDetail.maxVacancy
        : courseGroupDetail.data.outVacancies,
      courseGroupId: 0,
    });
    setOpenDrawer(true);
  };

  //CARGAR LA CURSADA DE UNA COMISION
  const newCourseGroupShedule = () => {
    setDataClassDaysDrawer({
      courseGroupsSchedulesId: 0,
      courseGroupId: 0,
      weekDayId: "",
      startHour: "",
      endHour: "",
    });
    setOpenClassDaysDrawer(true);
  };

  //ACCIONES COMISIONES
  const ActionsRow = ({ item }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleMenuItemClick = (event, index) => {
      //EDITAR
      if (index === 0) {
        setDataDrawer({
          title: item.row.title,
          courseGroupId: item.row.courseGroupId,
          description: item.row.description,
          classRoom: item.row.classRoom,
          maxVacancy: item.row.maxVacancy,
          outVacancies: item.row.outVacancies,
        });
        setOpenDrawer(true);
      }
      //ELIMINAR
      if (index === 1) {
        (async () => {
          const action = await dispatch(
            tryDeleteCourseGroup(item.row.courseGroupId)
          );
          if (action.payload.data.isValid) {
            setOpenDialog(true);
          }
        })();
      }

      setOpen(false);
    };
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem
                      key="setAttendance"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Editar
                    </MenuItem>

                    <MenuItem
                      key="downloadProgram"
                      onClick={(event) => handleMenuItemClick(event, 1)}
                    >
                      Eliminar
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  //ACCIONES CURSADA
  const ActionsRowSchedules = ({ item }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleMenuItemClick = (event, index) => {
      //EDITAR
      if (index === 0) {
        setDataClassDaysDrawer({
          courseGroupsSchedulesId: item.row.courseGroupsSchedulesId,
          courseGroupId: item.row.courseGroupId,
          weekDay: item.row.weekDay,
          weekDayId: item.row.weekDayId,
          startHour: item.row.startHour,
          endHour: item.row.endHour,
        });
        setOpenClassDaysDrawer(true);
      }
      //ELIMINAR
      if (index === 1) {
        (async () => {
          const action = await dispatch(
            tryDeleteCourseGroupsSchedules(item.row.courseGroupsSchedulesId)
          );
          if (action.payload.data.isValid) {
            setOpenDialog(true);
          }
        })();
      }

      setOpen(false);
    };
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };
    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem
                      key="setAttendance"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Editar
                    </MenuItem>

                    <MenuItem
                      key="downloadProgram"
                      onClick={(event) => handleMenuItemClick(event, 1)}
                    >
                      Eliminar
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  //COLUNMAS GRILLA COMISIONES
  const commissionsColumns = [
    { field: "courseGroupId", headerName: "Id", filterable: false },
    {
      field: "title",
      headerAlign: "center",
      align: "left",
      flex: 0.3,
      headerName: "TÍTULO",
      minWidth: 250,
      headerClassName: "headRow",
    },
    {
      field: "description",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "DESCRIPCIÓN",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "maxVacancy",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "VACANTES MAX.",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "outVacancies",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "VACANTES ACTUALES",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "classRoom",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "AULA",
      minWidth: 150,
      headerClassName: "headRow",
    },

    {
      field: "actions",
      headerAlign: "center",

      headerName: "",
      flex: 0.3,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) => <ActionsRow item={params} />,
    },
  ];

  //COLUNMAS GRILLA CURSADA
  const classDaysColumns = [
    { field: "courseGroupsSchedulesId", headerName: "Id", filterable: false },
    {
      field: "courseGroupId",
      headerAlign: "center",
      align: "left",
      flex: 0.3,
      headerName: "COMISIÓN",
      minWidth: 250,
      headerClassName: "headRow",
    },
    {
      field: "title",
      headerAlign: "center",
      align: "left",
      flex: 0.3,
      headerName: "COMISIÓN",
      minWidth: 250,
      headerClassName: "headRow",
    },
    {
      field: "weekDay",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "DÍA",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "weekDayId",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "DÍA",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "startHour",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "HORA DESDE",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "endHour",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "HORA HASTA",
      minWidth: 150,
      headerClassName: "headRow",
    },

    {
      field: "actions",
      headerAlign: "center",

      headerName: "",
      flex: 0.3,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) => <ActionsRowSchedules item={params} />,
    },
  ];

  return (
    <>
      {courseGroupDetail.isLoading ? (
        <BackCoursesCommissionSkeleton />
      ) : (
        <Grid
          container
          pt="0px"
          spacing={2}
          sx={{
            heigth: "auto",
            width: "auto",
            marginBottom: 3,
            marginTop: 0,
            paddingBottom: 10,
            marginLeft: 3,
            marginRight: 3,
          }}
          rowSpacing={6}
        >
          <Grid item xs={6}>
            <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
              Comisiones
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Button
              variant="contained"
              onClick={() => newCourseGroup()}
              endIcon={<Assignment />}
            >
              AGREGAR COMISIÓN
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              slots={{
                columnMenu: CustomColumnMenu,
              }}
              id="courseGroups"
              autoHeight={true}
              rowSelection={false}
              getRowId={(row) => row.courseGroupId}
              rows={commissionsRows}
              columns={commissionsColumns}
              pagination={false}
              hideFooter={true}
              hideFooterRowCount
              hideFooterPagination
              initialState={initializeCommissionsDataGrid()}
            />
          </Grid>
        </Grid>
      )}
      {courseGroupsSchedulesDetail.isLoading ? (
        <BackCoursesSchedulesSkeleton />
      ) : (
        <Grid
          container
          pt="0px"
          spacing={2}
          sx={{
            heigth: "auto",
            width: "auto",
            marginBottom: 3,
            marginTop: 0,
            paddingBottom: 10,
            marginLeft: 3,
            marginRight: 3,
          }}
          rowSpacing={6}
        >
          <Grid item xs={6}>
            <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
              Cursada
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Button
              variant="contained"
              onClick={() => newCourseGroupShedule()}
              endIcon={<Assignment />}
            >
              AGREGAR CURSADA
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              slots={{
                columnMenu: CustomColumnMenu,
              }}
              id="courseGroupsSchedules"
              autoHeight={true}
              rowSelection={false}
              getRowId={(row) => row.courseGroupsSchedulesId}
              rows={classDaysRows}
              columns={classDaysColumns}
              pagination={false}
              hideFooter={true}
              hideFooterRowCount
              hideFooterPagination
              initialState={initializeClassDaysDataGrid()}
            />
          </Grid>
        </Grid>
      )}
      {setOpenDrawer && (
        <CommissionsDrawer
          //Le paso al drawer los controladres de abrir y cerrar el mismo
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          //Le paso al drawer los datos para mostar
          courseGroupId={dataDrawer?.courseGroupId}
          course={course}
          titleGroup={dataDrawer?.title}
          descriptionGroup={dataDrawer?.description}
          maxVacancyGroup={dataDrawer?.maxVacancy}
          outVacanciesGroup={dataDrawer?.outVacancies}
          classRoomGroup={dataDrawer?.classRoom}
          //Le paso al drawer los controladores del Dialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      )}
      {openClassDaysDrawer && (
        <ClassDaysDrawer
          openDrawer={openClassDaysDrawer}
          setOpenDrawer={setOpenClassDaysDrawer}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          dataClassDaysDrawer={dataClassDaysDrawer}
        />
      )}
    </>
  );
};

export default CoursesCommissions;
