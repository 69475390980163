import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTypeTable,
  selectMaintenances,
} from "../../store/slices/maintenances";
import {
  selectNewTeacher,
  selectTeacherTaxes,
  tryPostClitaxvat,
  tryPostNewTeacher,
} from "../../store/slices/teacher";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import NewTeacherSkeleton from "./skeleton";
import ModalMessages from "../../components/layout/modal-messages";
import convertToSelectList from "../layout/functions/convert-to-select-list";
import { IsValidEmail } from "../../services/functions/validator";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { NumericFormat, PatternFormat } from "react-number-format";
import "dayjs/locale/es";
import Spinner from "../layout/spinner";
import { useFormik } from "formik";
import validateCuil from "../layout/functions/validate-cuil";

const dayjs = require("dayjs");

const validate = (values) => {
  const errors = {};

  if (!values.firstName || values.firstName === "")
    errors.firstName = "Requerido";

  if (!values.lastName || values.lastName === "") errors.lastName = "Requerido";

  if (!values.nationality || values.nationality <= 0)
    errors.nationality = "Requerido";

  if (!values.genre || values.genre === 0) errors.genre = "Requerido";

  if (!values.document || values.document === "") errors.document = "Requerido";

  if (!values.cuil || values.cuil === "") errors.cuil = "Requerido";

  if (!validateCuil(values.cuil)) errors.cuil = "Formato de CUIL inválido";

  if (values.cuil.length < 11) errors.cuil = "Longitud de CUIL inválida";

  if (values.birthDate === null) errors.birthDate = "Requerido";

  if (!values.birthDate || values.birthDate === "0001-01-01T00:00:00")
    errors.birthDate = "Requerido";

  if (dayjs(values.birthDate).$d.toString() === "Invalid Date")
    errors.birthDate = "Fecha inválida";

  if (!values.fullAddress || values.fullAddress === "")
    errors.fullAddress = "Requerido";

  if (!values.phoneAreaCode || values.phoneAreaCode === "")
    errors.phoneAreaCode = "Requerido";

  if (values.phoneAreaCode.length > 4)
    errors.phoneAreaCode = "Máximo 4 dígitos";

  if (!values.phoneNumber || values.phoneNumber === "")
    errors.phoneNumber = "Requerido";

  if (values.phoneNumber.length < 6)
    errors.phoneNumber = "El número debe estar comprendido entre 6 y 10 caracteres"; //prettier-ignore

  if (values.phoneNumber.length > 10)
    errors.phoneNumber =
      "El número debe estar comprendido entre 6 y 10 caracteres";

  if (!values.celAreaCode || values.celAreaCode === "")
    errors.celAreaCode = "Requerido";

  if (values.celAreaCode.length > 4) errors.celAreaCode = "Máximo 4 dígitos";

  if (!values.mobileNumber || values.mobileNumber === "")
    errors.mobileNumber = "Requerido";

  if (values.mobileNumber.length < 6) 
    errors.mobileNumber ="El número debe estar comprendido entre 6 y 10 caracteres"; //prettier-ignore

  if (values.mobileNumber.length > 10)
    errors.mobileNumber = "Máximo 10 dígitos";

  if (!values.province || values.province <= 0) errors.province = "Requerido";

  if (!values.email || values.email === "") errors.email = "Requerido";

  if (values.email !== values.emailCopy)
    errors.email = "Los correos electrónicos no coinciden";

  if (!IsValidEmail(values.email))
    errors.email = "Formato de correo electrónico inválido";

  if (values.CBU.length > 22) errors.CBU = "Longitud incorrecta";

  if (!values.IVACondition || values.IVACondition <= 0)
    errors.IVACondition = "Requerido";

  if (!values.academic || values.academic <= 0) errors.academic = "Requerido";

  if (!values.state || values.state <= 0) errors.state = "Requerido";

  return errors;
};

const NewTeacher = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const typeTables = useSelector(selectMaintenances);
  const newTeacher = useSelector(selectNewTeacher);
  const newTeacherTaxes = useSelector(selectTeacherTaxes);

  //manejo de las provincias para autocomplete
  const [selectedProvince, setSelectedProvince] = useState(null);

  const [openDialog, setOpenDialog] = useState("");

  const onlyLetters = (event) => {
    const onlyLettersPattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/;
    if (!onlyLettersPattern.test(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    dispatch(fetchTypeTable(16)); // Nationality
    dispatch(fetchTypeTable(24)); // StudentTypes
    dispatch(fetchTypeTable(17)); // Provinces
    dispatch(fetchTypeTable(49)); // Gender
    dispatch(fetchTypeTable(23)); // TBLSTATUSCLIS
    dispatch(fetchTypeTable(60)); // TBLTYPECONDVATS
    dispatch(fetchTypeTable(45)); // Academic
  }, []);

  const dniFormater = () => {
    if (formik.values.cuil) {
      const numberAsString = formik.values.cuil.toString().replace(/-/g, "");
      const setDNI = numberAsString.slice(2, -1);
      return parseInt(setDNI, 10);
    }
    if (!formik.values.cuil) return "";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      emailCopy: "",
      email: "",
      birthDate: null,
      fullAddress: "",
      phoneNumber: "",
      mobileNumber: "",
      document: "",
      cuil: "",
      state: null,
      genre: 0,
      nationality: null,
      celAreaCode: "",
      phoneAreaCode: "",
      province: null,
      CBU: "",
      alias: "",
      IVACondition: null,
      notes: "",
      academic: null,
    },
    validate,
    onSubmit: async (values) => {
      let teacherRequest = {
        id: 0,
        clientId: 0,
        username: values.email,
        email: values.email,
        avatar: "",
        clients: {
          id: 0,
          nationality: values.nationality,
          statusId: values.state,
          email: values.email,
          notes: values.notes,
          cliNaturals: {
            cliNarturalId: 0,
            clientId: 0,
            firstName: values.firstName,
            lastName: values.lastName,
          },
          cliAddress: [
            {
              cliAddressId: 0,
              clientId: 0,
              typeAddress: 2,
              fullAddress: values.fullAddress,
              street: "",
              number: "",
              province: values.province,
              country: 1,
              areaCode: values.phoneAreaCode,
              phone: values.phoneNumber,
            },
            {
              cliAddressId: 0,
              clientId: 0,
              typeAddress: 1,
              fullAddress: values.fullAddress,
              street: "",
              number: "",
              province: values.province,
              country: 1,
              areaCode: values.celAreaCode,
              phone: values.mobileNumber,
            },
          ],
          cliContacts: [],
          cliDocuments: [
            {
              cliDocumentId: 0,
              clientId: 0,
              typeDocumentId: 2,
              document: values.cuil.replace(/-/g, ""),
            },
            {
              cliDocumentId: 0,
              clientId: 0,
              typeDocumentId: 1,
              document: values.document.toString(),
            },
          ],
          cliRelations: [],
          cliSocialEconomics: {
            socialEconomicsId: 0,
            clientId: 0,
            situationId: 6, //EMPLEADO
            academicId: values.academic,
            labouralSituationId: 3, //AUTONOMO
            genderId: values.genre,
            birthDate: values.birthDate,
          },
          students: null,
          teachers: {
            teacherId: 0,
            clientId: 0,
            nickName: values.firstName,
            teacherTypeId: 1,
            rating: null,
          },
        },
      };

      const action = await dispatch(tryPostNewTeacher(teacherRequest));

      if (action.payload.isValid) {
        let taxRequest = {
          clientId: action.payload.data.clientId,
          cliTaxVatId: 0,
          typeVatConId: values.IVACondition,
          cliPaymentId: 0,
          alias: values.alias,
          number: values.CBU,
        };
        const postAction = await dispatch(tryPostClitaxvat(taxRequest));
        if (postAction.payload.isValid) {
          setOpenDialog(true);
        }
      }
    },
  });

  //MANEJO GUARDADO AUTOMATICO DEL DNI AL INGRESAR EL CUIL
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      document: dniFormater(),
    });
  }, [formik.values.cuil]);

  //MANEJO BOTON CANCEL
  const handleCancel = () => {
    navigate("/backoffice/teachers");
  };

  return (
    <Container fixed>
      <Grid
        container
        spacing={2}
        sx={{
          margin: 2,
        }}
      >
        <Grid
          sx={{
            backgroundColor: "primary.contrastText",
          }}
          item
          xs={12}
          md={12}
        >
          <Box sx={{ width: "100%" }}>
            <Grid item xs={12} sx={{ textAlign: "start" }}>
              <Button
                variant="text"
                size="large"
                style={{ fontWeight: "bold" }}
                sx={{ marginLeft: -3, marginBottom: 2, width: 100 }}
                onClick={() => navigate("/backoffice/teachers")}
              >
                VOLVER
              </Button>
            </Grid>
            <Grid item xs={12} marginLeft={3}>
              <Typography
                style={{ fontWeight: "bold", fontSize: 18 }}
                align="left"
              >
                Alta Profesor
              </Typography>
              <Divider
                paddingTop={3}
                flexItem
                sx={{ bgcolor: "secondary.light" }}
              />
            </Grid>

            <Container
              fixed
              sx={{
                paddingTop: 5,
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {newTeacher.isLoading ? (
                    <NewTeacherSkeleton />
                  ) : (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          id="firstName"
                          label="Nombre"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onKeyDown={onlyLetters}
                          onChange={formik.handleChange}
                          value={formik.values.firstName}
                          type="text"
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstName)
                          }
                          helperText={
                            formik.touched.firstName && formik.errors.firstName
                          }
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="lastName"
                          label="Apellido"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                          onKeyDown={onlyLetters}
                          error={
                            formik.touched.lastName &&
                            Boolean(formik.errors.lastName)
                          }
                          helperText={
                            formik.touched.lastName && formik.errors.lastName
                          }
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={6} textAlign="left">
                        <FormControl fullWidth>
                          <InputLabel id="nationality-label">
                            Nacionalidad
                          </InputLabel>
                          <Select
                            labelId="nationality-label"
                            name="nationality"
                            value={formik.values.nationality}
                            label="Nacionalidad"
                            size="small"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.nationality &&
                              Boolean(formik.errors.nationality)
                            }
                          >
                            {typeTables.TBLNATIONALITYS
                              ? convertToSelectList(
                                  typeTables.TBLNATIONALITYS.data
                                ).map((x) => (
                                  <MenuItem key={x.value} value={x.value}>
                                    {x.text}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                          {formik.touched.nationality && (
                            <FormHelperText
                              htmlFor="nationality"
                              error={
                                formik.touched.nationality &&
                                !!formik.errors.nationality
                              }
                            >
                              {formik.errors.nationality}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          <FormLabel id="genre-label">Género</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="genre-label"
                            name="genre"
                            onChange={formik.handleChange}
                            value={formik.values.genre}
                          >
                            {typeTables.TBLGENDER
                              ? convertToSelectList(
                                  typeTables.TBLGENDER.data
                                ).map((x) => (
                                  <FormControlLabel
                                    value={x.value}
                                    onChange={formik.handleChange}
                                    control={<Radio size="small" />}
                                    label={x.text}
                                  />
                                ))
                              : null}
                          </RadioGroup>
                          {formik.touched.genre && (
                            <FormHelperText
                              htmlFor="genre"
                              error={
                                formik.touched.genre && formik.errors.genre
                              }
                            >
                              {formik.errors.genre}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <NumericFormat
                            id="document"
                            label="DNI"
                            customInput={TextField}
                            variant="outlined"
                            size="small"
                            disabled={true}
                            onChange={(e) => {
                              const { value } = e.target;

                              formik.setFieldValue("document", value);
                            }}
                            value={dniFormater()}
                            error={
                              formik.touched.document &&
                              Boolean(formik.errors.document)
                            }
                            helperText={
                              formik.touched.document && formik.errors.document
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <PatternFormat
                            id="cuil"
                            label="CUIL"
                            customInput={TextField}
                            format="##-########-#"
                            mask="_"
                            variant="outlined"
                            size="small"
                            onChange={formik.handleChange}
                            value={formik.values.cuil}
                            required
                            error={
                              formik.touched.cuil && Boolean(formik.errors.cuil)
                            }
                            helperText={
                              formik.touched.cuil && formik.errors.cuil
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="email"
                          label="Correo Electrónico"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onPaste={(event) => event.preventDefault()}
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          type="email"
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="emailCopy"
                          label="Confirmación Correo Electrónico"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onPaste={(event) => event.preventDefault()}
                          onChange={formik.handleChange}
                          value={formik.values.emailCopy}
                          type="email"
                          autoComplete="off"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="fullAddress"
                          label="Dirección"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values.fullAddress}
                          error={
                            formik.touched.fullAddress &&
                            Boolean(formik.errors.fullAddress)
                          }
                          helperText={
                            formik.touched.fullAddress
                              ? formik.errors.fullAddress
                              : null
                          }
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={6} textAlign="left">
                        <FormControl fullWidth>
                          <Autocomplete
                            disablePortal
                            size="small"
                            onChange={(e, newV) => {
                              setSelectedProvince(newV);
                              formik.values.province = newV?.id;
                              formik.handleChange(e);
                            }}
                            value={selectedProvince}
                            options={typeTables?.TBLPROVINCES?.data || []}
                            getOptionLabel={(option) =>
                              option.description || ""
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Provincia" />
                            )}
                          />
                          {formik.touched.province && (
                            <FormHelperText
                              htmlFor="province"
                              error={
                                formik.touched.province &&
                                !!formik.errors.province
                              }
                            >
                              {formik.errors.province}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={2}>
                        <FormControl fullWidth>
                          <TextField
                            id="phoneAreaCode"
                            label="Código de Área"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              maxlength: "4",
                            }}
                            fullWidth
                            onChange={(e) => {
                              const { value } = e.target;
                              if (value.length > 0 && value[0] === "0") {
                                formik.setFieldValue(
                                  "phoneAreaCode",
                                  value.substring(1)
                                );
                              } else {
                                formik.setFieldValue("phoneAreaCode", value);
                              }
                            }}
                            value={formik.values.phoneAreaCode}
                            error={
                              formik.touched.phoneAreaCode &&
                              Boolean(formik.errors.phoneAreaCode)
                            }
                            helperText={
                              formik.touched.phoneAreaCode &&
                              formik.errors.phoneAreaCode
                            }
                            autoComplete="off"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <NumericFormat
                            id="phoneNumber"
                            label="Teléfono"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              maxlength: "10",
                            }}
                            fullWidth
                            customInput={TextField}
                            onChange={formik.handleChange}
                            value={formik.values.phoneNumber}
                            error={
                              formik.touched.phoneNumber &&
                              Boolean(formik.errors.phoneNumber)
                            }
                            helperText={
                              formik.touched.phoneNumber &&
                              formik.errors.phoneNumber
                            }
                            autoComplete="off"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl fullWidth>
                          <TextField
                            id="celAreaCode"
                            label="Código de Área"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              maxlength: "4",
                            }}
                            fullWidth
                            onChange={(e) => {
                              const { value } = e.target;
                              if (value.length > 0 && value[0] === "0") {
                                formik.setFieldValue(
                                  "celAreaCode",
                                  value.substring(1)
                                );
                              } else {
                                formik.setFieldValue("celAreaCode", value);
                              }
                            }}
                            value={formik.values.celAreaCode}
                            error={
                              formik.touched.celAreaCode &&
                              Boolean(formik.errors.celAreaCode)
                            }
                            helperText={
                              formik.touched.celAreaCode &&
                              formik.errors.celAreaCode
                            }
                            autoComplete="off"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <NumericFormat
                            id="mobileNumber"
                            label="Celular"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              maxlength: "10",
                            }}
                            onChange={formik.handleChange}
                            value={formik.values.mobileNumber}
                            customInput={TextField}
                            error={
                              formik.touched.mobileNumber &&
                              Boolean(formik.errors.mobileNumber)
                            }
                            helperText={
                              formik.touched.mobileNumber &&
                              formik.errors.mobileNumber
                            }
                            autoComplete="off"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <Alert severity="info">
                          En situaciones urgentes, contar con números de
                          teléfono y nombres de las personas que podrían atender
                          cualquier emergencia es fundamental. Esta información
                          se mantiene completamente confidencial.
                        </Alert>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="notes"
                          label="Información de emergencia"
                          multiline
                          rows={5}
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values.notes}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl
                          fullWidth
                          error={
                            formik.touched.birthDate &&
                            Boolean(formik.errors.birthDate)
                          }
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="es"
                          >
                            <DesktopDatePicker
                              id="birthDate"
                              disableFuture
                              name="birthDate"
                              label="Fecha de Nacimiento"
                              inputFormat="DD/MM/YYYY"
                              value={formik.values.birthDate}
                              onChange={(value) => {
                                formik.setFieldValue(
                                  "birthDate",
                                  dayjs(value).format("YYYY-MM-DD")
                                );
                              }}
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    {...params}
                                  />
                                  <FormHelperText>
                                    {formik.touched.birthDate &&
                                      formik.errors.birthDate}
                                  </FormHelperText>
                                </>
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} textAlign="left">
                        <FormControl fullWidth>
                          <InputLabel id="state-label">Estado</InputLabel>
                          <Select
                            labelId="state-label"
                            name="state"
                            value={formik.values.state}
                            label="Estado"
                            size="small"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.state &&
                              Boolean(formik.errors.state)
                            }
                          >
                            {typeTables.TBLSTATUSCLIS &&
                              convertToSelectList(
                                typeTables.TBLSTATUSCLIS.data
                              ).map((x) => (
                                <MenuItem key={x.value} value={x.value}>
                                  {x.text}
                                </MenuItem>
                              ))}
                          </Select>
                          {formik.touched.state && (
                            <FormHelperText
                              htmlFor="state"
                              error={
                                formik.touched.state && !!formik.errors.state
                              }
                            >
                              {formik.errors.state}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} textAlign="left">
                        <FormControl fullWidth>
                          <InputLabel id="academic-label">
                            Nivel de Estudios Alcanzados
                          </InputLabel>
                          <Select
                            labelId="academic-label"
                            name="academic"
                            value={formik.values.academic}
                            label="Nivel de Estudios Alcanzados"
                            size="small"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.academic &&
                              Boolean(formik.errors.academic)
                            }
                          >
                            {typeTables.TBLACADEMICS &&
                              convertToSelectList(
                                typeTables.TBLACADEMICS.data
                              ).map((x) => (
                                <MenuItem key={x.value} value={x.value}>
                                  {x.text}
                                </MenuItem>
                              ))}
                          </Select>
                          {formik.touched.academic && (
                            <FormHelperText
                              htmlFor="academic"
                              error={
                                formik.touched.academic &&
                                !!formik.errors.academic
                              }
                            >
                              {formik.errors.academic}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={4} textAlign="left">
                        <FormControl fullWidth>
                          <InputLabel id="IVACondition-label">
                            Condicion frente al IVA
                          </InputLabel>
                          <Select
                            labelId="IVACondition-label"
                            name="IVACondition"
                            value={formik.values.IVACondition}
                            label="Condicion frente al IVA"
                            size="small"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.IVACondition &&
                              Boolean(formik.errors.IVACondition)
                            }
                          >
                            {typeTables.TBLTYPECONDVATS &&
                              convertToSelectList(
                                typeTables.TBLTYPECONDVATS.data
                              ).map((x) => (
                                <MenuItem key={x.value} value={x.value}>
                                  {x.text}
                                </MenuItem>
                              ))}
                          </Select>
                          {formik.touched.IVACondition && (
                            <FormHelperText
                              htmlFor="IVACondition"
                              error={
                                formik.touched.IVACondition &&
                                !!formik.errors.IVACondition
                              }
                            >
                              {formik.errors.IVACondition}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          id="alias"
                          label="Alias"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values.alias}
                          error={
                            formik.touched.alias && Boolean(formik.errors.alias)
                          }
                          helperText={
                            formik.touched.alias && formik.errors.alias
                          }
                          autoComplete="off"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <NumericFormat
                          id="CBU"
                          label="CBU"
                          variant="outlined"
                          size="small"
                          fullWidth
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            maxlength: "22",
                          }}
                          customInput={TextField}
                          onChange={formik.handleChange}
                          value={formik.values.CBU}
                          error={
                            formik.touched.CBU && Boolean(formik.errors.CBU)
                          }
                          helperText={formik.touched.CBU && formik.errors.CBU}
                          autoComplete="off"
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={6} align="right">
                    <Button
                      variant="contained"
                      onClick={() => formik.handleSubmit()}
                      disabled={newTeacher.isLoading}
                    >
                      {newTeacher.isLoading && <Spinner color="primary" />}
                      Guardar
                    </Button>
                  </Grid>
                  <Grid item xs={6} align="left">
                    <Button variant="outlined" onClick={() => handleCancel()}>
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>

            {openDialog && (
              <>
                <Dialog
                  open={openDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"La información ha sido guardada con éxito"}
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpenDialog(false);
                        navigate("/backoffice/teachers");
                      }}
                      autoFocus
                    >
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>{" "}
              </>
            )}
          </Box>
          {newTeacher.errors && newTeacher.errors.length > 0 && (
            <ModalMessages errors={newTeacher.errors} />
          )}
          {newTeacherTaxes.errors && newTeacherTaxes.errors.length > 0 && (
            <ModalMessages errors={newTeacherTaxes.errors} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewTeacher;
