import React from "react";
import { Link } from "react-router-dom";

const HelperMail = ({ mailto, label }) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = `mailto:${process.env.REACT_APP_HELPERMAIL}`;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export default HelperMail;
