import React from "react";
import { Typography } from "@mui/material";

const LeftSection = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/logo_utedyc_1.png"}
          width="250px"
        />
      </div>
      <Typography
        color="primary.contrastText"
        variant="h5"
        align="center"
        component="h2"
      >
        <b>INSCRIPCIONES {new Date().getFullYear()}</b>
      </Typography>
      <Typography
        color="primary.contrastText"
        variant="subtitle2"
        align="center"
        sx={{ paddingTop: 3, paddingLeft: 10, paddingRight: 10 }}
      >
        <p>
          <b>Requisitos de inscripción para acceder a los cursos:</b>
        </p>
        <ul
          style={{
            display: "flex",
            "flex-direction": "column",
            "align-items": "center",
          }}
        >
          <li>Tener más de 18 años.</li>
          <li>Recordá que podés anotarte hasta en 2 cursos.</li>
        </ul>
      </Typography>
      <Typography
        color="primary.contrastText"
        variant="subtitle2"
        align="center"
        sx={{ paddingTop: 1, paddingLeft: 10, paddingRight: 10 }}
      >
        <p>
          Si sos Afiliada/o, aportante o familiar directo, deberás presentar
          número o carnet de afiliada/o. En caso de ser empleada/o de UTEDYC
          será el recibo de sueldo. Seguí los pasos a continuación para
          completar tu proceso de inscripción. Recordá abonar la cuota, en caso
          de que corresponda.
        </p>
      </Typography>
      <Typography
        color="primary.contrastText"
        align="center"
        sx={{
          fontWeight: "700",
          paddingTop: 1,
          paddingBottom: 2,
          paddingLeft: 7,
          paddingRight: 7,
        }}
      >
        ¡Asegurá tu vacante, aprovechá al máximo los cursos que elegís y
        transformá tu futuro profesional!
      </Typography>
    </>
  );
};

export default LeftSection;
