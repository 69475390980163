import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  AlertTitle,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import {
  tryStudentTicketsSummary,
  selectStudentTicketsSummary,
} from "../../store/slices/student-tickets";
import StudentTicketsSummarySkeleton from "./skeleton";
import moment from "moment";
import ModalMessages from "../layout/modal-messages";

const StudentTicketsSummary = ({ businessId }) => {
  const dispatch = useDispatch();

  const ticketSummary = useSelector(selectStudentTicketsSummary);

  useEffect(() => {
    dispatch(tryStudentTicketsSummary(businessId));
  }, []);

  return (
    <Container>
      {!ticketSummary.isLoading ? (
        <Grid pt={3} pb={3} container spacing={3}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 20 }}>
              <strong style={{ fontWeight: "bold" }}>Resumen</strong>
            </Typography>
          </Grid>
          {ticketSummary.haveTicketsNotRead && (
            <Grid item xs={12}>
              <Alert severity="warning">
                <AlertTitle>Comunicaciones</AlertTitle>
                Por favor, verificar las comunicaciones pendientes.
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Última comunicación enviada:{" "}
              {ticketSummary.lastTicketSubmitted
                ? moment(ticketSummary.lastTicketSubmitted).format("ll")
                : "Sin información"}
            </Typography>
            <Typography sx={{ color: "grey" }}>
              {ticketSummary.subjectLastTicketSubmitted
                ? ticketSummary.subjectLastTicketSubmitted
                : "Sin información"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Última comunicación respondida:{" "}
              {ticketSummary.lastTicketAnswered
                ? moment(ticketSummary.lastTicketAnswered).format("ll")
                : "Sin información"}
            </Typography>
            <Typography sx={{ color: "grey" }}>
              {ticketSummary.subjectLastTicketAnswered
                ? ticketSummary.subjectLastTicketAnswered
                : "Sin información"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Comunicaciones respondidas/finalizadas{" "}
              {ticketSummary.ticketsNotAnsweredNotRead ?? 0} de{" "}
              {ticketSummary.totalTickets ?? 0}
            </Typography>
            <LinearProgress
              sx={{
                fontSize: 60,
                width: "400px",
                height: "10px",
              }}
              variant="determinate"
              value={ticketSummary.progress ?? 0}
            />
            <Typography sx={{ color: "grey" }}>
              Record de comunicaciones
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <StudentTicketsSummarySkeleton />
      )}
      {ticketSummary.errors && ticketSummary.errors.length > 0 && (
        <ModalMessages errors={ticketSummary.errors} />
      )}
    </Container>
  );
};
export default StudentTicketsSummary;
