import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiStudents from "../../../services/apiStudents";
import * as apiMaintenance from "../../../services/apiMaintenances";

export const studentTicketsSlice = createSlice({
  name: "studentTickets",
  initialState: {
    item: {
      isLoading: false,
      errors: [],
    },
    summary: {
      haveTicketsNotRead: true,
      lastTicketSubmitted: undefined,
      subjectLastTicketSubmitted: "",
      lastTicketAnswered: undefined,
      subjectLastTicketAnswered: "",
      ticketsNotAnsweredNotRead: 0,
      totalTickets: 0,
      progress: 0,
      isLoading: false,
      errors: [],
      status: "idle",
    },
    list: {
      data: [],
      totalPages: 1,
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    export: {
      id: 0,
      name: "",
      file: "",
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    updated: {
      data: false,
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    details: {
      item: {
        ticketId: 0,
        ownerId: null,
        originalSenderId: 0,
        topicId: 0,
        subTopicId: 0,
        subject: null,
        statusTicketId: 0,
        closeDate: null,
        ticketDetails: [],
      },
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    tabSummary: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    subTopics: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    closedTicket: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setStudentTickets: (state, { payload }) => {
      state.item = { ...state.item, ...payload };
    },

    setStudentTicketsLoading: (state, { payload }) => {
      state.item.isLoading = payload;
    },
    setStudentTicketsListLoading: (state, { payload }) => {
      state.list.isLoading = payload;
    },
    setStudentTicketsDetailsLoading: (state, { payload }) => {
      state.details.isLoading = payload;
    },
    setCleanErrorsStudentTickets: (state) => {
      state.item.errors = [];
      state.summary.errors = [];
      state.list.errors = [];
      state.export.errors = [];
      state.updated.errors = [];
      state.details.errors = [];
      state.tabSummary.errors = [];
      state.subTopics.errors = [];
      state.closedTicket.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryStudentTicketsSummary.pending, (state) => {
      state.summary.status = "loading";
      state.summary.isLoading = true;
      state.summary.errors = [];
    });
    builder.addCase(
      tryStudentTicketsSummary.fulfilled,
      (state, { payload }) => {
        state.summary.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.summary.errors = errors;

        if (payload.data.isValid === true) {
          state.summary = { ...payload.data.data };
        }

        state.summary.isLoading = false;
      }
    );
    builder.addCase(tryStudentTicketsSummary.rejected, (state, { payload }) => {
      state.summary.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.summary.errors = errors;
      state.summary.isLoading = false;
    });
    builder.addCase(tryStudentTickets.pending, (state) => {
      state.list.status = "loading";
      state.list.isLoading = true;
      state.list.errors = [];
    });
    builder.addCase(tryStudentTickets.fulfilled, (state, { payload }) => {
      state.list.status = "succeeded";

      if (payload.data.isValid) {
        state.list = { ...payload.data };
        state.list.totalPages = payload.meta.totalPages;
      }

      const errors = payload.isValid ? payload.errors : [];
      state.list.errors = errors;

      state.list.isLoading = false;
    });
    builder.addCase(tryStudentTickets.rejected, (state, { payload }) => {
      state.list.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.list.errors = errors;
      state.list.isLoading = false;
    });
    builder.addCase(tryCreateStudentTicket.pending, (state) => {
      state.updated.status = "loading";
      state.updated.isLoading = true;
      state.updated.errors = [];
    });
    builder.addCase(tryCreateStudentTicket.fulfilled, (state, { payload }) => {
      state.updated.status = "succeeded";

      if (payload.data.isValid) {
        state.updated = { ...payload.data };
      }

      const errors = payload.isValid ? payload.errors : [];
      state.updated.errors = errors;

      state.updated.isLoading = false;
    });
    builder.addCase(tryCreateStudentTicket.rejected, (state, { payload }) => {
      state.updated.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.data.status !== 401) {
        if (payload.data.isValid === false) errors = payload.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.updated.errors = errors;
      state.updated.isLoading = false;
    });
    builder.addCase(tryGetStudentTicketDetails.pending, (state) => {
      state.details.status = "loading";
      state.details.isLoading = true;
      state.details.errors = [];
    });
    builder.addCase(
      tryGetStudentTicketDetails.fulfilled,
      (state, { payload }) => {
        state.details.status = "succeeded";

        if (payload.data.isValid) {
          state.details.item = { ...payload.data.data };
        }

        const errors = payload.isValid ? payload.errors : [];
        state.details.errors = errors;

        state.details.isLoading = false;
      }
    );
    builder.addCase(
      tryGetStudentTicketDetails.rejected,
      (state, { payload }) => {
        state.details.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.data.status !== 401) {
          if (payload.data.isValid === false) errors = payload.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.details.errors = errors;
        state.details.isLoading = false;
      }
    );

    //TRAE EL RESUMEN DE LAS ULTIMAS COMUNICACIONES

    builder.addCase(tryGetTicketsTabSummary.pending, (state) => {
      state.tabSummary.status = "loading";
      state.tabSummary.isLoading = true;
      state.tabSummary.errors = [];
    });
    builder.addCase(tryGetTicketsTabSummary.fulfilled, (state, { payload }) => {
      state.tabSummary.status = "succeeded";

      if (payload.data.isValid) {
        state.tabSummary.data = [...payload.data.data];
      }

      const errors = payload.isValid ? payload.errors : [];
      state.tabSummary.errors = errors;

      state.tabSummary.isLoading = false;
    });
    builder.addCase(tryGetTicketsTabSummary.rejected, (state, { payload }) => {
      state.tabSummary.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.data.status !== 401) {
        if (payload.data.isValid === false) errors = payload.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.tabSummary.errors = errors;
      state.tabSummary.isLoading = false;
    });

    //EXPORTA EL DETALLE DE COMUNICACIONES

    builder.addCase(tryStudentTicketsExport.pending, (state) => {
      state.export.status = "loading";
      state.export.isLoading = true;
      state.export.errors = [];
    });
    builder.addCase(tryStudentTicketsExport.fulfilled, (state, { payload }) => {
      state.export.status = "succeeded";

      if (payload.data.isValid) {
        state.export.data = { ...payload.data.data };
      }

      const errors = payload.isValid ? payload.errors : [];
      state.export.errors = errors;

      state.export.isLoading = false;
    });
    builder.addCase(tryStudentTicketsExport.rejected, (state, { payload }) => {
      state.export.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.data.status !== 401) {
        if (payload.data.isValid === false) errors = payload.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.export.errors = errors;
      state.export.isLoading = false;
    });

    // TRAE LA LISTA DE SUBTOPICS ASOCIADA A UN TOPIC
    builder.addCase(tryGetSubtopics.pending, (state) => {
      state.subTopics.status = "loading";
      state.subTopics.isLoading = true;
      state.subTopics.errors = [];
    });
    builder.addCase(tryGetSubtopics.fulfilled, (state, { payload }) => {
      state.subTopics.status = "succeeded";

      if (payload.data.isValid) {
        state.subTopics.data = [...payload.data.data];
      }

      const errors = payload.isValid ? payload.errors : [];
      state.subTopics.errors = errors;

      state.subTopics.isLoading = false;
    });
    builder.addCase(tryGetSubtopics.rejected, (state, { payload }) => {
      state.subTopics.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.data.status !== 401) {
        if (payload.data.isValid === false) errors = payload.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.subTopics.errors = errors;
      state.subTopics.isLoading = false;
    });

    //CIERRA UN TICKET
    builder.addCase(tryCloseTicket.pending, (state) => {
      state.closedTicket.status = "loading";
      state.closedTicket.isLoading = true;
      state.closedTicket.errors = [];
    });
    builder.addCase(tryCloseTicket.fulfilled, (state, { payload }) => {
      state.closedTicket.status = "succeeded";

      if (payload.data.isValid) {
        state.closedTicket.data = { ...payload.data.data };
      }

      const errors = payload.isValid ? payload.errors : [];
      state.closedTicket.errors = errors;

      state.closedTicket.isLoading = false;
    });
    builder.addCase(tryCloseTicket.rejected, (state, { payload }) => {
      state.closedTicket.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.data.status !== 401) {
        if (payload.data.isValid === false) errors = payload.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.closedTicket.errors = errors;
      state.closedTicket.isLoading = false;
    });
  },
});

export const {
  setStudentTickets,
  setStudentTicketsLoading,
  setStudentTicketsListLoading,
  setStudentTicketsDetailsLoading,
  setCleanErrorsStudentTickets,
} = studentTicketsSlice.actions;

export default studentTicketsSlice.reducer;

export const tryCleanErrorsStudentTickets = () => (dispatch) => {
  dispatch(setCleanErrorsStudentTickets());
};

export const selectStudentTickets = (state) => state.studentTickets.list;

export const selectStudentTicketsSummary = (state) => state.studentTickets.summary; //prettier-ignore

export const selectStudentTicketsExport = (state) => state.studentTickets.export; //prettier-ignore

export const selectStudentTicketsDetail = (state) => state.studentTickets.item;

export const selectSubTopics = (state) => state.studentTickets.subTopics;

export const selectStudentTicketsUpdate = (state) =>
  state.studentTickets.updated; //pretttier-ignore

export const selectStudentTicketsTabSummary = (state) =>
  state.studentTickets.tabSummary;

export const selectStudentTicketDetails = (state) => state.studentTickets.details; //prettier-ignore

export const tryStudentTicketsSummary = createAsyncThunk(
  "studentTickets/tryStudentTicketsSummary",
  async (studentId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getTicketsSummary(studentId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryStudentTicketsExport = createAsyncThunk(
  "studentTickets/tryStudentTicketsExport",
  async (studentId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getStudentTicketsExport(studentId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetSubtopics = createAsyncThunk(
  "studentTickets/tryGetSubtopics",
  async (topicId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiMaintenance.getSubtopics(topicId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryStudentTickets = createAsyncThunk(
  "studentTickets/tryStudentTickets",
  async ({ studentId, pageNumber }, { rejectWithValue }) => {
    try {
      const query = {
        isPaginated: true,
        Sort: "Desc",
        PageNumber: pageNumber,
        PageSize: 5,
      };

      const response = await apiStudents.getTicketsByStudentHeader(
        studentId,
        query
      );

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryCreateStudentTicket = createAsyncThunk(
  "studentTickets/tryCreateStudentTicket",
  async ({ businessId, request }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setStudentTicketsListLoading(true));
      const response = await apiStudents.createStudentTicket(
        businessId,
        request
      );
      dispatch(setStudentTicketsListLoading(false));
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetStudentTicketDetails = createAsyncThunk(
  "studentTickets/tryGetStudentTicketDetails",
  async ({ studentId, ticketId }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setStudentTicketsListLoading(true));
      const response = await apiStudents.getStudentTicketDetails(
        studentId,
        ticketId
      );
      dispatch(setStudentTicketsListLoading(false));
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetAttachmentByTicketDetail = createAsyncThunk(
  "studentTickets/tryGetAttachmentByTicketDetail",
  async (
    { businessId, ticketId, ticketDetaildId },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setStudentTicketsDetailsLoading(true));
      const response = await apiStudents.getAttachmentByTicketDetail(
        businessId,
        ticketId,
        ticketDetaildId
      );
      dispatch(setStudentTicketsDetailsLoading(false));
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetTicketsTabSummary = createAsyncThunk(
  "studentTickets/tryGetTicketsTabSummary",
  async (studentId, { rejectWithValue }) => {
    try {
      const query = {
        isPaginated: true,
        Sort: "Desc",
        PageNumber: 1,
        PageSize: 5,
      };

      const response = await apiStudents.getTicketsTabSummary(studentId, query);

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryCloseTicket = createAsyncThunk(
  "studentTickets/tryCloseTicket",
  async (ticketId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.postCloseTicket(ticketId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
