import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBackStudents,
  selectDonwloadedStudentsReport,
  selectExportedStudents,
  selectStudentMails,
  tryExportStudentsAll,
  tryGetStudentMails,
  tryGetStudentsAll,
  tryGetStudentsReport,
} from "../../store/slices/backoffice-students";
import {
  Button,
  ButtonGroup,
  Card,
  Chip,
  ClickAwayListener,
  Container,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import {
  MoreHoriz,
  Download,
  PersonAdd,
  Email,
  Assessment,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import CustomColumnMenu from "../layout/grid-column-menu";
import { DataGrid } from "@mui/x-data-grid";
import ModalMessages from "../layout/modal-messages";
import Spinner from "../layout/spinner";
import StudentsListSkeleton from "./skeleton";
import ModalMails from "./modal/mailModal";

const BackOfficeStudentsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //TRAE EL DETALLE DE LOS MAILS
  const studentMails = useSelector(selectStudentMails);

  //TRAE EL DETALLE DE LOS ALUMNOS
  const students = useSelector(selectBackStudents);

  //TRAE EL DETALLE DE LOS ALUMNOS EXPORTADOS
  const exportedSudents = useSelector(selectExportedStudents);

  //MANEJO DE LAS COLUMNAS
  const [rows, setRows] = useState([]);

  //TRA EL DETALLE DEL REPORTE
  const downloadedReport = useSelector(selectDonwloadedStudentsReport);

  //MANEJO MODAL MAILS
  const [open, setOpen] = useState(false);
  const [mails, setMails] = useState(null);

  useEffect(() => {
    dispatch(tryGetStudentsAll());
  }, []);

  useEffect(() => {
    setRows(
      students.data.map((x) => {
        return {
          clientId: x.clientId,
          fullName: x.fullName,
          statusCli: x.statusCli,
          studentId: x.studentId,
          cuil: addDashes(x.cuil),
          userId: x.userId,
          clientId: x.clientId,
          unansweredTicket: x.unansweredTicket,
          activeCourses: x.activeCourses,
          condition: x.condition,
          coursesWait: x.coursesWait,
          pendingPayments: x.pendingPayments,
          username: x.username,
          studentTypeId: x.studentTypeId,
          statusCliId: x.statusCliId,
        };
      })
    );
  }, [students]);

  //Exporta el listado de estudiantes
  const handleClickExport = async () => {
    const action = await dispatch(tryExportStudentsAll());
    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //DESCARGA EL REPORTE DE ALUMNOS
  const handleClickReport = async () => {
    const action = await dispatch(tryGetStudentsReport());
    if (action.payload.data.isValid) window.open(action.payload.data.data.file);
  };

  //AGREGA GUIONES A LOS CUILS
  function addDashes(number) {
    if (typeof number !== "string") {
      number = number.toString();
    }

    if (number.length <= 2) {
      return number;
    }

    const pre = number.slice(0, 2);
    const mid = number.slice(2, -1);
    const last = number.slice(-1);

    return `${pre}-${mid}-${last}`;
  }

  //ACCIONES
  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          studentId: false,
          courseGroupId: false,
          userId: false,
          clientId: false,
          username: false,
          statusCliId: false,
        },
      },
      pagination: { paginationModel: { pageSize: 10 } },
    };
  };

  //NAVEGAR A PREINSCRIBE
  const navigateNewStudent = () => {
    navigate("/signup");
  };

  const ActionsRow = ({ item }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleMenuItemClick = (event, index) => {
      //ADMINISTRAR ESTUDIANTE
      if (index === 0) {
        navigate("/students", {
          state: {
            studentId: item.row.studentId,
            userId: item.row.userId,
            clientId: item.row.clientId,
            username: item.row.username,
            studentCuil: item.row.cuil,
          },
        });
      }

      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    <MenuItem
                      key="adminStudent"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Administrar Alumno
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const handleClickEmails = async (isActive) => {
    const action = await dispatch(tryGetStudentMails(isActive));
    if (action.payload.data.isValid) {
      setMails(action.payload.data.data);
      setOpen(true);
    }
  };

  const columns = [
    { field: "username", headerName: "username", flex: 1, filterable: false },
    { field: "clientId", headerName: "clientId", flex: 1, filterable: false },
    { field: "studentId", headerName: "studentId", flex: 1, filterable: false },
    { field: "userId", headerName: "userId", flex: 1, filterable: false },
    {
      field: "fullName",
      headerName: "Nombre Completo",
      flex: 2,
      headerClassName: "headRow",
    },
    {
      field: "cuil",
      headerName: "CUIL/CUIT",
      flex: 1,
      headerClassName: "headRow",
    },
    {
      field: "condition",
      headerName: "Condición Frente a UTEDYC",
      flex: 1,
      headerClassName: "headRow",
    },

    {
      field: "activeCourses",
      headerAlign: "center",
      align: "center",
      headerName: "Cursos Activos",
      flex: 1,
      headerClassName: "headRow",
    },

    {
      field: "coursesWait",
      headerAlign: "center",
      align: "center",
      headerName: "Cursos en Espera",
      flex: 1,
      headerClassName: "headRow",
    },

    {
      field: "pendingPayments",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      headerName: "Pagos sin revisar",
      headerClassName: "headRow",
    },
    {
      field: "unansweredTicket",
      headerName: "Comunicaciones Pendientes",
      align: "center",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";
        let chipColor;
        let chipValue;
        if (params.value) {
          chipColor = "warning";
          chipValue = "SI";
        }
        if (!params.value) {
          chipColor = "success";
          chipValue = "NO";
        }

        return <Chip label={chipValue} color={chipColor} size="small" />;
      },
    },
    {
      field: "statusCliId",
      headerName: "Estado",
      align: "center",
      flex: 1,
      headerClassName: "headRow",
      renderCell: (params) => {
        if (params.value === null) return "";
        let chipColor;
        if (params.value === 1) {
          chipColor = "success";
        }
        if (params.value === 2) {
          chipColor = "error";
        }
        if (params.value === 3) {
          chipColor = "warning";
        }

        return (
          <Chip label={params.row.statusCli} color={chipColor} size="small" />
        );
      },
    },

    {
      field: "actions",
      headerAlign: "center",

      headerName: "",
      flex: 1,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) => <ActionsRow item={params} />,
    },
  ];

  return (
    <>
      {students.isLoading ? (
        <StudentsListSkeleton />
      ) : (
        <>
          <Grid container spacing={3} sx={{ pt: 3, pb: 3 }}>
            <Grid item xs={2}>
              <Typography
                align="left"
                style={{ fontWeight: "bold", fontSize: 18 }}
              >
                Alumnos
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ textAlign: "end" }}>
              <Button
                variant="contained"
                disabled={studentMails.isLoading}
                endIcon={<Email />}
                onClick={() => handleClickEmails(false)}
                sx={{ marginRight: 1 }}
              >
                {studentMails.isLoading && <Spinner color="primary" />}
                CORREOS TODOS
              </Button>
              <Button
                variant="contained"
                disabled={studentMails.isLoading}
                endIcon={<Email />}
                onClick={() => handleClickEmails(true)}
                sx={{ marginRight: 1 }}
              >
                {studentMails.isLoading && <Spinner color="primary" />}
                CORREOS CURSANDO
              </Button>

              <Button
                variant="contained"
                disabled={students.isLoading || exportedSudents.isLoading}
                endIcon={<Download />}
                onClick={() => handleClickExport()}
                sx={{ marginRight: 1 }}
              >
                {students.isLoading && <Spinner color="primary" />}
                EXPORTAR
              </Button>
              <Button
                variant="contained"
                disabled={
                  students.isLoading ||
                  exportedSudents.isLoading ||
                  downloadedReport.isLoading
                }
                endIcon={<Assessment />}
                onClick={() => handleClickReport()}
                sx={{ marginRight: 1 }}
              >
                {downloadedReport.isLoading && <Spinner color="primary" />}
                REPORTE
              </Button>
              <Button
                variant="contained"
                color="success"
                endIcon={<PersonAdd />}
                onClick={() => navigateNewStudent()}
              >
                CARGAR ALUMNO
              </Button>
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                slots={{
                  columnMenu: CustomColumnMenu,
                }}
                autoHeight={true}
                rowSelection={false}
                getRowId={(row) => row.clientId}
                disableRowSelectionOnClick
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 25, 50]}
                initialState={initializeDataGrid()}
                sx={{
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: 250,
                  },
                }}
              />
            </Grid>
          </Grid>
          {students.errors && students.errors.length > 0 && (
            <ModalMessages errors={students.errors} />
          )}
          {exportedSudents.errors && exportedSudents.errors.length > 0 && (
            <ModalMessages errors={exportedSudents.errors} />
          )}
        </>
      )}
      {open && <ModalMails mails={mails} open={open} setOpen={setOpen} />}
    </>
  );
};

export default BackOfficeStudentsList;
