import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCourseFees,
  selectNewAutoFees,
  selectNewCourseFees,
  tryAutoCourseFees,
  tryDeleteCourseFee,
  tryGetCourseFees,
} from "../../store/slices/back-course";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { MoreHoriz, Assignment, PostAdd } from "@mui/icons-material";
import FeesDrawer from "./drawer";
import CustomColumnMenu from "../layout/grid-column-menu";
import BackCoursesFeesSkeleton from "./skeleton";
import { NumericFormat } from "react-number-format";
import { DataGrid } from "@mui/x-data-grid";
import Spinner from "../layout/spinner";
import ModalMessages from "../layout/modal-messages";

const CoursesPrices = ({ course }) => {
  const dispatch = useDispatch();

  //Setea si se abre el drawer
  const [openDrawer, setOpenDrawer] = useState(false);

  //Setea la información que se envía al drawer
  const [dataDrawer, setDataDrawer] = useState(undefined);

  //Setea si se abre el dialog
  const [openDialog, setOpenDialog] = useState(false);

  //DEFINO LAS FILAS
  const [rows, setRows] = useState([]);

  //Trae el estado de los costos
  const coursesFeesDetail = useSelector(selectCourseFees);
  const newCoursesFeesDetail = useSelector(selectNewCourseFees);
  const newAutoFees = useSelector(selectNewAutoFees);

  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          feesId: false,
          frequencyTypeId: false,
          studentTypeId: false,
          feeType: false,
        },
      },
      pagination: false,
    };
  };

  //DETALLE DE LAS TARIFAS
  const fetchData = async () => {
    let list = [];
    const action = await dispatch(tryGetCourseFees(course));
    if (action.payload.data.isValid) {
      if (action.payload.data.data.length > 0) {
        list = action.payload.data.data.map((x) => {
          return {
            feesId: x.feesId,
            studentType: x.studentType.description,
            feesType: x.feesType.description,
            feeAmount: x.feeAmount,
            frequencyType: x.frequencyType.description,
            studentTypeId: x.studentTypeId,
            feeType: x.feeType,
            frequencyTypeId: x.frequencyTypeId,
          };
        });
      }
    }
    setRows(list);
  };

  useEffect(() => {
    fetchData();
  }, []);

  //AUTOMATIZAR COSTOS
  const automaticCourseFees = async () => {
    const action = await dispatch(tryAutoCourseFees(course));
    if (action.payload.data.isValid) {
      fetchData();
    }
  };

  //REFRESCAR LA GRILLA
  useEffect(() => {
    (!openDrawer || !openDialog) && fetchData();
  }, [openDrawer, openDialog]);

  //AGREGAR COSTO
  const newCourseFees = () => {
    setDataDrawer({
      courseId: course,
      feesId: 0,
      studentTypeId: "",
      feeType: "",
      feeAmount: "",
      frequencyTypeId: "",
    });
    setOpenDrawer(true);
  };

  //ACCIONES
  const ActionsRow = ({ item }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleMenuItemClick = (event, index) => {
      //EDITAR
      if (index === 0) {
        setDataDrawer({
          courseId: course,
          feesId: item.row.feesId,
          studentTypeId: item.row.studentTypeId,
          feeType: item.row.feeType,
          feeAmount: item.row.feeAmount,
          frequencyTypeId: item.row.frequencyTypeId,
        });
        setOpenDrawer(true);
      }
      //ELIMINAR
      if (index === 1) {
        (async () => {
          const action = await dispatch(tryDeleteCourseFee(item.row.feesId));
          if (action.payload.data.isValid) {
            setOpenDialog(true);
            await dispatch(tryGetCourseFees(course));
          }
        })();
      }

      setOpen(false);
    };
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Acciones"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <MoreHoriz />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="left"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem
                      key="setAttendance"
                      onClick={(event) => handleMenuItemClick(event, 0)}
                    >
                      Editar
                    </MenuItem>

                    <MenuItem
                      key="downloadProgram"
                      onClick={(event) => handleMenuItemClick(event, 1)}
                    >
                      Eliminar
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  //COLUMNAS
  const columns = [
    { field: "feesId", headerName: "Id", filterable: false },
    {
      field: "studentType",
      headerAlign: "center",
      align: "left",
      flex: 0.3,
      headerName: "TÍPO DE ESTUDIANTE",
      minWidth: 250,
      headerClassName: "headRow",
    },
    {
      field: "feesType",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "TIPO DE CARGO",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "feeAmount",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "MONTO",
      minWidth: 150,
      headerClassName: "headRow",
      renderCell: (params) => (
        <NumericFormat
          key={`${params.field}_${params.id}`}
          value={params.value ?? 0}
          decimalScale="2"
          thousandSeparator="."
          displayType="text"
          decimalSeparator=","
          prefix="$"
          fixedDecimalScale="2"
        />
      ),
    },
    {
      field: "frequencyType",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "FRECUENCIA",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "feeType",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "feeType",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "studentTypeId",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "studentTypeId",
      minWidth: 150,
      headerClassName: "headRow",
    },
    {
      field: "frequencyTypeId",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      headerName: "frequencyTypeId",
      minWidth: 150,
      headerClassName: "headRow",
    },

    {
      field: "actions",
      headerAlign: "center",

      headerName: "",
      flex: 0.3,
      headerClassName: "headRow",
      filterable: false,
      align: "center",
      renderCell: (params) => <ActionsRow item={params} />,
    },
  ];

  return (
    <>
      {coursesFeesDetail.isLoading ? (
        <BackCoursesFeesSkeleton />
      ) : (
        <Grid
          container
          pt="0px"
          spacing={2}
          sx={{
            heigth: "auto",
            width: "auto",
            marginBottom: 3,
            marginTop: 0,
            paddingBottom: 10,
            marginLeft: 3,
            marginRight: 3,
          }}
          rowSpacing={6}
        >
          <Grid item xs={4}>
            <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
              Costos
            </Typography>
          </Grid>
          <Grid item xs={8} align="right">
            <Button
              variant="contained"
              onClick={() => automaticCourseFees()}
              endIcon={<PostAdd />}
              sx={{ marginRight: 1 }}
              color="success"
              disabled={newAutoFees.isLoading}
            >
              {newAutoFees.isLoading && <Spinner color="primary" />}
              COSTOS AUTOMATICOS
            </Button>
            <Button
              variant="contained"
              onClick={() => newCourseFees()}
              endIcon={<Assignment />}
            >
              COSTO MANUAL
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              slots={{
                columnMenu: CustomColumnMenu,
              }}
              autoHeight={true}
              rowSelection={false}
              getRowId={(row) => row.feesId}
              rows={rows}
              columns={columns}
              pagination={false}
              hideFooter={true}
              hideFooterRowCount
              hideFooterPagination
              initialState={initializeDataGrid()}
            />
          </Grid>
        </Grid>
      )}
      <FeesDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        dataDrawer={dataDrawer}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
      {newAutoFees.errors && newAutoFees.errors.length > 0 && (
        <ModalMessages errors={newAutoFees.errors} />
      )}
    </>
  );
};

export default CoursesPrices;
