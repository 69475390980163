import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiStudents from "../../../services/apiStudents";

export const paymentNotificationSlice = createSlice({
  name: "paymentNotification",
  initialState: {
    item: {
      paymentId: 0,
      studentCourseId: 0,
      paymentCode: "",
      paymentAmount: 0,
      wayOfPayment: 0,
      complement: "",
      paymentDate: "",
      fileOfPayment: "",
      stateOfPayment: 0,
      isValid: false,
      isLoading: false,
      errors: [],
    },
  },
  reducers: {
    setPaymentNotification: (state, { payload }) => {
      state.item = { ...state.list, ...payload };
    },

    setPaymentNotificationLoading: (state, { payload }) => {
      state.item.isLoading = payload;
    },
    setCleanErrorsStudentPaymentNotification: (state) => {
      state.item.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryPaymentNotificationDetail.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(
      tryPaymentNotificationDetail.fulfilled,

      (state, { payload }) => {
        state.item.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.item.errors = errors;

        if (payload.data.isValid === true) {
          state.item = payload.data;
        }

        state.item.isLoading = false;
      }
    );
    builder.addCase(
      tryPaymentNotificationDetail.rejected,
      (state, { payload }) => {
        state.item.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.item.errors = errors;
        state.item.isLoading = false;
      }
    );
  },
});

export const selectPaymentsNotification = (state) =>
  state.paymentNotification.item;

export const {
  setPaymentNotification,
  setPaymentNotificationLoading,
  setCleanErrorsStudentPaymentNotification,
} = paymentNotificationSlice.actions;

export default paymentNotificationSlice.reducer;

export const tryCleanErrorsStudentPaymentNotification = () => (dispatch) => {
  dispatch(setCleanErrorsStudentPaymentNotification());
};

export const tryPaymentNotificationDetail = createAsyncThunk(
  "paymentNotification/trySelectedPaymentNotification",
  async (studentId, courseId, paymentId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado

      const response = await apiStudents.getPaymentNotification(
        studentId,
        courseId,
        paymentId
      );
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
