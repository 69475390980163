import React from "react";
import { Container, Divider, Grid, Skeleton, Typography } from "@mui/material";

const BackCoursesGeneralSkeleton = () => {
  return (
    <Container
      sx={{
        paddingTop: 5,
      }}
    >
      <Grid
        container
        sx={{
          heigth: "auto",
          width: "auto",
          marginBottom: 3,
          marginTop: 0,
          paddingBottom: 10,

          gap: "3px",
        }}
      >
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Datos Generales
          </Typography>
          <Divider
            paddingTop={3}
            flexItem
            sx={{ bgcolor: "secondary.light" }}
          />
        </Grid>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} marginTop={4}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={4} marginTop={4}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={4} marginTop={4}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={4} marginTop={4}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: 5 }}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} width={565} />
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <Skeleton variant="rectangular" height={40} width={565} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} marginTop={4}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={6} marginTop={4}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} align="center">
            <img
              style={{ with: 260, height: 277, marginTop: 75 }}
              src={
                process.env.PUBLIC_URL +
                "/images/1b2730a4de2129fd3166ea63181c3839.png"
              }
            />
          </Grid>
          <Grid container marginTop={6}>
            <Grid item xs={12} marginLeft={3}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
            <Grid item xs={12} marginLeft={3}>
              <Skeleton variant="rectangular" height={100} />
            </Grid>
            <Grid item xs={12} marginLeft={3}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
            <Grid item xs={12} marginLeft={3}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
            <Grid item xs={12} marginLeft={3}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: 5 }}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} width={565} />
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <Skeleton variant="rectangular" height={40} width={565} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} marginTop={4}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={6} marginTop={4}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
        </Grid>

        <Grid item xs={12} marginTop={4}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
        <Grid item xs={12} marginTop={4}>
          <Skeleton variant="rectangular" height={40} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default BackCoursesGeneralSkeleton;
