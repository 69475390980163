const getExtensionFromBase64 = (base64) => {
  var strings = base64.split(",");
  var extension;
  switch (
    strings[0] //check  extension
  ) {
    case "data:image/jpeg;base64":
      extension = "jpeg";
      break;
    case "data:image/png;base64":
      extension = "png";
      break;
    case "data:application/pdf":
      extension = "pdf";
      break;
    default:
      extension = "jpg";
      break;
  }
  return extension;
};

export default getExtensionFromBase64;
