import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiStudents from "../../../services/apiStudents";

export const backStudentsSlice = createSlice({
  name: "studentsBack",
  initialState: {
    list: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    exported: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    inscribed: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    allMails: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },

    courseGroupEmails: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    donwloadedReport: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    donwloadedReportAttendance: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    userDocument: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    studentScolarship: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },

  reducers: {
    setLoading: (state, { payload }) => {
      state.list.isLoading = payload;
    },
    setCleanErrorsBackofficeStudents: (state) => {
      state.list.errors = [];
      state.exported.errors = [];
      state.inscribed.errors = [];
      state.allMails.errors = [];
      state.courseGroupEmails.errors = [];
      state.donwloadedReport.errors = [];
      state.donwloadedReportAttendance.errors = [];
      state.userDocument.errors = [];
      state.studentScolarship.errors = [];
    },
  },
  extraReducers(builder) {
    //TRAE TODOS LOS ALUMNOS
    builder.addCase(tryGetStudentsAll.pending, (state) => {
      state.list.status = "loading";
      state.list.isLoading = true;
      state.list.errors = [];
    });
    builder.addCase(tryGetStudentsAll.fulfilled, (state, { payload }) => {
      state.list.status = "succeeded";

      if (payload.data.isValid) {
        state.list.data = [...payload.data.data];
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.list.errors = errors;

      state.list.isLoading = false;
    });

    builder.addCase(tryGetStudentsAll.rejected, (state, { payload }) => {
      state.list.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.list.errors = errors;
      state.list.isLoading = false;
    });

    //EXPORTA EL LISTADO DE ALUMNOS
    builder.addCase(tryExportStudentsAll.pending, (state) => {
      state.exported.status = "loading";
      state.exported.isLoading = true;
      state.exported.errors = [];
    });
    builder.addCase(tryExportStudentsAll.fulfilled, (state, { payload }) => {
      state.exported.status = "succeeded";

      if (payload.data.isValid) {
        state.exported.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.exported.errors = errors;

      state.exported.isLoading = false;
    });

    builder.addCase(tryExportStudentsAll.rejected, (state, { payload }) => {
      state.exported.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.exported.errors = errors;
      state.exported.isLoading = false;
    });

    //INSCRIBE A UN ALUMNO PREINSCRIPTO O EN LISTA DE ESPERA
    builder.addCase(tryInscribe.pending, (state) => {
      state.inscribed.status = "loading";
      state.inscribed.isLoading = true;
      state.inscribed.errors = [];
    });
    builder.addCase(tryInscribe.fulfilled, (state, { payload }) => {
      state.inscribed.status = "succeeded";

      if (payload.data.isValid) {
        state.inscribed.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.inscribed.errors = errors;

      state.inscribed.isLoading = false;
    });

    builder.addCase(tryInscribe.rejected, (state, { payload }) => {
      state.inscribed.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.inscribed.errors = errors;
      state.inscribed.isLoading = false;
    });

    //TRAE EL LISTADO DE MAILS
    builder.addCase(tryGetStudentMails.pending, (state) => {
      state.allMails.status = "loading";
      state.allMails.isLoading = true;
      state.allMails.errors = [];
    });
    builder.addCase(tryGetStudentMails.fulfilled, (state, { payload }) => {
      state.allMails.status = "succeeded";

      if (payload.data.isValid) {
        state.allMails.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.allMails.errors = errors;

      state.allMails.isLoading = false;
    });

    builder.addCase(tryGetStudentMails.rejected, (state, { payload }) => {
      state.allMails.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.allMails.errors = errors;
      state.allMails.isLoading = false;
    });

    //TRAE TODOS LOS MAILS DE ESTUDIANTES DE UNA COMISION
    builder.addCase(tryGetCourseGroupEmails.pending, (state) => {
      state.courseGroupEmails.status = "loading";
      state.courseGroupEmails.isLoading = true;
      state.courseGroupEmails.errors = [];
    });
    builder.addCase(tryGetCourseGroupEmails.fulfilled, (state, { payload }) => {
      state.courseGroupEmails.status = "succeeded";

      if (payload.data.isValid) {
        state.courseGroupEmails.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.courseGroupEmails.errors = errors;

      state.courseGroupEmails.isLoading = false;
    });

    builder.addCase(tryGetCourseGroupEmails.rejected, (state, { payload }) => {
      state.courseGroupEmails.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.courseGroupEmails.errors = errors;
      state.courseGroupEmails.isLoading = false;
    });

    //EXPORTA EL REPORTE DE ESTUDIANTES
    builder.addCase(tryGetStudentsReport.pending, (state) => {
      state.donwloadedReport.status = "loading";
      state.donwloadedReport.isLoading = true;
      state.donwloadedReport.errors = [];
    });
    builder.addCase(tryGetStudentsReport.fulfilled, (state, { payload }) => {
      state.donwloadedReport.status = "succeeded";

      if (payload.data.isValid) {
        state.donwloadedReport.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.donwloadedReport.errors = errors;

      state.donwloadedReport.isLoading = false;
    });

    builder.addCase(tryGetStudentsReport.rejected, (state, { payload }) => {
      state.donwloadedReport.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.donwloadedReport.errors = errors;
      state.donwloadedReport.isLoading = false;
    });

    //EXPORTA EL REPORTE DE ASISTENCIAS
    builder.addCase(tryGetCoursesReportAttendance.pending, (state) => {
      state.donwloadedReportAttendance.status = "loading";
      state.donwloadedReportAttendance.isLoading = true;
      state.donwloadedReportAttendance.errors = [];
    });
    builder.addCase(
      tryGetCoursesReportAttendance.fulfilled,
      (state, { payload }) => {
        state.donwloadedReportAttendance.status = "succeeded";

        if (payload.data.isValid) {
          state.donwloadedReportAttendance.data = { ...payload.data.data };
        }

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.donwloadedReportAttendance.errors = errors;

        state.donwloadedReportAttendance.isLoading = false;
      }
    );

    builder.addCase(
      tryGetCoursesReportAttendance.rejected,
      (state, { payload }) => {
        state.donwloadedReportAttendance.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.donwloadedReport.errors = errors;
        state.donwloadedReport.isLoading = false;
      }
    );

    //DESCARGA EL ARCHIVO ADJUNTO DE UN FAMILIAR O UN EMPLEADO
    builder.addCase(tryGetFileDocument.pending, (state) => {
      state.userDocument.status = "loading";
      state.userDocument.isLoading = true;
      state.userDocument.errors = [];
    });
    builder.addCase(tryGetFileDocument.fulfilled, (state, { payload }) => {
      state.userDocument.status = "succeeded";

      if (payload.data.isValid) {
        state.userDocument.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.userDocument.errors = errors;

      state.userDocument.isLoading = false;
    });

    builder.addCase(tryGetFileDocument.rejected, (state, { payload }) => {
      state.userDocument.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.userDocument.errors = errors;
      state.userDocument.isLoading = false;
    });

    //GENERA UNA BECA ASOCIADA A UN CURSO DE UN ESTUDIANTE
    builder.addCase(tryPostScolarship.pending, (state) => {
      state.studentScolarship.status = "loading";
      state.studentScolarship.isLoading = true;
      state.studentScolarship.errors = [];
    });
    builder.addCase(tryPostScolarship.fulfilled, (state, { payload }) => {
      state.studentScolarship.status = "succeeded";

      if (payload.data.isValid) {
        state.studentScolarship.data = { ...payload.data.data };
      }

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.studentScolarship.errors = errors;

      state.studentScolarship.isLoading = false;
    });

    builder.addCase(tryPostScolarship.rejected, (state, { payload }) => {
      state.studentScolarship.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.studentScolarship.errors = errors;
      state.studentScolarship.isLoading = false;
    });
  },
});

export const { setLoading, setCleanErrorsBackofficeStudents } =
  backStudentsSlice.actions;

export default backStudentsSlice.reducer;

export const tryCleanErrorsBackofficeStudents = () => (dispatch) => {
  dispatch(setCleanErrorsBackofficeStudents());
};

export const selectBackStudents = (state) => state.studentsBack.list;

export const selectExportedStudents = (state) => state.studentsBack.exported;

export const selectInscribed = (state) => state.studentsBack.inscribed;

export const selectStudentMails = (state) => state.studentsBack.allMails;

export const selectCourseGroupEmails = (state) =>
  state.studentsBack.courseGroupEmails;

export const selectDonwloadedStudentsReport = (state) =>
  state.studentsBack.donwloadedReport;

export const selectFileDocument = (state) => state.studentsBack.userDocument;
export const selectStudentScolarship = (state) =>
  state.studentsBack.studentScolarship;

export const tryGetStudentsAll = createAsyncThunk(
  "studentsBack/tryGetStudentsAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiStudents.getStudentsAll();
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryExportStudentsAll = createAsyncThunk(
  "studentsBack/tryExportStudentsAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiStudents.getExportStudentsAll();
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryInscribe = createAsyncThunk(
  "studentsBack/tryInscribe",
  async ({ studentId, courseId, model }, { rejectWithValue }) => {
    try {
      const response = await apiStudents.postInscribe(
        studentId,
        courseId,
        model
      );
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetStudentMails = createAsyncThunk(
  "studentsBack/tryGetStudentMails",
  async (isActive, { rejectWithValue }) => {
    try {
      const response = await apiStudents.getStudentMails(isActive);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetCourseGroupEmails = createAsyncThunk(
  "studentsBack/tryGetCourseGroupEmails",
  async (courseGroupId, { rejectWithValue }) => {
    try {
      const response = await apiStudents.getCourseGroupEmails(courseGroupId);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetStudentsReport = createAsyncThunk(
  "studentsBack/tryGetStudentsReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiStudents.getStudentsReport();
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetCoursesReportAttendance = createAsyncThunk(
  "teachersBack/tryGetCoursesReportAttendance",
  async (request, { rejectWithValue }) => {
    try {
      const response = await apiStudents.getAttendanceReport(request);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryGetFileDocument = createAsyncThunk(
  "studentsBack/tryGetFileDocument",
  async (clientId, { rejectWithValue }) => {
    try {
      const response = await apiStudents.getFileDocument(clientId);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);

export const tryPostScolarship = createAsyncThunk(
  "studentsBack/tryPostScolarship",
  async ({ studentCourseId, notes }, { rejectWithValue }) => {
    try {
      const response = await apiStudents.postScolarship(studentCourseId, notes);
      return response;
    } catch (error) {
      return rejectWithValue(error); // Se envia de esta forma para poder recuperar en el "reject" el http.status
    }
  }
);
