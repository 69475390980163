import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/slices/currentUser";
import { Card, CardContent, Grid, Tabs, Tab, Box } from "@mui/material";
import StudentData from "../../components/student-data";
import StudentCourses from "../../components/student-courses";
import StudentPayments from "../../components/student-payments";
import StudentCoursesSummary from "../../components/student-courses-summary";
import StudentPaymentsSummary from "../../components/student-payment-summary";
import StudentSecurity from "../../components/student-security";
import StudentTicketsSummary from "../../components/student-tickets-summary";
import StudentTickets from "../../components/student-tickets";
import StudentSkeleton from "../../components/student-data/skeleton";
import {
  PersonOutline,
  LockOpen,
  AttachMoney,
  NotificationsNone,
  MenuBook,
} from "@mui/icons-material";
import StudentTagSummary from "../../components/student-tag-summary";
import { useLocation } from "react-router-dom";

//Provisional para crear el skeleton
const isLoading = false;

const StudentsPage = () => {
  const location = useLocation();
  const state = location.state;
  const { userId, businessId, clientId, roleId } = useSelector(selectCurrentUser); //prettier-ignore

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid
        container
        pt={0}
        spacing={2}
        // direction={["row", "column"]}
        sx={{
          // heigth: "auto",
          width: "100%",
          marginBottom: 3,
          marginTop: 0,
          paddingBottom: 10,
        }}
      >
        <Grid item md={12} lg={4} p={0}>
          <Card elevation={3} align="center" sx={{ heigth: "auto" }}>
            {!isLoading ? (
              <>
                {state ? (
                  <StudentData userId={state.userId} />
                ) : (
                  <StudentData userId={userId} />
                )}
              </>
            ) : (
              <StudentSkeleton />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={8} p={0}>
          <Grid item p={0}>
            {!isLoading && (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      <Tabs value={value} onChange={handleChange} centered>
                        <Tab
                          icon={<PersonOutline />}
                          label="RESUMEN"
                          iconPosition="start"
                        />
                        <Tab
                          icon={<MenuBook />}
                          label="CURSOS"
                          iconPosition="start"
                        />
                        <Tab
                          icon={<AttachMoney />}
                          label="PAGOS"
                          iconPosition="start"
                        />
                        <Tab
                          icon={<NotificationsNone />}
                          label="COMUNICACIONES"
                          iconPosition="start"
                        />
                        <Tab
                          icon={<LockOpen />}
                          label="SEGURIDAD"
                          iconPosition="start"
                        />
                      </Tabs>
                    </Box>
                  </Grid>
                </Grid>
                {value === 0 && (
                  <Grid container spacing={3}>
                    {/* ******** ULTIMOS CURSOS ************ */}
                    <Grid item xs={12} p={0}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <StudentCourses
                              businessId={state.studentId}
                              userId={state.userId}
                              isSummary={true}
                            />
                          ) : (
                            <StudentCourses
                              businessId={businessId}
                              userId={userId}
                              isSummary={true}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    {/* ******** ULTIMAS COMUNICACIONES ************ */}
                    <Grid item xs={12} p={0}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <StudentTagSummary businessId={state.studentId} />
                          ) : (
                            <StudentTagSummary businessId={businessId} />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    {/* ******** ULTIMOS PAGOS ************ */}
                    <Grid item xs={12} p={0}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <StudentPayments
                              isSummary={true}
                              businessId={state.studentId}
                              clientId={state.clientId}
                            />
                          ) : (
                            <StudentPayments
                              isSummary={true}
                              businessId={businessId}
                              clientId={clientId}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
                {value === 1 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} p={0}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <StudentCoursesSummary
                              businessId={state.studentId}
                            />
                          ) : (
                            <StudentCoursesSummary businessId={businessId} />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} p={0}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <StudentCourses
                              businessId={state.studentId}
                              isSummary={false}
                              userId={state.userId}
                              roleId={roleId}
                            />
                          ) : (
                            <StudentCourses
                              businessId={businessId}
                              isSummary={false}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
                {value === 2 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} p={0}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <StudentPaymentsSummary
                              businessId={state.studentId}
                            />
                          ) : (
                            <StudentPaymentsSummary businessId={businessId} />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <StudentPayments
                              isSummary={false}
                              businessId={state.studentId}
                              clientId={state.clientId}
                              roleId={roleId}
                            />
                          ) : (
                            <StudentPayments
                              isSummary={false}
                              businessId={businessId}
                              clientId={clientId}
                              roleId={roleId}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
                {value === 3 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} p={0}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <StudentTicketsSummary
                              businessId={state.studentId}
                            />
                          ) : (
                            <StudentTicketsSummary businessId={businessId} />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} p={0}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <StudentTickets
                              businessId={state.studentId}
                              clientId={state.clientId}
                              roleId={roleId}
                            />
                          ) : (
                            <StudentTickets
                              businessId={businessId}
                              clientId={clientId}
                              roleId={roleId}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
                {value === 4 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} p={0}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <StudentSecurity
                              userId={state.userId}
                              username={state.username}
                              roleId={roleId}
                            />
                          ) : (
                            <StudentSecurity userId={userId} roleId={roleId} />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StudentsPage;
