import { createSlice } from "@reduxjs/toolkit";
import * as apiMaintenances from "../../../services/apiMaintenances";

export const catalogsSlice = createSlice({
  name: "catalogs",
  initialState: {
    catalogs: {
      data: [],
      title: "",
      className: "",
      query: {
        pageSize: 5,
        pageNumber: 1,
        sortEnum: 0,
      },
      meta: {
        currentPage: 1,
        pageSize: 10,
        totalPages: 1,
        totalCount: 1,
        hasNextPage: false,
        hasPreviousPage: false,
      },
      isLoading: false,
      errors: [],
    },
    catalog: {
      isLoading: false,
      errors: [],
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setCatalogs: (state, { payload }) => {
      const { className } = payload.meta;

      state.catalogs.className = className;

      state.catalogs = payload.data;
    },
    setCatalogsLoading: (state, { payload }) => {
      if (payload === true) {
        state.catalogs.errors = [];
        state.catalog.errors = [];
      }

      state.catalogs.isLoading = payload;
    },
    setCatalogsErrors: (state, { payload }) => {
      state.catalogs.errors = payload.data.errors;
    },
    setCleanErrorsCatalogs: (state) => {
      state.catalogs.errors = [];
      state.catalog.errors = [];
    },
  },
});

export const {
  setCatalogs,
  setCatalogsLoading,
  setCatalogsErrors,
  setCleanErrorsCatalogs,
} = catalogsSlice.actions;

export default catalogsSlice.reducer;

export const tryCleanErrorsCatalogs = () => (dispatch) => {
  dispatch(setCleanErrorsCatalogs());
};

export const selectCatalogs = (state) => state.catalogs.catalogs;

export const fetchCatalogs = (typeTableId, query) => (dispatch) => {
  dispatch(setCatalogsLoading(true));

  apiMaintenances
    .getCatalogs(typeTableId, query)
    .then((resp) => {
      if (resp) {
        dispatch(setCatalogs(resp));
        dispatch(setCatalogsLoading(false));
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(setCatalogsErrors(err));
      dispatch(setCatalogsLoading(false));
    });
};
