import React from "react";
import { CircularProgress } from "@mui/material";

const Spinner = ({ color }) => {
  return (
    <CircularProgress
      size={24}
      sx={{
        position: "absolute",
      }}
      color={color}
    />
  );
};

export default Spinner;
