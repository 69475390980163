import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiTeachers from "../../../services/apiTeachers";

export const teacherClicasesSlice = createSlice({
  name: "teacherClicases",
  initialState: {
    summary: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    header: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    cliPrecasesDetail: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    exportPrecasesDetail: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    cliCaseBill: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    newCliCaseBill: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    deletedBill: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    downloadedFile: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },
  reducers: {
    setTeacherClicasesSummary: (state, { payload }) => {
      state.summary.data = { ...state.summary, ...payload };
    },
    setTeacherClicasesSummaryLoading: (state, { payload }) => {
      state.summary.isLoading = payload;
    },
    setTeacherClicasesHeader: (state, { payload }) => {
      state.header.data = { ...state.summary, ...payload };
    },
    setTeacherClicasesHeaderLoading: (state, { payload }) => {
      state.header.isLoading = payload;
    },
    setTeacherCliPrecasesDetail: (state, { payload }) => {
      state.cliPrecasesDetail.data = { ...state.summary, ...payload };
    },
    setTeacherCliPrecasesDetailLoading: (state, { payload }) => {
      state.cliPrecasesDetail.isLoading = payload;
    },
    setTeacherExportPrecasesDetail: (state, { payload }) => {
      state.exportPrecasesDetail.data = { ...state.summary, ...payload };
    },
    setTeacherExportPrecasesDetaillLoading: (state, { payload }) => {
      state.exportPrecasesDetail.isLoading = payload;
    },
    setTeacherCliCaseBill: (state, { payload }) => {
      state.cliCaseBill.data = { ...state.summary, ...payload };
    },
    setTeacherCliCaseBillLoading: (state, { payload }) => {
      state.cliCaseBill.isLoading = payload;
    },
    setTeacherNewCliCaseBill: (state, { payload }) => {
      state.cliCaseBill.data = { ...state.summary, ...payload };
    },
    setTeacherNewCliCaseBillLoading: (state, { payload }) => {
      state.cliCaseBill.isLoading = payload;
    },
    setTeacherDeletedBill: (state, { payload }) => {
      state.deletedBill.data = { ...state.summary, ...payload };
    },
    setTeacherDeletedBillLoading: (state, { payload }) => {
      state.deletedBill.isLoading = payload;
    },
    setCleanErrorsTeacherPayments: (state) => {
      state.summary.errors = [];
      state.header.errors = [];
      state.cliPrecasesDetail.errors = [];
      state.exportPrecasesDetail.errors = [];
      state.cliCaseBill.errors = [];
      state.newCliCaseBill.errors = [];
      state.deletedBill.errors = [];
      state.downloadedFile.errors = [];
    },
  },
  extraReducers(builder) {
    //TRAE EL SUMMARY DE LAS LIQUIDACIONES DE UN PROFESOR
    builder.addCase(tryGetTeacherCliCasesSummary.pending, (state) => {
      state.summary.status = "loading";
      state.summary.isLoading = true;
      state.summary.errors = [];
    });
    builder.addCase(
      tryGetTeacherCliCasesSummary.fulfilled,
      (state, { payload }) => {
        state.summary.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.summary.errors = errors;

        if (payload.data.isValid) {
          state.summary.data = { ...payload.data.data };
        }

        state.summary.isLoading = false;
      }
    );
    builder.addCase(
      tryGetTeacherCliCasesSummary.rejected,

      (state, { payload }) => {
        state.summary.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.summary.errors = errors;
        state.summary.isLoading = false;
      }
    );

    //TRAE HEADER DE LAS PRELIQUIDACIONES DE UN PROFESOR
    builder.addCase(tryGetTeacherCliprecasesHeader.pending, (state) => {
      state.header.status = "loading";
      state.header.isLoading = true;
      state.header.errors = [];
    });
    builder.addCase(
      tryGetTeacherCliprecasesHeader.fulfilled,
      (state, { payload }) => {
        state.header.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.header.errors = errors;

        if (payload.data.isValid) {
          state.header.data = [...payload.data.data];
        }

        state.header.isLoading = false;
      }
    );
    builder.addCase(
      tryGetTeacherCliprecasesHeader.rejected,

      (state, { payload }) => {
        state.header.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.header.errors = errors;
        state.header.isLoading = false;
      }
    );

    //TRAE EL DETALLE DE UN CASO DE LA PRELIQUIDACION del profesor

    builder.addCase(tryGetTeacherCliprecasesSubdetail.pending, (state) => {
      state.cliPrecasesDetail.status = "loading";
      state.cliPrecasesDetail.isLoading = true;
      state.cliPrecasesDetail.errors = [];
    });
    builder.addCase(
      tryGetTeacherCliprecasesSubdetail.fulfilled,
      (state, { payload }) => {
        state.cliPrecasesDetail.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.cliPrecasesDetail.errors = errors;

        if (payload.data.isValid) {
          state.cliPrecasesDetail.data = [...payload.data.data];
        }

        state.cliPrecasesDetail.isLoading = false;
      }
    );
    builder.addCase(
      tryGetTeacherCliprecasesSubdetail.rejected,

      (state, { payload }) => {
        state.cliPrecasesDetail.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.cliPrecasesDetail.errors = errors;
        state.cliPrecasesDetail.isLoading = false;
      }
    );

    //EXPORTAR DETALLE DE PRELIQUIDACION
    builder.addCase(tryGetExportTeacherCliprecases.pending, (state) => {
      state.exportPrecasesDetail.status = "loading";
      state.exportPrecasesDetail.isLoading = true;
      state.exportPrecasesDetail.errors = [];
    });
    builder.addCase(
      tryGetExportTeacherCliprecases.fulfilled,
      (state, { payload }) => {
        state.exportPrecasesDetail.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.exportPrecasesDetail.errors = errors;

        if (payload.data.isValid) {
          state.exportPrecasesDetail.data = { ...payload.data.data };
        }

        state.exportPrecasesDetail.isLoading = false;
      }
    );
    builder.addCase(
      tryGetExportTeacherCliprecases.rejected,

      (state, { payload }) => {
        state.exportPrecasesDetail.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.exportPrecasesDetail.errors = errors;
        state.exportPrecasesDetail.isLoading = false;
      }
    );

    //TRAE EL DETALLE DE UNA FACTURA ASOCIADA A UN CASO
    builder.addCase(tryGetTeacherCliCaseBill.pending, (state) => {
      state.cliCaseBill.status = "loading";
      state.cliCaseBill.isLoading = true;
      state.cliCaseBill.errors = [];
    });
    builder.addCase(
      tryGetTeacherCliCaseBill.fulfilled,
      (state, { payload }) => {
        state.cliCaseBill.status = "succeeded";

        const errors = payload.data.isValid ? payload.data.errors : [];
        state.cliCaseBill.errors = errors;

        if (payload.data.isValid) {
          state.cliCaseBill.data = { ...payload.data.data };
        }

        state.cliCaseBill.isLoading = false;
      }
    );
    builder.addCase(
      tryGetTeacherCliCaseBill.rejected,

      (state, { payload }) => {
        state.cliCaseBill.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.cliCaseBill.errors = errors;
        state.cliCaseBill.isLoading = false;
      }
    );

    //ASIGNA UNA FACTURA A UN CASE DE LA PRELIQUIDACION

    builder.addCase(tryPostTeacherClicase.pending, (state) => {
      state.newCliCaseBill.status = "loading";
      state.newCliCaseBill.isLoading = true;
      state.newCliCaseBill.errors = [];
    });
    builder.addCase(tryPostTeacherClicase.fulfilled, (state, { payload }) => {
      state.newCliCaseBill.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.newCliCaseBill.errors = errors;

      if (payload.data.isValid) {
        state.newCliCaseBill.data = { ...payload.data.data };
      }

      state.newCliCaseBill.isLoading = false;
    });
    builder.addCase(
      tryPostTeacherClicase.rejected,

      (state, { payload }) => {
        state.newCliCaseBill.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.newCliCaseBill.errors = errors;
        state.newCliCaseBill.isLoading = false;
      }
    );

    //ELIMINA UNA FACTURA PRESENTADA
    builder.addCase(tryDeleteTeacherClicase.pending, (state) => {
      state.deletedBill.status = "loading";
      state.deletedBill.isLoading = true;
      state.deletedBill.errors = [];
    });
    builder.addCase(tryDeleteTeacherClicase.fulfilled, (state, { payload }) => {
      state.deletedBill.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.deletedBill.errors = errors;

      if (payload.data.isValid) {
        state.deletedBill.data = { ...payload.data.data };
      }

      state.deletedBill.isLoading = false;
    });
    builder.addCase(
      tryDeleteTeacherClicase.rejected,

      (state, { payload }) => {
        state.deletedBill.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.deletedBill.errors = errors;
        state.deletedBill.isLoading = false;
      }
    );

    //DESCARGAR ARCHIVO
    builder.addCase(tryDownloadFile.pending, (state) => {
      state.downloadedFile.status = "loading";
      state.downloadedFile.isLoading = true;
      state.downloadedFile.errors = [];
    });
    builder.addCase(tryDownloadFile.fulfilled, (state, { payload }) => {
      state.downloadedFile.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.downloadedFile.errors = errors;

      if (payload.data.isValid) {
        state.downloadedFile.data = { ...payload.data.data };
      }

      state.downloadedFile.isLoading = false;
    });
    builder.addCase(
      tryDownloadFile.rejected,

      (state, { payload }) => {
        state.downloadedFile.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        // Token expiró
        if (payload.status === 401)
          errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

        state.downloadedFile.errors = errors;
        state.downloadedFile.isLoading = false;
      }
    );
  },
});

export const {
  setTeacherClicasesSummary,
  setTeacherClicasesSummaryLoading,
  setTeacherClicasesHeader,
  setTeacherClicasesHeaderLoading,
  setTeacherCliPrecasesDetail,
  setTeacherCliPrecasesDetailLoading,
  setTeacherExportPrecasesDetail,
  setTeacherExportPrecasesDetaillLoading,
  setTeacherCliCaseBill,
  setTeacherCliCaseBillLoading,
  setTeacherNewCliCaseBill,
  setTeacherNewCliCaseBillLoading,
  setTeacherDeletedBill,
  setTeacherDeletedBillLoading,
  setCleanErrorsTeacherPayments,
} = teacherClicasesSlice.actions;

export default teacherClicasesSlice.reducer;

export const tryCleanErrorsTeacherPayments = () => (dispatch) => {
  dispatch(setCleanErrorsTeacherPayments());
};

export const selectTeacherCliCasesSummary = (state) =>
  state.teacherClicases.summary;

export const selectTeacherCliPreCasesHeader = (state) =>
  state.teacherClicases.header;

export const selectTeacherCliPreCasesDetail = (state) =>
  state.teacherClicases.cliPrecasesDetail;

export const selectTeacherCliCaseBill = (state) =>
  state.teacherClicases.cliCaseBill;

export const selectTeacherNewCliCaseBill = (state) =>
  state.teacherClicases.newCliCaseBill;

export const selectDownloadedFile = (state) =>
  state.teacherClicases.downloadedFile;

export const selectDeleted = (state) => state.teacherClicases.deletedBill;

export const tryGetTeacherCliCasesSummary = createAsyncThunk(
  "teacherClicases/tryGetTeacherCliCasesSummary",
  async (teacherId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getTeacherCliCasesSummary(teacherId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetTeacherCliprecasesHeader = createAsyncThunk(
  "teacherClicases/tryGetTeacherCliprecasesHeader",
  async ({ teacherId, isFirstOrLastRecords }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getTeacherCliprecasesHeader(
        teacherId,
        isFirstOrLastRecords
      );

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetTeacherCliprecasesSubdetail = createAsyncThunk(
  "teacherClicases/tryGetTeacherCliprecasesSubdetail",
  async ({ teacherId, cliPrecasesid }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getTeacherCliprecasesSubdetail(
        teacherId,
        cliPrecasesid
      );

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetExportTeacherCliprecases = createAsyncThunk(
  "teacherClicases/tryExportTeacherCliprecases",
  async (teacherId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getExportTeacherCliprecases(teacherId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetTeacherCliCaseBill = createAsyncThunk(
  "teacherClicases/tryGetTeacherCliCaseBill",
  async (clicaseid, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.getTeacherCliCaseBill(clicaseid);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryPostTeacherClicase = createAsyncThunk(
  "teacherClicases/tryPostTeacherClicase",
  async (model, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.postTeacherClicase(model);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryDeleteTeacherClicase = createAsyncThunk(
  "teacherClicases/tryDeleteTeacherClicase",
  async (clicaseId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.deleteTeacherClicase(clicaseId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryDownloadFile = createAsyncThunk(
  "teacherClicases/tryDownloadFile",
  async (cliprecasesid, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiTeachers.downloadFile(cliprecasesid);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
