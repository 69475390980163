import apiBase from "./axios/apiBase";
import getQueryString from "./functions/getQueryString";

const controller = "courses";

export async function getPatents(queryModel) {
  const query = getQueryString(queryModel);

  return apiBase
    .get(`/${controller}/patents?${query}`)
    .then((response) => {
      if ([200, 201, 202, 400, 401].includes(response.status))
        return response.data;

      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function getCourseGroupAttendances(courseGroupId) {
  return apiBase.get(`/${controller}/courseGroup/${courseGroupId}/attendances`);
}

export async function getMoodleCourses() {
  return apiBase.get(`/${controller}/moodle/courses`);
}

//Detalle de un curso
export async function getCourse(courseId) {
  return apiBase.get(`/${controller}/${courseId}`);
}

//Detalle de tarifas de un curso
export async function getCourseFees(courseId) {
  return apiBase.get(`/${controller}/${courseId}/courseFees`);
}

export async function getModules(courseId) {
  return apiBase.get(`/${controller}/${courseId}/courseModule`);
}

export async function getCourseGroup(courseId) {
  return apiBase.get(`/${controller}/${courseId}/courseGroup`);
}

export async function getCourseGroupsSchedules(courseId) {
  return apiBase.get(
    `/${controller}/${courseId}/courseGroup/courseGroupsSchedules`
  );
}

export async function getAvailableCourses(queryModel) {
  const query = getQueryString(queryModel);
  return apiBase
    .get(`/${controller}/availables?${query}`)
    .then((response) => {
      if ([200, 201, 202, 400, 401].includes(response.status))
        return response.data;

      return false;
    })
    .catch((err) => {
      return false;
    });
}

/**
 * It makes a GET request to the server, and if the response is 200, 201, 202, 400, or 401, it opens a
 * new tab with the response data
 * @param courseId - The id of the course
 * @returns The file is being returned.
 */
export async function getCourseInformationFile(courseId) {
  return apiBase
    .get(`/${controller}/${courseId}/download`)
    .then((response) => {
      if ([200, 201, 202, 400, 401].includes(response.status)) {
        return response.data;
      }

      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function getAllCourses(queryModel) {
  const query = getQueryString(queryModel);
  return apiBase
    .get(`/${controller}/all?${query}`)
    .then((response) => {
      if ([200, 201, 202, 400, 401].includes(response.status)) {
        return response.data;
      }

      return false;
    })
    .catch((err) => {
      return false;
    });
}

export async function createCourse(model, validateFees) {
  return apiBase.post(`/${controller}?validateFees=${validateFees}`, model);
}

export async function postModule(model) {
  return apiBase.post(`/${controller}/courseModule`, model);
}

export async function postCourseGroup(model) {
  return apiBase.post(`/${controller}/courseGroup`, model);
}

export async function postCourseFees(model) {
  return apiBase.post(`/${controller}/courseFees`, model);
}

export async function postCourseGroupsSchedules(model) {
  return apiBase.post(
    `/${controller}/courseGroup/CourseGroupsSchedules`,
    model
  );
}

export async function deleteModule(courseModuleId) {
  return apiBase.delete(`/${controller}/courseModule/${courseModuleId}`);
}

export async function deleteCourseGroup(courseGroupId) {
  return apiBase.delete(`/${controller}/courseGroup/${courseGroupId}`);
}

export async function deleteCourseGroupsSchedules(courseGroupsSchedulesId) {
  return apiBase.delete(
    `/${controller}/courseGroup/courseGroupsSchedules/${courseGroupsSchedulesId}`
  );
}

export async function deleteCourseFees(courseFeesId) {
  return apiBase.delete(`/${controller}/courseFees/${courseFeesId}`);
}

export async function deleteCourse(courseId) {
  return apiBase.delete(`/${controller}/${courseId}`);
}
// export async function deletePatent(patentId) {
// 	return apiBase.delete(`/${controller}/patents/${patentId}`);
// }

export async function postAssignTeacher(model) {
  return apiBase.post(`/${controller}/teacherCourses`, model);
}

export async function putDisassociateTeacher(teacherCourseId) {
  return apiBase.put(
    `/${controller}/teacherCourses/${teacherCourseId}/deallocate`
  );
}

export async function getCoursesReport() {
  return apiBase.get(`/${controller}/all/detail/repost`);
}

export async function getMoodleCategories() {
  return apiBase.get(`/${controller}/moodle/categories`);
}

export async function getAutoCourseFees(courseId) {
  return apiBase.put(`/${controller}/courseFees/${courseId}`);
}
