import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { selectUser } from "../../store/slices/users";
import {
  Container,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";
import ValidateCUIL from "../../components/signup/validate-cuil";
import PersonalData from "../../components/signup/personal-data";
import CoursesAvailables from "../../components/signup/courses-availables";
import Completion from "../../components/signup/completion";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#white",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.alternative,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#white",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "gray",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: theme.palette.primary,
  }),
  "& .QontoStepIcon-completedIcon": {
    color: theme.palette.success.alternative,
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

const QontoStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
};

const SignUpPage = () => {
  const { state } = useLocation();
  const [currentStep, setCurrentStep] = useState(0);
  const [cuil, setCuil] = useState(null);
  const [returnToStudents, setReturnToStudents] = useState(false);

  //Detecta si se visualiza desde un dispositivo móvil
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { clients } = useSelector(selectUser);
  let studentId = clients.students.studentId;

  const steps = [
    { key: 0, label: "Validar CUIL" },
    { key: 1, label: "Datos Personales" },
    { key: 2, label: "Cursos disponibles" },
    { key: 3, label: "Finalizar" },
  ];

  useEffect(() => {
    studentId = clients.students.studentId;
  }, [clients.students]);

  useEffect(() => {
    if (state !== null) {
      setCuil(state.cuil);
      if (state.returnToStudents) setReturnToStudents(true);
      setCurrentStep(1);
    }
  }, []);

  const CustomizedSteppers = () => (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={currentStep}
        connector={<QontoConnector />}
      >
        {steps.map((x) => (
          <Step key={x.key}>
            <StepLabel
              sx={{ color: "#ffff" }}
              StepIconComponent={QontoStepIcon}
            />
          </Step>
        ))}
      </Stepper>
    </Stack>
  );

  return (
    <Container
      {...(isMobile ? { maxWidth: "xs" } : { fixed: true })}
      sx={{
        margin: isMobile ? 0 : undefined,
        padding: isMobile ? 0 : undefined,
      }}
    >
      <CustomizedSteppers />
      {currentStep === 0 && (
        <ValidateCUIL
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setCuil={setCuil}
          isMobile={isMobile}
        />
      )}
      {currentStep === 1 && (
        <PersonalData
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          cuil={cuil}
          isMobile={isMobile}
          returnToStudents={returnToStudents}
        />
      )}
      {currentStep === 2 && (
        <CoursesAvailables
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          studentId={studentId}
          isMobile={isMobile}
        />
      )}
      {currentStep === 3 && (
        <Completion
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isMobile={isMobile}
        />
      )}
    </Container>
  );
};

export default SignUpPage;
