import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/slices/currentUser";
import {
  PersonOutline,
  LockOpen,
  AttachMoney,
  NotificationsNone,
  MenuBook,
} from "@mui/icons-material";
import { Box, Card, CardContent, Grid, Tab, Tabs } from "@mui/material";
import TeacherData from "../../components/teacher-data";
import TeacherDataSkeleton from "../../components/teacher-data/skeleton";
import TeacherSecurity from "../../components/teacher-security";
import TeacherTickets from "../../components/teacher-tickets";
import TeacherTicketsSummary from "../../components/teacher-tickets-summary";
import TeacherPaymentsList from "../../components/teacher-payments";
import TeacherCoursesSummary from "../../components/teacher-courses-summary";
import TeacherCourses from "../../components/teacher-courses";
import TeacherPaymentsSummary from "../../components/teacher-payments-summary";
import TeacherTabSummary from "../../components/teacher-tab-summary";
import { useLocation } from "react-router-dom";

const isLoading = false;

export default function TeachersPage() {
  const location = useLocation();
  const state = location.state;
  const { userId, businessId, clientId, roleId } = useSelector(selectCurrentUser); //prettier-ignore
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => setValue(newValue);

  return (
    <>
      <Grid
        container
        pt="0px"
        spacing={2}
        sx={{
          heigth: "auto",
          width: "auto",
          marginBottom: 3,
          marginTop: 0,
          paddingBottom: 10,
        }}
      >
        {/* Left menu resume */}
        <Grid item xs={4}>
          <Card
            elevation={3}
            align="center"
            sx={{ heigth: "auto", width: "auto" }}
          >
            {!isLoading ? (
              <>
                {state ? (
                  <TeacherData
                    userId={state.userId}
                    clientId={state.clientId}
                  />
                ) : (
                  <TeacherData userId={userId} />
                )}
              </>
            ) : (
              <TeacherDataSkeleton />
            )}
          </Card>
        </Grid>
        {/* Right menu tabs*/}
        <Grid item xs={8}>
          <Grid item>
            {!isLoading && (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      <Tabs value={value} onChange={handleChange} centered>
                        <Tab
                          icon={<PersonOutline />}
                          label="RESUMEN"
                          iconPosition="start"
                        />
                        <Tab
                          icon={<MenuBook />}
                          label="CURSOS"
                          iconPosition="start"
                        />
                        <Tab
                          icon={<AttachMoney />}
                          label="PAGOS"
                          iconPosition="start"
                        />
                        <Tab
                          icon={<NotificationsNone />}
                          label="COMUNICACIONES"
                          iconPosition="start"
                        />
                        <Tab
                          icon={<LockOpen />}
                          label="SEGURIDAD"
                          iconPosition="start"
                        />
                      </Tabs>
                    </Box>
                  </Grid>
                </Grid>
                {/* SUMMARY */}
                {value === 0 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <TeacherCourses
                              businessId={state.teacherId}
                              isSummary={true}
                            />
                          ) : (
                            <TeacherCourses
                              businessId={businessId}
                              isSummary={true}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <TeacherTabSummary businessId={state.teacherId} />
                          ) : (
                            <TeacherTabSummary businessId={businessId} />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <TeacherPaymentsList
                              isSummary={true}
                              businessId={state.teacherId}
                              clientId={state.clientId}
                            />
                          ) : (
                            <TeacherPaymentsList
                              isSummary={true}
                              businessId={businessId}
                              clientId={clientId}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
                {/* COURSES */}
                {value === 1 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <TeacherCoursesSummary
                              businessId={state.teacherId}
                            />
                          ) : (
                            <TeacherCoursesSummary businessId={businessId} />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <TeacherCourses
                              businessId={state.teacherId}
                              isSummary={false}
                            />
                          ) : (
                            <TeacherCourses
                              businessId={businessId}
                              isSummary={false}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
                {/* PAYMENTS */}
                {value === 2 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <TeacherPaymentsSummary
                              businessId={state.teacherId}
                            />
                          ) : (
                            <TeacherPaymentsSummary businessId={businessId} />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <TeacherPaymentsList
                              isSummary={false}
                              businessId={state.teacherId}
                              clientId={state.clientId}
                              roleId={roleId}
                            />
                          ) : (
                            <TeacherPaymentsList
                              isSummary={false}
                              businessId={businessId}
                              clientId={clientId}
                              roleId={roleId}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
                {/* TICKETS */}

                {value === 3 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <TeacherTicketsSummary
                              businessId={state.teacherId}
                            />
                          ) : (
                            <TeacherTicketsSummary businessId={businessId} />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <TeacherTickets
                              businessId={state.teacherId}
                              clientId={state.clientId}
                            />
                          ) : (
                            <TeacherTickets
                              businessId={businessId}
                              clientId={clientId}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
                {/* SECURITY */}
                {value === 4 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          {state ? (
                            <TeacherSecurity
                              userId={state.userId}
                              username={state.username}
                              roleId={roleId}
                            />
                          ) : (
                            <TeacherSecurity userId={userId} roleId={roleId} />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
