import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  tryValidateStudentCurrentCuil,
} from "../../../../store/slices/users";
import { useFormik } from "formik";
import { Box, Button, TextField, Typography } from "@mui/material";

import Spinner from "../../../layout/spinner";
import { PatternFormat } from "react-number-format";

import ModalMessages from "../../../layout/modal-messages";
import HelperMail from "../../../layout/helper-mail";

const validate = (values) => {
  const errors = {};
  let cuilReplaced = values.cuil
    .replace("-", "")
    .replace(/_/g, "")
    .replace("-", "");
  if (values.cuil === "") errors.cuil = "Requerido";

  if (cuilReplaced.toString().length !== 11)
    errors.cuil = "Longitud de CUIL inválida";

  return errors;
};

const RightSection = ({ currentStep, setCurrentStep, setCuil }) => {
  const dispatch = useDispatch();
  const { isLoading, errors } = useSelector(selectUser);

  const formik = useFormik({
    initialValues: {
      cuil: "",
    },
    validate,

    onSubmit: async (values) => {
      setCuil(values.cuil);
      const validateStudentCurrentCuil = await dispatch(
        tryValidateStudentCurrentCuil(values.cuil)
      );

      if (!validateStudentCurrentCuil.payload.isValid) formik.errors.cuil = "";

      if (validateStudentCurrentCuil.payload.isValid) {
        setCurrentStep(currentStep + 1);
      }
    },
  });

  return (
    <Box sx={{ width: "100%", paddingTop: 20 }}>
      <Typography
        variant="h5"
        align="center"
        color="primary"
        sx={{
          fontWeight: "700",
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 2,
        }}
      >
        Formulario de inscripción
      </Typography>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/signUp.png"}
          width="260px"
        />
      </div>
      <Typography color="primary" align="center">
        ¡Comencemos nuestro proceso de inscripción! Serán unos pocos pasos...🚧
      </Typography>
      <Typography
        color="primary"
        align="center"
        variant="subtitle2"
        sx={{ paddingTop: 5, paddingBottom: 2 }}
      >
        Por favor, introduzca su CUIL.
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            paddingRight: 2,
          }}
        >
          <PatternFormat
            id="cuil"
            label="CUIL"
            variant="outlined"
            size="small"
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.cuil}
            error={formik.errors.cuil != null}
            helperText={formik.errors.cuil}
            customInput={TextField}
            format="##-########-#"
            mask="_"
          />

          <Button
            variant="contained"
            sx={{ marginTop: 2, marginBottom: 2 }}
            fullWidth
            disabled={!formik.isValid || isLoading}
            type="submit"
          >
            {isLoading && <Spinner color="primary" />}
            Continuar
          </Button>
        </Box>
      </form>
      {errors && errors.length > 0 && <ModalMessages errors={errors} />}

      <Typography
        color="primary"
        align="center"
        variant="subtitle2"
        pr={2}
        sx={{ paddingTop: 5, paddingBottom: 2 }}
      >
        <HelperMail label="¿Tienes alguna duda? Envíanos un mail" />
      </Typography>
    </Box>
  );
};

export default RightSection;
