import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStudentPath,
  tryStudentCoursesPath,
} from "../../../store/slices/student-courses";
import {
  selectStudentAttendance,
  tryGetStudentAttendance,
} from "../../../store/slices/student-courses-attendances";

import {
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import ModalMessages from "../../layout/modal-messages";
import CustomColumnMenu from "../../layout/grid-column-menu";
import Spinner from "../../layout/spinner";
import moment from "moment";
import { DataGrid, GridCloseIcon } from "@mui/x-data-grid";

const StudentPathDrawer = ({
  openPathDrawer,
  setOpenPathDrawer,
  studentId,
  courseId,
  title,
}) => {
  const dispatch = useDispatch();
  const studentPath = useSelector(selectStudentPath);
  const attendanceTable = useSelector(selectStudentAttendance);
  const [rows, setRows] = useState([]);

  const model = {
    studentId: studentId,
    courseId: courseId,
  };

  //Verifica si se muestra el record de asistencia
  const perfectAssistance = () => {
    let valuesSplitted = studentPath.summary.split("/");
    let result = valuesSplitted[0] / valuesSplitted[1];
    if (result === 1) return "Record de Asistencia";
    return "";
  };

  //beforeToday false indica que NO se filtren las clases hasta el dia de hoy
  const beforeToday = false;
  useEffect(() => {
    dispatch(tryStudentCoursesPath(model));
    dispatch(tryGetStudentAttendance({ model, beforeToday }));
  }, []);

  useEffect(() => {
    setRows(
      attendanceTable.data.map((x) => {
        return {
          id: x.attendanceId,
          attendanceId: x.attendanceId,
          studentCourseId: x.studentCourseId,
          attendanceDate: x.attendanceDate,
          studentValid: x.studentValid ?? false,
          teacherValid: x.teacherValid ?? false,
        };
      })
    );
  }, [attendanceTable.data]);

  const initializeDataGrid = () => {
    return {
      ...rows,
      columns: {
        ...columns,
        columnVisibilityModel: {
          id: false,
        },
      },
      pagination: { paginationModel: { pageSize: 6 } },
    };
  };

  const columns = [
    { field: "id", headerName: "Id", filterable: false },
    {
      field: "attendanceDate",
      headerAlign: "left",
      align: "left",
      flex: 0.3,
      headerName: "Cronograma de Clases",
      headerClassName: "headRow",
      width: 100,

      valueFormatter: (params) => {
        if (params.value === null) return "";
        return moment(params.value).format("ll");
      },
    },
  ];

  return (
    <Drawer
      anchor="right"
      open={openPathDrawer}
      PaperProps={{
        sx: { zIndex: 1203, width: 500, boxSizing: "border-box" },
      }}
      onClose={() => setOpenPathDrawer(false)}
      sx={{
        zIndex: 1203,
        width: 500,
        flexShrink: 0,
      }}
    >
      <Grid container p={1}>
        <Grid item xs={10}>
          <Typography
            variant="h4"
            align="left"
            sx={{
              paddingLeft: 1,
              paddingRight: 5,
              paddingBottom: 1,
              paddingTop: 4,
            }}
          >
            Mi Trayectoria
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "end" }}>
          <IconButton
            sx={{ color: "white" }}
            onClick={() => setOpenPathDrawer(false)}
          >
            <GridCloseIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: 1,
            paddingBottom: 3,
          }}
        ></Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        spacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        p={2}
        sx={{ backgroundColor: "white" }}
      >
        <Grid item xs={12}>
          <Typography
            style={{ color: "black", fontWeight: "bold", fontSize: 18 }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop={-3} paddingRight={3}>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{ bgcolor: "secondary.light" }}
          />
        </Grid>

        {studentPath && (
          <>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold", color: "black" }}>
                Ultima clase:{" "}
                {studentPath.lastEdition
                  ? moment(studentPath.lastEdition).format("ll")
                  : "Sin información"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold", color: "black" }}>
                Próxima cursada:{" "}
                {studentPath.nextToStudy
                  ? moment(studentPath.nextToStudy).format("ll")
                  : "Sin información"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold", color: "black" }}>
                Clases hasta la fecha: {studentPath.classesTaught ?? "0"} Total
                de Clases: {studentPath.totalClasses ?? "0"}
              </Typography>
              <Typography style={{ color: "black" }}>
                <strong>{studentPath.summary ?? "0"}</strong> clases completadas
                y clases impartidas{" "}
                {studentPath.studentConditionCoursesDescription !== "" && (
                  <Chip
                    label={studentPath.studentConditionCoursesDescription}
                    color={
                      studentPath.conditioningType === 1 ? "success" : "error"
                    }
                    size="small"
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold", color: "black" }}>
                Porcentaje de asistencia: % {studentPath.progress}
              </Typography>
              <LinearProgress
                sx={{
                  fontSize: 60,
                  width: "400px",
                  height: "10px",
                }}
                variant="determinate"
                value={studentPath.progress}
              />
              <Typography sx={{ color: "grey" }}>
                {perfectAssistance()}
              </Typography>
            </Grid>
            <Grid item xs={12} p={3}>
              <DataGrid
                slots={{
                  columnMenu: CustomColumnMenu,
                }}
                autoHeight={true}
                rowSelection={false}
                getRowId={(row) => row.id}
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[5]}
                initialState={initializeDataGrid()}
              />
            </Grid>
            <Grid item sx={{ width: "100%", textAlign: "center" }}>
              <Button
                variant="contained"
                onClick={() => setOpenPathDrawer(false)}
                disabled={studentPath.isLoading}
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  width: 100,
                  marginRight: 2,
                }}
              >
                {studentPath.isLoading && <Spinner color="primary" />}
                ACEPTAR
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      {studentPath.errors && studentPath.errors.length > 0 && (
        <ModalMessages errors={studentPath.errors} />
      )}
    </Drawer>
  );
};

export default StudentPathDrawer;
