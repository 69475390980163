import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiCourses from "../../../services/apiCourses";
import * as apiStudents from "../../../services/apiStudents";
export const studentCoursesSlice = createSlice({
  name: "studentCourses",
  initialState: {
    item: {
      lastEdition: undefined,
      nextToStudy: undefined,
      classesCompleted: 0,
      classesTaught: 0,
      conditioningType: 0,
      descriptionCourseLastEdition: "",
      descriptionCourseNextToStudy: "",
      totalClasses: 0,
      summary: "",
      progress: 0,
      studentConditionCoursesDescription: "Incompleto",
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    summary: {
      lastEdition: undefined,
      nextToStudy: undefined,
      classesCompleted: 0,
      classesTaught: 0,
      conditioningType: 0,
      descriptionCourseLastEdition: "",
      descriptionCourseNextToStudy: "",
      totalClasses: 0,
      summary: "",
      progress: 0,
      studentConditionCoursesDescription: "Incompleto",
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    list: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    export: {
      id: 0,
      name: "",
      file: "",
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    unsubscribe: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    certificate: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
    exportedCourseGroup: {
      data: [],
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },

  reducers: {
    setStudentCourses: (state, { payload }) => {
      state.list.data = payload;
    },
    setLoading: (state, { payload }) => {
      state.list.isLoading = payload;
    },
    setAddStudentCourse: (state, { payload }) => {
      state.list.data.push(payload);
    },
    setRemoveStudentCourse: (state, { payload }) => {
      if (payload > -1) {
        state.list.data.splice(payload, 1);
      }
    },
    setStudentPath: (state, { payload }) => {
      state.item.data = payload;
    },
    setCleanCertificateErrors: (state) => {
      state.certificate.errors = [];
    },
    setCleanErrorsStudentCourses: (state) => {
      state.item.errors = [];
      state.summary.errors = [];
      state.list.errors = [];
      state.export.errors = [];
      state.unsubscribe.errors = [];
      state.certificate.errors = [];
      state.exportedCourseGroup.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryProgramDownload.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(tryProgramDownload.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      if (payload.data.isValid) {
        state.item = { ...payload.data.data };
      }
      state.item.isLoading = false;
    });
    builder.addCase(tryProgramDownload.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.item.errors = errors;
      state.item.isLoading = false;
    });

    ////////// DESISTIR
    builder.addCase(tryUnubscribeCourse.pending, (state) => {
      state.unsubscribe.status = "loading";
      state.unsubscribe.isLoading = true;
      state.unsubscribe.errors = [];
    });
    builder.addCase(tryUnubscribeCourse.fulfilled, (state, { payload }) => {
      state.unsubscribe.status = "succeeded";

      if (payload.data.isValid) {
        state.unsubscribe = { ...payload.data.data };
      }
      state.unsubscribe.isLoading = false;
    });
    builder.addCase(tryUnubscribeCourse.rejected, (state, { payload }) => {
      state.unsubscribe.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.unsubscribe.errors = errors;
      state.unsubscribe.isLoading = false;
    });

    ////////DESCARGAR CERTIFICADO
    builder.addCase(tryCertificateDownload.pending, (state) => {
      state.certificate.status = "loading";
      state.certificate.isLoading = true;
      state.certificate.errors = [];
    });
    builder.addCase(tryCertificateDownload.fulfilled, (state, { payload }) => {
      state.certificate.status = "succeeded";

      if (payload.data.isValid) {
        state.certificate = { ...payload.data.data };
      }
      state.certificate.isLoading = false;
    });
    builder.addCase(tryCertificateDownload.rejected, (state, { payload }) => {
      state.certificate.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.certificate.errors = errors;
      state.certificate.isLoading = false;
    });
    /////TRAYECTORIA
    builder.addCase(tryStudentCoursesPath.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(tryStudentCoursesPath.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      if (payload.isValid) {
        state.item = { ...payload.data };
      }
      state.item.errors = payload.isValid ? payload.errors : [];
      state.item.isLoading = false;
    });
    builder.addCase(tryStudentCoursesPath.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.isValid === false) errors = payload.errors;

        if (payload.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.item.errors = errors;
      state.item.isLoading = false;
    });

    // RESUMEN DE LOS CURSOS
    builder.addCase(tryGetStudentCoursesSummary.pending, (state) => {
      state.summary.status = "loading";
      state.summary.isLoading = true;
      state.summary.errors = [];
    });
    builder.addCase(
      tryGetStudentCoursesSummary.fulfilled,
      (state, { payload }) => {
        state.summary.status = "succeeded";

        if (payload.data.isValid) {
          state.summary = { ...payload.data.data };
        }
        state.summary.isLoading = false;
      }
    );
    builder.addCase(
      tryGetStudentCoursesSummary.rejected,
      (state, { payload }) => {
        state.summary.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        state.summary.errors = errors;
        state.summary.isLoading = false;
      }
    );
    builder.addCase(tryGetStudentCoursesHeader.pending, (state) => {
      state.list.status = "loading";
      state.list.isLoading = true;
      state.list.errors = [];
    });
    builder.addCase(
      tryGetStudentCoursesHeader.fulfilled,
      (state, { payload }) => {
        state.list.status = "succeeded";
        const response = payload.data;

        if (response.data.isValid) {
          state.list = { ...response.data };
        }
        state.list.isLoading = false;
      }
    );
    builder.addCase(
      tryGetStudentCoursesHeader.rejected,
      (state, { payload }) => {
        state.list.status = "failed";
        let errors = [];

        // Distinto de "No autorizado"
        if (payload.status !== 401) {
          if (payload.data.isValid === false) errors = payload.data.errors;

          if (payload.data.isValid === undefined)
            errors = [
              {
                errorCode: "99999",
                errorMessage: "Error consulte al administrador.",
              },
            ];
        }

        state.list.errors = errors;
        state.list.isLoading = false;
      }
    );
    builder.addCase(tryStudentCoursesExport.pending, (state) => {
      state.export.status = "loading";
      state.export.isLoading = true;
      state.export.errors = [];
    });
    builder.addCase(tryStudentCoursesExport.fulfilled, (state, { payload }) => {
      state.export.status = "succeeded";

      if (payload.data.isValid) {
        state.export = { ...payload.data };
      }
      state.export.isLoading = false;
    });
    builder.addCase(tryStudentCoursesExport.rejected, (state, { payload }) => {
      state.export.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.export.errors = errors;
      state.export.isLoading = false;
    });

    //EXPORTA EL LISTADO DE ALUMNOS DE UNA COMISION
    builder.addCase(tryGetExportCourseGroup.pending, (state) => {
      state.exportedCourseGroup.status = "loading";
      state.exportedCourseGroup.isLoading = true;
      state.exportedCourseGroup.errors = [];
    });
    builder.addCase(tryGetExportCourseGroup.fulfilled, (state, { payload }) => {
      state.exportedCourseGroup.status = "succeeded";

      if (payload.data.isValid) {
        state.exportedCourseGroup = { ...payload.data };
      }
      state.exportedCourseGroup.isLoading = false;
    });
    builder.addCase(tryGetExportCourseGroup.rejected, (state, { payload }) => {
      state.exportedCourseGroup.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      state.exportedCourseGroup.errors = errors;
      state.exportedCourseGroup.isLoading = false;
    });
  },
});

export const {
  setStudentCourses,
  setLoading,
  setAddStudentCourse,
  setRemoveStudentCourse,
  setStudentPath,
  setCleanCertificateErrors,
  setCleanErrorsStudentCourses,
} = studentCoursesSlice.actions;

export default studentCoursesSlice.reducer;

export const tryCleanErrorsStudentCourses = () => (dispatch) => {
  dispatch(setCleanErrorsStudentCourses());
};

export const selectStudentCourses = (state) => state.studentCourses.list;
export const selectStudentPath = (state) => state.studentCourses.item;
export const selectUnsubscribe = (state) => state.studentCourses.unsubscribe;
export const selectCertificate = (state) => state.studentCourses.certificate;
export const selectStudentCoursesHeader = (state) => state.studentCourses.list;
export const selectStudentCoursesSummary = (state) =>
  state.studentCourses.summary;

export const tryCleanCertificateErrors = () => (dispatch) => {
  dispatch(setCleanCertificateErrors());
};

export const selectStudentCoursesExport = (state) =>
  state.studentCourses.export;

export const tryAddStudentCourses = (model) => (dispatch) => {
  dispatch(setLoading(true));

  dispatch(setAddStudentCourse(model));
  dispatch(setLoading(false));
};

export const tryRemoveStudentCourses = (index) => (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setRemoveStudentCourse(index));
  dispatch(setLoading(false));
};

export const tryProgramDownload = createAsyncThunk(
  "studentCourses/tryProgramDownload",
  async (courseId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiCourses.getCourseInformationFile(courseId);
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetStudentCoursesSummary = createAsyncThunk(
  "studentCoursesSummary/tryGetStudentCoursesSummary",
  async (studentId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getStudentCoursesSummary(studentId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryCertificateDownload = createAsyncThunk(
  "studentCourses/tryCertificateDownload",
  async ({ studentId, courseId }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getCourseCertificate(
        studentId,
        courseId
      );
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
export const tryGetStudentCoursesHeader = createAsyncThunk(
  "studentCoursesSummary/tryGetStudentCoursesHeader",
  async ({ studentId, isFirstOrLastRecords }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getStudentCoursesHeader(
        studentId,
        isFirstOrLastRecords
      );

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

////////////////////////////////////// DESISTIR
export const tryUnubscribeCourse = createAsyncThunk(
  "studentCourses/tryUnubscribeCourse",
  async ({ studentId, courseId, model }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.postUnsubscribeCourse(
        studentId,
        courseId,
        model
      );
      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

////////// MI TRAYECTORIA EN EN UN CURSO
export const tryStudentCoursesPath = createAsyncThunk(
  "studentCourses/tryGetStudentCoursesPath",
  async ({ studentId, courseId }, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getStudentCoursesPath(
        studentId,
        courseId
      );

      return response.data;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryStudentCoursesExport = createAsyncThunk(
  "studentCoursesSummary/tryStudentCoursesExport",
  async (studentId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.postStudentCoursesExport(studentId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);

export const tryGetExportCourseGroup = createAsyncThunk(
  "studentCoursesSummary/tryGetExportCourseGroup",
  async (courseGroupId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado
      const response = await apiStudents.getExportCourseGroup(courseGroupId);

      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
