import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTeacher,
  selectTeacherBankData,
  tryGetTeacher,
  tryGetTeacherBankData,
  tryPostClitaxvat,
  tryUpdateAvatar,
  tryUpdateTeacherData,
} from "../../store/slices/teacher";
import {
  fetchTypeTable,
  selectMaintenances,
} from "../../store/slices/maintenances";
import { selectBlocked, tryBlockUser } from "../../store/slices/users";
import { selectCurrentUser } from "../../store/slices/currentUser";
import {
  Divider,
  CardContent,
  Typography,
  Avatar,
  Button,
  Grid,
  TextField,
  Chip,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import {
  CloudUploadOutlined,
  Cancel,
  Save,
  CameraAlt,
} from "@mui/icons-material";
import ModalMessages from "../layout/modal-messages";
import TeacherDataSkeleton from "./skeleton";
import Spinner from "../layout/spinner";
import { NumericFormat, PatternFormat } from "react-number-format";
import convertToSelectList from "../layout/functions/convert-to-select-list";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { IsValidEmail } from "../../services/functions/validator";

const validate = (values) => {
  const errors = {};

  const {
    emailCopy,
    email,
    fullAddress,
    cellPhone,
    telPhone,
    cellPhoneAreaCode,
    telPhoneAreaCode,
  } = values;

  if (!email) errors.email = "Requerido";

  if (email !== emailCopy)
    errors.email = "Los correos electrónicos no coinciden";

  if (!IsValidEmail(email))
    errors.email = "Formato de correo electrónico invalido";

  if (!fullAddress) errors.fullAddress = "Requerido";

  if (!cellPhone) errors.cellPhone = "Requerido";

  if (cellPhone.length < 6) 
    errors.cellPhone = "El número debe estar comprendido entre 6 y 10 caracteres"; //prettier-ignore

  if (!telPhone) errors.telPhone = "Requerido";

  if (telPhone.length < 6) 
    errors.telPhone ="El número debe estar comprendido entre 6 y 10 caracteres"; //prettier-ignore

  if (!cellPhoneAreaCode) errors.cellPhoneAreaCode = "Requerido";

  if (!telPhoneAreaCode) errors.telPhoneAreaCode = "Requerido";

  return errors;
};

const TeacherData = ({ userId, clientId }) => {
  const { roleId, businessId } = useSelector(selectCurrentUser);
  const blockedState = useSelector(selectBlocked);
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [enableEdit, setEnableEdit] = useState(false);
  const [enableEditProfilePicture, setEnableEditProfilePicture] =
    useState(false);
  //Manejo de los dialogs de confirmacion y exito
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const dispatch = useDispatch();
  const typeTables = useSelector(selectMaintenances);

  const bankData = useSelector(selectTeacherBankData);
  const teacher = useSelector(selectTeacher);

  const { isLoading, errors, clients } = teacher;
  const {
    cliAddress,
    cliNaturals,
    cliRelations,
    cliSocialEconomics,
    teachers,
  } = clients;

  const fullName = `${cliNaturals.firstName} ${cliNaturals.lastName}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      state: "",
      type: "",
      username: "",
      emailCopy: "",
      email: "",
      cuit: "",
      nationality: "",
      fullAddress: "",
      cellPhoneAreaCode: 0,
      cellPhone: 0,
      telPhoneAreaCode: 0,
      telPhone: 0,
      gender: 0,
      sectional: 0,
      notes: "",
      academic: null,
      alias: "",
      number: "",
      typeVatConId: "",
      cliTaxVatId: "",
      cliPaymentId: "",
    },
    validate,
    onSubmit: async (values) => {
      let request = {};

      request.id = teacher.id;
      request.clientId = teacher.clientId;
      request.email = values.email;
      request.avatar = teacher.avatar ?? file;
      request.username = teacher.username;
      request.clients = {};
      request.clients.email = values.email;
      request.clients.cliContacts = [];
      request.clients.cliDocuments = [];
      request.clients.cliRelations = [];
      request.clients.cliSocialEconomics = {};
      request.clients.notes = values.notes;
      request.clients.cliNaturals = { ...cliNaturals };
      request.clients.teachers = { ...teachers };
      request.clients.students = null;
      request.clients.cliAddress = cliAddress.map((x) => {
        let address = { ...x };

        if (x.typeAddress === 2) {
          address.fullAddress = values.fullAddress;
          address.province = values.province;
          address.phone = values.cellPhone;
          address.areaCode = values.cellPhoneAreaCode;
        }

        if (x.typeAddress === 1) {
          address.fullAddress = values.fullAddress;
          address.province = values.province;
          address.phone = values.telPhone;
          address.areaCode = values.telPhoneAreaCode;
        }
        return address;
      });

      request.clients.cliSocialEconomics.socialEconomicsId =
        cliSocialEconomics.socialEconomicsId;
      request.clients.cliSocialEconomics.sectional = null;
      request.clients.cliSocialEconomics.sectionalId = null;
      request.clients.cliSocialEconomics.birthDate = cliSocialEconomics.birthDate; // prettier-ignore
      request.clients.cliSocialEconomics.clientId = cliSocialEconomics.clientId;
      request.clients.cliSocialEconomics.genderId = values.gender;
      request.clients.cliSocialEconomics.situationId = cliSocialEconomics.situationId; // prettier-ignore
      request.clients.cliSocialEconomics.academicId = values.academic;
      request.clients.statusId = values.state;

      await dispatch(tryUpdateTeacherData(request));

      let cliTaxRequest = {
        clientId: cliSocialEconomics.clientId,
        cliTaxVatId: values.cliTaxVatId,
        typeVatConId: values.typeVatConId,
        cliPaymentId: values.cliPaymentId,
        alias: values.alias,
        number: values.number,
      };
      await dispatch(tryPostClitaxvat(cliTaxRequest));
      fetchData();
    },
  });

  const fetchData = async () => {
    const action = await dispatch(tryGetTeacher(userId));
    const { data } = action.payload;
    let bankDataRequest;
    if (roleId === 1) {
      bankDataRequest = await dispatch(tryGetTeacherBankData(clientId));
    }
    if (roleId !== 1) {
      bankDataRequest = await dispatch(tryGetTeacherBankData(businessId));
    }

    if (bankDataRequest.payload.isValid) {
      formik.setValues({
        state: data.clients.statuscli?.id || "",
        type: teachers?.tblTeacherType?.description || "",
        username: data.username || "",
        emailCopy: data.email || "",
        email: data.email || "",
        cuit:
          data.clients.cliDocuments?.find((x) => x.typeDocumentId === 2)
            ?.document || "",
        nationality: data.clients.nationalities?.description || "",
        fullAddress: cliAddress[0]?.fullAddress || "",
        province: data.clients.cliAddress[0]?.province || "",
        cellPhoneAreaCode:
          data.clients.cliAddress?.find((x) => x.tblTypeAddress.id === 2)
            ?.areaCode || "",
        cellPhone:
          data.clients.cliAddress?.find((x) => x.tblTypeAddress.id === 2)
            ?.phone || "",
        telPhoneAreaCode:
          data.clients.cliAddress?.find((x) => x.tblTypeAddress.id === 1)
            ?.areaCode || "",
        telPhone:
          data.clients.cliAddress?.find((x) => x.tblTypeAddress.id === 1)
            ?.phone || "",
        gender: data.clients.cliSocialEconomics?.genderId || "",
        notes: data.clients.notes || "",
        academic:
          data.clients.cliSocialEconomics?.academicId !== 0
            ? data.clients.cliSocialEconomics?.academicId
            : null,
        alias: bankDataRequest.payload.data?.alias || "",
        number: bankDataRequest.payload.data?.number || "",
        typeVatConId: bankDataRequest.payload.data?.typeVatConId || "",
        cliTaxVatId: bankDataRequest.payload.data?.cliTaxVatId || "",
        cliPaymentId: bankDataRequest.payload.data?.cliPaymentId || "",
      });
    }
  };

  useEffect(() => {
    dispatch(fetchTypeTable(49)); // Gender
    dispatch(fetchTypeTable(45)); // Academic
    dispatch(fetchTypeTable(17)); // Provinces
    dispatch(fetchTypeTable(19)); // Sectionals
    dispatch(fetchTypeTable(20)); // Situation
    dispatch(fetchTypeTable(14)); // LabouralSituation
    dispatch(fetchTypeTable(18)); // Relationship
    dispatch(fetchTypeTable(51)); // Topics
    dispatch(fetchTypeTable(23)); // TBLSTATUSCLIS
    dispatch(fetchTypeTable(60)); // TBLTYPECONDVATS
    fetchData();
  }, []);

  const blockUser = async (userId) => {
    const action = await dispatch(tryBlockUser(userId));
    if (action.payload.isValid) setOpenDialog(true);
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 5000000,
    onDropAccepted: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        setFile(base64data);
        setPreview(URL.createObjectURL(file));
      };
    },
  });

  const thumbs = preview && (
    <div style={thumb}>
      <div style={thumbInner}>
        <img
          src={preview}
          style={img}
          alt="Imagen de perfil"
          onLoad={() => {
            URL.revokeObjectURL(preview);
          }}
        />
      </div>
    </div>
  );

  const handleEditDetail = () => {
    if (enableEdit) formik.handleSubmit(); //Si el botón es de guardar se ejecuta el submit
    if (formik.isValid) setEnableEdit(!enableEdit); //Si el formulario es válido se cambia el estado del formulario, caso contrario se sigue en modo de edición para mostrar los errores
  };

  const handleCancel = () => {
    fetchData();
    setEnableEdit(!enableEdit);
    setFile();
    setPreview();
  };

  const handleEditProfilePicture = () => {
    enableEditProfilePicture &&
      dispatch(tryUpdateAvatar({ userId: teacher.id, image: file }));

    setEnableEditProfilePicture(!enableEditProfilePicture);
    setPreview();
  };

  const handleCancelEditProfilePicture = () => {
    setEnableEditProfilePicture(!enableEditProfilePicture);
    setFile();
    setPreview();
  };

  return (
    <>
      {!isLoading ? (
        <>
          {teacher.clientId !== 0 && (
            <>
              <CardContent>
                {enableEditProfilePicture ? (
                  <Box
                    sx={{
                      border: "2px #1d4d72 solid",
                      borderRadius: 2,
                    }}
                  >
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <Grid
                          container
                          sx={{
                            ":hover": {
                              cursor: "pointer",
                            },
                          }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          <Grid
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CloudUploadOutlined
                              color="primary"
                              fontSize="large"
                            />
                          </Grid>
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              color="primary"
                              align="center"
                              variant="subtitle2"
                              sx={{ fontWeight: 700, paddingTop: 5 }}
                            >
                              Seleccione un archivo o arrástrelo aquí
                            </Typography>
                            <Typography
                              color="primary"
                              align="center"
                              variant="subtitle2"
                              sx={{ paddingTop: 5, paddingBottom: 2 }}
                            >
                              Imagen con un tamaño no mayor a 5 MB.
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sm={4}
                            md={4}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ marginTop: 2 }}
                          />
                        </Grid>
                      </div>
                      <aside style={thumbsContainer}>{thumbs}</aside>
                    </section>
                  </Box>
                ) : (
                  <>
                    <Avatar
                      sx={{
                        width: 135,
                        height: 135,
                        margin: "25px 0px 25px",
                      }}
                      alt={`Foto de Perfil de ${cliNaturals.firstName} ${cliNaturals.lastName}`}
                      src={teacher.avatar}
                    >{`${cliNaturals.firstName} ${cliNaturals.lastName}`}</Avatar>
                  </>
                )}
                <Grid
                  container
                  spacing={1}
                  rowSpacing={2}
                  pb={2}
                  direction="row"
                >
                  <Grid item xs>
                    <IconButton
                      onClick={() => handleEditProfilePicture()}
                      sx={{ width: "170px", height: "35px" }}
                      variant="contained"
                      color="primary"
                      disabled={enableEditProfilePicture && !preview}
                      disableRipple
                    >
                      {enableEditProfilePicture ? <Save /> : <CameraAlt />}
                    </IconButton>
                    {enableEditProfilePicture && (
                      <IconButton
                        onClick={() => handleCancelEditProfilePicture()}
                        sx={{ width: "170px", height: "35px" }}
                        variant="outlined"
                        color="error"
                        disableRipple
                      >
                        <Cancel />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <Typography sx={{ fontSize: 25, paddingTop: 1 }}>
                  <strong style={{ fontWeight: "bold" }}>{fullName}</strong>
                </Typography>
              </CardContent>
              <CardContent>
                <Divider light sx={{ borderColor: "secondary.light" }} />
                <form onSubmit={formik.handleSubmit}>
                  <Grid
                    container
                    pt={2}
                    textAlign="left"
                    spacing={1}
                    rowSpacing={2}
                    pl={2}
                    direction="row"
                  >
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>Estado</Typography>
                    </Grid>
                    {enableEdit ? (
                      <>
                        <Grid item xs={8}>
                          <FormControl fullWidth>
                            <Select
                              name="state"
                              id="state"
                              value={formik.values.state}
                              disabled={!enableEdit}
                              size="small"
                              onChange={formik.handleChange}
                            >
                              {typeTables.TBLSTATUSCLIS &&
                                convertToSelectList(
                                  typeTables.TBLSTATUSCLIS.data
                                ).map((x) => (
                                  <MenuItem key={x.value} value={x.value}>
                                    {x.text}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={8}>
                          <Chip
                            size="small"
                            label={typeTables.TBLSTATUSCLIS.data.map((x) => {
                              if (x.id == formik.values.state)
                                return x.description;
                            })}
                            color={
                              formik.values.state == 1 ? "success" : "error"
                            }
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>
                        Tipo de Profesor:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Chip
                        size="small"
                        label={formik.values.type}
                        color="success"
                      />
                    </Grid>

                    {cliRelations.length > 0 && (
                      <>
                        <Grid item xs={4}>
                          <Typography fontWeight={700}>
                            Relación con el afiliado:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography>
                            {cliRelations[0].relationship}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontWeight={700}>
                            Seccional Relacionado:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography>
                            {cliRelations[0].tblSectional.description}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>Nacionalidad:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{formik.values.nationality}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>CUIL:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography fontWeight={700}>
                        <FormControl fullWidth>
                          <Typography>
                            <PatternFormat
                              id="cuil"
                              customInput={TextField}
                              format="##-########-#"
                              mask="_"
                              size="small"
                              variant="standard"
                              disabled={true}
                              onChange={formik.handleChange}
                              value={formik.values.cuit}
                              required
                            />
                          </Typography>
                        </FormControl>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>Email: *</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography fontWeight={700}>
                        {enableEdit ? (
                          <TextField
                            required
                            fullWidth
                            id="email"
                            size="small"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            disabled={!enableEdit}
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                            autoComplete="off"
                          />
                        ) : (
                          <Typography>{formik.values.email}</Typography>
                        )}
                      </Typography>
                    </Grid>
                    {enableEdit ? (
                      <>
                        <Grid item xs={4}>
                          <Typography fontWeight={700}>
                            Email Confirmación: *
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            required
                            fullWidth
                            id="emailCopy"
                            type="email"
                            size="small"
                            onPaste={(event) => event.preventDefault()}
                            onChange={formik.handleChange}
                            value={formik.values.emailCopy}
                            disabled={!enableEdit}
                            autoComplete="off"
                          />{" "}
                        </Grid>
                      </>
                    ) : null}
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>Dirección: *</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography fontWeight={700}>
                        {enableEdit ? (
                          <TextField
                            id="fullAddress"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.fullAddress}
                            error={
                              formik.touched.fullAddress &&
                              Boolean(formik.errors.fullAddress)
                            }
                            helperText={
                              formik.touched.fullAddress &&
                              formik.errors.fullAddress
                            }
                            autoComplete="off"
                          />
                        ) : (
                          <Typography>{formik.values.fullAddress}</Typography>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>Provincia: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {enableEdit ? (
                        <FormControl fullWidth>
                          <Select
                            name="province"
                            id="province"
                            value={formik.values.province}
                            size="small"
                            disabled={!enableEdit}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.province &&
                              Boolean(formik.errors.province)
                            }
                          >
                            {typeTables.TBLPROVINCES &&
                              convertToSelectList(
                                typeTables.TBLPROVINCES.data
                              ).map((x) => (
                                <MenuItem key={x.value} value={x.value}>
                                  {x.text}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography>
                          {cliAddress[0].tblProvinces?.description}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>Celular: *</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {enableEdit ? (
                        <FormControl fullWidth>
                          <TextField
                            name="cellPhoneAreaCode"
                            label="Cód. Área"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              maxlength: "4",
                            }}
                            onChange={(e) => {
                              const { value } = e.target;
                              if (value.length > 0 && value[0] === "0") {
                                formik.setFieldValue(
                                  "cellPhoneAreaCode",
                                  value.substring(1)
                                );
                              } else {
                                formik.setFieldValue(
                                  "cellPhoneAreaCode",
                                  value
                                );
                              }
                            }}
                            value={formik.values.cellPhoneAreaCode}
                            error={
                              formik.touched.cellPhoneAreaCode &&
                              Boolean(formik.errors.cellPhoneAreaCode)
                            }
                            helperText={
                              formik.touched.cellPhoneAreaCode &&
                              formik.errors.cellPhoneAreaCode
                            }
                            autoComplete="off"
                          />
                        </FormControl>
                      ) : (
                        <Typography>
                          {formik.values.cellPhoneAreaCode}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      {enableEdit ? (
                        <FormControl>
                          <NumericFormat
                            id="cellPhone"
                            label="Número"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              maxlength: "10",
                            }}
                            customInput={TextField}
                            onChange={formik.handleChange}
                            value={formik.values.cellPhone}
                            error={
                              formik.touched.cellPhone &&
                              Boolean(formik.errors.cellPhone)
                            }
                            helperText={
                              formik.touched.cellPhone &&
                              formik.errors.cellPhone
                            }
                            autoComplete="off"
                          />
                        </FormControl>
                      ) : (
                        <Typography>{formik.values.cellPhone}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>Teléfono: *</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {enableEdit ? (
                        <FormControl>
                          <TextField
                            id="telPhoneAreaCode"
                            label="Cód. Área"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              maxlength: "4",
                            }}
                            onChange={(e) => {
                              const { value } = e.target;
                              if (value.length > 0 && value[0] === "0") {
                                formik.setFieldValue(
                                  "telPhoneAreaCode",
                                  value.substring(1)
                                );
                              } else {
                                formik.setFieldValue("telPhoneAreaCode", value);
                              }
                            }}
                            value={formik.values.telPhoneAreaCode}
                            error={
                              formik.touched.telPhoneAreaCode &&
                              Boolean(formik.errors.telPhoneAreaCode)
                            }
                            helperText={
                              formik.touched.telPhoneAreaCode &&
                              formik.errors.telPhoneAreaCode
                            }
                            autoComplete="off"
                          />
                        </FormControl>
                      ) : (
                        <Typography>
                          {formik.values.telPhoneAreaCode}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      {enableEdit ? (
                        <FormControl>
                          <NumericFormat
                            id="telPhone"
                            label="Número"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              maxlength: "10",
                            }}
                            fullWidth
                            customInput={TextField}
                            onChange={formik.handleChange}
                            value={formik.values.telPhone}
                            error={
                              formik.touched.telPhone &&
                              Boolean(formik.errors.telPhone)
                            }
                            helperText={
                              formik.touched.telPhone && formik.errors.telPhone
                            }
                            autoComplete="off"
                          />
                        </FormControl>
                      ) : (
                        <Typography>{formik.values.telPhone}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>Género:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {enableEdit ? (
                        <FormControl fullWidth>
                          <Select
                            name="gender"
                            id="gender"
                            value={formik.values.gender}
                            a
                            disabled={!enableEdit}
                            size="small"
                            onChange={formik.handleChange}
                          >
                            {typeTables.TBLGENDER &&
                              convertToSelectList(
                                typeTables.TBLGENDER.data
                              ).map((x) => (
                                <MenuItem key={x.value} value={x.value}>
                                  {x.text}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography>
                          {cliSocialEconomics.gender.description}
                        </Typography>
                      )}
                    </Grid>
                    {formik.values.sectional === 0 && (
                      <>
                        <Grid item xs={4}>
                          <Typography fontWeight={700}>Seccional: </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl fullWidth>
                            <Select
                              name="sectional"
                              id="sectional"
                              value={formik.values.sectional}
                              disabled={!enableEdit}
                              size="small"
                              onChange={formik.handleChange}
                              error={
                                formik.touched.sectional &&
                                Boolean(formik.errors.sectional)
                              }
                            >
                              {typeTables.TBLSECTIONALS &&
                                convertToSelectList(
                                  typeTables.TBLSECTIONALS.data
                                ).map((x) => (
                                  <MenuItem key={x.value} value={x.value}>
                                    {x.text}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4}>
                      <Typography fontWeight={700}>Nivel Académico:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {enableEdit ? (
                        <FormControl fullWidth>
                          <Select
                            name="academic"
                            labelId="academic-label"
                            value={formik.values.academic}
                            disabled={!enableEdit}
                            size="small"
                            onChange={formik.handleChange}
                          >
                            {typeTables.TBLACADEMICS &&
                              convertToSelectList(
                                typeTables.TBLACADEMICS.data
                              ).map((x) => (
                                <MenuItem key={x.value} value={x.value}>
                                  {x.text}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography>
                          {cliSocialEconomics.academic.description}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      <Typography fontWeight={700}>
                        Información de emergencia:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {enableEdit ? (
                        <TextField
                          id="notes"
                          multiline
                          rows={5}
                          fullWidth
                          onChange={formik.handleChange}
                          value={formik.values.notes}
                        />
                      ) : (
                        <Typography>{clients.notes}</Typography>
                      )}
                    </Grid>

                    {roleId === 1 && (
                      <>
                        <Grid item xs={12}>
                          <Divider
                            light
                            sx={{ borderColor: "secondary.light" }}
                          />
                        </Grid>
                        <Grid item xs={12} align="left">
                          <Typography>Datos Bancarios</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontWeight={700}>Alias:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography fontWeight={700}>
                            {enableEdit ? (
                              <TextField
                                id="alias"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.alias}
                                autoComplete="off"
                              />
                            ) : (
                              <Typography>{formik.values.alias}</Typography>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontWeight={700}>CBU:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography fontWeight={700}>
                            {enableEdit ? (
                              <NumericFormat
                                id="number"
                                label="CBU"
                                variant="outlined"
                                size="small"
                                fullWidth
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                  maxlength: "22",
                                }}
                                customInput={TextField}
                                onChange={formik.handleChange}
                                value={formik.values.number}
                              />
                            ) : (
                              <Typography>{formik.values.number}</Typography>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontWeight={700}>
                            Relación frente al IVA:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography fontWeight={700}>
                            {enableEdit ? (
                              <FormControl fullWidth>
                                <Select
                                  name="typeVatConId"
                                  id="typeVatConId"
                                  value={formik.values.typeVatConId}
                                  disabled={!enableEdit}
                                  size="small"
                                  onChange={formik.handleChange}
                                >
                                  {typeTables.TBLTYPECONDVATS &&
                                    convertToSelectList(
                                      typeTables.TBLTYPECONDVATS.data
                                    ).map((x) => (
                                      <MenuItem key={x.value} value={x.value}>
                                        {x.text}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            ) : (
                              <Typography>
                                {bankData.data.typeVatCon}
                              </Typography>
                            )}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid textAlign="center" container pt={2} spacing={1}>
                      <Grid item xs>
                        <Button
                          disabled={!formik.dirty}
                          onClick={() => handleEditDetail()}
                          sx={{ width: "170px", height: "35px" }}
                          variant="contained"
                        >
                          {enableEdit ? "GUARDAR" : "EDITAR"}
                        </Button>
                      </Grid>
                      {enableEdit && (
                        <Grid item xs>
                          <Button
                            onClick={() => handleCancel()}
                            sx={{ width: "170px", height: "35px" }}
                            variant="outlined"
                            color="error"
                          >
                            Cancelar
                          </Button>
                        </Grid>
                      )}
                      {!enableEdit && roleId == 1 && (
                        <Grid item xs>
                          <Button
                            onClick={() => setConfirmationDialog(true)}
                            sx={{ width: "170px", height: "35px" }}
                            disabled={blockedState.isLoading}
                            variant="contained"
                            color={
                              formik.values.state == 1 ? "error" : "success"
                            }
                          >
                            {blockedState.isLoading && (
                              <Spinner color="primary" />
                            )}
                            {formik.values.state == 1
                              ? "BLOQUEAR"
                              : "HABILITAR"}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </>
          )}
          {openDialog && (
            <>
              <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"La información ha sido guardada con éxito"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenDialog(false);
                      fetchData();
                    }}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}
          {confirmationDialog && (
            <>
              <Dialog
                open={confirmationDialog}
                onClose={confirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {formik.values.state == 1
                    ? "¿Estás seguro que deseas bloquear al usuario?"
                    : "¿Estás seguro que deseas habilitar al usuario?"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      blockUser(userId);
                      setConfirmationDialog(false);
                    }}
                    autoFocus
                  >
                    Aceptar
                  </Button>
                  <Button
                    onClick={() => setConfirmationDialog(false)}
                    autoFocus
                  >
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>{" "}
            </>
          )}
        </>
      ) : (
        <TeacherDataSkeleton />
      )}
      {errors && errors.length > 0 && <ModalMessages errors={errors} />}
      {blockedState.errors && blockedState.errors.length > 0 && (
        <ModalMessages errors={blockedState.errors} />
      )}
    </>
  );
};

export default TeacherData;
