import React from "react";
import { Grid, Skeleton } from "@mui/material";

const TeacherTicketsSkeleton = () => {
  return (
    <>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          sx={{
            height: "40px",
            mb: 2,
            border: "1px solid #c3c3c3 ",
            borderRadius: 5,
            backgroundColor: "#dbdbdb",
          }}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            height: "40px",
            mb: 2,
            border: "1px solid #c3c3c3 ",
            borderRadius: 5,
            backgroundColor: "#white",
          }}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            height: "40px",
            mb: 2,
            border: "1px solid #c3c3c3 ",
            borderRadius: 5,
            backgroundColor: "#dbdbdb",
          }}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            height: "40px",
            mb: 2,
            border: "1px solid #c3c3c3 ",
            borderRadius: 5,
            backgroundColor: "#white",
          }}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            height: "40px",
            mb: 2,
            border: "1px solid #c3c3c3 ",
            borderRadius: 5,
            backgroundColor: "#dbdbdb",
          }}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            height: "40px",
            mb: 2,
            border: "1px solid #c3c3c3 ",
            borderRadius: 5,
            backgroundColor: "#white",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ height: "60px" }} />
      </Grid>
    </>
  );
};

export default TeacherTicketsSkeleton;
