import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const StudentCoursesSkeleton = () => {
  return (
    <>
      <div className="headRow">
        <Skeleton
          variant="rectangular"
          sx={{ width: "150px", height: "40px", marginLeft: "20px" }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ width: "150px", height: "40px", marginLeft: "20px" }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ width: "150px", height: "40px", marginLeft: "20px" }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ width: "150px", height: "40px", marginLeft: "20px" }}
        />
      </div>
      <div>
        <Stack spacing={1} sx={{ marginTop: "25px" }}>
          <Skeleton variant="rectangular" sx={{ height: "40px" }} />
          <Skeleton variant="rectangular" sx={{ height: "40px" }} />
          <Skeleton variant="rectangular" sx={{ height: "40px" }} />
          <Skeleton variant="rectangular" sx={{ height: "40px" }} />
        </Stack>
      </div>
    </>
  );
};

export default StudentCoursesSkeleton;
