import apiBase from "./axios/apiBase";
import getQueryString from "./functions/getQueryString";

const controller = "teacher";

export async function tryGetTeacherData(userId) {
  return apiBase.get(`${controller}/${userId}`);
}

export async function tryUpdateTeacherData(request) {
  return apiBase.post(`${controller}`, request);
}

export async function getAllTeachers() {
  return apiBase.get(`/${controller}/all`);
}

//#region Teacher Tickets

export async function getTeacherTicketsSummary(teacherId) {
  return apiBase.get(`/${controller}/${teacherId}/tickets/all/summary`);
}

export function getTicketsByTeacherHeader(teacherId, queryModel) {
  const query = getQueryString(queryModel);

  return apiBase.get(
    `/${controller}/${teacherId}/tickets​/all​/header?${query}`
  );
}

export function getTeacherByBOHeader() {
  return apiBase.get(`/${controller}/all/header`);
}

export function createTeacherTicket(model) {
  return apiBase.post(`/${controller}/ticket`, model);
}

export function postNewTeacher(model) {
  return apiBase.post(`/${controller}`, model);
}

export function postClitaxvat(model) {
  return apiBase.post(`/${controller}/clitaxvat/clipayments`, model);
}

export async function getTeacherCliCasesSummary(teacherId) {
  return apiBase.get(`/${controller}/${teacherId}/clicases/summary`);
}

export function getTeacherTicketDetails(ticketId) {
  return apiBase.get(`/${controller}/ticket/${ticketId}/detail`);
}

export function getAttachmentByTicketDetail(ticketId, ticketDetailId) {
  return apiBase.get(
    `/${controller}/ticket/${ticketId}/detail/${ticketDetailId}`
  );
}

export async function getTeacherCliprecasesHeader(
  teacherId,
  isFirstOrLastRecords
) {
  return apiBase.get(
    `/${controller}/${teacherId}/cliprecases/header?isFirstOrLastRecords=${isFirstOrLastRecords}`
  );
}

export async function getCoursesSummary(teacherId) {
  return apiBase.get(`/${controller}/${teacherId}/courses/all/summary`);
}

export async function getCoursesHeader(teacherId, isFirstOrLastRecords) {
  return apiBase.get(
    `/${controller}/${teacherId}/courses/all/header?isFirstOrLastRecords=${isFirstOrLastRecords}`
  );
}

export async function getTeacherCliprecasesSubdetail(teacherId, cliPrecasesid) {
  return apiBase.get(
    `/${controller}/${teacherId}/clicases/${cliPrecasesid}/SubDetails`
  );
}

export async function getExportTeacherCliprecases(teacherId) {
  return apiBase.get(`/${controller}/${teacherId}/cliprecases/header/export`);
}

export async function getTeacherCliCaseBill(clicaseId) {
  return apiBase.get(`/${controller}/clicase/${clicaseId}`);
}

export async function postTeacherClicase(model) {
  return apiBase.post(`/${controller}/clicase`, model);
}

export async function deleteTeacherClicase(clicaseId) {
  return apiBase.delete(`/${controller}/clicases/${clicaseId}`);
}

export async function postTicksStudentsAttendances({
  attendanceDate,
  request,
  teacherCourseId,
  teacherPresent,
}) {
  return apiBase.post(
    `/${controller}/teachercourse/${teacherCourseId}/studentattendances?attendaceDate=${attendanceDate}&teacherPresent=${teacherPresent}`,
    request
  );
}

export async function getStudentGrades(courseGroupId) {
  return apiBase.get(
    `/${controller}/studentcourses/coursegroup/${courseGroupId}/score`
  );
}

export async function getStudentCourses(teacherid) {
  return apiBase.get(`/${controller}/${teacherid}/courses/all/export`);
}

export async function putStudentCourseGrades(request) {
  return apiBase.put(`/${controller}/studentcourses/score`, request);
}

export async function getCourseGroupAttendances(
  courseGroupId,
  teacherCourseId
) {
  return apiBase.get(
    `/${controller}/courseGroup/${courseGroupId}/teachercourse/${teacherCourseId}/attendances`
  );
}

export async function getStudentListByDate(
  courseGroupId,
  attendanceDate,
  teacherCourseId
) {
  return apiBase.get(
    `/${controller}/courseGroup/${courseGroupId}/attendances/${attendanceDate}/teachercourse/${teacherCourseId}`
  );
}
//#endregion

export async function getTeacherTicketsExport(studentId) {
  return apiBase.post(`/${controller}/${studentId}/tickets/all/export`);
}

export async function downloadFile(cliprecasesid) {
  return apiBase.get(`/${controller}/cliprecases/${cliprecasesid}/attachment`);
}

export async function getExportTeachersAll() {
  return apiBase.get(`/${controller}/all/detail`);
}

export async function postCloseTeacherTicket(ticketId) {
  return apiBase.post(`/${controller}/tickets/${ticketId}/close`);
}

export function getTeacherMails(isActive) {
  return apiBase.get(`/${controller}/all/emails?isActive=${isActive}`);
}

export async function postPreliquidation(request) {
  return apiBase.post(`/${controller}/Preliquidation`, request);
}

export async function getBatchProcessHeader() {
  return apiBase.get(`/${controller}/batchprocess/header`);
}

export async function getBatchProcessDetail(batchprocessid) {
  return apiBase.get(`/${controller}/cliprecases/${batchprocessid}/header`);
}

export async function putManualCliPrecase(requestBody) {
  return apiBase.put(
    `/${controller}/batchprocess/administration/manual`,
    requestBody
  );
}

export async function putNotificationEmails(batchprocessId, actionType) {
  return apiBase.put(
    `/${controller}/batchprocess/${batchprocessId}/administration/${actionType}`
  );
}

export async function deleteTeacherCourseFee(teacherCourseFeeId) {
  return apiBase.delete(
    `/${controller}/batchprocess/administration/${teacherCourseFeeId}`
  );
}

export async function getBatchProcessFile(batchprocessid) {
  return apiBase.get(`/${controller}/cliprecases/${batchprocessid}/detail`);
}

export async function getTeachersReport() {
  return apiBase.get(`/${controller}/all/detail/repost`);
}

export async function getTeacherBankData(clientId) {
  return apiBase.get(`/${controller}/${clientId}/clitaxvat/clipayments`);
}

export async function getCasesToTreasury(teacherId) {
  return apiBase.get(`/${controller}/${teacherId}/send/cases/treasury`);
}
