import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { emphasize, styled } from "@mui/material/styles";
import { Chip, Breadcrumbs, Card, CardContent } from "@mui/material";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/slices/currentUser";
import {
  Home,
  Dashboard,
  School,
  Settings,
  Bookmark,
  Work,
  Groups,
  Payments,
  PersonAdd,
} from "@mui/icons-material";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const urlRoles = {
  URL_BACKOFFICE: "/backoffice/usersprofile",
  URL_TEACHER: "/teachers",
  URL_STUDENT: "/students",
};

const Breadcrumb = () => {
  const location = useLocation();
  const { roleId } = useSelector(selectCurrentUser);

  const [urlRedirect, setUrlRedirect] = useState(null);

  const changeUrlRedirect = () => {
    if (roleId === 1) {
      setUrlRedirect(urlRoles.URL_BACKOFFICE);
    }
    if (roleId === 2) {
      setUrlRedirect(urlRoles.URL_STUDENT);
    }
    if (roleId === 3) {
      setUrlRedirect(urlRoles.URL_TEACHER);
    }
  };

  useEffect(() => {
    if (roleId !== null) {
      changeUrlRedirect();
    }
  }, [roleId, urlRedirect]);

  const listMenu = [
    {
      id: 1,
      description: "Inicio",
      icon: <Dashboard color="primary" fontSize="small" />,
      url: "/home",
    },
    {
      id: 2,
      description: "Mi UTEDYC",
      icon: <School color="primary" fontSize="small" />,
      url: urlRedirect,
    },
    {
      id: 3,
      description: "Configuración",
      icon: <Settings color="primary" fontSize="small" />,
      url: "/backoffice/typetables",
    },
    {
      id: 4,
      description: "Cursos",
      icon: <Bookmark color="primary" fontSize="small" />,
      url: "/courses",
    },
    {
      id: 5,
      description: "Profesores",
      icon: <Work color="primary" fontSize="small" />,
      url: "/backoffice/teachers",
    },
    {
      id: 6,
      description: "Alumnos",
      icon: <School color="primary" fontSize="small" />,
      url: "/backoffice/students",
    },
    {
      id: 7,
      description: "Usuarios",
      icon: <Groups color="primary" fontSize="small" />,
      url: "/backoffice/userslist",
    },
    {
      id: 8,
      description: "Liquidaciones",
      icon: <Payments color="primary" fontSize="small" />,
      url: "/backoffice/settlements",
    },
    {
      id: 9,
      description: "Administrar Preliquidación",
      icon: <Payments color="primary" fontSize="small" />,
      url: "/backoffice/AdminSettlements",
    },
    {
      id: 10,
      description: "Alta Profesor",
      icon: <PersonAdd color="primary" fontSize="small" />,
      url: "/backoffice/newteacher",
    },
  ];

  const includeLocations = () =>
    listMenu.filter((x) => x.url === location.pathname);

  return (
    <>
      {includeLocations().length > 0 && (
        <Card sx={{ height: 50, width: "100%", px: 0 }}>
          <CardContent sx={{ py: 1.5 }}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <StyledBreadcrumb
                component="a"
                href="/"
                onClick={(e) => e.preventDefault()}
                label="SIF"
                icon={<Home color="primary" fontSize="small" />}
              />
              {location.pathname !== "/" && (
                <StyledBreadcrumb
                  component="a"
                  href={location.pathname}
                  label={includeLocations()[0].description}
                  icon={includeLocations()[0].icon}
                />
              )}
            </Breadcrumbs>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Breadcrumb;
