import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchTypeTable,
  selectMaintenances,
} from "../../store/slices/maintenances";
import {
  selectCourse,
  selectNewCourse,
  tryCreateCourse,
  tryGetCourseDetail,
} from "../../store/slices/back-course";
import {
  selectMoodleCourses,
  tryGetMoodleCategories,
  tryGetMoodleCourses,
} from "../../store/slices/backoffice-courses";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  Slider,
  Input,
  Autocomplete,
  FormHelperText,
  Box,
  Checkbox,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  CheckCircle,
  ContentPaste,
  ContentPasteOff,
  Unpublished,
} from "@mui/icons-material";
import ModalMessages from "../layout/modal-messages";
import BackCoursesGeneralSkeleton from "./skeleton";
import Spinner from "../layout/spinner";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { PatternFormat } from "react-number-format";
import convertToSelectList from "../layout/functions/convert-to-select-list";
import { IsValidDate } from "../../services/functions/validator";
import { FormatDate } from "../../services/functions/formatDate";
import moment from "moment";

//Obtengo la fecha actual
const today = FormatDate();

const validate = (values) => {
  const errors = {};

  const newDate = FormatDate(values.initDate, "DD/MM/YYYY", null);
  const newEndDate = FormatDate(values.endDate, "DD/MM/YYYY", null);
  const newRegDate = FormatDate(
    values.initRegistracionDate,
    "DD/MM/YYYY",
    null
  );
  const newEndRegDate = FormatDate(
    values.endRegistracionDate,
    "DD/MM/YYYY",
    null
  );
  // 26/06/24: UTEDYC Sec. Formación solicita la modificación de 30 días a 45.
  // Verificar si newRegDate está dentro del rango de 45 días antes y después de newDate
  const minRegDate = newDate.clone().subtract(45, "days");
  const maxRegDate = newDate.clone().add(45, "days");
  //  Verificar si newEndRegDate está dentro del rango de 45 días antes y después de newEndDate
  const maxEndRegDate = newEndDate.clone().subtract(45, "days");

  //Fecha INICIAL
  const isValidInitDate = IsValidDate(values.initDate);
  if (!isValidInitDate) {
    errors.initDate = "Ingrese una fecha válida en formato DD MM AAAA";
  }
  if (isValidInitDate) {
    if (!values.initDate || values.initDate === "")
      errors.initDate = "Requerido";
  }

  //Fecha FINAL
  const isValidEndDate = IsValidDate(values.endDate);
  if (!isValidEndDate)
    errors.endDate = "Ingrese una fecha válida en formato DD MM AAAA";
  if (isValidEndDate) {
    if (!values.endDate || values.endDate === "") errors.endDate = "Requerido";
    if (newDate.isSameOrAfter(newEndDate)) {
      errors.endDate = "Incongruencia en las fechas";
    }
  }
  //Fecha INICIAL REGISTRACION
  const isValidInitRegistracionDate = IsValidDate(values.initRegistracionDate);
  if (!isValidInitRegistracionDate)
    errors.initRegistracionDate =
      "Ingrese una fecha válida en formato DD MM AAAA";
  if (isValidInitRegistracionDate) {
    if (!values.initRegistracionDate || values.initRegistracionDate === "")
      errors.initRegistracionDate = "Requerido";
    if (newRegDate.isBefore(minRegDate) || newRegDate.isAfter(maxRegDate)) {
      errors.initRegistracionDate =
        "Fecha de inscripción debe estar dentro del rango de 45 días antes y después de la fecha de Inicio";
    }
  }
  //Fecha FINAL REGISTRACION
  const isValidEndRegistracionDate = IsValidDate(values.endRegistracionDate);
  if (!isValidEndRegistracionDate)
    errors.endRegistracionDate =
      "Ingrese una fecha válida en formato DD MM AAAA";
  if (isValidEndRegistracionDate) {
    if (!values.endRegistracionDate || values.endRegistracionDate === "")
      errors.endRegistracionDate = "Requerido";
    if (newRegDate.isSameOrAfter(newEndRegDate)) {
      errors.endRegistracionDate = "Incongruencia en las fechas";
    }
    if (
      newEndRegDate.isBefore(newRegDate) ||
      newEndRegDate.isAfter(maxEndRegDate)
    ) {
      errors.endRegistracionDate =
        "Fecha de fin de inscripción debe estar entre la fecha de inicio de inscripción, y la fecha de fin de curso menos 45 días";
    }
  }

  if (!values.briefDescription || values.briefDescription === "")
    errors.briefDescription = "Requerido";

  //SOLO PARA CURSOS NO VIRTUALES
  if (values.methodology !== 1) {
    if (
      !values.maxVacancy ||
      values.maxVacancy === "" ||
      values.maxVacancy === 0
    )
      errors.maxVacancy = "Requerido";

    if (!values.convocatoryStage || values.convocatoryStage === "")
      errors.convocatoryStage = "Requerido";

    if (!values.category || values.category === "")
      errors.category = "Requerido";

    if (!values.academicYear || values.academicYear === "")
      errors.academicYear = "Requerido";
  }

  return errors;
};

const CoursesGeneral = ({
  course,
  setCourse,
  setOutVacancies,
  setMethodStat,
  methodStat,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //MANEJO CATEGORIAS MOODLE
  const [selectedMoodleCategory, setSelectedMoodleCategory] = useState(null);
  const [moodleCategories, setMoodleCategories] = useState([]);

  //Trae el detalle de los cursos de moodle
  const moodleCourses = useSelector(selectMoodleCourses);

  //Trae las tablas catalogo
  const typeTables = useSelector(selectMaintenances);

  //Trae el detalle de un cruso
  const courseDetail = useSelector(selectCourse);

  //Trae el detalle de un nuevo curso
  const newCourseState = useSelector(selectNewCourse);

  //Controla si se muestra o no un Dialog de confirmación
  const [openDialog, setOpenDialog] = useState(false);

  //Controla el manejo de autocomplete
  const [selectedMoodleCourse, setSelectedMoodleCourse] = useState(null);

  //Manejo del switch
  const [checked, setChecked] = useState(true);
  const methodologyState = (value) => {
    if (value) return 1;
    if (!value) return 2;
  };

  //Manejo del checkbox DOCU ADICIONAL
  const [closed, setClosed] = useState(false);
  const handleCheckboxChange = (event) => {
    setClosed(event.target.checked);
    setIsValid(false);
    formik.setFieldValue("additionalDocs", event.target.checked);
  };

  //Manejo del checkbox NO VALIDAR
  const [isValid, setIsValid] = useState(false);
  const handleCheckboxValidateChange = (event) => {
    setIsValid(event.target.checked);
  };

  const handleCancel = () => {
    navigate("/courses");
  };

  const formik = useFormik({
    initialValues: {
      courseId: parseInt(course),
      briefDescription: null,
      initDate: null,
      endDate: null,
      totalDuration: 0,
      maxVacancy: 0,
      category: null,
      methodology: methodologyState(checked),
      convocatoryStage: null,
      academicYear: null,
      typeOfCertificate: null,
      minimumAttendance: 0,
      state: 2,
      courseExternalId: null,
      initRegistracionDate: null,
      endRegistracionDate: null,
      additionalDocs: false,
    },
    validate,
    onSubmit: async () => {
      //Guardo los datos del formik
      const postModel = {};
      postModel.academicYear = formik.values.academicYear;
      postModel.briefDescription = formik.values.briefDescription;
      postModel.category = formik.values.category;
      postModel.convocatoryStage = formik.values.convocatoryStage;
      postModel.courseId = formik.values.courseId;
      postModel.courseExternalId = formik.values.courseExternalId;
      postModel.endDate = formik.values.endDate;
      postModel.initDate = formik.values.initDate;
      postModel.maxVacancy = formik.values.maxVacancy;
      postModel.methodology = formik.values.methodology;
      postModel.minimumAttendance = formik.values.minimumAttendance;
      postModel.state = formik.values.state;
      postModel.totalDuration = formik.values.totalDuration;
      postModel.typeOfCertificate = formik.values.typeOfCertificate;
      postModel.initRegistracionDate = formik.values.initRegistracionDate;
      postModel.endRegistracionDate = formik.values.endRegistracionDate;
      postModel.additionalDocs = formik.values.additionalDocs;

      //Formateo las fechas como las quiere la api
      postModel.initDate = FormatDate(formik.values.initDate, "DD/MM/YYYY", "YYYY-MM-DDTHH:mm:ss.sssZ") //prettier-ignore
      postModel.endDate = FormatDate(formik.values.endDate, "DD/MM/YYYY", "YYYY-MM-DDTHH:mm:ss.sssZ"); //prettier-ignore
      postModel.endRegistracionDate = FormatDate(formik.values.endRegistracionDate, "DD/MM/YYYY", "YYYY-MM-DDTHH:mm:ss.sssZ"); //prettier-ignore
      postModel.initRegistracionDate = FormatDate(formik.values.initRegistracionDate, "DD/MM/YYYY", "YYYY-MM-DDTHH:mm:ss.sssZ"); //prettier-ignore
      //Llamo a la api para crear un curso
      const model = postModel;
      const validateFees = !isValid; // pasar true o false para validar o no que existan fees asociadas a un curso antes de cambiarlo de estado
      const action = await dispatch(tryCreateCourse({ model, validateFees }));

      if (action.payload.data.isValid) {
        //Abro el Dialog de confirmación
        setOpenDialog(true);
        //Seteo el id recien generado en el estado del curso global //seteo si el curso es virtual a nivel global
        setMethodStat(action.payload.data.data.methodology);
        setCourse(action.payload.data.data.courseId);
        setOutVacancies(action.payload.data.data.outVacancies);
      }
    },
  });

  const fetchMoodleData = async () => {
    setSelectedMoodleCategory(null);
    const action = await dispatch(tryGetMoodleCategories());
    if (action.payload.data.isValid) {
      let categories = action.payload.data.data;
      categories.push({
        id: 0,
        name: "Todas",
      });

      setMoodleCategories(categories);
    }
  };

  useEffect(() => {
    fetchMoodleData();
  }, []);

  useEffect(() => {
    setSelectedMoodleCourse(null);
    dispatch(fetchTypeTable(7)); //TBLCATEGORYS
    dispatch(fetchTypeTable(53)); //TBLCERTIFICATE
    dispatch(fetchTypeTable(9)); //TBLCONVOCATORYSTAGE
    dispatch(fetchTypeTable(56)); //TBLNACADEMICYEASRS_ACADEMICYEAR
    dispatch(fetchTypeTable(54)); //TBLSTATCOURSES
    dispatch(tryGetMoodleCategories());
    dispatch(tryGetMoodleCourses());
  }, []);

  useEffect(() => {
    if (course != 0) {
      (async () => {
        await dispatch(tryGetCourseDetail(course));
      })();
    }
  }, [course]);

  useEffect(() => {
    if (course != 0) {
      formik.setValues({
        courseId: course,
        briefDescription: courseDetail.data.briefDescription ?? null,
        initDate: FormatDate(courseDetail.data.initDate, null, "DD/MM/YYYY"), //prettier-ignore
        endDate:  FormatDate(courseDetail.data.endDate, null, "DD/MM/YYYY"), //prettier-ignore
        initRegistracionDate: FormatDate(courseDetail.data.initRegistracionDate, null, "DD/MM/YYYY"), //prettier-ignore
        endRegistracionDate: FormatDate(courseDetail.data.endRegistracionDate, null, "DD/MM/YYYY"), //prettier-ignore
        totalDuration: courseDetail.data.totalDuration ?? 0,
        maxVacancy: courseDetail.data.maxVacancy ?? null,
        category: courseDetail.data.category ?? null,
        methodology: courseDetail.data.methodology,
        academicYear: courseDetail.data.academicYear ?? "",
        convocatoryStage: courseDetail.data.convocatoryStage ?? null,
        typeOfCertificate: courseDetail.data.typeOfCertificate ?? null,
        minimumAttendance: courseDetail.data.minimumAttendance ?? 0,
        state: courseDetail.data.state ?? 2,
        courseExternalId: courseDetail.data.courseExternalId,
        additionalDocs: courseDetail.data.additionalDocs,
      });
      courseDetail.data.additionalDocs && setClosed(true);
      courseDetail.data.methodology == 1 ? setChecked(true) : setChecked(false);
      setOutVacancies(courseDetail.data.outVacancies);
    }
  }, [courseDetail]);

  useEffect(() => {
    const moodleSelectedCourse = (moodleCourses?.data || []).find(
      (option) => option.id === courseDetail.data.courseExternalId
    );

    setSelectedMoodleCourse(moodleSelectedCourse);
  }, [moodleCourses.data]);

  const [value, setValue] = useState(30);

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  const onSwitchMode = (e) => {
    setChecked(e.target.checked);
    checked
      ? formik.setFieldValue("methodology", 2)
      : formik.setFieldValue("methodology", 1);
  };

  const handleChangeMoodleCategory = (event, newCategory) => {
    setSelectedMoodleCategory(newCategory);
  };

  const handleChangeVirtualCourse = (event, newCourse) => {
    setSelectedMoodleCourse(newCourse);
    if (newCourse) {
      formik.setFieldValue("courseExternalId", newCourse?.id);
      formik.handleChange(event);
      formik.setFieldValue("briefDescription", newCourse?.displayName);

      let initDate = moment.unix(newCourse?.startDate).format("DD/MM/YYYY");
      formik.setFieldValue("initDate", initDate);

      let endDate =
        newCourse?.endDate !== 0
          ? moment.unix(newCourse.endDate).format("DD/MM/YYYY")
          : "";

      formik.setFieldValue("endDate", endDate);
    }
  };

  return (
    <>
      {courseDetail.isLoading ? (
        <BackCoursesGeneralSkeleton />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            pt="0px"
            spacing={2}
            sx={{
              heigth: "auto",
              width: "auto",
              marginBottom: 3,
              marginTop: 0,
              paddingBottom: 10,
              marginLeft: 3,
              marginRight: 3,
            }}
            rowSpacing={6}
          >
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                Datos Generales
              </Typography>
              <Divider
                paddingTop={3}
                flexItem
                sx={{ bgcolor: "secondary.light" }}
              />
            </Grid>

            <Grid container xs={course == 0 ? 3 : 6} marginTop={3}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography marginBottom={1} style={{ fontWeight: "bold" }}>
                  Tipo de Curso
                </Typography>{" "}
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Presencial</Typography>
                  <Switch
                    checked={checked}
                    onChange={(e) => onSwitchMode(e)}
                    disabled={course != 0}
                  />
                  <Typography>Virtual</Typography>
                </Stack>
              </Grid>
            </Grid>

            {course == 0 && (
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    size="small"
                    disabled={
                      !checked ||
                      methodStat == 1 ||
                      formik.values.courseId !== 0
                    }
                    onChange={(e, newV) => handleChangeMoodleCategory(e, newV)}
                    value={selectedMoodleCategory}
                    loading={moodleCourses.isLoading}
                    options={moodleCategories.sort((a, b) => a.id - b.id) || []}
                    getOptionLabel={(option) => option.name ?? ""}
                    renderInput={(params) => (
                      <TextField {...params} label="Categorías Virtuales" />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option.id}>
                        {option.name}
                      </Box>
                    )}
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  labelId="briefDescription-label"
                  name="briefDescription"
                  label="Título"
                  variant="outlined"
                  size="small"
                  disabled={checked}
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.briefDescription}
                  type="text"
                  error={
                    formik.touched.briefDescription &&
                    formik.errors.briefDescription != null
                  }
                  helperText={
                    formik.touched.briefDescription &&
                    formik.errors.briefDescription
                  }
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            {!checked || methodStat == 2 ? (
              <Grid item xs={6}>
                <InputLabel id="category-label">
                  Categorías Presenciales
                </InputLabel>
                <Select
                  labelId="category-label"
                  name="category"
                  label="Categoría"
                  size="small"
                  value={formik.values.category}
                  variant="outlined"
                  fullWidth
                  disabled={checked}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  error={
                    formik.touched.category && formik.errors.category != null
                  }
                  helperText={formik.touched.category && formik.errors.category}
                >
                  {typeTables.TBLCATEGORYS
                    ? convertToSelectList(typeTables.TBLCATEGORYS.data).map(
                        (x) => (
                          <MenuItem key={x.value} value={x.value}>
                            {x.text}
                          </MenuItem>
                        )
                      )
                    : null}
                </Select>
              </Grid>
            ) : (
              course == 0 && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      size="small"
                      disabled={
                        !checked ||
                        methodStat == 1 ||
                        formik.values.courseId !== 0
                      }
                      onChange={(e, newV) => handleChangeVirtualCourse(e, newV)}
                      value={selectedMoodleCourse}
                      loading={moodleCourses.isLoading}
                      options={(moodleCourses?.data || []).filter(
                        (option) =>
                          option.visible === 1 &&
                          (selectedMoodleCategory?.id === 0
                            ? true
                            : option.categoryId === selectedMoodleCategory?.id)
                      )}
                      getOptionLabel={(option) => option.displayName ?? ""}
                      renderInput={(params) => (
                        <TextField {...params} label="Cursos Moodle" />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                          {option.displayName}
                        </Box>
                      )}
                    />
                    <FormHelperText
                      htmlFor="courseExternalId"
                      error={
                        formik.touched.courseExternalId &&
                        !!formik.errors.courseExternalId
                      }
                    >
                      {formik.errors.courseExternalId}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )
            )}
            {checked && formik.values.courseId !== 0 && <Grid item xs={6} />}
            <Grid container xs={6} marginTop={3}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography marginBottom={1} style={{ fontWeight: "bold" }}>
                  Tipo de Certificado
                </Typography>{" "}
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="typeOfCertificate"
                    name="typeOfCertificate"
                    onChange={formik.handleChange}
                    value={formik.values.typeOfCertificate}
                  >
                    {typeTables.TBLTYPEOFCERTIFICATES
                      ? convertToSelectList(
                          typeTables.TBLTYPEOFCERTIFICATES.data
                        ).map((x) => (
                          <FormControlLabel
                            value={x.value}
                            onChange={formik.handleChange}
                            control={<Radio size="small" />}
                            label={x.text}
                            disabled={checked}
                          />
                        ))
                      : null}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ marginLeft: 3, marginTop: 5 }}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                  Fechas asociadas al inicio y término del curso
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Se refiere a la Fecha de Inicio y Fin del curso, tomando en
                  cuenta que la fechas se incluyen como parte del curso.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <InputLabel id="courseType">Fecha de Inicio</InputLabel>
              <PatternFormat
                id="initDate"
                label=""
                variant="outlined"
                size="small"
                fullWidth
                autoComplete="off"
                disabled={checked && formik.values.courseId !== 0}
                onChange={(e) => {
                  const { value } = e.target;
                  formik.setFieldValue("initDate", value);
                }}
                value={formik.values.initDate}
                error={
                  formik.touched.initDate && formik.errors.initDate != null
                }
                helperText={formik.touched.initDate && formik.errors.initDate}
                customInput={TextField}
                format="##/##/####"
                mask="_"
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel id="courseType">Fecha de Fin</InputLabel>
              <PatternFormat
                id="endDate"
                label=""
                variant="outlined"
                size="small"
                autoComplete="off"
                fullWidth
                disabled={checked && formik.values.courseId !== 0}
                onChange={(e) => {
                  const { value } = e.target;
                  formik.setFieldValue("endDate", value);
                }}
                value={formik.values.endDate}
                error={formik.touched.endDate && formik.errors.endDate != null}
                helperText={formik.touched.endDate && formik.errors.endDate}
                customInput={TextField}
                format="##/##/####"
                mask="_"
              />
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="initRegistracionDate-label">
                Inicio Inscripción
              </InputLabel>
              <PatternFormat
                id="initRegistracionDate"
                label=""
                variant="outlined"
                size="small"
                fullWidth
                autoComplete="off"
                disabled={
                  checked &&
                  formik.values.courseId !== 0 &&
                  !formik.values.initRegistracionDate
                }
                onChange={(e) => {
                  const { value } = e.target;
                  formik.setFieldValue("initRegistracionDate", value);
                }}
                value={formik.values.initRegistracionDate}
                error={
                  formik.touched.initRegistracionDate &&
                  formik.errors.initRegistracionDate != null
                }
                helperText={
                  formik.touched.initRegistracionDate &&
                  formik.errors.initRegistracionDate
                }
                customInput={TextField}
                format="##/##/####"
                mask="_"
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel id="endRegistracionDate-label">
                Fin Inscripción
              </InputLabel>
              <PatternFormat
                id="endRegistracionDate"
                label=""
                variant="outlined"
                size="small"
                autoComplete="off"
                fullWidth
                disabled={
                  checked &&
                  formik.values.courseId !== 0 &&
                  !formik.values.endRegistracionDate
                }
                onChange={(e) => {
                  const { value } = e.target;
                  formik.setFieldValue("endRegistracionDate", value);
                }}
                value={formik.values.endRegistracionDate}
                error={
                  formik.touched.endRegistracionDate &&
                  formik.errors.endRegistracionDate != null
                }
                helperText={
                  formik.touched.endRegistracionDate &&
                  formik.errors.endRegistracionDate
                }
                customInput={TextField}
                format="##/##/####"
                mask="_"
              />
            </Grid>

            <Grid item xs={6} align="center">
              <img
                style={{ with: 260, height: 277, marginTop: 75 }}
                src={
                  process.env.PUBLIC_URL +
                  "/images/1b2730a4de2129fd3166ea63181c3839.png"
                }
              />
            </Grid>

            <Grid container xs={6}>
              <Grid item xs={12}>
                <Typography
                  style={{ fontWeight: "bold", fontSize: 14 }}
                  sx={{ marginLeft: 3, marginTop: 3, marginRight: 3 }}
                >
                  Otros
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ marginLeft: 3, marginRight: 3 }}>
                  Incluya la cantidad de Vacantes disponibles para el curso,
                  mismas que irán agotándose a medida que los Estudiantes se Pre
                  Inscriban/Inscriban en el curso, así como las horas de
                  duración del curso que deberán ir en función de las fechas de
                  duración y plan de asistencia. El porcentaje mínimo de clases
                  para aprobar se refiere a la cantidad mínima de horas de clase
                  que el estudiante deberá tener marcadas con asistencia por el
                  profesor para poder aprobar el Curso en tratamiento
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {" "}
                <Typography
                  style={{ fontWeight: "bold", fontSize: 13 }}
                  sx={{ marginLeft: 3, marginTop: 3, marginRight: 3 }}
                >
                  Vacantes máximas
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Slider
                  id="maxVacancySlider"
                  name="maxVacancySlider"
                  sx={{ marginLeft: 3, marginTop: 1, marginRight: 3 }}
                  value={formik.values.maxVacancy}
                  max={200}
                  onChange={(e) => {
                    const { value } = e.target;

                    formik.setFieldValue("maxVacancy", value);
                  }}
                  aria-labelledby="input-slider"
                  error={
                    formik.touched.maxVacancy &&
                    formik.errors.maxVacancy != null
                  }
                />
                <Typography variant="caption" color="error">
                  {formik.touched.maxVacancy && formik.errors.maxVacancy}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Input
                  id="maxVacancyInput"
                  name="maxVacancyInput"
                  sx={{ marginLeft: 6, marginTop: 3, marginRight: 3 }}
                  size="small"
                  value={formik.values.maxVacancy}
                  error={
                    formik.touched.maxVacancy &&
                    formik.errors.maxVacancy != null
                  }
                  helperText={
                    formik.touched.maxVacancy && formik.errors.maxVacancy
                  }
                  onChange={(e) => {
                    const { value } = e.target;

                    formik.setFieldValue("maxVacancy", value);
                  }}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {" "}
                <Typography
                  style={{ fontWeight: "bold", fontSize: 13 }}
                  sx={{ marginLeft: 3, marginTop: 3, marginRight: 3 }}
                >
                  Horas de duración
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Slider
                  id="hoursSlider"
                  name="totalDurationSlider"
                  sx={{ marginLeft: 3, marginTop: 1, marginRight: 3 }}
                  value={formik.values.totalDuration}
                  max={4000}
                  onChange={(e) => {
                    const { value } = e.target;

                    formik.setFieldValue("totalDuration", value);
                  }}
                  aria-labelledby="input-slider"
                  error={
                    formik.touched.totalDuration &&
                    formik.errors.totalDuration != null
                  }
                />
                <Typography variant="caption" color="error">
                  {formik.touched.totalDuration && formik.errors.totalDuration}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Input
                  id="hoursInput"
                  name="totalDurationInput"
                  sx={{ marginLeft: 6, marginTop: 3, marginRight: 3 }}
                  size="small"
                  value={formik.values.totalDuration}
                  onChange={(e) => {
                    const { value } = e.target;

                    formik.setFieldValue("totalDuration", value);
                  }}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Typography
                  style={{ fontWeight: "bold", fontSize: 13 }}
                  sx={{ marginLeft: 3, marginTop: 3, marginRight: 3 }}
                >
                  Porcentaje mínimo de clases para aprobar (%)
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Slider
                  id="minClassesPassSlider"
                  name="minimumAttendanceSlider"
                  sx={{ marginLeft: 3, marginTop: 1, marginRight: 3 }}
                  value={formik.values.minimumAttendance}
                  max={100}
                  onChange={(e) => {
                    const { value } = e.target;

                    formik.setFieldValue("minimumAttendance", value);
                  }}
                  aria-labelledby="input-slider"
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  id="minClassesPassInput"
                  name="minimumAttendanceInput"
                  sx={{ marginLeft: 6, marginTop: 3, marginRight: 3 }}
                  size="small"
                  value={formik.values.minimumAttendance}
                  onChange={(e) => {
                    const { value } = e.target;

                    formik.setFieldValue("minimumAttendance", value);
                  }}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ marginLeft: 3 }}>
              <Grid item xs={12}>
                <Typography
                  style={{ fontWeight: "bold", fontSize: 14, paddingTop: 30 }}
                >
                  Datos de Convocatoria
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Incluya el año asociado al Ciclo lectivo así como el
                  cuatrimestre asociado a la etapa de convocatoria desde UTEDYC
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="academicYear">Ciclo Lectivo</InputLabel>
              <Select
                labelId="academicYear-label"
                name="academicYear"
                label="Ciclo Lectivo"
                variant="outlined"
                size="small"
                fullWidth
                value={formik.values.academicYear}
                onChange={formik.handleChange}
                error={
                  formik.touched.academicYear &&
                  formik.errors.academicYear != null
                }
                helperText={
                  formik.touched.academicYear && formik.errors.academicYear
                }
                autoComplete="off"
              >
                {typeTables.TBLACADEMICYEARS
                  ? convertToSelectList(typeTables.TBLACADEMICYEARS.data).map(
                      (x) => (
                        <MenuItem key={x.value} value={x.value}>
                          {x.text}
                        </MenuItem>
                      )
                    )
                  : null}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="convocatoryStage">Etapa</InputLabel>
              <Select
                labelId="convocatoryStage-label"
                name="convocatoryStage"
                value={formik.values.convocatoryStage}
                label="Ciclo Lectivo"
                variant="outlined"
                size="small"
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.convocatoryStage &&
                  formik.errors.convocatoryStage != null
                }
                helperText={
                  formik.touched.convocatoryStage &&
                  formik.errors.convocatoryStage
                }
                autoComplete="off"
              >
                {typeTables.TBLCONVOCATORYSTAGES
                  ? convertToSelectList(
                      typeTables.TBLCONVOCATORYSTAGES.data
                    ).map((x) => (
                      <MenuItem key={x.value} value={x.value}>
                        {x.text}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                Estado del Curso
              </Typography>

              <Typography>
                El Estado del Curso permitirá que el mismo pueda
                disponibilizarse para todos aquellos Estudiantes que quieran
                participar de él. El estado Activo es aquél que lo habilita para
                usarlo en Pre Inscripciones, en Proceso de Instalación podrá
                usarse mientras está en un estado intermedio de configuración, e
                Inactivo es aquél que se usará cuando no se quiera
                disponibilizar el curso para los Estudiantes. Recuerda que un
                Curso que tiene Estudiantes vinculados no debería ser
                modificado.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="state">Estado</InputLabel>
                <Select
                  labelId="state-label"
                  name="state"
                  label="Estado"
                  variant="outlined"
                  size="small"
                  fullWidth
                  defaultValue={course == 0 ? 2 : undefined}
                  disabled={course == 0}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  Complete="off"
                >
                  {typeTables.TBLSTATCOURSES
                    ? convertToSelectList(typeTables.TBLSTATCOURSES.data).map(
                        (x) => (
                          <MenuItem key={x.value} value={x.value}>
                            {x.text}
                          </MenuItem>
                        )
                      )
                    : null}
                </Select>
              </FormControl>
            </Grid>
            {!checked && (
              <>
                <Grid item xs={6} marginTop={-5}>
                  {!closed ? (
                    <ContentPasteOff color="secondary" fontSize="large" />
                  ) : (
                    <ContentPaste color="secondary" fontSize="large" />
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={closed}
                        onChange={handleCheckboxChange}
                        fontSize="large"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    }
                    label={
                      <Alert
                        severity="info"
                        style={{
                          marginTop: "1rem",
                          maxWidth: "100%",
                          marginBottom: 13,
                        }}
                      >
                        <AlertTitle>Documentación Adicional</AlertTitle>
                        Utiliza esta casilla para indicar si el curso requerirá
                        documentación adicional
                        <br /> por parte de los estudiantes. [Ej.Curso de
                        Guardavidas]
                      </Alert>
                    }
                    color="secondary"
                    style={{ color: "black", marginLeft: 2, marginTop: 5 }}
                  />
                </Grid>
                <Grid item xs={6} marginTop={-5}>
                  {!isValid ? (
                    <CheckCircle color="secondary" fontSize="large" />
                  ) : (
                    <Unpublished color="secondary" fontSize="large" />
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isValid}
                        disabled={closed}
                        onChange={handleCheckboxValidateChange}
                        fontSize="large"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    }
                    label={
                      <Alert
                        severity="warning"
                        style={{
                          marginTop: "1rem",
                          maxWidth: "100%",
                          marginBottom: 13,
                        }}
                      >
                        <AlertTitle>No Validar Costos</AlertTitle>
                        Utiliza esta casilla para crear un curso sin costos.
                        <br />
                        Esta opción no estará disponible si el curso requiere{" "}
                        documentación adicional.
                      </Alert>
                    }
                    color="secondary"
                    style={{ color: "black", marginLeft: 2, marginTop: 5 }}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={6} align="right">
              <Button variant="contained" onClick={() => formik.handleSubmit()}>
                {newCourseState.isLoading && <Spinner color="primary" />}
                Guardar
              </Button>
            </Grid>
            <Grid item xs={6} align="left">
              <Button variant="outlined" onClick={() => handleCancel()}>
                Cancelar
              </Button>
            </Grid>
            {openDialog && (
              <>
                <Dialog
                  open={openDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"La información ha sido guardada con éxito"}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} autoFocus>
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>{" "}
              </>
            )}
            {newCourseState.errors && newCourseState.errors.length > 0 && (
              <ModalMessages errors={newCourseState.errors} />
            )}
          </Grid>
        </form>
      )}
    </>
  );
};

export default CoursesGeneral;
