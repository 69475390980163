const getQueryString = (queryModel) => {
  return Object.keys(queryModel)
    .map((key) => {
      if (queryModel[key] === undefined) return "";

      return `${key}=${queryModel[key]}`;
    })
    .join("&");
};

export default getQueryString;
