import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStudentCoursesSummary,
  tryGetStudentCoursesSummary,
} from "../../store/slices/student-courses";
import { selectCurrentUser } from "../../store/slices/currentUser";
import {
  Alert,
  AlertTitle,
  Chip,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import StudentCoursesSummarySkeleton from "./skeleton";
import ModalMessages from "../layout/modal-messages";
import { Container } from "@mui/system";
import moment from "moment";

const StudentCoursesSummary = ({ businessId }) => {
  const dispatch = useDispatch();
  const { roleId } = useSelector(selectCurrentUser);

  const coursesSummary = useSelector(selectStudentCoursesSummary);

  useEffect(() => {
    dispatch(tryGetStudentCoursesSummary(businessId));
  }, []);

  //Verifica si se muestra el record de asistencia
  const perfectAssistance = () => {
    let valuesSplitted = coursesSummary.summary.split("/");
    let result = valuesSplitted[0] / valuesSplitted[1];
    if (result === 1) return "Record de Asistencia";
    return "";
  };

  return (
    <Container>
      {!coursesSummary.isLoading ? (
        <Grid sx={{ pt: 3, pb: 3 }} container spacing={3}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 20 }}>
              <strong style={{ fontWeight: "bold" }}>Resumen</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="warning">
              <AlertTitle>Asistencia</AlertTitle>
              {roleId !== 1 ? (
                <>
                  Por favor, verificar si tienes asistencia aún no registrada en
                  alguno de los Cursos a los que estás atendiendo.
                </>
              ) : (
                <>
                  Por favor, verificar si el alumno tiene asistencia aún no
                  registrada en alguno de los Cursos a los que está atendiendo.
                </>
              )}{" "}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Última cursada{" "}
              {coursesSummary.lastEdition
                ? moment(coursesSummary.lastEdition).format("ll")
                : "Sin información"}
            </Typography>
            <Typography sx={{ color: "grey" }}>
              {coursesSummary.descriptionCourseLastEdition
                ? coursesSummary.descriptionCourseLastEdition
                : "Sin información"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Próxima cursada{" "}
              {coursesSummary.nextToStudy
                ? moment(coursesSummary.nextToStudy).format("ll")
                : "Sin información"}
            </Typography>
            <Typography sx={{ color: "grey" }}>
              {coursesSummary.descriptionCourseNextToStudy
                ? coursesSummary.descriptionCourseNextToStudy
                : "Sin información"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Clases hasta la fecha: {coursesSummary.classesTaught ?? "0"} Total
              de Clases: {coursesSummary.totalClasses ?? "0"}
            </Typography>
            <Typography>
              <strong>{coursesSummary.summary ?? "0"}</strong> clases
              completadas y clases impartidas{" "}
              {coursesSummary.studentConditionCoursesDescription !== "" && (
                <Chip
                  label={coursesSummary.studentConditionCoursesDescription}
                  color={
                    coursesSummary.conditioningType === 1 ? "success" : "error"
                  }
                  size="small"
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Porcentaje de Asistencia Verificada: %{coursesSummary.progress}
            </Typography>
            <LinearProgress
              sx={{
                fontSize: 60,
                width: "400px",
                height: "10px",
              }}
              variant="determinate"
              value={coursesSummary.progress}
            />
            <Typography sx={{ color: "grey" }}>
              {perfectAssistance()}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <StudentCoursesSummarySkeleton />
      )}
      {coursesSummary.errors && coursesSummary.errors.length > 0 && (
        <ModalMessages errors={coursesSummary.errors} />
      )}
    </Container>
  );
};

export default StudentCoursesSummary;
