import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as apiStudents from "../../../services/apiStudents";

export const studentCuilSlice = createSlice({
  name: "studentCuil",
  initialState: {
    item: {
      data: "",
      isValid: "",
      errors: [],
      isLoading: false,
      status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    },
  },

  reducers: {
    setStudentCuil: (state, { payload }) => {
      state.item.data = payload;
    },
    setLoading: (state, { payload }) => {
      state.item.isLoading = payload;
    },
    setCleanErrorsStudentCuil: (state) => {
      state.item.errors = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(tryStudentCuil.pending, (state) => {
      state.item.status = "loading";
      state.item.isLoading = true;
      state.item.errors = [];
    });
    builder.addCase(tryStudentCuil.fulfilled, (state, { payload }) => {
      state.item.status = "succeeded";

      const errors = payload.data.isValid ? payload.data.errors : [];
      state.item.errors = errors;

      if (payload.data.isValid === true) {
        state.item = payload.data;
      }

      state.item.isLoading = false;
    });
    builder.addCase(tryStudentCuil.rejected, (state, { payload }) => {
      state.item.status = "failed";
      let errors = [];

      // Distinto de "No autorizado"
      if (payload.status !== 401) {
        if (payload.data.isValid === false) errors = payload.data.errors;

        if (payload.data.isValid === undefined)
          errors = [
            {
              errorCode: "99999",
              errorMessage: "Error consulte al administrador.",
            },
          ];
      }

      // Token expiró
      if (payload.status === 401)
        errors = [{ errorCode: "HTTP401", errorMessage: "Su sesión expiró" }];

      state.list.errors = errors;
      state.list.isLoading = false;
    });
  },
});

export const { setStudentCuil, setLoading, setCleanErrorsStudentCuil } =
  studentCuilSlice.actions;

export default studentCuilSlice.reducer;

export const tryCleanErrorsStudentCuil = () => (dispatch) => {
  dispatch(setCleanErrorsStudentCuil());
};

export const selectStudentCuil = (state) => state.studentCuil.item;

export const tryStudentCuil = createAsyncThunk(
  "studentCuil/tryStudentCuil",
  async (userId, { rejectWithValue }) => {
    try {
      // getState // devuelve el estado

      const response = await apiStudents.getCuilById(userId);
      return response;
    } catch (err) {
      console.error(err);
      // Se envia de esta forma para poder recuperar en el "reject" el http.status
      return rejectWithValue(err);
    }
  }
);
