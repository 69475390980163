import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tryLogin, selectCurrentUser } from "../../../store/slices/currentUser";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
  InputAdornment,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import AlertMessages from "../../layout/alert-messages";
import Spinner from "../../layout/spinner";
import HelperMail from "../../layout/helper-mail";
import animationLogin from "../../../animations/38435-register.json";
import Lottie from "lottie-react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const validate = (values) => {
  const errors = {};
  if (!values.username) errors.username = "Requerido";

  if (!values.password) errors.password = "Requerido";

  return errors;
};

const RightSection = (setStudentId) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { isLoading, errors } = useSelector(selectCurrentUser);

  const formik = useFormik({
    initialValues: {
      username: undefined,
      password: undefined,
    },
    validate,
    onSubmit: async (values) => {
      const action = await dispatch(tryLogin(values));

      if (action.payload.data.isValid) {
        setStudentId(values.studentId);
        // setIsOpen(false);
        navigation("/teachers");
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Box sx={{ width: "100%" }}>
      <Lottie animationData={animationLogin} />
      <Typography
        variant="h5"
        align="center"
        color="primary"
        sx={{
          fontWeight: "700",
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 2,
          fontSize: 16,
        }}
      >
        Sistema Integral de Formación
      </Typography>
      <Typography
        variant="h6"
        align="center"
        color="primary"
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 2,
          fontSize: 14,
        }}
      >
        Ingreso
      </Typography>
      <Container
        fixed
        sx={{
          paddingRight: 2,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <InputLabel htmlFor="outlined-adornment-user">Usuario</InputLabel>
              <OutlinedInput
                name="username"
                label="Usuario"
                variant="outlined"
                size="small"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.username}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="outlined-adornment-password">
                Contraseña
              </InputLabel>
              <OutlinedInput
                name="password"
                label="Contraseña"
                variant="outlined"
                size="small"
                fullWidth
                type={showPassword ? "text" : "password"}
                onChange={formik.handleChange}
                value={formik.values.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onKeyDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ marginTop: 2, marginBottom: 2 }}
                fullWidth
                disabled={!formik.isValid || isLoading}
                type="submit"
              >
                {isLoading && <Spinner color="primary" />}
                Iniciar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      {errors && errors.length > 0 && (
        <AlertMessages type="error" data={errors} />
      )}
      <Typography
        color="primary"
        align="center"
        variant="subtitle2"
        sx={{ paddingTop: 2 }}
      >
        <Link href="/signup">Registrarme</Link>
      </Typography>
      <Typography
        color="primary"
        align="center"
        variant="subtitle2"
        sx={{ paddingTop: 5, paddingBottom: 2 }}
      >
        ¿Olvidaste tu contraseña?
      </Typography>
      <Typography
        color="primary"
        align="center"
        variant="subtitle2"
        sx={{ paddingTop: 5, paddingBottom: 2 }}
      >
        <HelperMail label="¿Tienes alguna duda? Envíanos un mail" />
      </Typography>
    </Box>
  );
};

export default RightSection;
